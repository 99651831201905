import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { Form, Divider, Row, Input, Icon, Button, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthLayout from '../AuthLayout';
import APIService from '../../../shared/api';

const Container = styled.div`
  background: white;
  width: 30%;
  min-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }
`;

type Props = {} & FormComponentProps & RouteComponentProps;

const ForgotPassword: React.FC<Props> = ({ form, history }) => {
  const { getFieldDecorator, getFieldError } = form;
  const [showPasswordField, setShowPasswordField] = React.useState(false);
  const [confirmDirty, setConfirmDirty] = React.useState(false);

  const [isSubmitting, setSubmitting] = React.useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setSubmitting(true);
        if (!showPasswordField) {
          APIService.authService
            .requestPasswordReset(values.email)
            .then(() => {
              notification.success({ message: 'Check your email for password reset code!' });
              setShowPasswordField(true);
            })
            .catch(err => {
              console.error(err);
              notification.error({ message: 'Something went wrong!' });
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          APIService.authService
            .resetPassword(values.email, values.code.trim(), values.password)
            .then(() => {
              notification.success({ message: 'Password updated!' });
              history.push('/auth/log-in');
            })
            .catch(err => {
              console.error(err);
              notification.error({ message: 'Something went wrong!' });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }
    });
  };

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (_: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback("Password doesn't match!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (_: any, value: any, callback: any) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  return (
    <AuthLayout title="Forgot Password">
      <Container>
        <Form onSubmit={handleSubmit} layout="horizontal">
          <Divider orientation={'left'}>Enter email to recover your password</Divider>
          <Row>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Email must be valid' },
                ],
              })(
                <Input
                  formNoValidate={true}
                  prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="email"
                  placeholder="Email"
                />,
              )}
            </Form.Item>
          </Row>
          {showPasswordField && (
            <Row>
              <Form.Item>
                {getFieldDecorator('code', {
                  rules: [{ required: true, message: 'Verification is required' }],
                })(
                  <Input
                    formNoValidate={true}
                    prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Enter verification code"
                  />,
                )}
              </Form.Item>
              <Inline>
                <Form.Item hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        pattern: RegExp(
                          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
                        ),
                        message: (
                          <>
                            <Icon
                              type="question-circle"
                              theme="filled"
                              style={{ marginRight: 5 }}
                            />
                            <span>Password is invalid</span>
                          </>
                        ),
                      },
                      {
                        validator: validateToNextPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Enter New Password"
                    />,
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('confirmPassword', {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      onBlur={handleConfirmBlur}
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Enter Confirm Password"
                    />,
                  )}
                </Form.Item>
              </Inline>
              {!!getFieldError('password') && (
                <Inline>
                  Make sure it's at least 8 characters including 1 number, 1 lowercase, 1 uppercase
                  letter and 1 special character.
                </Inline>
              )}
            </Row>
          )}
          <Row type={'flex'} style={{ marginTop: 16 }}>
            <Button
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ flex: 2 }}
            >
              Submit
            </Button>
            <Link to="/auth/log-in" style={{ flex: 1 }}>
              <Button type="link" style={{ width: '100%' }}>
                Cancel
              </Button>
            </Link>
          </Row>
        </Form>
      </Container>
    </AuthLayout>
  );
};

export default Form.create<Props>({ name: 'ForgotPassword' })(withRouter(ForgotPassword));
