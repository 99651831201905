import { AxiosPromise, AxiosInstance } from 'axios';
import { sanatizeToSnakeCase } from '../../strings';

export interface QueryResponseType {
  id: number;
  name: string;
  created_date: string;
  updated_date: string;
  json: JSON;
}

export interface QueryService {
  fetchAllQueries: () => AxiosPromise<QueryResponseType[]>;
  fetchQueryById: (id: number) => AxiosPromise<QueryResponseType>;
  createQuery: (name: string, data: any) => AxiosPromise<void>;
  deleteQuery: (id: number) => AxiosPromise<void>;
  shareQuery: (id: number, shareTo: number[], shareAll: boolean) => AxiosPromise<void>;
}

export const QueryService = (request: AxiosInstance): QueryService => {
  return {
    fetchAllQueries: () => request.get('/query'),
    fetchQueryById: id => request.get(`/query/${id}`),
    createQuery: (name, data) =>
      request.post('/query', {
        name,
        json: sanatizeToSnakeCase(data),
      }),
    deleteQuery: id => request.delete(`/query/${id}`),
    shareQuery: (id, shareTo, shareAll) =>
      request.post(`/query/${id}/share`, {
        user_ids: shareTo,
        all: shareAll,
      }),
  };
};
