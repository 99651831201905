import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Collapse, Icon, Spin, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';
import { ArticlesResponse } from '../../shared/api/services/content-service';
import MainLayout from '../../shared/components/MainLayout';

const { Title } = Typography;
const { Panel } = Collapse;

const Wrapper = styled('div')`
  background: white;
  display: flex;
  width: 100%;
  padding: 10px 25px;
  padding-top: 0;
  flex-direction: column;
  height: 100%;
  min-height: -webkit-fill-available;
`;
const Container = styled.div`
  display: flex;
  flex-grow: 2;
  height: 100%;
  margin: 20px auto;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 40px;
  padding-top: 20px;
  padding-bottom: 0;
  overflow-y: auto;
  & .ant-collapse-content {
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 25px;
    border: 0;
  }
`;

const Header = styled(Title)`
  position: relative;
  text-align: center;
  padding: 10px 0;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`;

const ArticleContainer = styled.div``;

interface Props {}

/**
 * Component rendering all Articles for the Help and FAQs link
 * @constructor
 */
const HelpAndFaqView: React.FC<Props> = () => {
  const [articleData, setArticleData] = useState<{ [key: string]: string }>({});
  const {
    data: articlesData,
    invoke: invokeArticlesData,
    loading: articlesLoading,
  } = useServiceState(APIService.contentService.fetchArticles);

  const handleChange = async (key: string | string[]) => {
    if (typeof key === 'string') {
      key = [key];
    }
    key.forEach(async (item: string) => {
      // No api call if panel is previously loaded (opened)
      if (articleData[item] === undefined || articleData[item].trim() === '') {
        const { data } = await APIService.contentService.fetchArticleDetail(item);
        setArticleData((prevArticles: { [key: string]: string }) => {
          return {
            ...prevArticles,
            [item]: data.content,
          };
        });
      }
    });
  };
  useEffect(() => {
    invokeArticlesData();
  }, [invokeArticlesData]);

  return (
    <MainLayout>
      <Container>
        <Wrapper>
          <Header level={4}>Help And FAQs</Header>
          <Spin spinning={articlesLoading}>
            <Collapse
              onChange={handleChange}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              {!_.isEmpty(articlesData) &&
                articlesData.map((article: ArticlesResponse) => {
                  return (
                    <Panel
                      header={article.name}
                      key={String(article.id)}
                    >
                      <ArticleContainer
                        dangerouslySetInnerHTML={{ __html: articleData[article.id] }}
                      />
                    </Panel>
                  );
                })}
            </Collapse>
          </Spin>
        </Wrapper>
      </Container>
    </MainLayout>
  );
};

export default withRouter(HelpAndFaqView);
