import { AxiosInstance, AxiosPromise } from 'axios';

export type HistoricalSourceType = 'fund_list' | 'universe_list' | 'portfolio_model' | 'peer_group';
export type HistoricalType = 'aum' | 'ror';
export type HistoricalPeriodType = 'monthly' | 'yearly';

export type HistoricalDataListRecord = {
  id: number;
  name: string;
  type: HistoricalSourceType;
};

export type HistoricalDataType = {
  date: string;
  value: number;
};

export type HistoricalDataRecord = {
  user_fund_id: string;
  fund_id: string;
  fund_name: string;
  firm_name: string;
  strategy_name?: string;
  sub_strategy_name?: string;
  regional_focus?: string;
  inception_date?: string;
  historical_data: HistoricalDataType[];
};

export type HistoricalDataResponse = {
  current_page: number;
  total_pages: number;
  total_count: number;
  data: HistoricalDataRecord[];
  min_date: string;
  max_date: string;
};

export type HistoricalDataParams = {
  sourceType: HistoricalSourceType;
  dataType: HistoricalType;
  period: HistoricalPeriodType;
  entityId: number;
  page?: number;
};

export interface HistoricRorAumService {
  fetchHistoricalDataList: () => AxiosPromise<HistoricalDataListRecord[]>;
  fetchHistoricalData: (params: HistoricalDataParams) => AxiosPromise<HistoricalDataResponse>;
  exportHistoricalData: (params: HistoricalDataParams) => AxiosPromise<string>;
}

export const HistoricRorAumService = (request: AxiosInstance): HistoricRorAumService => {
  return {
    fetchHistoricalDataList: () => request.get('/historical-data/lists'),
    fetchHistoricalData: params =>
      request.post('/historical-data', {
        source_type: params.sourceType,
        data_type: params.dataType,
        period: params.period,
        entity_id: params.entityId,
        page: params.page,
      }),
    exportHistoricalData: params =>
      request.post(
        '/historical-data/export',
        {
          source_type: params.sourceType,
          data_type: params.dataType,
          period: params.period,
          entity_id: params.entityId,
        },
        {
          responseType: 'blob',
        },
      ),
  };
};
