import React from 'react';
import styled from 'styled-components';
import { Layout, Button, Icon, Select, Row } from 'antd';
import RankingForm from './RankingForm';
import { RankingParams } from '../../shared/api/services/ranking.service';
import { useServiceState } from '../../shared/hooks/useServiceState';
import { DisclaimerResponse } from '../../shared/api/models/ResponsesTypes';
import APIService from '../../shared/api';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Container = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  overflow-x: auto;

  &.show-all {
    .ranking-table {
      min-width: 25%;
    }
  }
`;

const DisclaimerContainer = styled(PerfectScrollbar)`
  min-height: 80px;
  max-height: 80px;
  padding: 0 20px;
  font-size: 9px;
`;

type Props = {
  params: Partial<RankingParams>;
  updateParams: (params: Partial<RankingParams>) => void;
  showAllFilter?: boolean;
  pageSize: number;
  setPageSize: (size: number) => void;
};

const RankingLayout: React.FC<Props> = ({
  params,
  updateParams,
  showAllFilter,
  pageSize,
  setPageSize,
  children,
}) => {
  const [showFilter, setShowFilter] = React.useState(true);
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);

  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  React.useEffect(() => {
    invokeDisclaimerData('ranking');
  }, [invokeDisclaimerData]);

  return (
    <Container>
      <Layout.Sider
        title="Filter"
        theme="light"
        collapsible
        collapsed={!showFilter}
        collapsedWidth={0}
        trigger={null}
        width={300}
        style={{
          position: 'relative',
          borderRight: showFilter ? '1px solid #d9d9d9' : 'none',
          boxSizing: 'border-box',
        }}
      >
        <PerfectScrollbar>
          <RankingForm
            showAllFilter={!!showAllFilter}
            params={params}
            updateParams={updateParams}
          />
        </PerfectScrollbar>
      </Layout.Sider>
      <PerfectScrollbar
        style={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'auto' }}
      >
        <Row type="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            style={{ margin: '8px 16px', alignSelf: 'flex-start' }}
            onClick={() => setShowFilter(!showFilter)}
          >
            <Icon type="filter" />
          </Button>
          <div style={{ marginRight: 16 }}>
            Show top{'  '}
            <Select<number> value={pageSize} onChange={setPageSize}>
              <Select.Option key={10}>10</Select.Option>
              <Select.Option key={25}>25</Select.Option>
              <Select.Option key={50}>50</Select.Option>
            </Select>
          </div>
        </Row>
        <Wrapper className={!showFilter ? 'show-all' : undefined}>{children}</Wrapper>
        <Button
          type="link"
          style={{ alignSelf: 'flex-end', fontSize: '12px' }}
          onClick={() => setShowDisclaimer(!showDisclaimer)}
        >
          {showDisclaimer ? 'Hide Disclaimer' : 'Show Disclaimer'}
        </Button>
        {showDisclaimer && (
          <DisclaimerContainer
            dangerouslySetInnerHTML={{
              __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
            }}
          />
        )}
      </PerfectScrollbar>
    </Container>
  );
};

export default RankingLayout;
