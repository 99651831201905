import React from 'react';
import { Modal, Checkbox, Select, Row } from 'antd';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';

interface Props {
  visible: boolean;
  onRequestClose: () => void;
  onSubmit: (isAll: boolean, userIds?: number[]) => void;
}

/**
 * ShareModal - Generic modal for sharing to other users
 * @param visible [boolean] - Modal visibility flag
 * @param onRequestClose - callback to call when modal is being closed
 * @param onSubmit - callback to call when modal is being submitted
 */
const ShareModal: React.FC<Props> = ({ visible, onRequestClose, onSubmit }) => {
  const [isShareToAll, setShareToAll] = React.useState(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>();

  const { data: userData, invoke: invokeFetchUser, loading: userLoading } = useServiceState(
    APIService.clientService.fetchAllUsers,
  );

  React.useEffect(() => {
    invokeFetchUser();
  }, [invokeFetchUser]);

  return (
    <Modal
      title="Share To..."
      visible={visible}
      onCancel={onRequestClose}
      onOk={() => onSubmit(isShareToAll, isShareToAll ? [] : selectedUserIds)}
      okText="Share"
    >
      <Row>
        <Checkbox checked={isShareToAll} onChange={e => setShareToAll(e.target.checked)}>
          Share to all users
        </Checkbox>
      </Row>
      <div style={{ margin: '8px 0' }}>or</div>
      <Row>
        <Select
          disabled={isShareToAll}
          loading={userLoading}
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Selecte 1 or more user to share"
          allowClear
          onChange={(values: number[]) => setSelectedUserIds(values)}
          showSearch
          filterOption={(input, option) => {
            if (!option || !option.props || !option.props.children) {
              return false;
            }
            return (
              (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        >
          {userData &&
            userData.length >= 0 &&
            userData.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.email}
              </Select.Option>
            ))}
        </Select>
      </Row>
    </Modal>
  );
};

export default ShareModal;
