import React from 'react';
import { Select } from 'antd';
import { PortfolioModelRecord } from '../api/models/ResponsesTypes';

interface Props {
  onChange?: (selected: { key: string; label: string }) => void;
  data?: PortfolioModelRecord[];
  selectedId?: string;
}

const { Option } = Select;

/**
 * This will create a select dropdown for the Model List
 * @param data
 * @param onChange
 * @param selectedId
 * @constructor
 */
const PortfolioModelSelect: React.FC<Props> = ({ data, onChange, selectedId }) => {
  const selectedList = data && data.find(item => item.id === Number(selectedId));
  return (
    <Select
      labelInValue
      placeholder="Select Portfolio Model"
      style={{ width: '100%' }}
      showSearch
      value={
        selectedId && data
          ? {
              key: `${selectedId}`,
              label: selectedList ? selectedList.name : '',
            }
          : undefined
      }
      onChange={onChange}
      filterOption={(input, option) => {
        if (!option || !option.props || !option.props.children) {
          return false;
        }
        return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {data &&
        data.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
    </Select>
  );
};

export default PortfolioModelSelect;
