import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import {
  CustomRendererProps,
  FIELD_TYPES,
  FieldProps,
  renderFormFields,
} from './helper/advanceFormFields';

type Props = {
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
  options: string[];
};

/**
 * Other Filters Panel inside the Advanced search filters
 * @param data
 * @param update
 * @constructor
 */
const OtherFiltersPanel: React.FC<Props> = ({ data, update, options }) => {
  const [fields, setFields] = useState<Array<FieldProps | CustomRendererProps>>([]);
  const [dates, setDates] = useState<{
    statsStartDate: string | undefined;
    statsEndDate: string | undefined;
  }>({
    statsStartDate: data.statsStartDate,
    statsEndDate: data.statsEndDate,
  });

  useEffect(() => {
    const newFields: (FieldProps | CustomRendererProps)[] = [
      {
        key: 'statsStartDate',
        name: 'Stats Start Date',
        type: FIELD_TYPES.DROPDOWN,
        optionData: options
          .filter(d =>
            dates.statsEndDate
              ? moment(d, 'YYYY-MM') < moment(dates.statsEndDate, 'YYYY-MM')
              : true,
          )
          .map(date => {
            return { label: date, value: date };
          }),
      },
      {
        key: 'statsEndDate',
        name: 'Stats End Date',
        type: FIELD_TYPES.DROPDOWN,
        optionData: options
          .slice()
          .reverse()
          .filter(d =>
            dates.statsStartDate
              ? moment(d, 'YYYY-MM') > moment(dates.statsStartDate, 'YYYY-MM')
              : true,
          )
          .map(date => {
            return { label: date, value: date };
          }),
      },
    ];
    setFields(newFields);
  }, [dates, options]);

  const handleUpdate: React.Dispatch<Partial<FetchFundParams>> = (
    payload: Partial<FetchFundParams>,
  ) => {
    setDates(prevDates => {
      return {
        ...prevDates,
        ...payload,
      };
    });
    update(payload);
  };

  return <>{renderFormFields(fields, data, handleUpdate)}</>;
};

export default OtherFiltersPanel;
