import React from 'react';
import Plotly from 'react-plotly.js';
import styled from 'styled-components';
import { PeerGroupTableDataType } from './PeerGroup/PeerGroupForm';

const LegendContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const Legend = styled('div')<{ color: string }>`
  position: relative;
  &:not(:last-child) {
    margin-right: 48px;
  }
  &:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background: ${props => props.color};
  }
`;

type Props = {
  data: PeerGroupTableDataType[];
  endDate: string;
};

/**
 * Performance vs Universe Chart in Peer Group Tab
 */
export const PerformanceVersusUniverseChart: React.FC<Props> = ({ data, endDate }) => {
  const BAR_X = [
    endDate,
    'Prev. Month',
    '3 Months',
    '6 Months',
    'YTD',
    '1 Year',
    '2 Years (Ann)',
    '3 Years (Ann)',
    '5 Years (Ann)',
  ];

  const getScatterData = (name: string): number[] => {
    const dataByName = data.find(item => item.name === name) as {
      [key: string]: any;
    };
    const KEYS = [
      'current',
      'prevMonth',
      'threeMonths',
      'sixMonths',
      'ytd',
      'oneYear',
      'twoYears',
      'threeYears',
      'fiveYears',
    ];
    if (!dataByName) {
      return Array(KEYS.length).fill(0);
    }
    return KEYS.map<number>(
      key => (dataByName[key] && typeof dataByName[key] === 'number' && dataByName[key]) || null,
    );
  };

  return (
    <div>
      <Plotly
        data={[
          {
            x: BAR_X,
            y: getScatterData('Percentile 95th'),
            type: 'bar',
            marker: {
              opacity: 0.5,
              color: '#F0F1FF',
            },
            line: {
              width: 0,
            },
          },
          {
            x: BAR_X,
            y: getScatterData('Percentile 5th'),
            type: 'bar',
            marker: {
              opacity: 0.5,
              color: '#F0F1FF',
            },
            line: {
              width: 0,
            },
          },
          {
            x: BAR_X,
            y: getScatterData('Percentile 5th'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#84DBEB', symbol: 'diamond', size: 10 },
            name: 'Percentile 5th',
          },
          {
            x: BAR_X,
            y: getScatterData('Percentile 25th'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#FF6B0F', symbol: 'square', size: 10 },
            name: 'Percentile 25th',
          },
          {
            x: BAR_X,
            y: getScatterData('Median Return'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#6DA6C1', symbol: 'circle', size: 10 },
            name: 'Median Return',
          },
          {
            x: BAR_X,
            y: getScatterData('Percentile 75th'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#D4D4A9', symbol: 'diamond', size: 10 },
            name: 'Percentile 75th',
          },
          {
            x: BAR_X,
            y: getScatterData('Percentile 95th'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#00A9E6', symbol: 'square', size: 10 },
            name: 'Percentile 95th',
          },
          {
            x: BAR_X,
            y: getScatterData('Fund'),
            type: 'scatter',
            mode: 'markers',
            marker: { color: '#000', symbol: 'circle', size: 10 },
            name: 'Fund Return',
          },
        ]}
        layout={{
          margin: {
            l: 50,
            r: 50,
            b: 100,
            t: 100,
          },
          xaxis: {
            showgrid: true,
            zeroline: true,
            title: 'Period',
            type: 'category', // to prevent plotly from auto format the X_BAR data as date array since the first value is a date string format (YYYY-MM)
          },
          yaxis: {
            showgrid: true,
            zeroline: true,
            title: 'Percent Return',
          },
          barmode: 'relative',
          showlegend: false,
        }}
        style={{ width: '100%', height: '100%' }}
        config={{
          displaylogo: false,
        }}
      />
      <LegendContainer>
        <Legend color="#000">Fund Return</Legend>
        <Legend color="#00A9E6">Percentile 95th</Legend>
        <Legend color="#D4D4A9">Percentile 75th</Legend>
        <Legend color="#6DA6C1">Median Return</Legend>
        <Legend color="#FF6B0F">Percentile 25th</Legend>
        <Legend color="#84DBEB">Percentile 5th</Legend>
      </LegendContainer>
    </div>
  );
};
