import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import APIService from '../../shared/api';
import { Colors } from '../../shared/colors';
import { CombinedReducers } from '../../index';
import { UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import IndexSelect, { IndexSelections } from './IndexSelectInput';
import RootActionEnums from '../../redux/actions/root.ActionEnums';

const Container = styled('div')`
  min-width: 300px;
  max-width: 25%;
  /* @media screen and (min-width: 1500px) {
    width: 25%;
  }
  @media screen and (max-width: 1500px) {
    width: 50%;
  } */
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .ant-table-thead > tr > th .ant-table-header-column {
    display: block;
  }
`;

type StateProps = {
  user: UserProfileDetails;
};
type DispatchProps = {
  addPane: (record: any) => void;
};

type Props = {
  onSelectIndexFamily: (selectedIndex: IndexSelections) => void;
  loading: boolean;
  dataSource?: any[];
  defaultIndex: IndexSelections;
};

const IndexTable: React.FC<StateProps & DispatchProps & Props> = ({
  user,
  defaultIndex,
  onSelectIndexFamily,
  loading,
  dataSource,
  addPane,
}) => {
  const FundName = styled('span')`
    color: ${user.hfrdb_trial ? Colors.gray : Colors.primary};
    cursor: ${user.hfrdb_trial ? 'auto' : 'pointer'};
  `;

  return (
    <Container className="ranking-table">
      <Table
        scroll={{ y: 'calc(100vh - 345px)' }}
        rowKey="fund_id"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={[
          {
            title: <IndexSelect defaultIndex={defaultIndex} onSelectIndex={onSelectIndexFamily} />,
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '75%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
    </Container>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  addPane: record => {
    dispatch({ type: RootActionEnums.ADD_RANKING_PANE, payload: record });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexTable);
