import { AxiosInstance, AxiosPromise } from 'axios';
import browser from 'browser-detect';

export interface EventParams {
  source: string;
  code: EventCodes;
  subcode: EventSubCode;
  user_agent: string;
  os: string;
  // screen_x: string;
  // screen_y: string;
  client_created_date: string;
  fund_id: string;
  url: string;
  json_data: string;
}

export enum EventSubCode {
  FUND_CHART_VIEW_EVENT = 'FUND_CHART_VIEW',
  ROR_VIEW_EVENT = 'ROR_VIEW',
  ANNUALIZED_ROR_VIEW_EVENT = 'ANNUALIZED_ROR_VIEW',
  STANDARD_DEVIATION_VIEW_EVENT = 'STANDARD_DEVIATION_VIEW',
  SHARPE_RATIO_VIEW_EVENT = 'SHARPE_RATIO_VIEW',
  REGRESSION_VIEW_EVENT = 'REGRESSION_VIEW',
}

export enum EventCodes {
  // Session EVENTS
  LOG_IN_EVENT = 'LOG_IN',
  SIGN_UP_EVENT = 'SIGN_UP',
  FORGOT_PASSWORD_EVENT = 'FORGOT_PASSWORD',
  LOG_OUT_EVENT = 'LOG_OUT',
  ACCEPT_DISCLAIMER_EVENT = 'ACCEPT_DISCLAIMER',
  // Access EVENTS
  SEARCH_FUNDS_ACCESS_EVENT = 'SEARCH_FUNDS_ACCESS',
  ADVANCE_SEARCH_ACCESS_EVENT = 'ADVANCE_SEARCH_ACCESS',
  UPLOAD_FUNDS_ACCESS_EVENT = 'UPLOAD_FUNDS_ACCESS',
  DOWNLOAD_FILE_ACCESS_EVENT = 'DOWNLOAD_FILE_ACCESS',
  // Search UI EVENTS
  CHART_VIEW_EVENT = 'CHART_VIEW',
  SEARCH_VIEW_EVENT = 'SEARCH_VIEW',
  FUND_SEARCH_STATS_CHART_VIEW_EVENT = 'FUND_SEARCH_STATS_CHART_VIEW',
  // Funds Events
  VIEW_FUND_PROFILE_EVENT = 'VIEW_FUND_PROFILE',
  VIEW_PGA_EVENT = 'VIEW_PGA',
  // My Data Events
  MY_LIST_ACCESS_EVENT = 'MY_LIST_ACCESS',
  MY_MODELS_ACCESS_EVENT = 'MY_MODELS_ACCESS',
  MY_FUNDS_ACCESS_EVENT = 'MY_FUNDS_ACCESS',
  // User Created List Events
  VIEW_UCL_EVENT = 'VIEW_UCL',
  // Models Events
  VIEW_PORTFOLIO_MODEL_EVENT = 'VIEW_PORTFOLIO_MODEL',
  // My Funds Events
  VIEW_MY_FUNDS_EVENT = 'VIEW_MY_FUNDS',
  VIEW_MY_FUND_EVENT = 'VIEW_MY_FUND',
  // Rankings
  VIEW_STRATEGY_RANKING = 'VIEW_STRATEGY_RANKING',
  VIEW_FUND_RANKING = 'VIEW_FUND_RANKING',
  VIEW_RISK_PREMIA_RANKING = 'VIEW_RISK_PREMIA_RANKING',
  VIEW_INDEX_FAMILY = 'VIEW_INDEX_FAMILY',
}

class EventService {
  public request: AxiosInstance;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  public sendBackendEvent = (data: Partial<EventParams>): AxiosPromise<void> => {
    const platform = browser();
    return this.request.post('/event', {
      ...data,
      os: platform.os,
      source: 'HFR_WEB',
      user_agent: navigator.userAgent,
      url: window.location.href,
    });
  };

  public sendBackendEvents = (data: Partial<EventParams>[]): AxiosPromise<void> => {
    const platform = browser();
    data = data.map((item: Partial<EventParams>) => {
      return {
        ...item,
        os: platform.os,
        source: 'HFR_WEB',
        user_agent: navigator.userAgent,
        url: window.location.href,
      };
    });
    return this.request.post('/events', data);
  };

  public sendLoginEvent() {
    this.sendBackendEvent({
      code: EventCodes.LOG_IN_EVENT,
    });
  }

  public sendLogoutEvent() {
    this.sendBackendEvent({
      code: EventCodes.LOG_OUT_EVENT,
    });
  }

  public sendSignupEvent() {
    this.sendBackendEvent({
      code: EventCodes.SIGN_UP_EVENT,
    });
  }

  public sendForgotPasswordEvent() {
    this.sendBackendEvent({
      code: EventCodes.FORGOT_PASSWORD_EVENT,
    });
  }

  public sendAcceptDisclaimerEvent() {
    this.sendBackendEvent({
      code: EventCodes.ACCEPT_DISCLAIMER_EVENT,
    });
  }
  // Access Events
  public sendSearchFundAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.SEARCH_FUNDS_ACCESS_EVENT,
    });
  }

  public sendAdvanceSearchAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.ADVANCE_SEARCH_ACCESS_EVENT,
    });
  }

  public sendUploadFundsAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.UPLOAD_FUNDS_ACCESS_EVENT,
    });
  }

  public sendDownloadFileAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.DOWNLOAD_FILE_ACCESS_EVENT,
    });
  }
  // Search UI Events
  public sendChartViewEvent(type: EventSubCode) {
    this.sendBackendEvent({
      code: EventCodes.CHART_VIEW_EVENT,
      subcode: type,
    });
  }

  public sendSearchViewEvent() {
    this.sendBackendEvent({
      code: EventCodes.SEARCH_VIEW_EVENT,
    });
  }

  public sendStatsViewEvent() {
    this.sendBackendEvent({
      code: EventCodes.FUND_SEARCH_STATS_CHART_VIEW_EVENT,
    });
  }

  // Funds Events
  public sendViewFundProfileEvent(fund_id: string) {
    this.sendBackendEvent({
      code: EventCodes.VIEW_FUND_PROFILE_EVENT,
      fund_id,
    });
  }

  public sendViewPgaEvent(jsonData: string) {
    this.sendBackendEvent({
      code: EventCodes.VIEW_PGA_EVENT,
      json_data: jsonData,
    });
  }
  // My data Events
  public sendMyListAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.MY_LIST_ACCESS_EVENT,
    });
  }

  public sendMyModelsAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.MY_MODELS_ACCESS_EVENT,
    });
  }

  public sendMyFundsAccessEvent() {
    this.sendBackendEvent({
      code: EventCodes.MY_FUNDS_ACCESS_EVENT,
    });
  }
  // User Created List Events
  public sendViewUclEvent() {
    this.sendBackendEvent({
      code: EventCodes.VIEW_UCL_EVENT,
    });
  }
  // Models Events
  public sendViewPortfolioModelEvent() {
    this.sendBackendEvent({
      code: EventCodes.VIEW_PORTFOLIO_MODEL_EVENT,
    });
  }
  // My Funds Events
  public sendViewMyFundsEvent() {
    this.sendBackendEvent({
      code: EventCodes.VIEW_MY_FUNDS_EVENT,
    });
  }

  public sendViewMyFundEvent() {
    this.sendBackendEvent({
      code: EventCodes.VIEW_MY_FUND_EVENT,
    });
  }
}

export default EventService;
