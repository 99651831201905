import React, { FormEvent } from 'react';
import { Form, Row, Select, Col, Button, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { MapStateToProps, connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { buildDateSelectOptions } from '../funddetails/components/PeerGroup/utils';
import {
  FundDetailStrategy,
  FundDetailSubStrategy,
  FundDetailRegionalFocus,
} from '../../shared/api/models/FundDetails';
import { FundListRecord } from '../../shared/api/models/ResponsesTypes';
import { CombinedReducers } from '../..';
import APIService from '../../shared/api';
import { useServiceState } from '../../shared/hooks/useServiceState';
import { RankingParams } from '../../shared/api/services/ranking.service';
import FundAssetSelect from './FundAssetSelect';
import styled from 'styled-components';

const { Option } = Select;

const StyledForm = styled(Form)`
  margin-right: 16px;
`;

interface StateProps {
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
  universeListData?: FundListRecord[];
  hfrIndexes?: FundListRecord[];
}

type Props = {
  params?: Partial<RankingParams>;
  updateParams: (params: Partial<RankingParams>) => void;
  showAllFilter?: boolean;
} & FormComponentProps;

/**
 * Ranking Filter Form
 */

const RankingForm: React.FC<Props & StateProps> = ({
  params,
  updateParams,
  form,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
  showAllFilter,
}) => {
  const { getFieldDecorator, resetFields, setFieldsValue, getFieldValue } = form;

  const [rangeOptions, setRangeOptions] = React.useState();

  const [filteredSubStrategy, setFilteredSubStrategy] = React.useState<FundDetailSubStrategy[]>([]);

  React.useEffect(() => {
    const strategyFromForm = form.getFieldValue('strategy');
    if (strategyFromForm && strategyFromForm.length > 0) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && strategyFromForm.includes(subStrategy.strategy_code),
        ),
      );
    } else {
      setFilteredSubStrategy(subStrategyOptions);
    }
  }, [form, subStrategyOptions, setFilteredSubStrategy]);

  const { data: denominationData, invoke: fetchDenomination } = useServiceState(
    APIService.rankingService.fetchDenomination,
  );
  const { data: dateRangeData, invoke: fetchDateRange } = useServiceState(
    APIService.rankingService.fetchRankingDateRange,
  );

  const buildDateRangeOptions = React.useCallback(() => {
    if (!dateRangeData || !dateRangeData.first || !dateRangeData.last) {
      return {
        years: [],
        months: [],
      };
    }
    // YYYY-DD
    const firstYear = +dateRangeData.first_year;
    const lastYear = +dateRangeData.last_year;
    const yearOptions = new Array(lastYear - firstYear + 1)
      .fill(null)
      .map((_, index) => lastYear - index);

    const monthOptions = buildDateSelectOptions(
      moment(dateRangeData.last, 'YYYY-MM').diff(
        moment(dateRangeData.first, 'YYYY-MM'),
        'months',
        true,
      ) + 1,
      moment(dateRangeData.last, 'YYYY-MM'),
    );

    return {
      years: yearOptions,
      months: monthOptions,
    };
  }, [dateRangeData]);

  React.useEffect(() => {
    fetchDenomination();
    fetchDateRange();
  }, [fetchDenomination, fetchDateRange]);

  React.useEffect(() => {
    const rangeOptions = buildDateRangeOptions();
    setRangeOptions({ years: rangeOptions.years, months: rangeOptions.months });
    updateParams({ month: rangeOptions.months[0] });
  }, [buildDateRangeOptions, updateParams]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values: any) => {
      if (!err) {
        updateParams({
          ...values,
          in_HFRI: values.in_HFRI ? values.in_HFRI === 'true' : undefined,
          liquid_alt: values.liquid_alt ? values.liquid_alt === 'true' : undefined,
          ucits_compliant: values.ucits_compliant ? values.ucits_compliant === 'true' : undefined,
          regional_focus: values.regional_focus
            ? values.regional_focus.map((item: any) => +item)
            : undefined,
        });
      }
    });
  };

  const handleFundAssetsChange = React.useCallback(
    (gteValue, lteValue) => {
      setFieldsValue({
        fund_assets_gte: gteValue ? +gteValue : undefined,
        fund_assets_lte: lteValue ? +lteValue : undefined,
      });
    },
    [setFieldsValue],
  );

  return (
    <PerfectScrollbar>
      <StyledForm onSubmit={handleSubmit}>
        <Row>
          <Form.Item label="Year-Month">
            {getFieldDecorator('month', {
              initialValue: params && params.month,
            })(
              <Select onChange={() => setFieldsValue({ year: undefined })}>
                {rangeOptions &&
                  rangeOptions.months.map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="Year">
            {getFieldDecorator('year', { initialValue: params && params.year })(
              <Select onChange={() => setFieldsValue({ month: undefined })}>
                {rangeOptions &&
                  rangeOptions.years.map((item: string) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Row>
        {showAllFilter && (
          <>
            <Row>
              <Form.Item label="Strategy">
                {getFieldDecorator('strategy', {
                  rules: [{ type: 'array' }],
                  initialValue: params && params.strategy,
                })(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    placeholder="Select Strategy"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {strategyOptions.map(item => {
                      const { strategy_code, strategy_name } = item;
                      return (
                        <Option key={strategy_code} value={strategy_code}>
                          {strategy_name}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="Sub - Strategy">
                {getFieldDecorator('sub_strategy', { initialValue: params && params.sub_strategy })(
                  <Select
                    mode="multiple"
                    placeholder="Select Sub Strategy"
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filteredSubStrategy.map(item => {
                      const { sub_strategy_code, sub_strategy_name } = item;
                      return (
                        <Option key={sub_strategy_code} value={sub_strategy_code}>
                          {sub_strategy_name}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="Regional Focus">
                {getFieldDecorator('regional_focus', {
                  initialValue: params && params.regional_focus,
                })(
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    showSearch
                    allowClear
                    placeholder="Select Regional Focus"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                  >
                    {regionalFocusOptions.map(item => {
                      const { id, name } = item;
                      return (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Row>
          </>
        )}
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="Liquid Alt">
              {getFieldDecorator('liquid_alt', {
                initialValue:
                  params && params.liquid_alt ? (params.liquid_alt ? 'true' : 'false') : undefined,
              })(
                <Select style={{ width: '100%' }} placeholder="Liquid Alt" allowClear>
                  <Option key={'true'}>Yes</Option>
                  <Option key={'false'}>No</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="UCITS Compliant">
              {getFieldDecorator('ucits_compliant', {
                initialValue:
                  params && params.ucits_compliant
                    ? params.ucits_compliant
                      ? 'true'
                      : 'false'
                    : undefined,
              })(
                <Select style={{ width: '100%' }} placeholder="UCITS Compliant" allowClear>
                  <Option key={'true'}>Yes</Option>
                  <Option key={'false'}>No</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item label="In HFRI">
              {getFieldDecorator('in_HFRI', {
                initialValue:
                  params && params.in_HFRI ? (params.in_HFRI ? 'true' : 'false') : undefined,
              })(
                <Select style={{ width: '100%' }} placeholder="Is in HFRI?" allowClear>
                  <Option key={'true'}>Yes</Option>
                  <Option key={'false'}>No</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Denomination">
              {getFieldDecorator('denomination', { initialValue: params && params.denomination })(
                <Select style={{ width: '100%' }} placeholder="Denomination" allowClear>
                  {denominationData.length > 0 &&
                    denominationData.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {getFieldDecorator('fund_assets_gte')(<Input style={{ display: 'none' }} />)}
          {getFieldDecorator('fund_assets_lte')(<Input style={{ display: 'none' }} />)}
          <FundAssetSelect
            updateValues={handleFundAssetsChange}
            gteValue={getFieldValue('fund_assets_gte')}
            lteValue={getFieldValue('fund_assets_lte')}
          />
        </Row>
        <Row>
          <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
            Filter
          </Button>
          <Button
            type="default"
            onClick={() => {
              updateParams({});
              resetFields();
            }}
          >
            Clear
          </Button>
        </Row>
      </StyledForm>
    </PerfectScrollbar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  strategyOptions: state.searchReducer.strategyOptions,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
  regionalFocusOptions: state.searchReducer.regionalFocusOptions,
});

export default Form.create<Props>({ name: 'RankingForm' })(
  connect(mapStateToProps, null)(RankingForm),
);
