import { Col, Collapse, Divider, Row, Spin } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import APIService from '../../shared/api';
import { DisclaimerResponse } from '../../shared/api/models/ResponsesTypes';
import {
  InvestorDatabaseType,
  InvestorDBDetails,
  PeopleInvestorDetail,
} from '../../shared/api/services/investor-db.service';
import { useServiceState } from '../../shared/hooks/useServiceState';
import GenUtil from '../../shared/utils/gen-util';

const Wrapper = styled(PerfectScrollbar)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;
const DisclaimerContainer = styled(Row)`
  margin-top: 20px;
  font-size: 9px;
`;
const { Panel } = Collapse;

type DescriptionType = {
  key: keyof Partial<InvestorDatabaseType & { address: string }>;
  title: string;
  value?: string | string[];
  show: boolean;
  isShowAddress?: boolean;
};

type Props = {
  investorId: number;
  disclaimerData: DisclaimerResponse;
};

const renderStrategies = (data: string[]) => {
  return (
    <Row>
      {data.length === 0
        ? '-'
        : data.map((item, index) => {
            return (
              <>
                <Col span={7} style={{ fontWeight: 200 }} key={item}>
                  <Row style={{ width: '100%', padding: '10px 5px' }}> {item} </Row>
                </Col>
                {index !== data.length - 1 && (
                  <Col span={1} style={{ padding: '10px 0px' }}>
                    <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                  </Col>
                )}
              </>
            );
          })}
    </Row>
  );
};

const renderHeaderCell = (data: DescriptionType) => {
  return data.show ? (
    <Row key={data.key}>
      <Col span={8} style={{ fontWeight: 200 }}>
        <Row style={{ width: '100%', padding: '10px 5px' }}> {data.title} </Row>
      </Col>
      <Col span={1} style={{ padding: '10px 0px' }}>
        <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
      </Col>
      <Col span={15}>
        {Array.isArray(data.value) ? (
          data.value.map((item, idx) => (
            <Row
              key={`${idx}-${item}`}
              style={{
                width: '100%',
                height: 50,
                padding: '10px 5px',
              }}
            >
              {item}
            </Row>
          ))
        ) : (
          <Row
            style={{
              width: '100%',
              height: 50,
              padding: '10px 5px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data.value && data.key === 'website_address' ? (
              <a href={data.value} target="_blank">
                {data.value}
              </a>
            ) : data.value ? (
              data.value
            ) : (
              '-'
            )}
          </Row>
        )}
      </Col>
    </Row>
  ) : null;
};

const InvestorDetail: React.FC<Props> = ({ investorId, disclaimerData }) => {
  const {
    invoke: getInvestorDetail,
    data: investorDetails,
    loading: dataLoading,
  } = useServiceState<InvestorDBDetails>(APIService.investorDBService.fetchInvestorDetail);

  const investorData = React.useMemo(() => investorDetails.investor, [investorDetails.investor]);
  React.useEffect(() => {
    getInvestorDetail(investorId);
  }, [getInvestorDetail, investorId]);

  const DESC_FIELDS: DescriptionType[] = investorData && [
    {
      key: 'investor_type',
      title: 'Investor Type',
      value: investorData.investor_type,
      show: true,
    },
    {
      key: 'entity_type',
      title: 'Entity Type',
      value: investorData.entity_type,
      show: true,
    },
    // {
    //   key: 'investment_strategy',
    //   title: 'Investment Strategy Interest',
    //   value: investorData.investment_strategy,
    //   show: true,
    // },
    {
      key: 'investment_executive',
      title: 'Investment Executive',
      value: investorData.investment_executive,
      show: false,
    },
    // {
    //   key: 'other_investment_professionals',
    //   title: 'Other Investment Professionals',
    //   value: investorData.other_investment_professionals,
    //   show: true,
    // },
    {
      key: 'website_address',
      title: 'Website Address',
      value: investorData.website_address,
      show: true,
    },
    {
      key: 'company_email',
      title: 'Company E-mail',
      value: investorData.company_email,
      show: true,
    },
    {
      key: 'primary_phone_number',
      title: 'Primary Phone Number',
      value: investorData.primary_phone_number,
      show: true,
    },

    {
      key: 'address',
      title: 'Address',
      value: [
        investorData.primary_address1 || '-' + ' ' + investorData.primary_address2 || '-',
        `${investorData.primary_city ? investorData.primary_city : '-'}${
          investorData.primary_state ? `, ${investorData.primary_state}` : ''
        }${investorData.primary_postal_code ? ` ${investorData.primary_postal_code}` : ''}`,
        investorData.primary_country || '-',
      ],
      show: true,
      isShowAddress: true,
    },
  ];
  //   [
  //     {
  //       key: 'primary_phone_number',
  //       title: 'Primary Phone Number',
  //       value: investorData.primary_phone_number,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_address1',
  //       title: 'Primary Address - Street Address 1',
  //       value: investorData.primary_address1,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_address2',
  //       title: 'Primary Address - Street Address 2',
  //       value: investorData.primary_address2,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_city',
  //       title: 'Primary City',
  //       value: investorData.primary_city,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_state',
  //       title: 'Primary State/Province',
  //       value: investorData.primary_state,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_country',
  //       title: 'Primary Country',
  //       value: investorData.primary_country,
  //       show: true,
  //     },
  //     {
  //       key: 'primary_postal_code',
  //       title: 'Primary Postal Code',
  //       value: investorData.primary_postal_code,
  //       show: true,
  //     },
  //   ],
  //   [
  //     {
  //       key: 'secondary_phone_number',
  //       title: 'Secondary Phone Number',
  //       value: investorData.secondary_phone_number,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_address1',
  //       title: 'Secondary Address - Street Address 1',
  //       value: investorData.secondary_address1,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_address2',
  //       title: 'Secondary Address - Street Address 2',
  //       value: investorData.secondary_address2,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_city',
  //       title: 'Secondary City',
  //       value: investorData.secondary_city,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_state',
  //       title: 'Secondary State/Province',
  //       value: investorData.secondary_state,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_country',
  //       title: 'Secondary Country',
  //       value: investorData.secondary_country,
  //       show: true,
  //     },
  //     {
  //       key: 'secondary_postal_code',
  //       title: 'Secondary Postal Code',
  //       value: investorData.secondary_postal_code,
  //       show: true,
  //     },
  //   ],
  // ];alternative
  const COLLAPSE_ALTERNATIVE_FIELD: DescriptionType[] = investorData && [
    {
      key: 'alternative_assets',
      title: 'Total Assets',
      value: investorData.alternative_assets
        ? `USD ${GenUtil.getformattedBilionOrMilion(investorData.alternative_assets)}`
        : '-',
      show: true,
    },

    {
      key: 'alternative_assets_as_of',
      title: 'Date Total Assets',
      value: investorData.alternative_assets_as_of,
      show: true,
    },
  ];

  const COLLAPSE_ASSET_FIELD: DescriptionType[] = investorData && [
    {
      key: 'total_assets',
      title: 'Total Assets',
      value: investorData.total_assets
        ? `USD ${GenUtil.getformattedBilionOrMilion(investorData.total_assets)}`
        : '-',
      show: true,
    },

    {
      key: 'total_assets_as_of',
      title: 'Date Total Assets',
      value: investorData.total_assets_as_of,
      show: true,
    },
  ];

  const COLLAPSE_HEDGE_FUND_FIELD: DescriptionType[] = investorData && [
    {
      key: 'hedge_fund_assets',
      title: 'Hedge Fund Assets',
      value: investorData.hedge_fund_assets
        ? `USD ${GenUtil.getformattedBilionOrMilion(investorData.hedge_fund_assets)}`
        : '-',
      show: true,
    },

    {
      key: 'hedge_fund_assets_as_of',
      title: 'Date Hedge Fund Assets',
      value: investorData.hedge_fund_assets_as_of,
      show: true,
    },
  ];

  const columns: ColumnProps<PeopleInvestorDetail>[] = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: '34.5%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
  ];

  return (
    <Wrapper>
      {investorData && (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Row>
            <h2 style={{ margin: '0.5rem' }}>{investorData.company_name}</h2>
          </Row>
          <Row justify="space-between" type="flex" gutter={8}>
            <Col span={12}>
              <Row>
                <Col
                  span={24}
                  style={{ border: '1px solid #f1f1f1', padding: '8px' }}
                  className="headerCol"
                >
                  {DESC_FIELDS.map(col => renderHeaderCell(col))}
                </Col>
              </Row>
              <Row>
                <div>
                  <h4 style={{ margin: '0.5rem' }}>Investment Professionals</h4>
                </div>
                <Table
                  rowKey="full_name"
                  bordered
                  dataSource={investorDetails.people}
                  columns={columns}
                  pagination={
                    investorDetails.people && investorDetails.people.length > 10 ? undefined : false
                  }
                  style={{ width: '100%' }}
                />
              </Row>
            </Col>
            <Col span={12}>
              <Collapse defaultActiveKey="Strategies" style={{ marginBottom: 10 }}>
                <Panel header="Strategies" key="Strategies">
                  <Row
                    style={{ border: '1px solid #f1f1f1', padding: '8px' }}
                    className="headerCol"
                  >
                    {renderStrategies(investorData.investment_strategy || [])}
                  </Row>
                </Panel>
              </Collapse>
              <Collapse defaultActiveKey="Total_Assets" style={{ marginBottom: 10 }}>
                <Panel header="Total Assets" key="Total_Assets">
                  <Row
                    style={{ border: '1px solid #f1f1f1', padding: '8px' }}
                    className="headerCol"
                  >
                    {COLLAPSE_ASSET_FIELD.map(col => renderHeaderCell(col))}
                  </Row>
                </Panel>
              </Collapse>
              <Collapse defaultActiveKey="Hedge_Fund_Assets">
                <Panel header="Hedge Fund Assets" key="Hedge_Fund_Assets">
                  <Row
                    style={{ border: '1px solid #f1f1f1', padding: '8px' }}
                    className="headerCol"
                  >
                    {COLLAPSE_HEDGE_FUND_FIELD.map(col => renderHeaderCell(col))}
                  </Row>
                </Panel>
              </Collapse>
              <Collapse defaultActiveKey="Alternative-Assets" style={{ marginBottom: 10 }}>
                <Panel header="Alternative Assets" key="Alternative-Assets">
                  <Row
                    style={{ border: '1px solid #f1f1f1', padding: '8px' }}
                    className="headerCol"
                  >
                    {COLLAPSE_ALTERNATIVE_FIELD.map(col => renderHeaderCell(col))}
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      )}
      {dataLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'rgba(255, 255, 255, 0.5)',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}
        >
          <Spin tip={'Loading InvestorDB...'} />
        </div>
      )}
      {investorData && (
        <DisclaimerContainer
          dangerouslySetInnerHTML={{
            __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
          }}
        />
      )}
    </Wrapper>
  );
};

export default InvestorDetail;
