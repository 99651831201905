import { Col, Collapse, Divider, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ColumnProps } from 'antd/lib/table';
import {
  Benchmarks,
  DateValueRecordList,
  PortfolioModelStats,
  YearTableRowRecord,
} from '../../../shared/api/models/ResponsesTypes';
import RenderTableCell from '../../funddetails/components/RenderTableCell';
import VamiGraph from '../../../shared/components/VamiGraph';
import APIService from '../../../shared/api';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import GenUtil from '../../../shared/utils/gen-util';
import BenchmarkOptions from './BenchmarkOptions';

const { Panel } = Collapse;
const { Title } = Typography;

const Header = styled(Title)`
  position: relative;
  text-align: center;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`;

const TableWrapper = styled('div')`
  td,
  th {
    padding: 16px 4px !important;
  }
`;

const Container = styled(Row)`
  padding-top: 25px;
  .ant-collapse-content-box {
    padding: 10px;
  }
  .ant-table-body::-webkit-scrollbar {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  width: '100%';
  height: 50px;
  padding: 10px 5px;
`;

const StyledCol = styled(Col)`
  font-weight: 200;
  padding-top: 12px;
`;

interface Props {
  modelId: string;
  portfolioModelStats: PortfolioModelStats;
}

/**
 * Model Analysis component rendered at top in the PortfolioView
 * @param modelId - Selected portfolio model's ID
 * @param portfolioModelStats - Ror Table data + analytics data
 * @constructor
 */
const ModelAnalysis: React.FC<Props> = ({ modelId, portfolioModelStats }) => {
  const [benchmarks, setBenchmarks] = useState<Benchmarks>({
    benchmark1: '',
    benchmark2: '',
    benchmark3: '',
  });

  const { data: modelVami, loading: modelVamiLoading, invoke: invokeModelVami } = useServiceState<
    DateValueRecordList
  >(APIService.portfolioModelService.fetchPortfolioModelVami);

  useEffect(() => {
    // TODO change with portfolioModelState.benchmark value
    setBenchmarks({
      benchmark1: '2899',
      benchmark2: '2898',
      benchmark3: '50141',
    });
  }, [modelId, portfolioModelStats]);

  useEffect(() => {
    if (benchmarks.benchmark1 && benchmarks.benchmark2 && benchmarks.benchmark3) {
      invokeModelVami({
        modelId,
        params: {
          benchmark1: benchmarks.benchmark1,
          benchmark2: benchmarks.benchmark2,
          benchmark3: benchmarks.benchmark3,
        },
      });
    }
  }, [benchmarks, invokeModelVami, modelId]);

  const columns: ColumnProps<YearTableRowRecord>[] = [
    { title: 'Year', dataIndex: 'year', key: 'year', width: 100 },
    {
      title: 'Jan',
      dataIndex: '001',
      key: '001',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Feb',
      dataIndex: '002',
      key: '002',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Mar',
      dataIndex: '003',
      key: '003',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Apr',
      dataIndex: '004',
      key: '004',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'May',
      dataIndex: '005',
      key: '005',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Jun',
      dataIndex: '006',
      key: '006',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Jul',
      dataIndex: '007',
      key: '007',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Aug',
      dataIndex: '008',
      key: '008',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Sep',
      dataIndex: '009',
      key: '009',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Oct',
      dataIndex: '010',
      key: '010',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Nov',
      dataIndex: '011',
      key: '011',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Dec',
      dataIndex: '012',
      key: '012',
      width: 100,
      render: RenderTableCell,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: RenderTableCell,
    },
  ];
  const dataSource: YearTableRowRecord[] = [];
  for (const yearlyRecord of portfolioModelStats.hypothetical_performance_history) {
    let record: YearTableRowRecord = {} as YearTableRowRecord;
    record.year = `${yearlyRecord.year}`;
    record.total = `${yearlyRecord.rors
      .reduce((s, ror) => s + ror.ror, 0)
      .toLocaleString(undefined, { maximumFractionDigits: 2 })}`;
    for (const ror of yearlyRecord.rors) {
      record[String(ror.month).padStart(3, '0')] = GenUtil.digitWithExactPrecision(ror.ror);
    }
    dataSource.push(record);
  }
  return (
    <Container id="model-analysis">
      <Collapse defaultActiveKey={['1']} style={{ marginBottom: '20px' }}>
        <Panel header={<span id="performance-history">Model Analysis</span>} key="1">
          <Header level={4}>Hypothetical Performance History </Header>
          <TableWrapper>
            <Table dataSource={dataSource} columns={columns} pagination={false} />
          </TableWrapper>
          <Header id="analytics" level={4}>
            Analytics
          </Header>
          <Row>
            <Col span={12} style={{ padding: '8px' }} className="headerCol">
              <Row>
                <StyledCol span={8}>Annualized Ror</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.annualized_ror} % </StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>Annualized Standard Deviation</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.annualized_standard_deviation} %</StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>Annualized Monthly Ror</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.average_monthly_ror} %</StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>Standard Deviation</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.standard_deviation} %</StyledRow>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ padding: '8px' }} className="headerCol">
              <Row>
                <StyledCol span={8}>Winning Months</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.winning_months} %</StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>High Month</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.high_month} %</StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>Low Month</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.low_month} %</StyledRow>
                </Col>
              </Row>
              <Row>
                <StyledCol span={8}>Sharpe Ratio</StyledCol>
                <Col span={1} style={{ padding: '10px 0px' }}>
                  <Divider style={{ height: 20, backgroundColor: '#ece9e9' }} type="vertical" />
                </Col>
                <Col span={15}>
                  <StyledRow> {portfolioModelStats.sharpe_ratio} %</StyledRow>
                </Col>
              </Row>
            </Col>
          </Row>
          <BenchmarkOptions benchmarks={benchmarks} handleChange={setBenchmarks} />
          <VamiGraph vami={modelVami} loading={modelVamiLoading} />
        </Panel>
      </Collapse>
    </Container>
  );
};

export default ModelAnalysis;
