import { AxiosPromise, AxiosInstance } from 'axios';

export interface ClientResponse {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export interface ClientService {
  fetchAllUsers: () => AxiosPromise<ClientResponse[]>;
}

export const ClientService = (request: AxiosInstance): ClientService => ({
  fetchAllUsers: () => request.get('/client/users'),
});
