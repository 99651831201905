import { AxiosPromise, AxiosInstance } from 'axios';

export interface AdminService {
  openAdmin: () => AxiosPromise<any>;
}

export const AdminService = (request: AxiosInstance): AdminService => ({
  openAdmin: () =>
    request.get('/admin').then(res => {
      window.location = res.request.responseURL;
      return res;
    }),
});
