import React from 'react';
import { DistributionRecord } from '../../../shared/api/models/ResponsesTypes';
import PanelDetailGraph from '../../../shared/components/PanelDetailGraph';

interface OwnProps {
  up_and_down:
    | {
        ups: DistributionRecord;
        downs: DistributionRecord;
      }
    | undefined;
}

/**
 * This renders UP and Down graph component based on profileStats api
 * @param up_and_down
 * @constructor
 */
const UpDownCaptureGraph: React.FC<OwnProps> = ({ up_and_down }) => {
  const getUpDownCaptureData = () => {
    const graphData = [];
    if (up_and_down) {
      const x = ['Up Average ROR', 'Down Average ROR'];
      graphData.push(
        {
          x,
          y: [up_and_down.ups.b1, up_and_down.downs.b1],
          name: 'B1',
          type: 'bar',
        },
        {
          x,
          y: [up_and_down.ups.b2, up_and_down.downs.b2],
          name: 'B2',
          type: 'bar',
        },
        {
          x,
          y: [up_and_down.ups.b3, up_and_down.downs.b3],
          name: 'B3',
          type: 'bar',
        },
        {
          x,
          y: [up_and_down.ups.fund, up_and_down.downs.fund],
          name: 'Fund',
          type: 'bar',
        },
      );
    }
    const graphLayout = {
      autosize: true,
      responsive: true,
      barmode: 'group',
      xaxis: {
        showgrid: false,
        showline: false,
      },
      margin: {
        l: 50,
        r: 50,
        b: 100,
        t: 100,
        pad: 4,
      },
      title: 'Up/Down Capture Vs. HFRI Fund Weighted Comp. Index',
    };
    return {
      header: 'Up capture/Down Capture',
      graphData,
      graphLayout,
    };
  };

  return <PanelDetailGraph {...getUpDownCaptureData()} />;
};

export default React.memo(UpDownCaptureGraph);
