import moment from 'moment';
import numeral from 'numeral';
class GenUtil {
  static clearNamespace({ namespace = '' }) {
    if (namespace) {
      for (const key in localStorage) {
        if (key.split('_')[0] === namespace) {
          localStorage.removeItem(key);
        }
      }
    }
  }

  static digitWithExactPrecision(x: string | Number, precision = 2): string {
    return Number(x).toFixed(precision);
  }

  static digitWithMaxPrecision(x: string | Number, precision = 2): number {
    return Number(Number(x).toFixed(precision));
  }

  static getformattedNumber(x: string | Number): string {
    return numeral(x).format('0,0[.]00');
  }

  static getformattedBilionOrMilion(x: string | number): string {
    const value = Number(x);
    if (value > 1000000000000) {
      return numeral(value / 1000000000000).format('0,0[.]00') + 'T';
    }
    if (value > 1000000000) {
      return numeral(value / 1000000000).format('0,0[.]00') + 'B';
    } else if (value > 1000000) {
      return numeral(value / 1000000).format('0,0[.]00') + 'M';
    }
    return numeral(value).format('0,0[.]00');
  }

  // returns date in YYYY-MM format
  static getFormattedYearAndMonth(
    date: string | moment.Moment,
    format: string | undefined = undefined,
  ): string {
    return format ? moment(date, format).format('YYYY-MM') : moment(date).format('YYYY-MM');
  }
  // returns date in YYYY-MM-DD format
  static getFormattedYearMonthAndDay(
    date: string | moment.Moment,
    format: string | undefined = undefined,
  ): string {
    return format ? moment(date, format).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD');
  }
}

export default GenUtil;
