import { Reducer } from 'redux';
import _ from 'lodash';
import { PortfolioModelRecord } from '../../../shared/api/models/ResponsesTypes';
import ModelActionEnums from './model-action-enums';

export type PortfolioPane = {
  key: string;
  portfolioModelId: string;
  portfolioModelName: string;
};

export type InitialModelReducer = {
  portfolioPanes: PortfolioPane[];
  portfolioActiveKey: string | number;
  portfolioModels: PortfolioModelRecord[];
  selectedFunds: string[];
};

const initModelState: InitialModelReducer = {
  portfolioPanes: [{ key: '0', portfolioModelId: '', portfolioModelName: '' }],
  portfolioActiveKey: '0',
  portfolioModels: [],
  selectedFunds: [],
};

const modelReducer: Reducer<InitialModelReducer, any> = (
  state: InitialModelReducer = initModelState,
  { type, payload },
) => {
  switch (type) {
    case ModelActionEnums.RESET_STORE: {
      return initModelState;
    }
    case ModelActionEnums.REMOVE_PORTFOLIO_PANE: {
      const portfolioPanes = state.portfolioPanes.filter(pane => pane.key !== payload.key);

      let { portfolioActiveKey } = state;
      //check if its the only pane
      if (_.isEmpty(portfolioPanes)) {
        return {
          ...state,
          portfolioPanes: initModelState.portfolioPanes,
          portfolioActiveKey: initModelState.portfolioActiveKey,
        };
      } else {
        // check if its the active-pane
        portfolioActiveKey =
          portfolioActiveKey === payload.key ? portfolioPanes[0].key : portfolioActiveKey;
        return {
          ...state,
          portfolioPanes,
          portfolioActiveKey,
        };
      }
    }
    case ModelActionEnums.ADD_PORTFOLIO_PANE: {
      let isPaneOpenArr = state.portfolioPanes.filter(pane => pane.key === payload.key);
      if (isPaneOpenArr.length) {
        return {
          ...state,
          portfolioActiveKey: payload.key,
        };
      } else if (state.portfolioPanes.length < 11) {
        const portfolioPanes: PortfolioPane[] = [
          ...state.portfolioPanes,
          {
            key: payload.key,
            portfolioModelId: payload.portfolioModelId || '',
            portfolioModelName: payload.portfolioModelName || '',
          },
        ];
        return {
          ...state,
          portfolioPanes,
          portfolioActiveKey: payload.key,
        };
      } else {
        return state;
      }
    }
    case ModelActionEnums.CHANGE_PORTFOLIO_PANE: {
      return {
        ...state,
        portfolioActiveKey: payload,
      };
    }

    case ModelActionEnums.UPDATE_PORTFOLIO_PANE: {
      const panes: PortfolioPane[] = state.portfolioPanes.filter(pane => pane.key === payload.key);
      if (panes && panes.length > 0) {
        const newPanes: PortfolioPane[] = [];
        for (let pane of state.portfolioPanes) {
          if (pane.key === payload.key) {
            newPanes.push({
              key: payload.portfolioModelId,
              portfolioModelId: payload.portfolioModelId,
              portfolioModelName: payload.portfolioModelName,
            });
          } else {
            newPanes.push(pane);
          }
        }
        return {
          ...state,
          portfolioPanes: newPanes,
          portfolioActiveKey: payload.portfolioModelId,
        };
      } else {
        return {
          ...state,
          portfolioPanes: [
            ...state.portfolioPanes,
            {
              key: payload.portfolioModelId,
              portfolioModelId: payload.portfolioModelId,
              portfolioModelName: payload.portfolioModelName,
            },
          ],
          portfolioActiveKey: payload.portfolioModelId,
        };
      }
    }

    case ModelActionEnums.UPDATE_PORTFOLIO_MODELS: {
      return {
        ...state,
        portfolioModels: payload.data,
      };
    }

    case ModelActionEnums.UPDATE_SELECTED_FUNDS: {
      return {
        ...state,
        selectedFunds: payload.selectedFunds,
      };
    }
    default: {
      return state;
    }
  }
};

export default modelReducer;

export { initModelState };
