import { Button, Collapse, Icon, Input, Modal, Row } from 'antd';
import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { CombinedReducers } from '../../../..';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import { Colors } from '../../../../shared/colors';
import { NewIndicator } from '../../../../shared/components/NewIndicator';
import { InitialSearchState } from '../../redux/search.reducers';
import { AdvancedFieldsType, getAdvancedFields } from './helper/AdvancedFields';
import { renderFormFields } from './helper/advanceFormFields';

const StyledModal = styled(Modal)`
  .ant-select,
  .ant-cascader-picker {
    width: 100%;
  }
`;

const InputWrapper = styled('div')`
  position: absolute;
  top: 10px;
  right: 55px;
  i {
    margin-right: -15px;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const FilterInput = styled(Input)`
  width: auto;
  padding-left: 20px;
  margin-bottom: 16px;
  border: none;
  background: transparent;
  border-bottom: 1px solid ${Colors.secondary};
  input {
    border: none;
    :focus,
    :active {
      box-shadow: none;
    }
  }
  border-radius: 0;
  :focus,
  :active {
    box-shadow: none;
    border-bottom: 1px solid ${Colors.primary};
  }
`;

const { Panel } = Collapse;

type StateProps = InitialSearchState & {};

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
  initialFocus?: string;
};

const Footer: React.FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  return <Button onClick={() => onRequestClose()}>Close</Button>;
};

const AdvancedFilterModal: React.FC<Props & StateProps> = ({
  isOpen,
  onRequestClose,
  initialFocus,
  data,
  update,
  benchmarkOptions,
  liquidAltTypeOptions,
  liquidAltRegionOptions,
  maxFrontFeeOptions,
  maxDeferredFeeOptions,
  max12B1FeeOptions,
  productClassOptions,
  advanceNoticeOptions,
  minInvestmentCurrOptions,
  reportingOptions,
  reportingStyleOptions,
  returnsCurrencyOptions,
  redemptionOptions,
  subscriptionsOptions,
  firmAssetCurrencyOptions,
  fundAssetCurrencyOptions,
  countryOptions,
  stateOptions,
  rorDateRangeOptions,
  regionalFocusCountryOptions,
  nonSecRegAuthorities,
  nonSecCountryOptions,
  esgStrategyOptions,
  esgSubStrategyOptions,
  minorityTypeOptions,
  fundDropdowns,
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>();
  const [activeKeys, setActiveKeys] = React.useState<string[] | string>();

  React.useEffect(() => {
    setSearchTerm(initialFocus);
  }, [initialFocus]);

  const filteredList = React.useMemo(() => {
    const fullFilters = getAdvancedFields({
      data,
      update,
      benchmarkOptions,
      liquidAltTypeOptions,
      liquidAltRegionOptions,
      maxFrontFeeOptions,
      maxDeferredFeeOptions,
      max12B1FeeOptions,
      productClassOptions,
      advanceNoticeOptions,
      minInvestmentCurrOptions,
      reportingOptions,
      reportingStyleOptions,
      returnsCurrencyOptions,
      redemptionOptions,
      subscriptionsOptions,
      firmAssetCurrencyOptions,
      fundAssetCurrencyOptions,
      stateOptions,
      countryOptions,
      rorDateRangeOptions,
      regionalFocusCountryOptions,
      nonSecRegAuthorities,
      nonSecCountryOptions,
      esgStrategyOptions,
      esgSubStrategyOptions,
      minorityTypeOptions,
      fundDropdowns,
    });
    return searchTerm === undefined
      ? fullFilters
      : fullFilters.reduce((acc, curr) => {
          if (
            curr.fields.find(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
          ) {
            return [
              ...acc,
              {
                ...curr,
                fields: curr.fields.filter(item =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase()),
                ),
              },
            ];
          }
          return acc;
        }, [] as AdvancedFieldsType[]);
  }, [
    data,
    update,
    benchmarkOptions,
    liquidAltTypeOptions,
    liquidAltRegionOptions,
    maxFrontFeeOptions,
    maxDeferredFeeOptions,
    max12B1FeeOptions,
    productClassOptions,
    advanceNoticeOptions,
    minInvestmentCurrOptions,
    reportingOptions,
    reportingStyleOptions,
    returnsCurrencyOptions,
    redemptionOptions,
    subscriptionsOptions,
    firmAssetCurrencyOptions,
    fundAssetCurrencyOptions,
    stateOptions,
    countryOptions,
    rorDateRangeOptions,
    regionalFocusCountryOptions,
    searchTerm,
    nonSecRegAuthorities,
    nonSecCountryOptions,
    esgStrategyOptions,
    esgSubStrategyOptions,
    minorityTypeOptions,
    fundDropdowns,
  ]);

  return (
    <StyledModal
      width="1000px"
      title="Advanced Filters"
      visible={isOpen}
      style={{ top: 30 }}
      bodyStyle={{ height: '80vh', overflowY: 'auto' }}
      footer={<Footer onRequestClose={onRequestClose} />}
      onCancel={() => onRequestClose()}
    >
      <Wrapper>
        <InputWrapper>
          <Icon type="search" />
          <FilterInput
            autoFocus
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search filter by name"
            allowClear
          />
        </InputWrapper>
        <Collapse
          bordered={true}
          activeKey={searchTerm ? filteredList.map(item => item.groupName) : activeKeys}
          onChange={key => setActiveKeys(key)}
        >
          {filteredList &&
            filteredList.map(group => (
              <Panel
                header={
                  group.hasNew ? <NewIndicator>{group.groupName}</NewIndicator> : group.groupName
                }
                key={group.groupName}
              >
                <Row type="flex" justify="start" style={{ flexWrap: 'wrap' }}>
                  {renderFormFields(group.fields, data, update)}
                </Row>
                {group.groupName === 'Stats Calculation Settings' &&
                  (data.statsStartDate || data.statsEndDate || data.stats_benchmark) && (
                    <Row>
                      <div style={{ fontStyle: 'italic' }}>
                        <span style={{ color: Colors.danger }}>*</span> Takes up to 20s to execute
                        the search
                      </div>
                    </Row>
                  )}
              </Panel>
            ))}
        </Collapse>
      </Wrapper>
    </StyledModal>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    ...state.searchReducer,
  };
};

export default connect(mapStatesToProps)(AdvancedFilterModal);
