import { AxiosPromise, AxiosInstance } from 'axios';
import moment from 'moment';
import {
  UserFundRecord,
  UserFundDetails,
  UserFundCreateRequest,
  UserFundUpdateRequest,
  PeerGroupUniverseStatsResponse,
  PeerGroupFundBenchmarkResponse,
} from '../models/ResponsesTypes';
import { sanatizeToSnakeCase } from '../../strings';

export type FetchUserFundPeerGroupPdfParams = {
  benchmark1: string;
  benchmark2: string;
  benchmark3: string;
  end_date: string;
  universe_list_ids: number[];
  fund_list_ids: number[];
  portfolio_model_ids: number[];
  static_index_ids: number[];
  dynamic_index_ids: number[];
  strategy?: number[];
  sub_strategy?: number[];
  regional_focus?: number[];
  in_HFRI?: boolean;
  in_emerging_markets?: boolean;
};

export interface UserFundService {
  fetchAllUserFunds: () => AxiosPromise<UserFundRecord[]>;
  fetchUserFundDetails: (id: string) => AxiosPromise<UserFundDetails>;
  createNewUserFund: (data: UserFundCreateRequest) => AxiosPromise<void>;
  updateUserFund: (id: string, data: UserFundUpdateRequest) => AxiosPromise<void>;
  deleteUserFund: (id: number) => AxiosPromise<void>;
  shareUserFund: (id: number, shareTo: number[], isShareAll: boolean) => AxiosPromise<void>;
  fetchUniverseStats: ({
    fundId,
    universeListId,
    fundListId,
    portfolioModelId,
    endDate,
    staticIndexIds,
    dynamicIndexIds,
    strategy,
    subStrategy,
    regionalFocus,
    inHFRI,
    inEmergingMarkets,
  }: {
    fundId: string;
    universeListId?: string[];
    fundListId?: string[];
    portfolioModelId?: string[];
    endDate: string;
    staticIndexIds: string[];
    dynamicIndexIds: string[];
    strategy: string[];
    subStrategy: string[];
    regionalFocus: string[];
    inHFRI: boolean;
    inEmergingMarkets: boolean;
  }) => AxiosPromise<PeerGroupUniverseStatsResponse>;
  fetchFundAndBenchmark: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    endDate,
  }: {
    fundId: string;
    benchmark1: string;
    benchmark2: string;
    benchmark3: string;
    endDate: string | moment.Moment;
  }) => AxiosPromise<PeerGroupFundBenchmarkResponse>;
  fetchFundPdfReport: ({
    fundId,
    data,
  }: {
    fundId: string;
    data: FetchUserFundPeerGroupPdfParams;
  }) => AxiosPromise<Blob>;
}

export const UserFundService = (request: AxiosInstance): UserFundService => {
  return {
    fetchAllUserFunds: () => request.get('/user-fund'),
    fetchUserFundDetails: id => request.get(`/user-fund/${id}`),
    createNewUserFund: data => request.post('/user-fund', sanatizeToSnakeCase(data)),
    updateUserFund: (id, data) => request.put(`/user-fund/${id}`, sanatizeToSnakeCase(data)),
    deleteUserFund: id => request.delete(`/user-fund/${id}`),
    shareUserFund: (id, shareTo, isShareAll) =>
      request.post(`/user-fund/${id}/share`, {
        user_ids: shareTo,
        all: isShareAll,
      }),
    fetchUniverseStats: ({
      fundId,
      universeListId,
      portfolioModelId,
      fundListId,
      endDate,
      staticIndexIds,
      dynamicIndexIds,
      strategy,
      subStrategy,
      regionalFocus,
      inHFRI,
      inEmergingMarkets,
    }) => {
      return request.post(`/user-fund/${parseInt(fundId)}/peer-group-analysis/universe-stats`, {
        universe_list_ids: universeListId,
        fund_list_ids: fundListId,
        portfolio_model_ids: portfolioModelId,
        end_date: endDate,
        static_index_ids: staticIndexIds,
        dynamic_index_ids: dynamicIndexIds,
        strategy,
        sub_strategy: subStrategy,
        regional_focus: regionalFocus,
        in_HFRI: inHFRI,
        in_emerging_markets: inEmergingMarkets,
      });
    },
    fetchFundAndBenchmark: ({ fundId, benchmark1, benchmark2, benchmark3, endDate }) =>
      request.post(`/user-fund/${parseInt(fundId)}/peer-group-analysis/fund-and-benchmark`, {
        benchmark1,
        benchmark2,
        benchmark3,
        end_date: endDate,
      }),
    fetchFundPdfReport: ({
      fundId,
      data,
    }: {
      fundId: string;
      data: FetchUserFundPeerGroupPdfParams;
    }) =>
      request.post(`/user-fund/${fundId}/peer-group-analysis/pdf`, data, {
        responseType: 'blob',
      }),
  };
};
