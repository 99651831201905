import React from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const FundStrategyDescription = ({ fundStrategyDescription = '' }) => {
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel showArrow={true} header="Strategy Description" key="1">
        <p>{fundStrategyDescription}</p>
      </Panel>
    </Collapse>
  );
};

export default FundStrategyDescription;
