import { Collapse, Table } from 'antd';
import React from 'react';
import RenderTableCell from './RenderTableCell';

const { Panel } = Collapse;

const YEARLY_MONTHLY_COLUMNS = [
  { title: 'Year', dataIndex: 'year', key: 'year' },
  {
    title: 'Jan',
    dataIndex: '01',
    key: '01',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Feb',
    dataIndex: '02',
    key: '02',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Mar',
    dataIndex: '03',
    key: '03',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Apr',
    dataIndex: '04',
    key: '04',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'May',
    dataIndex: '05',
    key: '05',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Jun',
    dataIndex: '06',
    key: '06',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Jul',
    dataIndex: '07',
    key: '07',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Aug',
    dataIndex: '08',
    key: '08',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Sep',
    dataIndex: '09',
    key: '09',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Oct',
    dataIndex: '10',
    key: '10',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Nov',
    dataIndex: '11',
    key: '11',
    align: 'right',
    render: RenderTableCell,
  },
  {
    title: 'Dec',
    dataIndex: '12',
    key: '12',
    align: 'right',
    render: RenderTableCell,
  },
];

interface Props {
  header: string;
  tableData: any;
  columns?: any;
  showTotal?: boolean;
}

const YearlyMonthlyDatatable: React.FC<Props> = ({
  header,
  tableData,
  columns,
  showTotal = false,
}) => {
  columns = columns || YEARLY_MONTHLY_COLUMNS;
  if (showTotal) {
    columns = columns.concat({
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: RenderTableCell,
    });
  }
  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel showArrow={true} header={header} key="1">
        <Table dataSource={tableData} columns={columns} pagination={false} />
      </Panel>
    </Collapse>
  );
};

export default YearlyMonthlyDatatable;
