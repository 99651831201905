import React from 'react';
import View from '@cobuildlab/react-flux-state';
import { Spin, notification } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, MapDispatchToPropsFunction } from 'react-redux';
import Axios from 'axios';
import { authStore, USER_ERROR_EVENT, USER_EVENT } from '../../modules/auth/auth-store';
import { fetchUser } from '../../modules/auth/auth-actions';
import { UserModel } from '../../modules/auth/User.model';
import SearchAction from '../../modules/search/search-action';
import PortfolioModelAction from '../../modules/model/portfolio-model-action';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { FundFirmPane } from '../../redux/reducers/root.reducer';
import { history } from '../../shared/history';
import { UserProfileDetails } from '../api/models/ResponsesTypes';
import APIService from '../api';

interface DispatchProps {
  fetchStrategyOptions: () => void;
  fetchSubStrategyOptions: () => void;
  fetchRegionalFocusOptions: () => void;
  fetchRegionalFocusCountryOptions: () => void;
  fetchLiquidAltTypeOptions: () => void;
  fetchLiquidAltRegionOptions: () => void;
  fetchMax12B1FeeOptions: () => void;
  fetchMaxFrontFeeOption: () => void;
  fetchMaxDeferredFeeOption: () => void;
  fetchProductClassOptions: () => void;
  fetchBenchmarkOptions: () => void;
  fetchAdvanceNoticeOptions: () => void;
  fetchMinInvestmentCurrOptions: () => void;
  fetchReportingOptions: () => void;
  fetchReportingStyleOptions: () => void;
  fetchReturnsCurrencyOptions: () => void;
  fetchRedemptionOptions: () => void;
  fetchSubscriptionsOptions: () => void;
  fetchFirmAssetCurrencyOptions: () => void;
  fetchFundAssetCurrencyOptions: () => void;
  fetchUserDefinedList: () => void;
  fetchUniverseList: () => void;
  fetchPortfolioModelList: () => void;
  fetchStaticHFRIndex: () => void;
  fetchDynamicHFRIndex: () => void;
  fetchCountryOptions: () => void;
  fetchStateOptions: () => void;
  fetchRORDateRangeOptions: () => void;
  fetchPeerGroupOptions: () => void;
  fetchNonSecRegAuthorities: () => void;
  fetchNonSecCountryOptions: () => void;
  fetchEsgStrategies: () => void;
  fetchEsgSubStrategies: () => void;
  fetchMinorityTypes: () => void;
  fetchFundDropdowns: () => void;
  addPane: (paneData: FundFirmPane) => void;
}

interface IOwnProps {}

/**
 * User Session component
 */

interface Props extends RouteComponentProps<{ fundId?: string; token?: string }> {
  // client: any;
  // logOut: () => void;
}
interface State {
  user: UserModel;
}

class Session extends View<Props & DispatchProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { user: authStore.getState(USER_EVENT) };
  }

  async componentDidMount() {
    this.subscribe(authStore, USER_EVENT, user => {
      this.setState({ user });
      this.props.fetchStrategyOptions();
      this.props.fetchSubStrategyOptions();
      this.props.fetchRegionalFocusOptions();
      this.props.fetchRegionalFocusCountryOptions();
      this.props.fetchLiquidAltTypeOptions();
      this.props.fetchLiquidAltRegionOptions();
      this.props.fetchMax12B1FeeOptions();
      this.props.fetchMaxFrontFeeOption();
      this.props.fetchMaxDeferredFeeOption();
      this.props.fetchProductClassOptions();
      this.props.fetchBenchmarkOptions();
      this.props.fetchMinInvestmentCurrOptions();
      this.props.fetchReportingOptions();
      this.props.fetchReportingStyleOptions();
      this.props.fetchReturnsCurrencyOptions();
      this.props.fetchRedemptionOptions();
      this.props.fetchSubscriptionsOptions();
      this.props.fetchAdvanceNoticeOptions();
      this.props.fetchFirmAssetCurrencyOptions();
      this.props.fetchFundAssetCurrencyOptions();
      this.props.fetchUserDefinedList();
      this.props.fetchUniverseList();
      this.props.fetchPortfolioModelList();
      this.props.fetchStaticHFRIndex();
      this.props.fetchDynamicHFRIndex();
      this.props.fetchCountryOptions();
      this.props.fetchStateOptions();
      this.props.fetchRORDateRangeOptions();
      this.props.fetchPeerGroupOptions();
      this.props.fetchNonSecRegAuthorities();
      this.props.fetchNonSecCountryOptions();
      this.props.fetchEsgStrategies();
      this.props.fetchEsgSubStrategies();
      this.props.fetchMinorityTypes();
      this.props.fetchFundDropdowns();
    });

    this.subscribe(authStore, USER_ERROR_EVENT, () => {
      this.props.history.push('/auth');
    });
    const { match, addPane } = this.props;
    const fundId = match.params.fundId;
    const token = match.params.token;

    const fetchMe = async () => {
      try {
        const { data: user } = await Axios.get<UserProfileDetails>('/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (token && user) {
          APIService.setToken(token);

          fetchUser();

          if (fundId) {
            history.push('/');
            addPane({
              title: '',
              key: `${fundId}-fund`,
              type: 'fund',
              fundFirmId: fundId,
            });
          } else {
            notification.warn({ message: 'Invalid credentials. Please login again!' });
            history.push('/');
          }
        }
      } catch (e) {
        notification.error({
          message:
            e.response.status === 401
              ? 'Invalid Token! Please login again'
              : e.response.data.message,
        });
        history.push('/auth');
        return;
      }
    };

    if (fundId) {
      fetchMe();
    } else {
      fetchUser();
    }
  }

  render() {
    if (!this.state.user) {
      return (
        <div className="center">
          <Spin size="large" />
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, IOwnProps> = dispatch => {
  return {
    fetchStrategyOptions: () => SearchAction.fetchStrategyOptions()(dispatch),
    fetchSubStrategyOptions: () => SearchAction.fetchSubStrategyOptions()(dispatch),
    fetchRegionalFocusOptions: () => SearchAction.fetchRegionalFocusOptions()(dispatch),
    fetchRegionalFocusCountryOptions: () =>
      SearchAction.fetchRegionalFocusCountryOptions()(dispatch),
    fetchLiquidAltTypeOptions: () => SearchAction.fetchLiquidAltTypeOptions()(dispatch),
    fetchLiquidAltRegionOptions: () => SearchAction.fetchLiquidAltRegionOptions()(dispatch),
    fetchMax12B1FeeOptions: () => SearchAction.fetchMax12B1FeeOptions()(dispatch),
    fetchMaxFrontFeeOption: () => SearchAction.fetchMaxFrontFeeOption()(dispatch),
    fetchMaxDeferredFeeOption: () => SearchAction.fetchMaxDeferredFeeOption()(dispatch),
    fetchProductClassOptions: () => SearchAction.fetchProductClassOptions()(dispatch),
    fetchBenchmarkOptions: () => SearchAction.fetchBenchmarkOptions()(dispatch),
    fetchAdvanceNoticeOptions: () => SearchAction.fetchAdvanceNoticeOptions()(dispatch),
    fetchMinInvestmentCurrOptions: () => SearchAction.fetchMinInvestmentCurrOptions()(dispatch),
    fetchReportingOptions: () => SearchAction.fetchReportingOptions()(dispatch),
    fetchReportingStyleOptions: () => SearchAction.fetchReportingStyleOptions()(dispatch),
    fetchReturnsCurrencyOptions: () => SearchAction.fetchReturnsCurrencyOptions()(dispatch),
    fetchRedemptionOptions: () => SearchAction.fetchRedemptionOptions()(dispatch),
    fetchSubscriptionsOptions: () => SearchAction.fetchSubscriptionsOptions()(dispatch),
    fetchFirmAssetCurrencyOptions: () => SearchAction.fetchFirmAssetCurrencyOptions()(dispatch),
    fetchFundAssetCurrencyOptions: () => SearchAction.fetchFundAssetCurrencyOptions()(dispatch),
    fetchUserDefinedList: () => SearchAction.fetchFundList()(dispatch),
    fetchUniverseList: () => SearchAction.fetchUniverseList()(dispatch),
    fetchPortfolioModelList: () => PortfolioModelAction.fetchPortfolioModels()(dispatch),
    fetchStaticHFRIndex: () => SearchAction.fetchStaticHFRIndexes(dispatch),
    fetchDynamicHFRIndex: () => SearchAction.fetchDynamicHFRIndexes(dispatch),
    fetchCountryOptions: () => SearchAction.fetchCountryOptions(dispatch),
    fetchStateOptions: () => SearchAction.fetchStateOptions(dispatch),
    fetchRORDateRangeOptions: () => SearchAction.fetchRORDateRangeOptions(dispatch),
    fetchPeerGroupOptions: () => SearchAction.fetchPeerGroupOptions(dispatch),
    fetchNonSecRegAuthorities: () => SearchAction.fetchNonSecRegAuthorities(dispatch),
    fetchNonSecCountryOptions: () => SearchAction.fetchNonSecCountryOptions(dispatch),
    fetchEsgStrategies: () => SearchAction.fetchEsgStrategies(dispatch),
    fetchEsgSubStrategies: () => SearchAction.fetchEsgSubStrategies(dispatch),
    fetchMinorityTypes: () => SearchAction.fetchMinorityTypes(dispatch),
    fetchFundDropdowns: () => SearchAction.fetchFundDropdowns(dispatch),
    addPane: (data: FundFirmPane) => {
      dispatch({ type: RootActionEnums.ADD_PANE, payload: data });
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Session));
