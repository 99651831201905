import React from 'react';
import styled from 'styled-components';
import { Modal, Input, Dropdown, Menu, Icon, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { MapDispatchToPropsFunction, connect } from 'react-redux';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import APIService from '../../../../shared/api';
import { useServiceState } from '../../../../shared/hooks/useServiceState';
import { QueryResponseType } from '../../../../shared/api/services/query.service';
import SearchActionEnums from '../../redux/search.ActionEnums';

const ButtonGroup = styled('div')`
  display: inline-block;
`;
interface DispatchProps {
  addSearchParams: (params: Partial<FetchFundParams>) => void;
}
interface Props {
  params: Partial<FetchFundParams>;
}

/**
 * QueryGroup - Componnent contains Save query/Load query buttons and its behaviour
 * @param params - Current query params
 * @param addSearchParams - redux dispatch action to store the params from the loaded query
 */
const QueryGroup: React.FC<DispatchProps & Props> = ({ params, addSearchParams }) => {
  const [showSaveQuery, setShowSaveQuery] = React.useState(false);
  const [inputName, setInputName] = React.useState();
  const inputRef = React.useRef<Input>(null);
  const { data: queryData, invoke: invokeFetchQuery } = useServiceState<QueryResponseType[]>(
    APIService.queryService.fetchAllQueries,
  );

  React.useEffect(() => {
    invokeFetchQuery();
  }, [invokeFetchQuery]);

  const handleSaveQuery = async () => {
    if (!inputName) {
      toast.info('Query Name is required!');
      inputRef && inputRef.current && inputRef.current.focus();
      return;
    }
    try {
      await APIService.queryService.createQuery(inputName, params);
      toast.success('Search is saved!');
      invokeFetchQuery();
      handleCancel();
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message || 'Can not save query. Please try again later!');
    }
  };

  const handleCancel = () => {
    setShowSaveQuery(false);
    setInputName(undefined);
  };

  const onClick = async ({ key }: { key: any }) => {
    const query = await APIService.queryService.fetchQueryById(key);
    const deSanatizeSnakeCase = _.mapKeys(query.data.json, (_value, key) => {
      switch (key) {
        case 'max_12b1_fee':
          return 'max_12b1Fee';
        default:
          return _.camelCase(key);
      }
    });
    addSearchParams(deSanatizeSnakeCase);
  };

  const renderQueryList = () => {
    return (
      <Menu style={{ maxHeight: 500, overflowY: 'auto' }} onClick={onClick}>
        {queryData && queryData.map(item => <Menu.Item key={item.id}>{item.name}</Menu.Item>)}
      </Menu>
    );
  };

  return (
    <ButtonGroup>
      <Tooltip title="Save Query">
        <Icon type="save" style={{ marginRight: 8 }} onClick={() => setShowSaveQuery(true)} />
      </Tooltip>
      <Tooltip title="Load Query">
        <Dropdown trigger={['click']} overlay={renderQueryList}>
          <Icon style={{ marginRight: 8 }} type="file" />
        </Dropdown>
      </Tooltip>
      <Modal
        title="Save your search"
        visible={showSaveQuery}
        width={500}
        centered
        onOk={() => {
          handleSaveQuery();
        }}
        onCancel={handleCancel}
      >
        <label>Search Name</label>
        <Input
          ref={inputRef}
          value={inputName}
          placeholder="Input search name"
          onChange={e => setInputName(e.target.value)}
        />
      </Modal>
    </ButtonGroup>
  );
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    addSearchParams: params => {
      dispatch({ type: SearchActionEnums.UPDATE_FORM_SEARCH_PARAMS, payload: params });
    },
  };
};

export default connect(null, mapDispatchToProps)(QueryGroup);
