import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Modal, notification, Pagination, Table, Tabs, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import { camelCase} from 'change-case';
import { connect, MapDispatchToProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { QueryResponseType } from '../../shared/api/services/query.service';
import { useServiceState } from '../../shared/hooks/useServiceState';
import { UserFundRecord } from '../../shared/api/models/ResponsesTypes';
import APIService from '../../shared/api';
import GenUtil from '../../shared/utils/gen-util';
import { Colors } from '../../shared/colors';
import SearchActionEnums from '../search/redux/search.ActionEnums';
import { FetchFundParams } from '../../shared/api/services/search.service';
import RootActionEnums from '../../redux/actions/root.ActionEnums';

const { TabPane } = Tabs;
const { confirm } = Modal;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
  }
`;
const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 140px);
`;
const Meta = styled('div')`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 16px;
    font-weight: 500;
  }
`;
interface DispatchProps {
  addSearchParams: (params: Partial<FetchFundParams>) => void;
  changePane: (key: string) => void;
}
interface Props {}

/**
 * Query View showing saved user queries
 * @param addSearchParams
 * @param changePane
 * @constructor
 */

const MySearchView: React.FC<DispatchProps & Props> = ({ addSearchParams, changePane }) => {
  const [dataSource, setDataSource] = useState<QueryResponseType[]>([]);
  const [page, setPage] = useState<number>(1);
  const { data: queryData, invoke: invokeQueryData, loading: queryLoading } = useServiceState<
    QueryResponseType[]
  >(APIService.queryService.fetchAllQueries);

  const history = useHistory();
  useEffect(() => {
    invokeQueryData();
  }, [invokeQueryData]);

  useEffect(() => {
    const ds: QueryResponseType[] = !_.isEmpty(queryData)
      ? queryData.slice((page - 1) * 15, page * 15).map(item => ({
          ...item,
          key: item.id,
        }))
      : [];
    setDataSource(ds);
  }, [queryData, page]);

  const COLUMNS: ColumnProps<QueryResponseType>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'View',
      dataIndex: 'json',
      key: 'json',
      width: 200,
      render: () => <Button> View </Button>,
      onCell: (record: any) => ({
        onClick: () => {
          const criteria: any = {};
          for (const key in record.json) {
            criteria[camelCase(key)] = record.json[key];
          }
          //Set Search pane as the active pane
          changePane('1');
          // update the search params
          addSearchParams(criteria);
          //Navigate to search screen
          history.push('/');
        },
      }),
    },
    {
      title: 'Created At',
      dataIndex: 'created_date',
      key: 'created_date',
      render: text => GenUtil.getFormattedYearMonthAndDay(text),
    },
    {
      title: 'Update At',
      dataIndex: 'updated_date',
      key: 'updated_date',
      render: text => GenUtil.getFormattedYearMonthAndDay(text),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Tooltip title="Delete">
          <Button type="danger" onClick={() => handleDelete(record)}>
            <Icon type="delete" />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const handleDelete = (record: UserFundRecord) => {
    confirm({
      title: `Do you want to delete '${record.name}'?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await APIService.queryService.deleteQuery(record.id);
          notification.success({ message: `${record.name} was deleted!`, placement: 'bottomLeft' });
          invokeQueryData();
        } catch (error) {
          console.error(error);
          notification.error({
            message: 'Something went wrong. Please try again later!',
            placement: 'bottomLeft',
          });
        }
      },
    });
  };
  return (
    <StyledTabs hideAdd activeKey="1" type="editable-card">
      <StyledTabPane tab="My Search" key="1" closable={false}>
        <Wrapper>
          <Table
            bordered
            loading={queryLoading}
            scroll={{ y: 'calc(100vh - 290px)' }}
            style={{ flexGrow: 1 }}
            size="small"
            pagination={false}
            dataSource={dataSource}
            columns={COLUMNS}
          />
          <Meta>
            <Pagination
              onChange={(p: number) => {
                setPage(p);
              }}
              total={queryData ? queryData.length : 0}
              current={page}
              pageSize={15}
            />
          </Meta>
        </Wrapper>
      </StyledTabPane>
    </StyledTabs>
  );
};
const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addSearchParams: params => {
      dispatch({ type: SearchActionEnums.UPDATE_FORM_SEARCH_PARAMS, payload: params });
    },
    changePane: (key: string) => {
      dispatch({ type: RootActionEnums.CHANGE_PANE, payload: key });
    },
  };
};

export default connect(null, mapDispatchToProps)(MySearchView);
