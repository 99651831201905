import { Icon, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import _ from 'lodash';
import React from 'react';
import { DownloadFileRecord } from '../../../shared/api/models/ResponsesTypes';
import GenUtil from '../../../shared/utils/gen-util';
import DownloadTableCell from './DownloadTableCell';

export interface DownloadTableData {
  key: number;
  type: string;
  file: string;
  size: string;
  description: string;
  download: string;
  date: string;
  file_bytes: number;
}

interface Props {
  tableData: DownloadFileRecord[];
}

const renderTypeCell = (fileType: string): JSX.Element => {
  return fileType === 'zip' ? (
    <Icon style={{ color: '#1890ff' }} type="folder-open" theme="filled" />
  ) : (
    <Icon style={{ color: '#1890ff' }} type="file" theme="filled" />
  );
};

const getTableDataSource = (tableData: DownloadFileRecord[]): DownloadTableData[] => {
  return tableData.map((data: DownloadFileRecord) => {
    return {
      key: data.id,
      type: data.extension ? data.extension.split('?')[0].substr(1) : '',
      file: data.name,
      size: data.size,
      description: data.description,
      download: data.name,
      date: data.updated_date,
      file_bytes: data.file_bytes,
    };
  });
};

/**
 * Download table component
 * @param tableData
 */

const DownloadDataTable: React.FC<Props> = ({ tableData }) => {
  const columns: ColumnProps<DownloadTableData>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      render: renderTypeCell,
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      sorter: (a, b) => a.file.localeCompare(b.file),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => GenUtil.getFormattedYearMonthAndDay(date),
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: '12%',
      sorter: (a, b) => {
        return a.file_bytes - b.file_bytes;
      },
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'download',
      render: (_, record: DownloadTableData) => <DownloadTableCell record={record} />,
    },
  ];
  const dataSource: DownloadTableData[] = _.isEmpty(tableData) ? [] : getTableDataSource(tableData);
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      bordered
      scroll={{ y: 'calc(100vh - 345px)' }}
      pagination={false}
    />
  );
};

export default DownloadDataTable;
