import React from 'react';
import styled from 'styled-components';

const IndicatorContainer = styled.span<{ withPadding?: boolean }>`
  display: inline-block;
  position: relative;
  padding-right: 22px;
`;
const Indicator = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  color: #e67f45;
  font-size: 10px;
  margin-left: 2px;
  margin-top: -5px;
`;

type NewIndicatorProps = {
  withPadding?: boolean; // Add a padding
};
export const NewIndicator: React.FC<NewIndicatorProps> = ({ children }) => {
  return (
    <IndicatorContainer>
      {children}
      <Indicator>New</Indicator>
    </IndicatorContainer>
  );
};
