import React from 'react';
import styled from 'styled-components';
import { Row, Col, Select, Form, Button } from 'antd';
import { connect, MapStateToProps } from 'react-redux';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import CreatableSelect from '../../../shared/components/CreatableSelect';
import { CombinedReducers } from '../../..';
import {
  FundDetailStrategy,
  FundDetailSubStrategy,
  FundDetailRegionalFocus,
} from '../../../shared/api/models/FundDetails';

const Wrapper = styled('div')``;

type Props = {
  form: WrappedFormUtils<any>;
  userFundSelectData?: { value: number; text: string }[];
  isCreateNew: boolean;
  setCreateNew: (isCreateNew: boolean) => void;
};

type StateProps = {
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
};

/**
 * The group of UserFund form dropdowns and a submit button.
 * @param form - Antd Form
 * @param userFundSelectData - Data for the options of UserFund dropdown
 * @param {boolean} isCreateNew - Is creating new is updating existed UserFund
 * @param setCreateNew - callback to update isCreateNew
 * @param strategyOptions - data for strategy options from redux store
 * @param subStrategyOptions - data for sub-strategy options from redux store
 * @param regionalFocusOptions - data for regional focus options from redux store
 */
const UserFundGroup: React.FC<StateProps & Props> = ({
  form,
  userFundSelectData,
  isCreateNew,
  setCreateNew,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
}) => {
  const { getFieldDecorator } = form;

  const [filteredSubStrategy, setFilteredSubStrategy] = React.useState<FundDetailSubStrategy[]>([]);

  React.useEffect(() => {
    const strategyFromForm = form.getFieldValue('strategies');
    if (strategyFromForm) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && strategyFromForm === subStrategy.strategy_code,
        ),
      );
    } else {
      setFilteredSubStrategy(subStrategyOptions);
    }
  }, [form, subStrategyOptions, setFilteredSubStrategy]);

  return (
    <Wrapper>
      <span>Adding to</span>
      <Row gutter={8}>
        <Col span={6}>
          <Form.Item>
            {getFieldDecorator('name')(
              <CreatableSelect
                data={userFundSelectData}
                placeholder="Select fund or enter to create new one"
                onCreate={isCreate => setCreateNew(isCreate)}
              />,
            )}
          </Form.Item>
        </Col>
        {isCreateNew && (
          <Col span={14}>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('strategies')(
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select Strategy"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {strategyOptions.map((item: FundDetailStrategy) => {
                      const { strategy_code, strategy_name } = item;
                      return (
                        <Select.Option key={strategy_code} value={strategy_code}>
                          {strategy_name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('subStrategy')(
                  <Select
                    placeholder="Select Sub Strategy"
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filteredSubStrategy.map((item: FundDetailSubStrategy) => {
                      const { sub_strategy_code, sub_strategy_name } = item;
                      return (
                        <Select.Option key={sub_strategy_code} value={sub_strategy_code}>
                          {sub_strategy_name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('regionalFocus')(
                  <Select
                    optionFilterProp="children"
                    allowClear
                    showSearch
                    placeholder="Select Regional Focus"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {regionalFocusOptions.map((item: FundDetailRegionalFocus) => {
                      const { id, name } = item;
                      return (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Col>
        )}
        <Col span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  strategyOptions: state.searchReducer.strategyOptions,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
  regionalFocusOptions: state.searchReducer.regionalFocusOptions,
});

export default connect<StateProps, {}, Props, CombinedReducers>(mapStateToProps)(UserFundGroup);
