import React from 'react';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import EditableContext from './EditableContext';
type Props = {} & FormComponentProps;

/**
 * Editable Row  represent a row in UserFund Table, that is wrapped with React context to provide the `form` to all its cells
 * @param form - Antd Form
 * @param restProps - all other props provided by the Table's components
 */
const EditableFormRow: React.FC<Props> = ({ form, ...props }) => {
  return (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );
};

export default Form.create()(EditableFormRow);
