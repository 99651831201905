import * as React from 'react';
import { useState } from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { Input, Modal, Tabs } from 'antd';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { CombinedReducers } from '../../../index';
import SearchAction from '../../search/search-action';
import PortfolioModelAction from '../portfolio-model-action';
import UniverseSelect from '../../../shared/components/UniverseSelect';
import {
  FundListRecord,
  PortfolioModelFundRecord,
  PortfolioModelRecord,
} from '../../../shared/api/models/ResponsesTypes';
import PortfolioModelSelect from '../../../shared/components/PortfolioModelSelect';
import APIService from '../../../shared/api';
import ModelActionEnums from '../redux/model-action-enums';
import { PortfolioPane } from '../redux/model-reducers';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface DispatchProps {
  addPane: (data: PortfolioPane) => void;
  fetchFundList: () => void;
  fetchPortfolioModels: () => Promise<PortfolioModelRecord[]>;
}

interface StateProps {
  selectedFunds: string[];
  fundList: FundListRecord[];
  portfolioModels: PortfolioModelRecord[];
}

enum FundListMode {
  ADD_FROM_LIST = 'ADD_FROM_LIST',
  ADD_FROM_MODELS = 'ADD_FROM_MODELS',
}

/**
 * This will create a Modal where we can create new Model based on Fund List or Existing Models list
 * @param fundList
 * @param isOpen
 * @param setOpen
 * @param portfolioModels
 * @param fetchPortfolioModels
 * @constructor
 */
const CreatePortfolioModal: React.FC<Props & DispatchProps & StateProps> = ({
  fundList,
  isOpen,
  setOpen,
  portfolioModels,
  addPane,
  fetchPortfolioModels,
}) => {
  const { TabPane } = Tabs;
  const [fundListMode, setFundListMode] = useState<FundListMode>(FundListMode.ADD_FROM_LIST);
  const [selectedFundListId, setSelectedFundListId] = useState<string>('');
  const [selectedPortfolioModelId, setSelectedPortfolioModelId] = useState<string>('');
  const [newListName, setNewListName] = useState<string>('');

  const refreshPortfolioModelList = () => {
    fetchPortfolioModels();
  };

  const createNewModel = () => {
    if (fundListMode === FundListMode.ADD_FROM_LIST && !selectedFundListId) {
      toast.error('Select one of the Universal List');
      return;
    } else if (fundListMode === FundListMode.ADD_FROM_MODELS && !selectedPortfolioModelId) {
      toast.error('Select one of the Model List');
      return;
    } else if (!newListName) {
      toast.error('Enter a valid name');
      return;
    }

    if (fundListMode === FundListMode.ADD_FROM_MODELS) {
      createNewModelFromPortfolioModel();
    } else if (fundListMode === FundListMode.ADD_FROM_LIST) {
      createNewModelFromFundList();
    }
  };

  const createNewModelFromPortfolioModel = async (): Promise<void> => {
    const res: AxiosResponse<PortfolioModelFundRecord[]> = await APIService.portfolioModelService.fetchPortfolioModelFunds(
      selectedPortfolioModelId,
    );
    const funds = res.data.map((portfolioFundRecord: PortfolioModelFundRecord) => {
      if (portfolioFundRecord.fund_id) {
        const { fund_id, weight } = portfolioFundRecord;
        return { fund_id, weight };
      } else {
        const { user_fund_id, weight } = portfolioFundRecord;
        return { user_fund_id, weight };
      }
    });
    await APIService.portfolioModelService.createNewModel(newListName, funds);
    toast.success(`Model ${newListName} has been created.`);
    const models: PortfolioModelRecord[] = await fetchPortfolioModels();
    models.sort((model1, model2) => (model1.id > model2.id ? 1 : -1));
    addPane({
      key: `${models[models.length - 1].id}`,
      portfolioModelId: `${models[models.length - 1].id}`,
      portfolioModelName: models[models.length - 1].name,
    });
    refreshPortfolioModelList();
    setOpen(false);
  };

  const createNewModelFromFundList = async (): Promise<void> => {
    if (!selectedFundListId) {
      toast.error('No Fund list is selected');
    } else {
      await APIService.portfolioModelService.createNewModelFromFundListId(
        newListName,
        selectedFundListId,
      );
      toast.success(`Model ${newListName} has been created.`);
      const models: PortfolioModelRecord[] = await fetchPortfolioModels();
      models.sort((model1, model2) => (model1.id > model2.id ? 1 : -1));
      addPane({
        key: `${models[models.length - 1].id}`,
        portfolioModelId: `${models[models.length - 1].id}`,
        portfolioModelName: models[models.length - 1].name,
      });
      refreshPortfolioModelList();
      setOpen(false);
    }
  };

  return (
    <Modal
      title="Create Portfolio Model"
      visible={isOpen}
      okText="Create Model"
      onOk={createNewModel}
      onCancel={() => setOpen(false)}
    >
      <Tabs activeKey={fundListMode} onChange={key => setFundListMode(key as FundListMode)}>
        <TabPane tab="Create from List" key={FundListMode.ADD_FROM_LIST}>
          <UniverseSelect
            data={fundList}
            selectedId={selectedFundListId}
            onChange={value => setSelectedFundListId(value.key)}
          />
          <Input
            style={{ marginTop: '30px', display: 'inline-block' }}
            placeholder="Enter name"
            value={newListName}
            onChange={event => setNewListName(event.target.value)}
          />
        </TabPane>
        <TabPane tab="Create from Portfolio Models" key={FundListMode.ADD_FROM_MODELS}>
          <PortfolioModelSelect
            data={portfolioModels}
            selectedId={selectedPortfolioModelId}
            onChange={value => setSelectedPortfolioModelId(value.key)}
          />
          <Input
            style={{ marginTop: '30px', display: 'inline-block' }}
            placeholder="Enter name"
            value={newListName}
            onChange={event => setNewListName(event.target.value)}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    selectedFunds: state.modelReducer.selectedFunds,
    fundList: state.rootReducer.fundList,
    portfolioModels: state.modelReducer.portfolioModels,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    addPane: (data: PortfolioPane) => {
      dispatch({ type: ModelActionEnums.ADD_PORTFOLIO_PANE, payload: data });
    },
    fetchFundList: () => SearchAction.fetchFundList()(dispatch),
    fetchPortfolioModels: (): Promise<PortfolioModelRecord[]> =>
      PortfolioModelAction.fetchPortfolioModels()(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePortfolioModal);
