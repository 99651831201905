import { AxiosInstance, AxiosPromise } from 'axios';
import { sanatizeToSnakeCase } from '../../strings';
import { FirmDetailResponse, FundDetails } from '../models/FundDetails';
import {
  AUMRecord,
  BenchmarkRecord,
  DisclaimerPrompt,
  DisclaimerResponse,
  ListResponse,
  ProfileStats,
  RORDetails,
  RORRecord,
} from '../models/ResponsesTypes';
import { FetchFundParams } from './search.service';

export interface FundServiceMethods {}

export interface FetchFundPdfParams {
  start_date: string;
  end_date: string;
  benchmark1: string;
  benchmark2: string;
  benchmark3: string;
}

export interface FundService {
  fetchRORDetails: ({
    fundId,
    fromDate,
    toDate,
  }: {
    fundId: string;
    fromDate?: string;
    toDate?: string;
  }) => AxiosPromise<RORDetails>;
  fetchAUMDetails: ({
    fundId,
    fromDate,
    toDate,
  }: {
    fundId: string;
    fromDate?: string;
    toDate?: string;
  }) => AxiosPromise<ListResponse<AUMRecord>>;
  fetchRORDetailsForBenchmarks: ({
    fundIds,
    fromDate,
    toDate,
  }: {
    fundIds: string[];
    fromDate?: string;
    toDate?: string;
  }) => AxiosPromise<ListResponse<RORRecord>>;
  fetchAvailableBenchmarkOptions: () => AxiosPromise<BenchmarkRecord[]>;
  fetchDisclaimer: (
    placement: 'universe' | 'model' | 'profile' | 'ranking',
  ) => AxiosPromise<DisclaimerResponse>;
  fetchDisclaimerPrompt: () => AxiosPromise<DisclaimerPrompt>;
  approveDisclaimerPrompt: () => AxiosPromise<void>;
  fetchFunds: (params: Partial<FetchFundParams>) => AxiosPromise<ListResponse<FundDetails>>;
  fetchExportFunds: (params: Partial<FetchFundParams>) => AxiosPromise<Blob>;
  fetchFundPdfReport: ({
    fundId,
    data,
  }: {
    fundId: string;
    data: FetchFundPdfParams;
  }) => AxiosPromise<Blob>;
  fetchFundDetails: (fundId: string) => AxiosPromise<FundDetails>;
  fetchFirmDetails: (firmId: string) => AxiosPromise<FirmDetailResponse>;
  fetchProfileStatsDetail: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    startDate,
    endDate,
  }: {
    fundId: string;
    benchmark1: string;
    benchmark2: string;
    benchmark3: string;
    startDate?: string;
    endDate?: string;
  }) => AxiosPromise<ProfileStats>;
  fetchAllCountries: () => AxiosPromise<string[]>;
  fetchAllCountryName: () => AxiosPromise<string[]>;
  fetchStateCodes: () => AxiosPromise<string[]>;
  fetchRORDateRange: () => AxiosPromise<any>;
  fetchPeerGroup: () => AxiosPromise;
}

export const FundService = (request: AxiosInstance): FundService => {
  return {
    fetchRORDetails: ({ fundId, fromDate, toDate }) =>
      request.get(`/funds-api/fund/${fundId.trim()}/ror`, {
        params: {
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),
    fetchAUMDetails: ({ fundId, fromDate, toDate }) =>
      request.get(`/funds-api/fund/${fundId.trim()}/aum`, {
        params: {
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),
    fetchRORDetailsForBenchmarks: ({ fundIds, fromDate, toDate }) =>
      request.get('/funds-api/fund/ror', {
        params: {
          fund_ids: JSON.stringify(fundIds.map(id => Number(id.trim()))),
          from: fromDate ? fromDate : null,
          to: toDate ? toDate : null,
        },
      }),
    fetchAvailableBenchmarkOptions: () => request.get('/funds-api/data/available-benchmarks'),
    fetchDisclaimer: (placement: 'universe' | 'model' | 'profile' | 'ranking' | 'download') =>
      request.get(`/disclaimer/${placement}`),
    fetchDisclaimerPrompt: () => request.get('/disclaimer/prompt/hfrweb'),
    approveDisclaimerPrompt: () => request.post('/disclaimer/prompt/hfrweb/approve'),
    fetchFunds: (params: Partial<FetchFundParams>) =>
      request.post('/funds-api/fund', { ...sanatizeToSnakeCase(params) }),
    fetchExportFunds: (params: Partial<FetchFundParams>) =>
      request.post(
        '/funds-api/fund/export',
        { ...sanatizeToSnakeCase(params) },
        {
          responseType: 'blob',
        },
      ),
    fetchFundPdfReport: ({ fundId, data }: { fundId: string; data: FetchFundPdfParams }) =>
      request.post(`/funds-api/fund/${fundId}/pdf`, data, {
        responseType: 'blob',
      }),
    fetchFundDetails: fundId => request.get(`/funds-api/fund/${fundId.trim()}`),
    fetchFirmDetails: firmId => request.get(`/funds-api/firm/${firmId.trim()}`),
    fetchProfileStatsDetail: ({
      fundId,
      benchmark1,
      benchmark2,
      benchmark3,
      startDate,
      endDate,
    }) => {
      return request.get(`/funds-api/fund/${parseInt(fundId, 10)}/profile-stats`, {
        params: {
          benchmark1: parseInt(benchmark1, 10),
          benchmark2: parseInt(benchmark2, 10),
          benchmark3: parseInt(benchmark3, 10),
          start_date: startDate ? startDate : null,
          end_date: endDate ? endDate : null,
        },
      });
    },
    fetchAllCountries: () => request.get('/country'),
    fetchAllCountryName: () => request.get('/funds-api/data/country-names'),
    fetchStateCodes: () => request.get('/funds-api/data/state-codes'),
    fetchRORDateRange: () => request.get('/funds-api/data/ror-date-range'),
    fetchPeerGroup: () => request.get('/funds-api/data/peer-group'),
  };
};
