import React from 'react';
import { Form, Input } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import EditableContext from './EditableContext';

type Props = {
  dataIndex: string;
  handleSave: (row: any) => void;
  record: any;
  editable: boolean;
};

/**
 * Editable Cell - The cell in UserFund Table
 * Support editing when focus. Data is saved when enter is hit or leaving the input
 * @param {string} dataIndex - the dataIndex key for each cell
 * @param record - the data object for corresponding row
 * @param handleSave - callback to update the editing data. Triggered when enter is hit or in onBlur of the input
 */
const EditableCell: React.FC<Props> = ({
  editable,
  dataIndex,
  record,
  handleSave,
  children,
  ...restProps
}) => {
  const inputRef = React.useRef<Input>(null);
  const form = React.useContext(EditableContext) as WrappedFormUtils<any>;
  const [editing, setEditing] = React.useState(false);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  React.useEffect(() => {
    if (editing) {
      inputRef && inputRef.current && inputRef.current.focus();
    }
  }, [editing]);

  const save = () => {
    form.validateFields((error, values) => {
      if (error) {
        return;
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  return (
    <td {...restProps}>
      {!editable && children}
      {editable &&
        (editing ? (
          <Form.Item style={{ margin: 0 }}>
            {form.getFieldDecorator(dataIndex, { initialValue: record[dataIndex] })(
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
            )}
          </Form.Item>
        ) : (
          <div
            style={{ paddingRight: 24, padding: '5px 12px', cursor: 'pointer' }}
            onClick={toggleEdit}
          >
            {children}
          </div>
        ))}
    </td>
  );
};

export default EditableCell;
