import { AxiosInstance, AxiosPromise } from 'axios';
import { DownloadFileRecord } from '../models/ResponsesTypes';

export interface DownloadFileService {
  fetchFiles: () => AxiosPromise<DownloadFileRecord[]>;
  downloadFile: (fileId: number) => AxiosPromise<Blob>;
}

export const DownloadFileService = (request: AxiosInstance): DownloadFileService => {
  return {
    fetchFiles: () => request.get('/files'),
    downloadFile: (fileId: number) => {
      return request.get(`/files/${fileId}`, {
        responseType: 'blob',
      });
    },
  };
};
