import { Button, Col, Icon, Row, Select, Spin, Tabs } from 'antd';
import JsFileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { CombinedReducers } from '../../index';
import APIService from '../../shared/api';
import { FundDetailRegionalFocus } from '../../shared/api/models/FundDetails';
import { DisclaimerResponse, UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import {
  HistoricalPeriodType,
  HistoricalSourceType,
  HistoricalType,
} from '../../shared/api/services/historic-ror-aum-service';
import { Colors } from '../../shared/colors';
import { UniverseSelectTypeEnum } from '../../shared/components/UniverseSelect';
import { useServiceState } from '../../shared/hooks/useServiceState';
import HistoricRorAumTable from './components/HistoricRorAumTable';
import PeerGroupSelect from './PeerGroupSelect';

const { TabPane } = Tabs;
const { Option } = Select;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
  margin: 20px;
  display: flex;
  width: calc(100vw - 10px);
  flex-direction: column;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
  }
`;
const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 140px);
`;

const FooterCell = styled.div`
  margin: 10px;
  display: flex;
  margin-left: 0;
  & > * {
    margin-right: 10px;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisclaimerContainer = styled(Row)`
  overflow-y: auto;
  min-height: 80px;
  max-height: 80px;
  font-size: 9px;
`;

export enum HistoricSearchType {
  MONTHLYROR = 'monthlyRor',
  YEARLYROR = 'yearlyRor',
  MONTHLYAUM = 'monthlyAum',
}

type Props = {};

type StateProps = {
  exportRorFundLimit: number;
  user: UserProfileDetails;
  peerGroupOptions: FundDetailRegionalFocus[];
};
/**
 * View for Historic ROR and AUM
 * @param universeListData
 * @constructor
 */
const HistoricRorAumView: React.FC<Props & StateProps> = ({
  exportRorFundLimit,
  user,
  peerGroupOptions,
}) => {
  const [selectedUniverseList, setSelectedUniverseList] = useState<string>();
  const [exportLoading, setExportLoading] = useState(false);

  const [selectedPeerGroup, setSelectedPeerGroup] = useState<string>();

  const [historicSearchType, setHistoricSearchType] = useState<HistoricSearchType>(
    HistoricSearchType.MONTHLYROR,
  );

  const [page, setPage] = useState<number>(1);

  const {
    data: historicalDataList,
    invoke: invokeHistoricalDataList,
    loading: historicalDataListLoading,
  } = useServiceState(APIService.historicRorAumService.fetchHistoricalDataList);

  const {
    data: historicalData,
    invoke: invokeHistoricalData,
    loading: historicalDataLoading,
  } = useServiceState(APIService.historicRorAumService.fetchHistoricalData);

  // const [tableData] = useState<HistoricRorAumResData[]>([]);
  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  useEffect(() => {
    invokeHistoricalDataList();
    invokeDisclaimerData('universe');
  }, [invokeHistoricalDataList, invokeDisclaimerData]);

  // transform historical data list
  const resolvedHistoricalDataList = React.useMemo(() => {
    return historicalDataList.length
      ? historicalDataList.map(item => {
          switch (item.type) {
            case 'universe_list':
              return {
                ...item,
                id: `${UniverseSelectTypeEnum.HFR}-${item.id}`,
                name: `(${UniverseSelectTypeEnum.HFR}) ${item.name}`,
              };
            case 'fund_list':
              return {
                ...item,
                id: `${UniverseSelectTypeEnum.LIST}-${item.id}`,
                name: `(${UniverseSelectTypeEnum.LIST}) ${item.name}`,
              };
            case 'portfolio_model':
              return {
                ...item,
                id: `${UniverseSelectTypeEnum.PORTFOLIO}-${item.id}`,
                name: `(${UniverseSelectTypeEnum.PORTFOLIO}) ${item.name}`,
              };
            default:
              return item;
          }
        })
      : [];
  }, [historicalDataList]);

  const getFilename = React.useCallback(() => {
    if (!historicalDataList || !selectedUniverseList) {
      return 'exported';
    }
    const selectedList = historicalDataList.find(
      item => item.id === +selectedUniverseList.split('-')[1],
    );
    return selectedList ? selectedList.name : 'exported';
  }, [historicalDataList, selectedUniverseList]);

  const getHistoricalData = React.useCallback(
    async (isExporting?: boolean) => {
      if (!selectedUniverseList && !selectedPeerGroup) {
        return;
      }
      const [listType, listId] = selectedUniverseList
        ? selectedUniverseList.split('-')
        : selectedPeerGroup
        ? [UniverseSelectTypeEnum.PEER_GROUP, selectedPeerGroup]
        : ['universe_list', ''];
      let sourceType: HistoricalSourceType;
      switch (listType) {
        case UniverseSelectTypeEnum.HFR:
          sourceType = 'universe_list';
          break;
        case UniverseSelectTypeEnum.LIST:
          sourceType = 'fund_list';
          break;
        case UniverseSelectTypeEnum.PORTFOLIO:
          sourceType = 'portfolio_model';
          break;
        case UniverseSelectTypeEnum.PEER_GROUP:
          sourceType = 'peer_group';
          break;
        default:
          sourceType = 'universe_list';
      }
      let dataType: HistoricalType;
      let period: HistoricalPeriodType;
      switch (historicSearchType) {
        case HistoricSearchType.MONTHLYAUM:
          dataType = 'aum';
          period = 'monthly';
          break;
        case HistoricSearchType.MONTHLYROR:
          dataType = 'ror';
          period = 'monthly';
          break;
        case HistoricSearchType.YEARLYROR:
          dataType = 'ror';
          period = 'yearly';
          break;
        default:
          dataType = 'aum';
          period = 'monthly';
      }
      if (isExporting) {
        setExportLoading(true);
        const { data } = await APIService.historicRorAumService.exportHistoricalData({
          sourceType,
          entityId: +listId,
          dataType,
          period,
        });
        JsFileDownload(data, `${getFilename()}.csv`, 'application/csv');
        setExportLoading(false);
      } else {
        invokeHistoricalData({ sourceType, entityId: +listId, dataType, period, page });
      }
    },
    [
      selectedUniverseList,
      selectedPeerGroup,
      historicSearchType,
      getFilename,
      invokeHistoricalData,
      page,
    ],
  );

  useEffect(() => {
    if ((selectedUniverseList || selectedPeerGroup) && historicSearchType) {
      getHistoricalData();
    }
  }, [selectedUniverseList, historicSearchType, getHistoricalData, selectedPeerGroup]);

  const handleRefresh = () => {
    getHistoricalData();
  };

  const handleExport = async () => {
    getHistoricalData(true);
  };

  const handlePeerGroupChange = (peerGroup?: string) => {
    setSelectedPeerGroup(peerGroup);
    setSelectedUniverseList(undefined);
  };

  const getExportMessage = () => {
    if (!historicalData.total_count) {
      return '';
    } else {
      return '** Data displayed above are from year 2000. Data from inception and prior to year 2000 are included via Download.';
      // return totalFunds > 500
      //   ? 'List exceeds 500 funds. Export to view ROR/AUM data'
      //   : 'Data displayed above are from year 2000. Data from inception and prior to year 2000 are included via Download.';
    }
  };

  return (
    <StyledTabs hideAdd activeKey="1" type="editable-card">
      <StyledTabPane tab="Historical ROR/AUM" key="1" closable={false}>
        <Spin spinning={historicalDataLoading} tip="loading">
          <Wrapper>
            <Row
              style={{
                marginBottom: 10,
              }}
            >
              <Col span={16}>
                <span>Available List: </span>
                <Select
                  loading={historicalDataListLoading}
                  style={{ width: 300, marginLeft: 10, marginRight: 10 }}
                  value={selectedUniverseList}
                  showSearch
                  placeholder="Select Universe List"
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    setSelectedUniverseList(value);
                    setSelectedPeerGroup(undefined);
                  }}
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {resolvedHistoricalDataList &&
                    resolvedHistoricalDataList.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                </Select>
                {user.has_peer_group_access && (
                  <PeerGroupSelect
                    value={selectedPeerGroup}
                    onChange={handlePeerGroupChange}
                    options={peerGroupOptions}
                  />
                )}
                <Button onClick={handleRefresh}>
                  <Icon type="sync" />
                </Button>
              </Col>
              <Col
                span={8}
                style={{
                  textAlign: 'right',
                }}
              >
                <span>Historical Type: </span>
                <Select
                  style={{ width: 200, marginLeft: 10 }}
                  value={historicSearchType}
                  showSearch
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    setHistoricSearchType(value);
                  }}
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  <Option value={HistoricSearchType.YEARLYROR}>Yearly ROR</Option>
                  <Option value={HistoricSearchType.MONTHLYROR}>Monthly ROR</Option>
                  <Option value={HistoricSearchType.MONTHLYAUM}>Monthly AUM (Millions)</Option>
                </Select>
              </Col>
            </Row>
            {exportRorFundLimit !== 0 && (
              <Row>
                <p style={{ color: '#f5222d' }}>
                  You only have limit of {exportRorFundLimit} fund export.
                </p>
              </Row>
            )}

            <HistoricRorAumTable
              tableData={historicalData.data}
              page={page}
              setPage={setPage}
              totalPages={historicalData.total_pages}
              totalCount={historicalData.total_count}
              historicSearchType={historicSearchType}
              minDate={historicalData.min_date}
              maxDate={historicalData.max_date}
            />
            <Footer>
              <FooterCell>
                <Button
                  type="primary"
                  loading={exportLoading}
                  disabled={historicalData.total_count === 0}
                  onClick={handleExport}
                >
                  Export
                </Button>
                <span>{getExportMessage()}</span>
              </FooterCell>
            </Footer>
            <DisclaimerContainer
              dangerouslySetInnerHTML={{
                __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
              }}
            />
          </Wrapper>
        </Spin>
      </StyledTabPane>
    </StyledTabs>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  exportRorFundLimit: state.rootReducer.userProfile.export_ror_fund_limit,
  user: state.rootReducer.userProfile,
  peerGroupOptions: state.searchReducer.peerGroupOptions,
});

export default connect(mapStateToProps)(HistoricRorAumView);
