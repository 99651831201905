class SearchActionEnums {
  static RESET_STORE = 'RESET_STORE';
  static UPDATE_FORM_SEARCH_PARAMS = 'update_form_search_params';
  static UPDATE_STRATEGY_OPTIONS = 'update_strategy_options';
  static UPDATE_SUB_STRATEGY_OPTIONS = 'update_sub_strategy_options';
  static UPDATE_REGIONAL_FOCUS_OPTIONS = 'update_regional_focus_options';
  static UPDATE_REGIONAL_FOCUS_COUNTRY_OPTIONS = 'update_regional_focus_country_options';
  static UPDATE_LIQUID_ALT_TYPE_OPTIONS = 'update_liquid_alt_type_options';
  static UPDATE_LIQUID_ALT_REGION_OPTIONS = 'update_liquid_alt_region_options';
  static UPDATE_MAX_12B1_FEE_OPTIONS = 'update_max_12b1_fee_options';
  static UPDATE_MAX_FRONT_FEE_OPTIONS = 'update_max_front_fee_options';
  static UPDATE_MAX_DEFERRED_FEE_OPTIONS = 'update_max_deferred_fee_options';
  static UPDATE_PRODUCT_CLASS_OPTIONS = 'update_product_class_options';
  static UPDATE_BENCHMARK_OPTIONS = 'update_benchmark_options';
  static UPDATE_ADVANCE_NOTICE_OPTIONS = 'update_advance_notice_options';
  static UPDATE_MIN_INVESTMENT_CURR_OPTIONS = 'update_min_investment_curr_options';
  static UPDATE_REPORTING_OPTIONS = 'update_reporting_options';
  static UPDATE_REPORTING_STYLE_OPTIONS = 'update_reporting_style_options';
  static UPDATE_RETURNS_CURRENCY_OPTIONS = 'update_returns_currency_options';
  static UPDATE_REDEMPTION_OPTIONS = 'update_redemptions_options';
  static UPDATE_SUBSCRIPTIONS_OPTIONS = 'update_subscriptions_options';
  static UPDATE_FIRM_ASSET_CURRENCY_OPTIONS = 'update_firm_asset_currency_options';
  static UPDATE_FUND_ASSET_CURRENCY_OPTIONS = 'update_fund_asset_currency_options';
  static UPDATE_COUNTRY_OPTIONS = 'UPDATE_COUNTRY_OPTIONS';
  static UPDATE_STATE_OPTIONS = 'UPDATE_STATE_OPTIONS';
  static UPDATE_ROR_DATE_RANGE_OPTIONS = 'UPDATE_ROR_DATE_RANGE_OPTIONS';
  static UPDATE_PEER_GROUP_OPTIONS = 'UPDATE_PEER_GROUP_OPTIONS';
  static UPDATE_NON_SEC_REG_AUTHORITIES_OPTIONS = 'UPDATE_NON_SEC_REG_AUTHORITIES_OPTIONS';
  static UPDATE_NON_SEC_COUNTRY_OPTIONS = 'UPDATE_NON_SEC_COUNTRY_OPTIONS';
  static UPDATE_ESG_STRATEGIES = 'UPDATE_ESG_STRATEGIES';
  static UPDATE_ESG_SUB_STRATEGIES = 'UPDATE_ESG_SUB_STRATEGIES';
  static UPDATE_MINORITY_TYPES = 'UPDATE_MINORITY_TYPES';
  static UPDATE_FUND_DROPDOWNS = 'UPDATE_FUND_DROPDOWNS';
}

export default SearchActionEnums;
