import { AxiosInstance, AxiosPromise } from 'axios';
import { sanatizeToSnakeCase } from '../../strings';

export interface FetchInvestorParams {
  alternative_assets_lte: number;
  alternative_assets_gte: number;
  alternative_assets_as_of_lte: string;
  alternative_assets_as_of_gte: string;
  company_name: string;
  investor_type: number[];
  primary_address1: string;
  primary_address2: string;
  primary_city: string;
  primary_state: number[];
  primary_country: string[];
  primary_postal_code: string;
  secondary_address1: string;
  secondary_address2: string;
  secondary_city: string;
  secondary_state: number[];
  secondary_country: number[];
  secondary_postal_code: string;
  primary_phone_number: string;
  secondary_phone_number: string;
  company_email: string;
  website_address: string;
  investment_executive: string;
  other_investment_professionals: string;
  entity_type: number[];
  total_assets_lte: number;
  total_assets_gte: number;
  hedge_fund_assets_lte: number;
  hedge_fund_assets_gte: number;
  total_assets_as_of_lte: string;
  total_assets_as_of_gte: string;
  hedge_fund_assets_as_of_lte: string;
  hedge_fund_assets_as_of_gte: string;
  investment_strategy: number[];
  investment_strategy_string: string;

  sort_by: string;
  order_by: string;
  limit: number;
  page: number;
}
export interface InvestorDBDropdownRespone {
  Country: DropdownInvestorDBType[];
  'Entity Type': DropdownInvestorDBType[];
  'Investor Type': DropdownInvestorDBType[];
  State: DropdownInvestorDBType[];
  Strategy: DropdownInvestorDBType[];
}
export interface DropdownInvestorDBType {
  id: number;
  value: string;
}
export interface InvestorDBResponse {
  count: number;
  results: InvestorDatabaseType[];
}

export interface PeopleInvestorDetail {
  full_name: string;
  role: string;
}

export interface InvestorDBDetails {
  investor: InvestorDatabaseType;
  people: PeopleInvestorDetail[];
}

export interface InvestorDatabaseType {
  alternative_assets: number;
  alternative_assets_as_of: string;
  company_email: string;
  company_name: string;
  entity_type: string;
  hedge_fund_assets: number;
  hedge_fund_assets_as_of: string;
  hfr_id: number;
  investment_executive: string;
  investment_strategy: string[];
  investment_strategy_string: string;
  investor_type: string;
  latitude: number;
  longitude: number;
  other_investment_professionals: string;
  primary_address1: string;
  primary_address2: string;
  primary_city: string;
  primary_country: string;
  primary_phone_number: string;
  primary_postal_code: string;
  primary_state: string;
  secondary_address1: string;
  secondary_address2: string;
  secondary_city: string;
  secondary_country: string;
  secondary_phone_number: string;
  secondary_postal_code: string;
  secondary_state: string;
  total_assets: number;
  total_assets_as_of: string;
  website_address: string;
}

export interface InvestorDBLocation {
  alternative_assets: number;
  alternative_assets_as_of: string;
  investment_executive: string;
  company_name: string;
  entity_type: string;
  hedge_fund_assets: string;
  hedge_fund_assets_as_of: string;
  hfr_id: number;
  investor_type: string;
  latitude?: number;
  longitude?: number;
  primary_address1: string;
  primary_address2: string;
  primary_city: string;
  primary_country: string;
  primary_postal_code: number;
  primary_state: string;
  secondary_address1: string;
  secondary_address2: string;
  secondary_city: string;
  secondary_country: string;
  secondary_postal_code: string;
  secondary_state: string;
  total_assets: number;
  total_assets_as_of: string;
}

export interface InvestorDBService {
  searchInvestor: (params: FetchInvestorParams) => AxiosPromise<InvestorDBResponse>;
  getDropdownSearch: () => AxiosPromise<InvestorDBDropdownRespone>;
  fetchLocations: (params: FetchInvestorParams) => AxiosPromise<InvestorDBLocation[]>;
  fetchInvestorDetail: (investorId: number) => AxiosPromise<InvestorDBDetails>;
  fetchDisclaimer: () => AxiosPromise;
  fetchExportInvestorDB: (params: Partial<FetchInvestorParams>) => AxiosPromise<Blob>;
}

export const InvestorDBService = (request: AxiosInstance): InvestorDBService => {
  return {
    searchInvestor: params => request.post('/investordb/search', params),
    getDropdownSearch: () => request.get('/investordb/dropdowns'),
    fetchLocations: params => request.post('/investordb/locations', params),
    fetchInvestorDetail: investorId => request.get(`/investordb/investors/${investorId}`),
    fetchDisclaimer: () => request.get('disclaimer/investordb'),
    fetchExportInvestorDB: params =>
      request.post(
        '/investordb/search/export',
        { ...sanatizeToSnakeCase(params) },
        {
          responseType: 'blob',
        },
      ),
  };
};
