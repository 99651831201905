import { Button, Col, Collapse, Icon, Input, Modal, notification, Row } from 'antd';
import React, { useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableLocation,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import RootActionEnums from '../../../../redux/actions/root.ActionEnums';
import { InitialRootReducer } from '../../../../redux/reducers/root.reducer';
import { Colors } from '../../../../shared/colors';
import { allColumns, ColumnPropsExtended, ColumnSource, defaultColumns } from './columnSource';

const InfoText = styled.span`
  margin-left: 10px;
`;

const Wrapper = styled.div`
  max-height: 90vh;
`;

enum DroppableIds {
  TargetColumns = 'targetColumns',
  SourceColumns = 'sourceColumn',
}

const { Panel } = Collapse;

const grid = 8;

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  borderRadius: '5px',
  padding: grid,
  minHeight: '100%',
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging ? Colors.primary : 'white',
  border: `1px solid ${Colors.primary}`,
  borderRadius: '5px',
  marginBottom: '3px',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list: ColumnPropsExtended[] | undefined, startIndex: number, endIndex: number) => {
  if (!list || !list.length) {
    return [];
  }
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const remove = (target: ColumnPropsExtended[], startIndex: number) => {
  const results = Array.from(target);
  results.splice(startIndex, 1);
  return results;
};

const move = (
  source: ColumnSource[],
  destination: any,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const sourceClone = source.reduce<ColumnPropsExtended[]>(
    (s, i) => (i.columns ? [...s, ...i.columns] : s),
    [],
  );

  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index - 1, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone,
  };
};

interface HeaderGroupProps {
  groupName: string;
  onAddAll: () => void;
}

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderGroup: React.FC<HeaderGroupProps> = ({ groupName, onAddAll }) => {
  return (
    <Flex>
      <span>{groupName}</span>
      <Button type="link" size="small" onClick={onAddAll}>
        Add all
      </Button>
    </Flex>
  );
};

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface StateProps {
  fundColumns?: ColumnPropsExtended[];
}

interface DispatchProps {
  updateFundColumns: (colKeys: string[]) => void;
}

const ColumnBuilder: React.FC<Props & StateProps & DispatchProps> = ({
  isOpen,
  setOpen,
  fundColumns,
  updateFundColumns,
}) => {
  const [targetColumns, setTargetColumns] = React.useState<ColumnPropsExtended[]>(
    (fundColumns && fundColumns.filter(col => !!col)) || defaultColumns,
  );

  const [textColumnSearch, setTextColumnSearch] = React.useState<string>('');
  const [activePanels, setActivePanels] = React.useState<string[]>([]);

  const sourceColumns = useMemo(() => {
    if (textColumnSearch !== '') {
      return allColumns.map(i => ({
        ...i,
        columns: i.columns.filter(
          c =>
            c.title &&
            c.title
              .toString()
              .toLowerCase()
              .includes(textColumnSearch.toLowerCase()) &&
            !targetColumns.some(t => t.key === c.key),
        ),
      }));
    }

    return allColumns.map(i => ({
      ...i,
      columns: i.columns.filter(c => !targetColumns.some(t => t.key === c.key)),
    }));
  }, [targetColumns, textColumnSearch]);

  React.useEffect(() => {
    textColumnSearch && setActivePanels(sourceColumns.map(item => item.groupName));
  }, [sourceColumns, textColumnSearch]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === DroppableIds.TargetColumns &&
      destination.droppableId !== DroppableIds.TargetColumns
    ) {
      const result = remove(targetColumns, source.index);

      setTargetColumns(result);
      return;
    }

    if (
      source.droppableId === DroppableIds.SourceColumns &&
      destination.droppableId === DroppableIds.TargetColumns
    ) {
      const result = move(sourceColumns, targetColumns, source, destination);

      // @ts-ignore
      setTargetColumns(result[DroppableIds.TargetColumns]);
      return;
    }

    if (
      source.droppableId === DroppableIds.TargetColumns &&
      destination.droppableId === DroppableIds.TargetColumns
    ) {
      const result = reorder(targetColumns, source.index, destination.index);

      setTargetColumns(result);
      return;
    }
  };

  const uniqueColumns = (columns: ColumnPropsExtended[]) =>
    Array.from(new Set(columns.map(item => item.key))).map(key => {
      return columns.find(item => item.key === key) as ColumnPropsExtended;
    });

  const handleAddGroupColumns = (columns: ColumnPropsExtended[]) => {
    setTargetColumns(uniqueColumns([...targetColumns, ...columns]));
  };

  const handleSubmit = () => {
    updateFundColumns(targetColumns.map(item => item.key));
    notification.success({ message: 'Updated columns successfully!', placement: 'bottomLeft' });
    setOpen(false);
  };

  const handleReset = () => {
    setTargetColumns(defaultColumns);
  };

  const handleAddAll = () => {
    const flatenColumns = allColumns.flatMap(item => item.columns);
    setTargetColumns(uniqueColumns(flatenColumns));
  };

  return (
    <Modal
      title="Update Columns"
      visible={isOpen}
      width={700}
      okText="Submit"
      onOk={handleSubmit}
      onCancel={() => setOpen(false)}
      bodyStyle={{ overflow: 'auto', maxHeight: '65vh' }}
    >
      <Wrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Icon type="info-circle" theme="twoTone" />
            <InfoText>
              Drag from <b>All Columns</b> to <b>Displayed Columns</b> to add.
            </InfoText>
          </Row>
          <Row>
            <Icon type="info-circle" theme="twoTone" />
            <InfoText>
              Drag from <b>Displayed Columns</b> to <b>All Columns</b> to remove.
            </InfoText>
          </Row>
          <Row>
            <Icon type="info-circle" theme="twoTone" />
            <InfoText>
              If it's no item in <b>Displayed Columns</b>, the default one will be used.
            </InfoText>
          </Row>
          <Row type="flex" style={{ marginTop: 20 }} gutter={16}>
            <Col span={12} style={{ marginBottom: 20 }} offset={12}>
              <div style={{ justifyContent: 'flex-end', alignItems: 'baseline' }}>
                <Input
                  prefix={<Icon type="search" />}
                  placeholder="Search Columns..."
                  onChange={e => setTextColumnSearch(e.target.value)}
                />
              </div>
            </Col>
            <Col span={12}>
              <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Displayed Columns</span>
                <Button type="link" size="small" onClick={handleReset}>
                  Reset
                </Button>
              </h3>
              <Droppable droppableId={DroppableIds.TargetColumns}>
                {({ innerRef, placeholder }, snapshot) => {
                  return (
                    <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {targetColumns &&
                        targetColumns.map((targetCol, index) => (
                          <Draggable
                            key={targetCol.key}
                            draggableId={`target_${targetCol.key}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}
                              >
                                {targetCol.title}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </Col>
            <Col span={12}>
              <h3 style={{ display: 'flex', justifyContent: 'space-between' }}>
                All Columns
                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    activePanels.length > 0
                      ? setActivePanels([])
                      : setActivePanels(sourceColumns.map(source => source.groupName))
                  }
                >
                  {activePanels.length > 0 ? 'Collapse all' : 'Expand all'}
                </Button>
                <Button type="link" size="small" onClick={handleAddAll}>
                  Add all
                </Button>
              </h3>
              <Droppable droppableId={DroppableIds.SourceColumns}>
                {({ innerRef, placeholder }, snapshot) => {
                  let draggableIndex = 0;
                  return (
                    <div ref={innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      <Collapse
                        bordered={false}
                        activeKey={activePanels}
                        onChange={key => {
                          setActivePanels(key as string[]);
                        }}
                      >
                        {sourceColumns.map(group => {
                          if (textColumnSearch !== '' && group.columns.length === 0) {
                            return null;
                          }
                          return (
                            <Panel
                              header={
                                <HeaderGroup
                                  groupName={group.groupName}
                                  onAddAll={() => handleAddGroupColumns(group.columns)}
                                />
                              }
                              key={`${group.groupName}`}
                            >
                              {group.columns &&
                                group.columns.map(col => {
                                  draggableIndex++;
                                  return (
                                    <Draggable
                                      key={col.key}
                                      draggableId={`source_${col.key}`}
                                      index={draggableIndex}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                          )}
                                        >
                                          {col.title}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {placeholder}
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                }}
              </Droppable>
            </Col>
          </Row>
        </DragDropContext>
      </Wrapper>
    </Modal>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    updateFundColumns: data => {
      dispatch({ type: RootActionEnums.UPDATE_FUND_TABLE_COLUMNS, payload: data });
    },
  };
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  const flattenAllColumns = allColumns.flatMap(item => item.columns);
  const fundColumnsAsKey = state.rootReducer.fundColumns;

  return {
    fundColumns: fundColumnsAsKey
      ? fundColumnsAsKey.map((key: any) => flattenAllColumns.find(item => item.key === key))
      : defaultColumns,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnBuilder);
