import { Button, Icon, Layout, notification, Pagination, Row } from 'antd';
import { SorterResult } from 'antd/lib/table';
import JsFileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { oc } from 'ts-optchain';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import APIService from '../../shared/api';
import { FundDetails } from '../../shared/api/models/FundDetails';
import {
  DisclaimerResponse,
  ListResponse,
  SearchStatistic,
  UserProfileDetails,
} from '../../shared/api/models/ResponsesTypes';
import { EventSubCode } from '../../shared/api/services/event-service';
import { FetchFundParams } from '../../shared/api/services/search.service';
import { NewIndicator } from '../../shared/components/NewIndicator';
import { useServiceState } from '../../shared/hooks/useServiceState';
import FundListModal from '../search/components/FundListModal';
import FundsChart from './components/FundsChart';
import FundsTable from './components/FundsTable';
import FundStatistics from './components/FundStatistics';
import SearchForm from './components/SearchForm/SearchForm';
import SearchActionEnums from './redux/search.ActionEnums';
import { searchValidator } from './search.validator';

const ButtonGroup = Button.Group;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
`;
const Actions = styled.div`
  & > .ant-btn-group {
    margin-bottom: 0;
  }
  & > button {
    margin-right: 10px;
  }
`;
const DisclaimerContainer = styled(PerfectScrollbar)`
  overflow-y: auto;
  min-height: 80px;
  max-height: 80px;
  padding: 0 20px;
  font-size: 9px;
`;

interface Props extends Partial<RouteComponentProps> {}

enum SearchTypeEnum {
  TABLE = 'table',
  CHART = 'chart',
  STATS = 'stats',
}

export interface LatestRORData {
  fund_id: string;
  fund_name: string;
  date: string;
  value: number;
  strategy: number;
  strategy_name: string;
  sub_strategy: number;
  sub_strategy_name: string;
  region_inv_focus: number;
  regional_focus: string;
  geo_avg_month_ror: number;
  high_month_ror: number;
  low_month_ror: number;
  one_month_ror: number;
  three_month_ror: number;
  six_month_ror: number;

  ann_ror: number;
  one_year_ann_ror: number;
  two_year_ann_ror: number;
  three_year_ann_ror: number;
  five_year_ann_ror: number;
  seven_year_ann_ror: number;
  ten_year_ann_ror: number;

  stdev: number;
  ann_stdev: number;
  one_year_ann_stdev: number;
  two_year_ann_stdev: number;
  three_year_ann_stdev: number;
  five_year_ann_stdev: number;
  seven_year_ann_stdev: number;
  ten_year_ann_stdev: number;

  sharpe_ratio: number;
  one_year_ann_sharpe_ratio: number;
  two_year_ann_sharpe_ratio: number;
  three_year_ann_sharpe_ratio: number;
  five_year_ann_sharpe_ratio: number;
  seven_year_ann_sharpe_ratio: number;
  ten_year_ann_sharpe_ratio: number;
}

interface DispatchProps {
  updateSelectedFunds: (selectedFunds: string[] | undefined) => void;
  addSearchParams: (params: Partial<FetchFundParams>) => void;
}

interface StateProps {
  user: UserProfileDetails;
  selectedFunds: string[] | undefined;
}

/**
 *
 */
const SearchView: React.FC<Props & StateProps & DispatchProps> = ({
  user,
  selectedFunds,
  updateSelectedFunds,
  addSearchParams,
}) => {
  const [showSearch, setShowSearch] = useState(true);

  const { data: rorData, invoke: invokeROR } = useServiceState<ListResponse<LatestRORData>>(
    APIService.searchService.fetchLatestRORData,
  );
  const { data: funds, invoke: invokeSearch, loading } = useServiceState<ListResponse<FundDetails>>(
    APIService.fundService.fetchFunds,
  );

  const { data: statData, invoke: fetchStatData } = useServiceState<SearchStatistic>(
    APIService.searchService.fetchStatistics,
  );

  const { data: allFundIds, invoke: invokeSearchFundIds } = useServiceState<ListResponse<string>>(
    APIService.searchService.fetchFundIds,
  );

  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  const [params, setParams] = useState<Partial<FetchFundParams | undefined>>(undefined);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentFundIds, setCurrentFundIds] = useState<string[]>();
  const [showAddTo, setShowAddTo] = useState(false);
  const [searchToggleValue, setSearchToggleValue] = useState<String>(SearchTypeEnum.TABLE);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const handleResultTypeChange = (type: SearchTypeEnum) => {
    switch (type) {
      case SearchTypeEnum.CHART:
        APIService.eventService.sendChartViewEvent(EventSubCode.FUND_CHART_VIEW_EVENT);
        break;
      case SearchTypeEnum.TABLE:
        APIService.eventService.sendSearchViewEvent();

        break;
      case SearchTypeEnum.STATS:
        APIService.eventService.sendStatsViewEvent();
        break;
    }
    setSearchToggleValue(type);
  };

  useEffect(() => {
    if (params) {
      try {
        searchValidator(params);
        APIService.eventService.sendSearchFundAccessEvent();
        invokeROR(params);
        invokeSearch({ page, limit: pageSize, ...params });
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [invokeROR, invokeSearch, page, pageSize, params]);

  useEffect(() => {
    if (params) {
      invokeSearchFundIds(params);
      fetchStatData(params);
    }
  }, [invokeDisclaimerData, invokeSearchFundIds, fetchStatData, params]);

  useEffect(() => {
    invokeDisclaimerData('universe');
  }, [invokeDisclaimerData]);

  useEffect(() => {
    if (!funds || !funds.items) {
      return;
    } else if (!funds.count) {
      notification.info({ message: 'No funds found' });
      return;
    }
    setCurrentFundIds(
      funds.items
        .filter(item => (selectedFunds ? selectedFunds.indexOf(item.fund_id) !== -1 : false))
        .map(filtered => filtered.fund_id),
    );
  }, [funds, selectedFunds, setCurrentFundIds]);

  const handlePageChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
    if (current !== 1) {
      setPage(1);
    }
  };

  const handleUpdatingFundIds = (updatingFundIds: string[]) => {
    const added = updatingFundIds.filter(item =>
      currentFundIds ? currentFundIds.indexOf(item) === -1 : true,
    );
    const removed = currentFundIds
      ? currentFundIds.filter(item => updatingFundIds.indexOf(item) === -1)
      : [];
    if (!selectedFunds) {
      updateSelectedFunds(added);
      return;
    }
    updateSelectedFunds([...selectedFunds.filter(item => removed.indexOf(item) === -1), ...added]);
  };

  const handleTableChange = (sorter: SorterResult<any>) => {
    if (sorter.order) {
      setParams({
        ...params,
        sortBy: sorter.columnKey,
        orderBy: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
    } else {
      if (params) {
        const { sortBy, orderBy, ...rest } = params;
        sortBy && orderBy && setParams(rest);
      }
    }
  };

  const handleSearch = (p: Partial<FetchFundParams>) => {
    setPage(1);
    setParams(p);
    addSearchParams(p);
    updateSelectedFunds(undefined);
  };

  const handleExport = async () => {
    setExportLoading(true);
    const res = await APIService.fundService.fetchExportFunds({ ...params });
    const filename = 'search-results.csv';
    JsFileDownload(res.data, filename, 'application/csv');
    setExportLoading(false);
  };

  const handleSelectAll = () => {
    if (!funds || !allFundIds) {
      return;
    }
    updateSelectedFunds(allFundIds.items);
  };

  const handleClearAll = () => {
    updateSelectedFunds([]);
  };

  const handleAddTo = () => {
    setShowAddTo(true);
  };

  const renderToggleButton = () => {
    if (funds && funds.items && funds.items.length > 0) {
      return (
        <ButtonGroup>
          <Button
            disabled={searchToggleValue === SearchTypeEnum.TABLE}
            onClick={() => handleResultTypeChange(SearchTypeEnum.TABLE)}
          >
            Table
          </Button>
          <Button
            disabled={searchToggleValue === SearchTypeEnum.CHART}
            onClick={() => handleResultTypeChange(SearchTypeEnum.CHART)}
          >
            <NewIndicator withPadding>Chart</NewIndicator>
          </Button>
          <Button
            disabled={searchToggleValue === SearchTypeEnum.STATS}
            onClick={() => handleResultTypeChange(SearchTypeEnum.STATS)}
          >
            <NewIndicator withPadding>Statistics</NewIndicator>
          </Button>
        </ButtonGroup>
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      <Layout.Sider
        title="Search Funds"
        theme="light"
        collapsible
        collapsedWidth={0}
        collapsed={!showSearch}
        width={400}
        style={{
          height: '100%',
          overflowY: 'auto',
          position: 'relative',
          borderRight: showSearch ? '1px solid #d9d9d9' : 'none',
          boxSizing: 'border-box',
        }}
        trigger={null}
      >
        <SearchForm onSearch={handleSearch} loading={loading} />
      </Layout.Sider>
      <div
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'auto',
          flexDirection: 'column',
        }}
      >
        <Row>
          <Button
            style={{ margin: '8px 16px', alignSelf: 'flex-start' }}
            onClick={() => setShowSearch(!showSearch)}
          >
            <Icon type="search" />
          </Button>
          {renderToggleButton()}
        </Row>
        {searchToggleValue === SearchTypeEnum.TABLE && (
          <FundsTable
            loading={loading}
            selectedItems={currentFundIds}
            setSelectedItems={handleUpdatingFundIds}
            items={oc(funds).items([])}
            handleSortChange={handleTableChange}
            searchPanelWidth={400}
            isShowSearch={showSearch}
          />
        )}
        {searchToggleValue === SearchTypeEnum.CHART && (
          <FundsChart rorData={rorData.items ? rorData.items : []} />
        )}
        {searchToggleValue === SearchTypeEnum.STATS && <FundStatistics data={statData} />}
        <br />
        {funds &&
        funds.items &&
        funds.items.length > 0 &&
        searchToggleValue === SearchTypeEnum.TABLE ? (
          <Footer>
            <Actions>
              <Button.Group>
                <Button onClick={handleSelectAll}>Select All</Button>
                <Button onClick={handleClearAll}>Clear All</Button>
              </Button.Group>
              <Button
                type="primary"
                disabled={!selectedFunds || selectedFunds.length <= 0}
                onClick={handleAddTo}
              >
                Add to...
              </Button>
              {showAddTo && <FundListModal isOpen={showAddTo} setOpen={setShowAddTo} />}
              <Button type="danger" disabled={true}>
                Delete
              </Button>
              {user.has_export_search_access && (
                <Button
                  type="primary"
                  disabled={user.hfrdb_trial}
                  style={{ width: 90 }}
                  onClick={handleExport}
                >
                  {exportLoading ? <Icon type="loading" /> : 'Export'}
                </Button>
              )}
            </Actions>
            <Pagination
              showSizeChanger
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              onChange={handlePageChange}
              total={funds ? funds.count : 0}
              current={page}
              pageSizeOptions={['10', '25', '50', '100']}
              onShowSizeChange={handlePageSizeChange}
            />
          </Footer>
        ) : null}
        <Button
          type="link"
          style={{ alignSelf: 'flex-end', fontSize: '12px' }}
          onClick={() => setShowDisclaimer(!showDisclaimer)}
        >
          {showDisclaimer ? 'Hide Disclaimer' : 'Show Disclaimer'}
        </Button>
        {showDisclaimer && (
          <DisclaimerContainer
            dangerouslySetInnerHTML={{
              __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
            }}
          />
        )}
      </div>
    </Container>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    updateSelectedFunds: data => {
      dispatch({ type: RootActionEnums.UPDATE_SELECTED_FUNDS, payload: data });
    },
    addSearchParams: params => {
      dispatch({ type: SearchActionEnums.UPDATE_FORM_SEARCH_PARAMS, payload: params });
    },
  };
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
    selectedFunds: state.rootReducer.selectedFunds,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchView);
