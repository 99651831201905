/**
 * class for the jwt-token utilities
 */
class TokenUtil {
  static jwtToken: string;
  static JWT_TOKEN = 'jwt_token';
  /**
   * method to get the jwt-token value
   */
  static getToken(): string | null {
    return localStorage.getItem(TokenUtil.JWT_TOKEN);
  }

  /**
   * sets the jwt-token to localStorage
   * @param token - jwt-token
   */
  static setToken(token: string) {
    localStorage.setItem(TokenUtil.JWT_TOKEN, token);
  }

  /**
   * Clears the jwt-token from localStorage
   */
  static clearToken() {
    localStorage.removeItem(TokenUtil.JWT_TOKEN);
  }
}

export default TokenUtil;
