import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../shared/colors';
import SigninCover from '../../assets/signin-cover.png';
import SignupCover from '../../assets/signup-cover.png';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Cover = styled.div`
  flex: 1;
  background-size: cover;
  display: flex;
  flex-direction: column;

  background-color: white;
  justify-content: center;
  align-items: flex-start;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
`;

const CoverTitle = styled.h1`
  color: ${Colors.white};
  text-align: right;
  display: block;
  font-size: 100px;
  font-family: 'Open Sans', verdana, arial, sans-serif;
  font-weight: bolder;
  line-height: 100px;
  max-width: 560px;
  background: rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 10px;
`;
const CoverSub = styled.h2`
  color: ${Colors.white};
  text-align: right;
  background: rgba(0, 0, 0, 0.2);
  font-size: 40px;
  font-family: 'Open Sans', verdana, arial, sans-serif;
  padding: 0 10px;
`;

type Props = {
  isRegister?: boolean;
  title: string;
  description?: string;
};

const AuthLayout: React.FC<Props> = ({ isRegister, title, description, children }) => {
  return (
    <Container>
      {/* <Cover>
        <CoverTitle>{title}</CoverTitle>
        {description && <CoverSub>{description}</CoverSub>}
      </Cover> */}
      <Cover>
        <Img src={isRegister ? SignupCover : SigninCover} />
      </Cover>
      {children}
    </Container>
  );
};

export default AuthLayout;
