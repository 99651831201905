import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import APIService from '../../../shared/api';
import {
  UniverseFundListRecord,
  UniverseFundListResponse,
} from '../../../shared/api/services/universe-list.service';
import GenUtil from '../../../shared/utils/gen-util';
import UniverseFundDataTable from './UniverseFundDataTable';

type Props = {
  showDetailModal: boolean;
  setShowDetailModal: (val: boolean) => void;
  selectedList?: any; // PortfolioModelRecord
  onUpdateUniverseFund: () => void;
};

type StateProps = {};

/**
 * Modal showing the view-only data of the selected list from `my-list` tab
 * @param selectedList
 * @param showDetailModal
 * @param setShowDetailModal
 * @param onUpdateUniverseFund - callback to be called after a successful update list
 * @constructor
 */
const UniverseDetailModal: React.FC<Props & StateProps> = ({
  selectedList,
  showDetailModal,
  setShowDetailModal,
  onUpdateUniverseFund,
}) => {
  const [page, setPage] = useState<number>(1);
  const [dataSource, setDataSource] = useState<UniverseFundListRecord[]>([]);

  const {
    data: universeFundData,
    invoke: invokeUniverseFundData,
    loading: universeFundDataLoading,
  } = useServiceState<UniverseFundListResponse>(APIService.universeListService.fetchFundsByListId);

  useEffect(() => {
    if (selectedList) {
      invokeUniverseFundData({
        listId: selectedList.id,
        page,
      });
    }
  }, [invokeUniverseFundData, selectedList, page]);

  useEffect(() => {
    if (!universeFundData.funds) {
      return;
    }
    const transformedDataSource: UniverseFundListRecord[] = universeFundData.funds
      ? universeFundData.funds.map((item: UniverseFundListRecord) => {
          return {
            ...item,
            inception_date: item.inception_date
              ? GenUtil.getFormattedYearMonthAndDay(item.inception_date)
              : '-',
            key: item.fund_id ? +item.fund_id : item.user_fund_id,
          };
        })
      : [];
    setDataSource(transformedDataSource);
  }, [universeFundData]);

  const handlePagination = (page: number) => {
    setPage(page);
  };

  return (
    <Modal
      visible={showDetailModal}
      title={'List Detail'}
      footer={null}
      width="90%"
      onCancel={() => setShowDetailModal(false)}
    >
      <Form>
        <Row gutter={6}>
          <Col span={4}>
            <Form.Item label="List Name">
              <Input
                placeholder="List Name"
                disabled={true}
                value={selectedList ? selectedList.name : undefined}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Type">
              <Input
                placeholder="Type"
                disabled={true}
                value={selectedList ? selectedList.type : undefined}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Fund Count">
              <Input
                placeholder="Fund Count"
                disabled={true}
                value={selectedList ? selectedList.fund_count : undefined}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Created At">
              <Input
                placeholder="Created At"
                disabled={true}
                value={selectedList ? selectedList.created_date : undefined}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Updated At">
              <Input
                placeholder="Updated At"
                disabled={true}
                value={selectedList ? selectedList.updated_date : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <UniverseFundDataTable
          selectedList={selectedList}
          dataSource={dataSource}
          loading={universeFundDataLoading}
          handlePagination={handlePagination}
          page={page}
          onDelete={async () => {
            await invokeUniverseFundData({
              listId: selectedList.id,
              page,
            });
            onUpdateUniverseFund();
          }}
          total={universeFundData.count}
        />
      </Form>
    </Modal>
  );
};

export default UniverseDetailModal;
