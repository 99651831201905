import { AxiosInstance, AxiosPromise } from 'axios';
import { FundListRecord } from '../models/ResponsesTypes';

export interface UniverseFundListRecord {
  fund_id: string;
  fund_name: string;
  firm_name: string;
  strategy_name: string;
  sub_strategy_name: string;
  inception_date: string;
  user_fund_id: string;
  user_fund_name: string;
  user_fund_strategy_name: string;
  user_fund_sub_strategy_name: string;
}

export interface UniverseFundListResponse {
  count: number;
  funds: UniverseFundListRecord[];
}

export interface UpdateFundListParams {
  name?: string;
  add?: string[];
  delete?: string[];
  user_fund_add?: number[];
  user_fund_delete?: string[];
}

export interface UniverseListService {
  fetchFundList: () => AxiosPromise<FundListRecord[]>;
  fetchFundsByListId: ({
    listId,
    page,
    sort_by,
    order_by,
  }: {
    listId: string;
    page?: number;
    sort_by?: 'ASC' | 'DESC';
    order_by?: keyof UniverseFundListRecord;
  }) => AxiosPromise<UniverseFundListResponse>;
  fetchGroupReportByListId: (listId: string) => AxiosPromise<void>;
  updateFundList: (id: string, data: UpdateFundListParams) => AxiosPromise<any>;
  createFundList: (name: string, fundIds: string[]) => AxiosPromise<any>;
  deleteFundList: (fundId: string) => AxiosPromise<any>;
  fetchUniverseList: () => AxiosPromise<FundListRecord[]>;
  fetchFundByUniverseListId: (listId: string) => AxiosPromise<string[]>;
  fetchGroupReportByUniverseListId: (listId: string) => AxiosPromise<void>;
  shareFundList: (id: string, shareTo: number[], isShareAll: boolean) => AxiosPromise<void>;
}

export const UniverseListService = (request: AxiosInstance): UniverseListService => {
  return {
    fetchFundList: () => request.get('/fund-list'),
    fetchFundsByListId: ({ listId, page, sort_by, order_by }) =>
      request.get(`/fund-list/${listId}`, {
        params: {
          page: page ? page : undefined,
          sort_by: sort_by ? sort_by : undefined,
          order_by: order_by ? order_by : undefined,
        },
      }),
    fetchGroupReportByListId: listId => request.post(`/fund-list/${listId}/group-report`),
    createFundList: (name, fundIds) =>
      request.post('/fund-list', {
        name,
        fund_ids: fundIds,
      }),
    deleteFundList: id => request.delete(`/fund-list/${id}`),
    updateFundList: (id, data) => request.put(`/fund-list/${id}`, data),
    fetchUniverseList: () => request.get('/universe-list'),
    fetchFundByUniverseListId: listId => request.get(`/universe-list/${listId}`),
    fetchGroupReportByUniverseListId: listId =>
      request.post(`/universe-list/${listId}/group-report`),
    shareFundList: (id, shareTo, isShareAll) =>
      request.post(`/fund-list/${id}/share`, {
        user_ids: shareTo,
        all: isShareAll,
      }),
  };
};
