import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import LoginView from './LoginView';
import SignUpView from './signup/SignUp';
import ForgotPassword from './ForgotPassword/ForgotPassword';

type Props = {};

const Auth: React.FC<Props> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/log-in`}>
        <LoginView />
      </Route>
      <Route path={`${path}/sign-up`} component={SignUpView} />
      <Route path={`${path}/forgot-password`} component={ForgotPassword} />
      <Redirect to={`${path}/log-in`} />
    </Switch>
  );
};

export default Auth;
