// Compare two arrays to find added/deleted items
export const diffTwoArrays = <T>(
  originalData: T[],
  updateData: T[],
  uniqueKey?: keyof T,
): { added: T[]; deleted: T[] } =>
  originalData.reduce(
    (acc, curr) => {
      const added = acc.added.filter((item: T) => {
        if (uniqueKey && typeof curr === 'object' && typeof item === 'object') {
          return item[uniqueKey] !== curr[uniqueKey];
        }
        return item !== curr;
      });
      return {
        added,
        deleted: [...acc.deleted, ...(added.length === acc.added.length ? [curr] : [])],
      };
    },
    { deleted: [] as T[], added: updateData },
  );
