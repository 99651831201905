import React from 'react';
import styled from 'styled-components';
import AuthLayout from '../AuthLayout';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 420px;
  width: 30%;
  height: 100vh;
  padding: 0 40px;

  button {
    width: min-content;
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

type Props = {};

const ThankYouPage: React.FC<Props> = () => {
  return (
    <AuthLayout isRegister title="Thank You">
      <Container>
        <h2>
          Thank you for registering with us. Before login, your account needs to be approved by HFR
          Team.
        </h2>
      </Container>
    </AuthLayout>
  );
};

export default ThankYouPage;
