import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import APIService from '../../../shared/api';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import { UserFundRecord } from '../../../shared/api/models/ResponsesTypes';
import UserFundDataTable, { UserFundTableData } from './UserFundDataTable';
import UserFundGroup from './UserFundGroup';

const Wrapper = styled('div')``;

type Props = {
  dataFromFile: UserFundTableData[];
  onRequestClose: () => void;
} & FormComponentProps;

/**
 * The Upload Custom Fund Form - Creating/Updating User Fund with uploaded csv data
 * @param {UserFundTableData[]} dataFromFile - data parsed from uploaded csv file
 * @param {} form - form prop from Antd's Form
 * @param onRequestClose - callback to close the Upload User Fund Modal
 */
const UploadCustomFundForm: React.FC<Props> = ({ dataFromFile, form, onRequestClose }) => {
  const [isCreateNew, setCreateNew] = React.useState(false);
  const [dataSource, setDataSource] = React.useState<UserFundTableData[]>(dataFromFile);

  const {
    data: userFundData,
    invoke: invokeUserFundData,
    // loading: userFundLoading,
  } = useServiceState<UserFundRecord[]>(APIService.userFundService.fetchAllUserFunds);

  React.useEffect(() => {
    invokeUserFundData();
  }, [invokeUserFundData]);

  const userFundSelectData =
    userFundData && Object.keys(userFundData).length >= 1
      ? userFundData.map(item => ({ value: item.id, text: item.name }))
      : undefined;

  const handleCreateUserFund = async (values: any) => {
    try {
      await APIService.userFundService.createNewUserFund({
        name: values.name,
        strategyCode: values.strategies,
        subStrategyCode: values.subStrategy,
        regionInvFocus: +values.regionalFocus,
        data: dataSource.map(userFundItem => ({
          yyyymmm: moment(userFundItem.date, 'YYYY-MM').format('YYYY0MM'),
          cur_ror_value: Number.parseFloat(userFundItem.ror),
          cur_asset_value: Number.parseFloat(userFundItem.aum),
        })),
      });
      notification.success({ message: 'New user fund has been created!', placement: 'bottomLeft' });
      onRequestClose();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Cannot create user fund! Please try again later',
        placement: 'bottomLeft',
      });
    }
  };

  const handleUpdateUserFund = async (data: any) => {
    try {
      await APIService.userFundService.updateUserFund(data.name, {
        name: userFundData && userFundData.find(item => item.id.toString() === data.name)!.name,
        strategyCode: data.strategies,
        subStrategyCode: data.subStrategy,
        regionInvFocus: +data.regionalFocus,
        add: dataSource.map(userFundItem => ({
          yyyymmm: moment(userFundItem.date, 'YYYY-MM').format('YYYY0MM'),
          cur_ror_value: Number.parseFloat(userFundItem.ror),
          cur_asset_value: Number.parseFloat(userFundItem.aum),
        })),
      });
      notification.success({ message: 'User fund data updated!', placement: 'bottomLeft' });
      onRequestClose();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Cannot update user fund! Please try again later',
        placement: 'bottomLeft',
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (isCreateNew) {
          handleCreateUserFund(values);
          return;
        }
        handleUpdateUserFund(values);
      }
    });
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <UserFundGroup
          form={form}
          isCreateNew={isCreateNew}
          setCreateNew={setCreateNew}
          userFundSelectData={userFundSelectData}
        />
        <UserFundDataTable data={dataSource} onDataChange={setDataSource} />
      </Form>
    </Wrapper>
  );
};

export default Form.create<Props>({ name: 'UserFundForm' })(UploadCustomFundForm);
