import { AxiosInstance, AxiosPromise } from 'axios';
import { sanatizeToSnakeCase } from '../../strings';
import { UserProfileDetails } from '../models/ResponsesTypes';

export interface SignUpParams {
  code: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  company: string;
  jobTitle: string;
  businessPhone: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  registrationCode: string;
}
export interface AuthService {
  preLogin: (email: string, password: string) => AxiosPromise<{ disable_2fa: boolean }>;
  login: (
    email: string,
    password: string,
    code: string,
    rememberMe: boolean,
  ) => AxiosPromise<{ token: string }>;
  logout: () => AxiosPromise<void>;
  signUp: (formData: SignUpParams) => AxiosPromise<any>;
  preSignUp: (email: string) => AxiosPromise<void>;
  checkCode: (email: string, verificationCode: string) => AxiosPromise<void>;
  requestPasswordReset: (email: string) => AxiosPromise<void>;
  resetPassword: (email: string, code: string, newPassword: string) => AxiosPromise<void>;
  updatePassword: ({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
  }) => AxiosPromise<void>;
  updateProfile: (params: Partial<UserProfileDetails>) => AxiosPromise<void>;
}

export const AuthService = (request: AxiosInstance): AuthService => {
  return {
    preLogin: (email, password) => request.post('/pre-login', { email, password }),
    login: (email, password, code, rememberMe) =>
      request.post('/login', { email, password, code, remember_me: rememberMe }),
    logout: () => request.post('/logout'),
    signUp: formData => request.post('/user', { ...sanatizeToSnakeCase(formData) }),
    preSignUp: email => request.post('/pre-sign-up', { email }),
    checkCode: (email, verificationCode) =>
      request.post('/check-code', { email, code: verificationCode }),
    requestPasswordReset: email => request.post('/request-password-reset', { email }),
    resetPassword: (email, code, newPassword) =>
      request.post('/password-reset', {
        email,
        code,
        new_password: newPassword,
      }),
    updatePassword: ({ password, newPassword }) =>
      request.post('/change-password', {
        password,
        new_password: newPassword,
      }),
    updateProfile: params => request.post('/user/update', params),
  };
};
