import Flux from '@cobuildlab/flux-state';
import TokenUtil from '../../shared/utils/token-util';

/**
 * Event that triggers a LOGIN error
 * @type {string}
 */
export const LOG_IN_ERROR_EVENT = 'onLogInErrorEvent';
export const SIGN_UP_ERROR_EVENT = 'onSignUpErrorEvent';
export const PRE_SIGN_UP_ERROR_EVENT = 'onPreSignUpErrorEvent';

/**
 * Event that triggers a User fetch Event
 * @type {string}
 */
export const USER_EVENT = 'onUserEvent';
export const USER_ERROR_EVENT = 'onUserError';
export const REQUEST_RECOVER_PASSWORD = 'onUserRequestRecoverPassword';

/**
 * Event that triggers a User update Event
 * @type {string}
 */
export const UPDATE_USER_EVENT = 'onUpdateUserEvent';

/**
 * Event that triggers when a user Logs in
 * @type {string}
 */
export const LOG_IN_EVENT = 'onLogInEvent';
export const LOG_OUT_EVENT = 'onLogOutEvent';
export const SIGN_UP_EVENT = 'onSignUpEvent';
export const PRE_SIGN_UP_EVENT = 'onPreSignUpEvent';

/**
 *
 */
class AuthStore extends Flux.DashStore {
  constructor() {
    super();
    this.addEvent(LOG_IN_ERROR_EVENT);
    this.addEvent(LOG_OUT_EVENT);
    this.addEvent(SIGN_UP_ERROR_EVENT);
    this.addEvent(PRE_SIGN_UP_ERROR_EVENT);
    this.addEvent(PRE_SIGN_UP_EVENT);
    this.addEvent(SIGN_UP_EVENT);
    this.addEvent(USER_EVENT);
    this.addEvent(USER_ERROR_EVENT);
    this.addEvent(UPDATE_USER_EVENT);
    this.addEvent(REQUEST_RECOVER_PASSWORD);

    const saveTokenReducer = (state: { token: string }) => {
      if (!state.token) {
        throw new Error('Unexpected Answer from server: expecting "token" property.');
      }
      TokenUtil.setToken(state.token);
      return state;
    };

    const token = TokenUtil.getToken();
    this.addFluxEvent({
      eventName: LOG_IN_EVENT,
      reducers: [saveTokenReducer],
      initialValue: token ? { token } : null,
    });
  }
}

const authStore = new AuthStore();

export { authStore };
