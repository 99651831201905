import { Tabs } from 'antd';
import React from 'react';
import { RouterProps } from 'react-router';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainLayout from '../../shared/components/MainLayout';
import SearchView from '../search/SearchView';
import FundDetailsView from '../funddetails/FundDetailsView';
import { FundFirmPane, InitialRootReducer } from '../../redux/reducers/root.reducer';
import RootActionEnums from '../../redux/actions/root.ActionEnums';

import { Colors } from '../../shared/colors';
import APIService from '../../shared/api';
import FirmDetailsView from '../funddetails/FirmDetailsView';
import UploadCustomFundModal from './UploadCustomFundModal';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  .ant-tabs-content {
    margin-top: -16px;
    flex-grow: 1;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;
    overflow: hidden;

    .ant-tabs-tabpane-active {
      height: 100%;
    }
  }
`;

interface DispatchProps {
  changePane: (key: string) => void;
  removePane: (key: string) => void;
}

interface StateProps {
  panes: FundFirmPane[];
  activeKey: string | number;
}

interface Props extends RouterProps {}

interface State {}

const HomeView: React.FC<Props & DispatchProps & StateProps & State> = ({
  removePane,
  changePane,
  panes,
  activeKey,
}) => {
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const onEdit = (targetKey: string | React.MouseEvent<HTMLElement>) => {
    if (typeof targetKey === 'string') {
      removePane(targetKey as string);
    }
  };
  // fired on changing the tabs
  const onChange = (activeKey: string) => {
    changePane(activeKey);
    // Send ViewFund event if not the `Search Fund` tab
    if (activeKey !== '1') {
      APIService.eventService.sendViewFundProfileEvent(activeKey.split('-')[0]);
    }
  };

  return (
    <MainLayout>
      {/* <StyledUploadBtn
        type="link"
        onClick={() => {
          setShowUploadModal(true);
        }}
      >
        <Icon type="upload" /> Upload your data
      </StyledUploadBtn> */}
      {showUploadModal && (
        <UploadCustomFundModal
          isOpen={showUploadModal}
          onRequestClose={() => setShowUploadModal(false)}
        />
      )}
      <StyledTabs
        hideAdd
        activeKey={activeKey.toString()}
        onChange={onChange}
        onEdit={onEdit}
        type="editable-card"
      >
        <TabPane tab="Search Funds" key="1" closable={false}>
          <PerfectScrollbar>
            <SearchView />
          </PerfectScrollbar>
        </TabPane>
        {panes.map(pane => (
          <TabPane tab={`${pane.title.substr(0, 16)}..`} key={pane.key} style={{ display: 'flex' }}>
            {pane.type === 'fund' ? (
              <FundDetailsView fundId={pane.fundFirmId} />
            ) : (
              <FirmDetailsView firmId={pane.fundFirmId} />
            )}
          </TabPane>
        ))}
      </StyledTabs>
    </MainLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    panes: state.rootReducer.panes,
    activeKey: state.rootReducer.activeKey,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    changePane: (key: string) => {
      dispatch({ type: RootActionEnums.CHANGE_PANE, payload: key });
    },
    removePane: (key: string) => {
      dispatch({ type: RootActionEnums.REMOVE_PANE, payload: key });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
