import React, { FormEvent } from 'react';
import { Button, Form, Icon, Input, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import APIService from '../../shared/api';

type Props = {
  email: string;
  password: string;
  rememberMe: boolean;
} & FormComponentProps;

const CheckCodeLogin: React.FC<Props> = ({ email, password, form, rememberMe }) => {
  const history = useHistory();
  const { getFieldDecorator } = form;
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        APIService.login(email, password, values.code ? values.code.trim() : '', rememberMe)
          .then(() => {
            notification.success({ message: 'Welcome!' });
            setLoading(false);
            history.push('/');
          })
          .catch(e => {
            toast.error(e.response.data.message);
            setLoading(false);
          });
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} layout="horizontal">
      <span>Enter Verification Code:</span>
      <Row>
        <Form.Item>
          <Input
            name={'email'}
            value={email}
            disabled
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="email"
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item>
          {getFieldDecorator('code', {
            rules: [{ required: true, message: 'Verification Code is required' }],
          })(
            <Input
              formNoValidate={true}
              name="code"
              prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Verification Code"
            />,
          )}
        </Form.Item>
        <Row type="flex">
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={loading}
            style={{ flex: 2 }}
          >
            Submit
          </Button>
          <Button type="link" style={{ flex: 1 }} onClick={() => history.push('/auth/login')}>
            Return to Login
          </Button>
        </Row>
      </Row>
    </Form>
  );
};

export default Form.create<Props>({ name: 'CheckCodeLogin' })(CheckCodeLogin);
