import Flux from '@cobuildlab/flux-state';
import { error } from 'pure-logger';
import { Fetch, RequestOption } from '../../shared/fetch';
import TokenUtil from '../../shared/utils/token-util';
import { store } from '../../index';

import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { USER_ERROR_EVENT, USER_EVENT } from './auth-store';

const URL = process.env.REACT_APP_AUTH_API_URL;

/**
 *
 * @returns {Promise<void|*>}
 */
export const fetchUser = async () => {
  const token = TokenUtil.getToken();
  if (!token) {
    return Flux.dispatchEvent(USER_ERROR_EVENT, new Error('Token not found'));
  }

  const request: RequestOption = {
    url: `${URL}/me`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let response;
  try {
    response = await Fetch.get(request);
    store.dispatch({
      type: RootActionEnums.UPDATE_USER_PROFILE,
      payload: {
        ...response,
      },
    });
  } catch (e) {
    error('loginAction:', e);
    return Flux.dispatchEvent(USER_ERROR_EVENT, e);
  }
  Flux.dispatchEvent(USER_EVENT, response);
  return response;
};
