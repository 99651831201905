import { FetchFundParams } from '../../shared/api/services/search.service';
import { ValidationError } from '../../shared/errors';
import { isValidString } from '../../shared/strings';

/**
 * validator function for the Login screen
 * @param data {LoginModel}
 */
export const searchValidator = (data: Partial<FetchFundParams> | undefined) => {
  let atLeastOneFilter = false;
  let isComparatorValid = true;
  let univerListRequired = true;

  if (data) {
    const {
      fundListId,
      universeListId,
      portfolioModelId,
      liquidAltType,
      isIndexFund,
      max_12b1Fee,
      maxDeferredFee,
      isOffshore,
      isLockup,
      maxFrontFee,
      ucitsCompliant,
      subStrategy,
      regionalFocus,
      addedToDbFrom,
      addedToDbTo,
      strategyDescription,
      strategy,
      liquidAltRegion,
      isHfrxFund,
      productClass,
      fundOrFirmName,
      isEmrgngmrktsFund,
      inceptionDateFrom,
      inceptionDateTo,
      fundId,
      fundName,
      liquidAltProduct,
      administrator,
      annualPerformAudit,
      auditDateFrom,
      auditDateTo,
      auditor,
      bankingAgentOnshore,
      custodian,
      legalAdviserOnshore,
      primeBroker,
      advanceNotice,
      domicile,
      structure,
      highWatermark,
      hurdleRate,
      individualAccountMinGte,
      individualAccountMinLte,
      leverage,
      lockup,
      minInvestmentCurr,
      minimumInvestmentGte,
      minimumInvestmentLte,
      additionalInvestmentGte,
      additionalInvestmentLte,
      openForInvestment,
      openToInvestorType,
      reporting,
      reportingStyle,
      returnsCurrency,
      fundRegistrationCode,
      redemptions,
      subscriptions,
      firmUsdValueLte,
      firmUsdValueGte,
      fundUsdValueLte,
      fundUsdValueGte,
      // Statistics Filters
      trackRecordGte,
      trackRecordLte,
      annRorGte,
      annRorLte,
      annStdevGte,
      annStdevLte,
      geoAvgMonthRorGte,
      geoAvgMonthRorLte,
      highMonthRorGte,
      highMonthRorLte,
      lowMonthRorGte,
      lowMonthRorLte,
      stdevGte,
      stdevLte,
      sharpeRatioGte,
      sharpeRatioLte,
      oneMonthRorGte,
      oneMonthRorLte,
      threeMonthRorGte,
      threeMonthRorLte,
      sixMonthRorGte,
      sixMonthRorLte,
      oneYearAnnRorGte,
      oneYearAnnRorLte,
      oneYearAnnStdevGte,
      oneYearAnnStdevLte,
      oneYearAnnSharpeRatioGte,
      oneYearAnnSharpeRatioLte,
      twoYearAnnRorGte,
      twoYearAnnRorLte,
      twoYearAnnStdevGte,
      twoYearAnnStdevLte,
      twoYearAnnSharpeRatioGte,
      twoYearAnnSharpeRatioLte,
      threeYearAnnRorGte,
      threeYearAnnRorLte,
      threeYearAnnStdevGte,
      threeYearAnnStdevLte,
      threeYearAnnSharpeRatioGte,
      threeYearAnnSharpeRatioLte,
      fiveYearAnnRorGte,
      fiveYearAnnRorLte,
      fiveYearAnnStdevGte,
      fiveYearAnnStdevLte,
      fiveYearAnnSharpeRatioGte,
      fiveYearAnnSharpeRatioLte,
      sevenYearAnnRorGte,
      sevenYearAnnRorLte,
      sevenYearAnnStdevGte,
      sevenYearAnnStdevLte,
      sevenYearAnnSharpeRatioGte,
      sevenYearAnnSharpeRatioLte,
      tenYearAnnRorGte,
      tenYearAnnRorLte,
      tenYearAnnStdevGte,
      tenYearAnnStdevLte,
      tenYearAnnSharpeRatioGte,
      tenYearAnnSharpeRatioLte,
      //
      statsStartDate,
      statsEndDate,
    } = data;
    if (isValidString(fundOrFirmName)) {
      atLeastOneFilter = true;
    }
    if (fundListId || universeListId || portfolioModelId) {
      univerListRequired = false;
    }
    if (isValidString(fundListId)) {
      atLeastOneFilter = true;
    }
    if (isValidString(universeListId)) {
      atLeastOneFilter = true;
    }
    if (isValidString(portfolioModelId)) {
      atLeastOneFilter = true;
    }
    if (strategy && strategy.length > 0) {
      atLeastOneFilter = true;
    }
    if (subStrategy && subStrategy.length > 0) {
      atLeastOneFilter = true;
    }
    if (regionalFocus && regionalFocus.length > 0) {
      atLeastOneFilter = true;
    }
    if (productClass && productClass.length > 0) {
      atLeastOneFilter = true;
    }

    if (isValidString(ucitsCompliant)) {
      atLeastOneFilter = true;
    }
    if (isValidString(liquidAltProduct)) {
      atLeastOneFilter = true;
    }
    if (isValidString(administrator)) {
      atLeastOneFilter = true;
    }
    if (isValidString(annualPerformAudit)) {
      atLeastOneFilter = true;
    }
    if (isValidString(auditDateFrom)) {
      atLeastOneFilter = true;
    }
    if (isValidString(auditDateTo)) {
      atLeastOneFilter = true;
    }
    if (isValidString(auditor)) {
      atLeastOneFilter = true;
    }
    if (isValidString(bankingAgentOnshore)) {
      atLeastOneFilter = true;
    }
    if (isValidString(custodian)) {
      atLeastOneFilter = true;
    }
    if (isValidString(legalAdviserOnshore)) {
      atLeastOneFilter = true;
    }
    if (isValidString(primeBroker)) {
      atLeastOneFilter = true;
    }
    if (liquidAltType && liquidAltType.length > 0) {
      atLeastOneFilter = true;
    }
    if (liquidAltRegion && liquidAltRegion.length > 0) {
      atLeastOneFilter = true;
    }
    if (max_12b1Fee && max_12b1Fee.length > 0) {
      atLeastOneFilter = true;
    }
    if (maxDeferredFee && maxDeferredFee.length > 0) {
      atLeastOneFilter = true;
    }
    if (maxFrontFee && maxFrontFee.length > 0) {
      atLeastOneFilter = true;
    }
    if (isValidString(fundId)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fundName)) {
      atLeastOneFilter = true;
    }
    //
    if (isValidString(isEmrgngmrktsFund)) {
      atLeastOneFilter = true;
    }
    if (isValidString(isIndexFund)) {
      atLeastOneFilter = true;
    }
    if (isValidString(isHfrxFund)) {
      atLeastOneFilter = true;
    }
    if (isValidString(isOffshore)) {
      atLeastOneFilter = true;
    }
    if (isValidString(isLockup)) {
      atLeastOneFilter = true;
    }
    if (isValidString(addedToDbFrom)) {
      atLeastOneFilter = true;
    }
    if (isValidString(inceptionDateFrom)) {
      atLeastOneFilter = true;
    }
    if (isValidString(addedToDbTo)) {
      atLeastOneFilter = true;
    }
    if (isValidString(inceptionDateTo)) {
      atLeastOneFilter = true;
    }
    if (isValidString(strategyDescription)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.firmId)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.firmName)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.iardCrdNumber)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.secNumber)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.secRegistered)) {
      atLeastOneFilter = true;
    }
    if (isValidString(data.isDiversityFirm)) {
      atLeastOneFilter = true;
    }
    if (advanceNotice && advanceNotice.length > 0) {
      atLeastOneFilter = true;
    }
    if (isValidString(domicile)) {
      atLeastOneFilter = true;
    }
    if (isValidString(structure)) {
      atLeastOneFilter = true;
    }
    if (isValidString(highWatermark)) {
      atLeastOneFilter = true;
    }
    if (isValidString(hurdleRate)) {
      atLeastOneFilter = true;
    }
    if (isValidString(individualAccountMinGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(individualAccountMinLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(leverage)) {
      atLeastOneFilter = true;
    }
    if (isValidString(lockup)) {
      atLeastOneFilter = true;
    }
    if (minInvestmentCurr && minInvestmentCurr.length > 0) {
      atLeastOneFilter = true;
    }
    if (isValidString(minimumInvestmentGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(minimumInvestmentLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(additionalInvestmentGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(additionalInvestmentLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(openForInvestment)) {
      atLeastOneFilter = true;
    }
    if (isValidString(openToInvestorType)) {
      atLeastOneFilter = true;
    }
    if (reporting && reporting.length > 0) {
      atLeastOneFilter = true;
    }
    if (reportingStyle && reportingStyle.length > 0) {
      atLeastOneFilter = true;
    }
    if (returnsCurrency && returnsCurrency.length > 0) {
      atLeastOneFilter = true;
    }
    if (isValidString(fundRegistrationCode)) {
      atLeastOneFilter = true;
    }
    if (redemptions && redemptions.length > 0) {
      atLeastOneFilter = true;
    }
    if (subscriptions && subscriptions.length > 0) {
      atLeastOneFilter = true;
    }
    if (isValidString(firmUsdValueLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(firmUsdValueGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fundUsdValueLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fundUsdValueGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(trackRecordGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(trackRecordLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(annRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(annRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(annStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(annStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(geoAvgMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(geoAvgMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(highMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(highMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(lowMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(lowMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(stdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(stdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sixMonthRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sixMonthRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(oneYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(twoYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(threeYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(fiveYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(sevenYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnRorGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnRorLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnStdevGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnStdevLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnSharpeRatioGte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(tenYearAnnSharpeRatioLte)) {
      atLeastOneFilter = true;
    }
    if (isValidString(statsStartDate)) {
      atLeastOneFilter = true;
    }
    if (isValidString(statsEndDate)) {
      atLeastOneFilter = true;
    }
    if (univerListRequired) {
      throw new ValidationError('At least 1 Universe list is required!');
    } else if (!atLeastOneFilter) {
      throw new ValidationError('You need to set at least one filter!');
    } else if (!isComparatorValid) {
      throw new ValidationError('You cannot use Comparator alone!');
    }
  }
};
