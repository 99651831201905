import React from 'react';
import { Modal, Select, Row } from 'antd';

type InvestorGroupType = {
  id: number;
  groupName: string;
};

const MOCK_GROUPS: InvestorGroupType[] = [
  {
    id: 1,
    groupName: 'Group 1',
  },
  {
    id: 2,
    groupName: 'Group 2',
  },
  {
    id: 3,
    groupName: 'Group 3',
  },
];

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedInvestors: string[];
};

const AddToGroupModal: React.FC<Props> = ({ isOpen, onRequestClose, selectedInvestors }) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={onRequestClose}
      title="Add Investor Database to Group"
      okText="Add"
    >
      <b>{selectedInvestors.length} Investor Databases selected</b>
      <Row style={{ marginTop: 16 }}>
        <Select style={{ width: '100%' }} placeholder="Select Group" allowClear showSearch>
          {MOCK_GROUPS.map(group => (
            <Select.Option key={group.id}>{group.groupName}</Select.Option>
          ))}
        </Select>
      </Row>
    </Modal>
  );
};

export default AddToGroupModal;
