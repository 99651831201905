import React from 'react';
import { Form, Input, Select } from 'antd';

interface BaseFormProps {
  label?: React.ReactNode;
  placeholder?: string;
}

interface FormInputProps extends BaseFormProps {
  onChange: (value: string | number | string[] | number[] | boolean) => void;
  value: string | number | number[] | string[] | boolean;
  style?: React.CSSProperties;
}

const FormInputItem: React.FC<FormInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  style,
}) => {
  return (
    <Form.Item label={label} style={style}>
      <Input
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        value={value as string}
      />
    </Form.Item>
  );
};

interface FormSelectProps<T> extends BaseFormProps {
  onChange?: (value: T, option: React.ReactElement<any> | React.ReactElement<any>[]) => void;
  value?: T;
  isDisabled?: boolean;
  isMulti?: boolean;
  style?: React.CSSProperties;
}

const FormSelectItem = <T extends {}>({
  label,
  onChange,
  isDisabled,
  value,
  placeholder,
  isMulti,
  style,
  children,
}: FormSelectProps<T> & { children?: React.ReactNode }) => {
  return (
    <Form.Item label={label} style={style}>
      {/*
      // @ts-ignore */}
      <Select
        mode={isMulti ? 'multiple' : 'default'}
        onChange={onChange}
        showSearch
        disabled={isDisabled}
        allowClear
        placeholder={placeholder}
        value={value}
        filterOption={(input, option) => {
          if (!option || !option.props || !option.props.children) {
            return false;
          }
          return (
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
      >
        {children}
      </Select>
    </Form.Item>
  );
};

type FormPairValueProps = BaseFormProps & {
  atleastValue?: number;
  atmostValue?: number;
  updateAtleastValue: (atleast: number) => void;
  updateAtmostValue: (atmost: number) => void;
  style?: React.CSSProperties;
};

const FormPairValueItem: React.FC<FormPairValueProps> = ({
  label,
  atleastValue,
  atmostValue,
  updateAtleastValue,
  updateAtmostValue,
  style,
}) => {
  return (
    <Form.Item label={label} style={style}>
      <Input
        style={{ width: 95, marginRight: 10 }}
        placeholder="At Least"
        type="number"
        onChange={e => {
          updateAtleastValue(Number.parseFloat(e.target.value));
        }}
        value={atleastValue}
      />
      <Input
        style={{ width: 95 }}
        placeholder="At Most"
        type="number"
        onChange={e => {
          updateAtmostValue(Number.parseFloat(e.target.value));
        }}
        value={atmostValue}
      />
    </Form.Item>
  );
};

export { FormInputItem, FormSelectItem, FormPairValueItem };
