import React from 'react';
import Table, { ColumnProps, TableComponents } from 'antd/lib/table';
import { Icon } from 'antd';
import styled from 'styled-components';
import EditableFormRow from './EditableFormRow';
import EditableCell from './EditableCell';

type Props = {
  isViewOnly?: boolean;
  data: UserFundTableData[];
  loading?: boolean;
  onDataChange: (data: UserFundTableData[]) => void;
};

const DeleteIcon = styled(Icon)`
  color: red;
`;

export interface UserFundTableData {
  id?: number;
  key: string;
  aum: string;
  ror: string;
  date: string;
}

/**
 * User Fund Data Table - Showing the data parsed from uploaded csv file. Inline editable supported.
 * @param {Boolean} isViewOnly - data represented in view only mode
 * @param {UserFundTableData[]} data - csv data for the table
 * @param {(data: UserFundTableData[]) => void} onDataChange - Callback to update the user fund data
 * @param {boolean} loading - Is data for the table being loaded
 * @param {(id: string) => void} onDelete - Callback when delete a row in table with the corresponding id
 */
const UserFundDataTable: React.FC<Props> = ({ isViewOnly, data, onDataChange, loading }) => {
  // Transform the data to check duplicated dates
  const transformedData = data.reduce<{ [key: string]: number[] }>((acc, current, index) => {
    const dateAsKey = current.date;
    return {
      ...acc,
      [dateAsKey]: [...(acc[dateAsKey] || []), index],
    };
  }, {});

  const handleSave = (newRow: UserFundTableData) => {
    onDataChange(
      data.map(item => {
        if (item.key !== newRow.key) {
          return item;
        }
        return newRow;
      }),
    );
  };

  const handleDelete = (row: UserFundTableData) => {
    onDataChange(data.filter(item => item.key !== row.key));
  };

  const columns: ColumnProps<UserFundTableData>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      onCell: record => ({
        record,
        handleSave,
        title: 'Date',
        editable: true,
        dataIndex: 'date',
      }),
      render: (data: string) => {
        return (
          <>
            <span>{data}</span>{' '}
            {transformedData[data].length > 1 && (
              <span style={{ color: '#ff4d4f', marginLeft: 10 }}>
                <Icon type="exclamation-circle" style={{ marginRight: 5 }} theme="filled" />
                Duplicated Date
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'ROR',
      dataIndex: 'ror',
      key: 'ror',
      sorter: true,
      onCell: record => ({
        record,
        handleSave,
        editable: true,
        title: 'ROR',
        dataIndex: 'ror',
      }),
    },
    {
      title: 'AUM',
      dataIndex: 'aum',
      key: 'aum',
      sorter: true,
      onCell: record => ({
        record,
        handleSave,
        editable: true,
        title: 'AUM',
        dataIndex: 'aum',
      }),
    },
  ];

  if (!isViewOnly) {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (record: UserFundTableData) => {
        return <DeleteIcon type="delete" onClick={() => handleDelete(record)} />;
      },
    });
  }

  const components: TableComponents | undefined = !isViewOnly
    ? {
        body: {
          row: EditableFormRow,
          cell: EditableCell,
        },
      }
    : undefined;

  return (
    <Table
      components={components}
      pagination={false}
      dataSource={data}
      columns={columns}
      loading={loading}
    />
  );
};

export default UserFundDataTable;
