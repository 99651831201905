import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button, Icon, Modal, notification, Pagination, Row, Table, Tabs, Tooltip } from 'antd';
import { connect, MapStateToProps } from 'react-redux';
import { toast } from 'react-toastify';
import awaitTo from 'async-await-error-handling';
import { TableRowSelection } from 'antd/lib/table';
import { Colors } from '../../shared/colors';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';
import {
  UserFundDataRecord,
  UserFundRecord,
  UserProfileDetails,
} from '../../shared/api/models/ResponsesTypes';
import { CombinedReducers } from '../..';
import {
  FundDetailRegionalFocus,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../shared/api/models/FundDetails';
import ShareModal from '../../shared/components/ShareModal';
import GenUtil from '../../shared/utils/gen-util';
import UserFundForm from './UserFundFormModal';
import AddToFundListModal from './components/AddToFundListModal';

const { TabPane } = Tabs;
const { confirm } = Modal;

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
  }
`;
const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 140px);
`;
const StyledButton = styled(Button)`
  margin-left: 8px;
`;
const Meta = styled('div')`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
  span {
    margin-right: 16px;
    font-weight: 500;
  }
`;
const ActionGroup = styled('div')`
  button {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {};

type StateProps = {
  user: UserProfileDetails;
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
};

const UserFundView: React.FC<Props & StateProps> = ({
  user,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [selectedFund, setSelectedFund] = useState<UserFundRecord>();
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedFundRows, setSelectedFundRows] = useState<number[]>([]);
  const [dataSource, setDataSource] = useState<UserFundRecord[]>([]);
  const {
    data: userFundData,
    invoke: invokeUserFundData,
    loading: userFundLoading,
  } = useServiceState<UserFundRecord[]>(APIService.userFundService.fetchAllUserFunds);

  useEffect(() => {
    invokeUserFundData();
  }, [invokeUserFundData]);

  useEffect(() => {
    const ds: UserFundRecord[] = !_.isEmpty(userFundData)
      ? userFundData.slice((page - 1) * 15, page * 15).map(item => ({
          ...item,
          created_date: GenUtil.getFormattedYearMonthAndDay(item.created_date),
          updated_date: GenUtil.getFormattedYearMonthAndDay(item.updated_date),
          key: item.id,
        }))
      : [];
    setDataSource(ds);
  }, [userFundData, page]);

  const handleUpdate = (record: UserFundRecord) => {
    setSelectedFund(record);
    setShowModal(true);
  };

  const handleClone = async (record: UserFundRecord) => {
    const index: number = userFundData.findIndex((val: UserFundRecord) => val.id === record.id);
    if (index >= 0) {
      if (record.name.startsWith('Clone ')) {
        notification.error({
          message: 'This is an already cloned User Fund',
          placement: 'bottomLeft',
        });
        return;
      }
      const userFundDetails = await APIService.userFundService.fetchUserFundDetails(
        String(record.id),
      );
      const [err] = await awaitTo<any>(
        APIService.userFundService.createNewUserFund({
          name: `Clone of ${record.name}`,
          strategyCode: record.strategy_code,
          subStrategyCode: record.sub_strategy_code,
          regionInvFocus: record.region_inv_focus,
          currency_code: record.currency_code,
          data: userFundDetails.data.user_fund_data.map((item: UserFundDataRecord) => {
            return {
              yyyymmm: item.yyyymmm,
              cur_ror_value: item.cur_ror_value,
              cur_asset_value: item.cur_ror_value,
            };
          }),
        }),
      );
      if (err) {
        notification.error({
          message: "Can't Clone the User Fund. User Fund is already cloned",
          placement: 'bottomLeft',
        });
      } else {
        notification.success({ message: 'User Fund is cloned!', placement: 'bottomLeft' });
        invokeUserFundData();
      }
    }
  };

  const handleViewData = async (record: UserFundRecord) => {
    APIService.eventService.sendViewMyFundEvent();
    setSelectedFund(record);
    setShowDetailModal(true);
  };

  const handleDelete = (record: UserFundRecord) => {
    confirm({
      title: `Do you want to delete '${record.name}'?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await APIService.userFundService.deleteUserFund(record.id);
          notification.success({ message: `${record.name} was deleted!`, placement: 'bottomLeft' });
          invokeUserFundData();
        } catch (error) {
          console.error(error);
          notification.error({
            message: 'Something went wrong. Please try again later!',
            placement: 'bottomLeft',
          });
        }
      },
    });
  };

  const handleShare = async (record: UserFundRecord) => {
    setShowShareModal(true);
    setSelectedFund(record);
  };

  const handleShareSubmit = async (isShareAll: boolean, userIds?: number[]) => {
    if (!selectedFund) {
      return;
    }
    try {
      await APIService.userFundService.shareUserFund(selectedFund.id, userIds || [], isShareAll);
      toast.success(`Fund list ${selectedFund.name} shared!`);
      setShowShareModal(false);
      setSelectedFund(undefined);
      invokeUserFundData();
    } catch (error) {
      console.error(error);
      toast.error('Cannot share the selected fund. Please try again later!');
    }
  };

  const handleCloseModal = () => {
    setSelectedFund(undefined);
    setShowModal(false);
  };

  const handleAdd = () => {
    setShowAddModal(true);
  };

  const rowSelection: TableRowSelection<UserFundRecord> = {
    selectedRowKeys: selectedFundRows,
    onChange: (_, selectedRows) => {
      setSelectedFundRows(selectedRows.map((row: UserFundRecord) => row.id));
    },
  };
  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy_code',
      key: 'strategy_code',
      render: (strategyCode: number) => {
        if (!strategyCode) {
          return null;
        }
        const strategy = strategyOptions.find(item => item.strategy_code === strategyCode);
        return strategy ? strategy.strategy_name : '';
      },
    },
    {
      title: 'Sub-Strategy',
      dataIndex: 'sub_strategy_code',
      key: 'sub_strategy_code',
      render: (subStrategyCode: number) => {
        if (!subStrategyCode) {
          return null;
        }
        const subStrategy = subStrategyOptions.find(
          item => item.sub_strategy_code === subStrategyCode,
        );
        return subStrategy ? subStrategy.sub_strategy_name : '';
      },
    },
    {
      title: 'Regional Focus',
      dataIndex: 'region_inv_focus',
      key: 'region_inv_focus',
      render: (regionalFocusId: number) => {
        if (!regionalFocusId) {
          return null;
        }
        const regionalFocus = regionalFocusOptions.find(
          item => item.id === regionalFocusId.toString(),
        );
        return regionalFocus ? regionalFocus.name : '';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'created_date',
      key: 'created_date',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_date',
      key: 'updated_date',
    },
    {
      title: 'Action',
      key: 'action',
      width: 260,
      render: (_: any, record: any) => (
        <ActionGroup>
          <Tooltip title="View Data">
            <Button type="default" onClick={() => handleViewData(record)}>
              <Icon type="eye" />
            </Button>
          </Tooltip>
          <Tooltip title="Clone">
            <Button type="default" onClick={() => handleClone(record)}>
              <Icon type="copy" />
            </Button>
          </Tooltip>
          <Tooltip title="Update">
            <Button type="default" onClick={() => handleUpdate(record)}>
              <Icon type="edit" />
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button type="danger" onClick={() => handleDelete(record)}>
              <Icon type="delete" />
            </Button>
          </Tooltip>
          <Tooltip title="Share">
            <Button type="default" disabled={user.hfrdb_trial} onClick={() => handleShare(record)}>
              <Icon type="share-alt" />
            </Button>
          </Tooltip>
        </ActionGroup>
      ),
    },
  ];

  return (
    <StyledTabs hideAdd activeKey="1" type="editable-card">
      <StyledTabPane tab="My Fund" key="1" closable={false}>
        <Wrapper>
          <Row
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip visible={user.hfrdb_trial} title="Your account do not have permission">
              <Button type="primary" onClick={() => setShowModal(true)} disabled={user.hfrdb_trial}>
                <Icon type="plus" />
                Add New
              </Button>
            </Tooltip>
            {showModal && (
              <Modal
                title={selectedFund ? 'Update Fund' : 'Create New Fund'}
                visible={showModal}
                width="90%"
                centered
                footer={null}
                maskClosable={false}
                onCancel={() => handleCloseModal()}
              >
                <UserFundForm
                  isViewOnly={false}
                  selectedUserFund={selectedFund}
                  onFundListUpdated={invokeUserFundData}
                  onRequestClose={() => handleCloseModal()}
                />
              </Modal>
            )}
            {showDetailModal && (
              <Modal
                title={'Fund Detail'}
                visible={showDetailModal}
                width="90%"
                centered
                footer={null}
                maskClosable={false}
                onCancel={() => setShowDetailModal(false)}
              >
                <UserFundForm
                  isViewOnly={true}
                  selectedUserFund={selectedFund}
                  onFundListUpdated={invokeUserFundData}
                  onRequestClose={() => setShowDetailModal(false)}
                />
              </Modal>
            )}
            {showAddModal && (
              <AddToFundListModal
                visible={showAddModal}
                selectedFunds={selectedFundRows}
                onRequestClose={() => {
                  setShowAddModal(false);
                }}
              />
            )}
          </Row>
          <Table
            bordered
            loading={userFundLoading}
            rowSelection={rowSelection}
            scroll={{ y: 'calc(100vh - 290px)' }}
            pagination={false}
            style={{ flexGrow: 1 }}
            size="small"
            dataSource={dataSource}
            columns={COLUMNS}
          />
          <Meta>
            <StyledButton disabled={_.isEmpty(selectedFundRows)} onClick={handleAdd}>
              Add to...
            </StyledButton>
            <Pagination
              onChange={(p: number) => {
                setPage(p);
              }}
              total={userFundData ? userFundData.length : 0}
              current={page}
              pageSize={15}
            />
          </Meta>
          {showShareModal && (
            <ShareModal
              visible={showShareModal}
              onRequestClose={() => setShowShareModal(false)}
              onSubmit={handleShareSubmit}
            />
          )}
        </Wrapper>
      </StyledTabPane>
    </StyledTabs>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
  strategyOptions: state.searchReducer.strategyOptions,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
  regionalFocusOptions: state.searchReducer.regionalFocusOptions,
});

export default connect(mapStateToProps)(UserFundView);
