import { Select } from 'antd';
import React from 'react';
import { FundDetailRegionalFocus } from '../../shared/api/models/FundDetails';

type Props = {
  value?: string;
  onChange: (value: string | undefined) => void;
  options: FundDetailRegionalFocus[];
};

const PeerGroupSelect: React.FC<Props> = ({ onChange, value, options }) => {
  const [searchText, setSearchText] = React.useState<string>();

  const handleSearch = (term: string) => {
    setSearchText(term);
  };

  const filteredOptions = React.useMemo(() => {
    if (!searchText) {
      return options;
    }
    return options.filter(item => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [options, searchText]);

  const existedOption = value ? options.find(peerGroup => peerGroup.id === value) : undefined;

  const optionsWithSelectedValue = existedOption
    ? [existedOption, ...filteredOptions.filter(item => item.id !== value).slice(0, 30)]
    : [...filteredOptions.filter(item => item.id !== value).slice(0, 30)];

  return (
    <Select
      onChange={(newValue: string) => {
        setSearchText(undefined);
        onChange(newValue);
      }}
      showSearch
      style={{ width: 300, marginLeft: 10, marginRight: 10 }}
      onSearch={input => handleSearch(input)}
      placeholder="Search for Peer Group"
      value={value}
      filterOption={false}
    >
      {optionsWithSelectedValue.map(item => (
        <Select.Option key={item.id} value={item.id}>
          {item.name} ({item.id})
        </Select.Option>
      ))}
    </Select>
  );
};

export default PeerGroupSelect;
