import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import APIService from '../../shared/api';
import { DisclaimerResponse, DownloadFileRecord } from '../../shared/api/models/ResponsesTypes';
import { Colors } from '../../shared/colors';
import { useServiceState } from '../../shared/hooks/useServiceState';
import DownloadDataTable from './components/DownloadDataTable';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
  }

  .ant-table-body {
    scrollbar-width: auto;
  }
`;
const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 140px);
`;

const Container = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  padding: 20px 0;
  background: white;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  padding: 20px 10px;
`;

const DisclaimerContainer = styled(PerfectScrollbar)`
  overflow-y: auto;
  min-height: 80px;
  max-height: 80px;
  padding: 0 20px;
  font-size: 9px;
`;

interface Props {}
/**
 * Raw Data File Tab-pane view
 */

const RawDataFileView: React.FC<Props> = () => {
  const { data: rawFileData, invoke: invokeRawFileData } = useServiceState<DownloadFileRecord[]>(
    APIService.downloadFileService.fetchFiles,
  );

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  useEffect(() => {
    invokeRawFileData();
  }, [invokeRawFileData]);

  useEffect(() => {
    invokeDisclaimerData('download');
  }, [invokeDisclaimerData]);

  return (
    <StyledTabs hideAdd activeKey="1" type="editable-card">
      <StyledTabPane tab="Files" key="1" closable={false}>
        <Container>
          <TableContainer>
            <h4> Latest Documents</h4>
            <DownloadDataTable tableData={rawFileData} />
          </TableContainer>
          <Button
            type="link"
            style={{ alignSelf: 'flex-end', fontSize: '12px' }}
            onClick={() => setShowDisclaimer(!showDisclaimer)}
          >
            {showDisclaimer ? 'Hide Disclaimer' : 'Show Disclaimer'}
          </Button>
          {showDisclaimer && (
            <DisclaimerContainer
              dangerouslySetInnerHTML={{
                __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
              }}
            />
          )}
        </Container>
      </StyledTabPane>
    </StyledTabs>
  );
};

export default RawDataFileView;
