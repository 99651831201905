import moment from 'moment';
import {
  PeerGroupFundBenchmarkResponse,
  PeerGroupUniverseStatsResponse,
  BenchmarkRecord,
} from '../../../../shared/api/models/ResponsesTypes';
import { PeerGroupTableDataType } from './PeerGroupForm';

/**
 * Remap data from API response to table data structure
 * @param sourceData {PeerGroupUniverseStatsResponse} - PeerGroupUniverseStats data
 */
export const resolveUniverseStatData = (
  sourceData: PeerGroupUniverseStatsResponse,
): PeerGroupTableDataType[] => {
  if (!sourceData || !Object.keys(sourceData).length) {
    return [];
  }

  const orderedRowArray: Array<{ key: string; name: string }> = [
    { key: 'number_in_universe', name: 'Number In Universe' },
    { key: 'fund_percentile', name: 'Fund Percentile (%)' },
    { key: 'maximum_return', name: 'Maximum Return' },
    { key: '95th_percentile', name: 'Percentile 95th' },
    { key: '75th_percentile', name: 'Percentile 75th' },
    { key: 'median_return', name: 'Median Return' },
    { key: '25th_percentile', name: 'Percentile 25th' },
    { key: '5th_percentile', name: 'Percentile 5th' },
    { key: 'minimum_return', name: 'Minimum Return' },
  ];
  return orderedRowArray.map(row => ({
    name: row.name,
    current: sourceData.first_month[row.key],
    prevMonth: sourceData.previous_month[row.key],
    threeMonths: sourceData.three_month[row.key],
    sixMonths: sourceData.six_month[row.key],
    ytd: sourceData.ytd[row.key],
    oneYear: sourceData.one_year[row.key],
    twoYears: sourceData.two_year[row.key],
    threeYears: sourceData.three_year[row.key],
    fiveYears: sourceData.five_year[row.key],
  }));
};

/**
 * Remap data from API response to table data structure
 * @param sourceData {PeerGroupFundBenchmarkResponse} - PeerGroup Funds and Benchmarks data
 * @param benchmarkObj {[key: string]: string} - The list of all available benchmarks to get the name based on selected benchmarks
 * @param selectedBenchmarks {number[]} - Selected benchmarks 1, 2, 3
 */
export const resolveFundAndBenchmarkData = (
  sourceData: PeerGroupFundBenchmarkResponse,
  benchmarkOptions: BenchmarkRecord[],
  selectedBenchmarks: string[],
): PeerGroupTableDataType[] => {
  const getBenchmarkName = (selectedBenchmarkId: string) => {
    const selected = benchmarkOptions.find(item => item.id === selectedBenchmarkId);
    return selected ? selected.name : '';
  };
  return Object.entries(sourceData).map(([key, data]) => {
    let name = '';
    if (key === 'b1') {
      name = getBenchmarkName(selectedBenchmarks[0]);
    }
    if (key === 'b2') {
      name = getBenchmarkName(selectedBenchmarks[1]);
    }
    if (key === 'b3') {
      name = getBenchmarkName(selectedBenchmarks[2]);
    }
    if (key === 'fund') {
      name = 'Fund';
    }
    return {
      name,
      current: data.first_month,
      prevMonth: data.previous_month,
      threeMonths: data.three_month,
      sixMonths: data.six_month,
      ytd: data.ytd,
      oneYear: data.one_year,
      twoYears: data.two_year,
      threeYears: data.three_year,
      fiveYears: data.five_year,
    };
  });
};

/**
 *
 * @param range - the number of options in the select that represents the months
 * @param date - calculation to be made with respect to this date
 */
export const buildDateSelectOptions = (range: number, date: moment.Moment) => {
  return Array.from({ length: range }).map((_a, index) => {
    return moment(date)
      .subtract(index, 'month')
      .format('YYYY-MM');
  });
};
