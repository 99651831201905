import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

type Props = {
  gteValue?: number;
  lteValue?: number;
  updateValues: (gteValue?: string, lteValue?: string) => void;
};

const FundAssetSelect: React.FC<Props> = ({ gteValue, lteValue, updateValues }) => {
  const [selectedCondition, setSelectedCondition] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState();

  // initialize
  React.useEffect(() => {
    if (!gteValue && !lteValue) {
      setSelectedCondition('all');
      setSelectedValue(undefined);
    } else if (gteValue && lteValue) {
      setSelectedCondition('between');
      setSelectedValue(`${gteValue}-${lteValue}`);
    } else if (!gteValue && lteValue) {
      setSelectedCondition('lte');
      setSelectedValue(`${lteValue}`);
    } else if (gteValue && !lteValue) {
      setSelectedCondition('gte');
      setSelectedValue(`${gteValue}`);
    }
  }, [gteValue, lteValue]);

  const handleTypeChange = (type: string) => {
    setSelectedCondition(type);
    switch (type) {
      case 'all':
        updateValues(undefined, undefined);
        break;
      case 'gte':
        updateValues('1', undefined);
        break;
      case 'lte':
        updateValues(undefined, '1');
        break;
      case 'between':
        updateValues('1', '10');
        break;
      default:
        return;
    }
  };

  const handleValueChange = (value: string) => {
    if (!value) {
      return;
    }
    switch (selectedCondition) {
      case 'gte':
        updateValues(value, undefined);
        break;
      case 'lte':
        updateValues(undefined, value);
        break;
      default:
        return;
    }
  };

  const handleBetweenValueChange = (value: string) => {
    const lte = value.split('-')[0];
    const gte = value.split('-')[1];
    updateValues(lte, gte);
  };

  return (
    <Form.Item label="Fund Assets">
      <Select style={{ width: '40%' }} onChange={handleTypeChange} value={selectedCondition}>
        <Option key="all">All</Option>
        <Option key="gte">{'>='}</Option>
        <Option key="lte">{'<='}</Option>
        <Option key="between">Between</Option>
      </Select>
      {selectedCondition !== 'all' && selectedCondition === 'between' && (
        <Select
          style={{ width: '55%', marginLeft: 8 }}
          onChange={handleBetweenValueChange}
          value={selectedValue}
        >
          <Option key={'1-10'}>1 and 10 Million</Option>
          <Option key={'10-25'}>10 and 25 Million</Option>
          <Option key={'25-50'}>25 and 50 Million</Option>
          <Option key={'50-10'}>50 and 100 Million</Option>
          <Option key={'100-250'}>100 and 250 Million</Option>
          <Option key={'250-500'}>250 and 500 Million</Option>
          <Option key={'500-1000'}>500M and 1 Billion</Option>
        </Select>
      )}
      {selectedCondition !== 'all' && selectedCondition !== 'between' && (
        <Select
          style={{ width: '55%', marginLeft: 8 }}
          onChange={handleValueChange}
          value={selectedValue}
        >
          <Option key={'1'}>1 Million</Option>
          <Option key={'10'}>10 Million</Option>
          <Option key={'25'}>25 Million</Option>
          <Option key={'50'}>50 Million</Option>
          <Option key={'75'}>75 Million</Option>
          <Option key={'100'}>100 Million</Option>
          <Option key={'200'}>200 Million</Option>
          <Option key={'300'}>300 Million</Option>
          <Option key={'500'}>500 Million</Option>
          <Option key={'1000'}>1 Billion</Option>
        </Select>
      )}
    </Form.Item>
  );
};

export default FundAssetSelect;
