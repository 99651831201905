import { Button, Collapse, Form, Row, Select } from 'antd';
import React, { FormEvent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import {
  FetchInvestorParams,
  InvestorDBDropdownRespone,
} from '../../shared/api/services/investor-db.service';
import {
  FormInputItem,
  FormPairValueItem,
  FormSelectItem,
} from '../search/components/SearchForm/FormItem';
import { FieldFilterMillions } from './InvestorDatabase';

const { Option } = Select;
const { Panel } = Collapse;
// const { MonthPicker } = DatePicker;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-spin-nested-loading {
    flex: 1;

    .ant-spin-container {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 16px;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

const Content = styled(PerfectScrollbar)`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled('div')`
  padding: 16px;
  padding-bottom: 0;
  border-top: 1px solid rgb(217, 217, 217);
  flex-wrap: 'wrap';
  display: flex;
`;
const MILLIONS = 1000000;
type Props = {
  onSearch: (p: Partial<FetchInvestorParams>) => void;
  listDropdownSearch: InvestorDBDropdownRespone;
  investorParams: Partial<FetchInvestorParams>;
};

export const SearchFormInvestor: React.FC<Props> = ({
  onSearch,
  listDropdownSearch,
  investorParams,
}) => {
  const initialParams: Partial<FetchInvestorParams> = Object.keys(investorParams).reduce(
    (acc, current) => {
      if (FieldFilterMillions.includes(current)) {
        return {
          ...acc,
          [current]: Number(investorParams[current as keyof FetchInvestorParams]) / MILLIONS,
        };
      }
      return { ...acc, [current]: investorParams[current as keyof FetchInvestorParams] };
    },
    {},
  );

  const [stateForm, setStateForm] = React.useState<Partial<FetchInvestorParams>>(initialParams);

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const extractedEmptyArrayProps = Object.entries(stateForm).reduce((result, [key, value]) => {
      if ((value && Array.isArray(value) && value.length <= 0) || !value) {
        return result;
      }
      return {
        ...result,
        [key]: value,
      };
    }, {} as Partial<FetchInvestorParams>);
    onSearch(extractedEmptyArrayProps);
  };

  const handleClearForm = () => {
    setStateForm({});
  };

  const renderInvestorType = () => (
    <FormSelectItem
      isMulti
      label="Investor Type"
      placeholder="Select Investor Type"
      onChange={(selected: number[]) => setStateForm({ ...stateForm, investor_type: selected })}
      value={stateForm.investor_type}
    >
      {listDropdownSearch &&
        listDropdownSearch['Investor Type'] &&
        listDropdownSearch['Investor Type'].map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderState = () => (
    <FormSelectItem
      isMulti
      label="State"
      placeholder="Select State"
      onChange={(selected: number[]) => setStateForm({ ...stateForm, primary_state: selected })}
      value={stateForm.primary_state}
    >
      {listDropdownSearch &&
        listDropdownSearch.State &&
        listDropdownSearch.State.map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderSecondaryState = () => (
    <FormSelectItem
      isMulti
      label="Secondary State"
      placeholder="Select Secondary State"
      onChange={(selected: number[]) => setStateForm({ ...stateForm, secondary_state: selected })}
      value={stateForm.secondary_state}
    >
      {listDropdownSearch &&
        listDropdownSearch.State &&
        listDropdownSearch.State.map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderCountry = () => (
    <FormSelectItem
      isMulti
      label="Country"
      placeholder="Select Country"
      onChange={(selected: string[]) => setStateForm({ ...stateForm, primary_country: selected })}
      value={stateForm.primary_country}
    >
      {listDropdownSearch &&
        listDropdownSearch.Country &&
        listDropdownSearch.Country.map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderSecondaryCountry = () => (
    <FormSelectItem
      isMulti
      label="Secondary Country"
      placeholder="Select Secondary Country"
      onChange={(selected: number[]) => setStateForm({ ...stateForm, secondary_country: selected })}
      value={stateForm.secondary_country}
    >
      {listDropdownSearch &&
        listDropdownSearch.Country &&
        listDropdownSearch.Country.map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderEntityType = () => (
    <FormSelectItem
      isMulti
      label="Entity Type"
      placeholder="Select Entity Type"
      onChange={(selected: number[]) => setStateForm({ ...stateForm, entity_type: selected })}
      value={stateForm.entity_type}
    >
      {listDropdownSearch &&
        listDropdownSearch['Entity Type'] &&
        listDropdownSearch['Entity Type'].map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  const renderPotentialStrategies = () => (
    <FormSelectItem
      isMulti
      label="Investment Strategy"
      placeholder="Select Investment Strategy"
      onChange={(selected: number[]) =>
        setStateForm({ ...stateForm, investment_strategy: selected })
      }
      value={stateForm.investment_strategy}
    >
      {listDropdownSearch &&
        listDropdownSearch.Strategy &&
        listDropdownSearch.Strategy.map(item => (
          <Option key={item.id} value={item.id}>
            {item.value}
          </Option>
        ))}
    </FormSelectItem>
  );

  return (
    <StyledForm style={{ height: '100%' }} onSubmit={handleSubmit}>
      <Header>
        <span>Search Query</span>
      </Header>
      <Content>
        <Row>
          <FormInputItem
            label="Company Name"
            placeholder="Company Name"
            value={stateForm.company_name || ''}
            onChange={value => setStateForm({ ...stateForm, company_name: value as string })}
          />
        </Row>
        <Row>{renderInvestorType()}</Row>
        <Row>{renderEntityType()}</Row>
        <Row>{renderPotentialStrategies()}</Row>
        <Row>
          <FormInputItem
            label="Investment Executive"
            placeholder="Investment Executive"
            value={stateForm.investment_executive || ''}
            onChange={value =>
              setStateForm({ ...stateForm, investment_executive: value as string })
            }
          />
        </Row>
        <Row>
          <FormInputItem
            label="Other Investment Professionals"
            placeholder="Other Investment Professionals"
            value={stateForm.other_investment_professionals || ''}
            onChange={value =>
              setStateForm({ ...stateForm, other_investment_professionals: value as string })
            }
          />
        </Row>
        <Row>
          <Collapse>
            <Panel header="Assets" key="Assets">
              <Row>
                <FormPairValueItem
                  key={'total_assets_lte'}
                  label="Total Assets (M)"
                  atleastValue={stateForm.total_assets_gte}
                  atmostValue={stateForm.total_assets_lte}
                  updateAtleastValue={value =>
                    setStateForm({ ...stateForm, total_assets_gte: value as number })
                  }
                  updateAtmostValue={value =>
                    setStateForm({ ...stateForm, total_assets_lte: value as number })
                  }
                />
              </Row>
              {/* <Row gutter={8}>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.total_assets_as_of_gte
                          ? moment(stateForm.total_assets_as_of_gte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          total_assets_as_of_gte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.total_assets_as_of_lte
                          ? moment(stateForm.total_assets_as_of_lte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          total_assets_as_of_lte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <FormPairValueItem
                  key={'hedge_fund_assets_lte'}
                  label="Hedge Fund Assets (M)"
                  atleastValue={stateForm.hedge_fund_assets_gte}
                  atmostValue={stateForm.hedge_fund_assets_lte}
                  updateAtleastValue={value =>
                    setStateForm({
                      ...stateForm,
                      hedge_fund_assets_gte: value as number,
                    })
                  }
                  updateAtmostValue={value =>
                    setStateForm({
                      ...stateForm,
                      hedge_fund_assets_lte: value as number,
                    })
                  }
                />
              </Row>
              {/* <Row gutter={8}>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.hedge_fund_assets_as_of_gte
                          ? moment(stateForm.hedge_fund_assets_as_of_gte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          hedge_fund_assets_as_of_gte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.hedge_fund_assets_as_of_lte
                          ? moment(stateForm.hedge_fund_assets_as_of_lte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          hedge_fund_assets_as_of_lte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row> */}
              <Row>
                <FormPairValueItem
                  key={'alternative_assets_lte'}
                  label="Alternative Assets (M)"
                  atleastValue={stateForm.alternative_assets_gte}
                  atmostValue={stateForm.alternative_assets_lte}
                  updateAtleastValue={value =>
                    setStateForm({
                      ...stateForm,
                      alternative_assets_gte: value as number,
                    })
                  }
                  updateAtmostValue={value =>
                    setStateForm({
                      ...stateForm,
                      alternative_assets_lte: value as number,
                    })
                  }
                />
              </Row>
              {/* <Row gutter={8}>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.alternative_assets_as_of_gte
                          ? moment(stateForm.alternative_assets_as_of_gte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          alternative_assets_as_of_gte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <MonthPicker
                      value={
                        stateForm.alternative_assets_as_of_lte
                          ? moment(stateForm.alternative_assets_as_of_lte)
                          : undefined
                      }
                      onChange={value =>
                        setStateForm({
                          ...stateForm,
                          alternative_assets_as_of_lte: value
                            ? moment(value).format('YYYY-MM') + '-01'
                            : undefined,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row> */}
            </Panel>
            <Panel header="Contact Info" key="Contact Info">
              <Row>
                <FormInputItem
                  label="Website Address"
                  placeholder="Website Address"
                  value={stateForm.website_address || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, website_address: value as string })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Company Email"
                  placeholder="Company Email"
                  value={stateForm.company_email || ''}
                  onChange={value => setStateForm({ ...stateForm, company_email: value as string })}
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Phone Number"
                  placeholder="Phone Number"
                  value={stateForm.primary_phone_number || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, primary_phone_number: value as string })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Address 1"
                  placeholder="Address 1"
                  value={stateForm.primary_address1 || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, primary_address1: value as string })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Address 2"
                  placeholder="Address 2"
                  value={stateForm.primary_address2 || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, primary_address2: value as string })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="City"
                  placeholder="Select City"
                  value={stateForm.primary_city || ''}
                  onChange={value => setStateForm({ ...stateForm, primary_city: value as string })}
                />
              </Row>
              <Row>{renderState()}</Row>
              <Row>{renderCountry()}</Row>
              <Row>
                <FormInputItem
                  label="Postal Code"
                  placeholder="Postal Code"
                  value={stateForm.primary_postal_code || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, primary_postal_code: value as string })
                  }
                />
              </Row>
            </Panel>
            <Panel header="Secondary Contact Info" key="Secondary Contact Info">
              <Row>
                <FormInputItem
                  label="Secondary Phone Number"
                  placeholder="Secondary Phone Number"
                  value={stateForm.secondary_phone_number || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, secondary_phone_number: value as string })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Secondary Address 1"
                  placeholder="Secondary Address 1"
                  value={stateForm.secondary_address1 || ''}
                  onChange={value =>
                    setStateForm({
                      ...stateForm,
                      secondary_address1: value as string,
                    })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Secondary Address 2"
                  placeholder="Secondary Address 2"
                  value={stateForm.secondary_address2 || ''}
                  onChange={value =>
                    setStateForm({
                      ...stateForm,
                      secondary_address2: value as string,
                    })
                  }
                />
              </Row>
              <Row>
                <FormInputItem
                  label="Secondary City"
                  placeholder="Secondary City"
                  value={stateForm.secondary_city || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, secondary_city: value as string })
                  }
                />
              </Row>
              <Row>{renderSecondaryState()}</Row>
              <Row>{renderSecondaryCountry()}</Row>
              <Row>
                <FormInputItem
                  label="Secondary Postal Code"
                  placeholder="Secondary Postal Code"
                  value={stateForm.secondary_postal_code || ''}
                  onChange={value =>
                    setStateForm({ ...stateForm, secondary_postal_code: value as string })
                  }
                />
              </Row>
            </Panel>
          </Collapse>
        </Row>
      </Content>
      <Footer>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8, marginBottom: 8 }}>
          Search
        </Button>
        <Button type="default" onClick={handleClearForm} style={{ marginRight: 8 }}>
          Clear
        </Button>
      </Footer>
    </StyledForm>
  );
};
