import React from 'react';
import { Select, Table } from 'antd';
import { MapStateToProps, connect, MapDispatchToProps } from 'react-redux';
import styled from 'styled-components';
import { CombinedReducers } from '../..';
import { FundDetailSubStrategy, FundDetailStrategy } from '../../shared/api/models/FundDetails';
import APIService from '../../shared/api';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { Colors } from '../../shared/colors';
import { UserProfileDetails } from '../../shared/api/models/ResponsesTypes';

const { Option } = Select;

const Container = styled('div')`
  min-width: 300px;
  max-width: 25%;
  /* @media screen and (min-width: 1500px) {
    width: 25%;
  }
  @media screen and (max-width: 1500px) {
    width: 50%;
  } */
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .ant-table-thead > tr > th .ant-table-header-column {
    display: block;
  }
`;

const StyledStrategy = styled('div')`
  margin: 0 16px;

  .ant-select-selection {
    border: none;
    width: min-content;
    box-shadow: none;
  }

  .ant-select-selection-selected-value {
    font-weight: 600;
    font-size: 16px;
    box-shadow: none;
  }

  .ant-select-arrow {
    right: 5px;
  }
`;

type StateProps = {
  user: UserProfileDetails;
  subStrategyOptions: FundDetailSubStrategy[];
};
type DispatchProps = {
  addPane: (record: any) => void;
};

type Props = {
  strategyId: number;
  onSelectStrategy: (strategyId: number) => void;
  onSelectSubStrategy: (subStrategyId: number | undefined) => void;
  loading: boolean;
  selectableOptions: FundDetailStrategy[];
  dataSource?: any[];
};

const StrategyTable: React.FC<Props & StateProps & DispatchProps> = ({
  strategyId,
  onSelectStrategy,
  user,
  selectableOptions,
  subStrategyOptions: subStrategies,
  onSelectSubStrategy,
  loading,
  dataSource,
  addPane,
}) => {
  const subStrategyOptions = subStrategies.filter(item => item.strategy_code === strategyId);
  const FundName = styled('span')`
    color: ${user.hfrdb_trial ? Colors.gray : Colors.primary};
    cursor: ${user.hfrdb_trial ? 'auto' : 'pointer'};
  `;

  return (
    <Container className="ranking-table">
      <StyledStrategy>
        <Select style={{ width: '100%' }} value={strategyId} onChange={onSelectStrategy}>
          {selectableOptions.length > 0 &&
            selectableOptions.map(item => (
              <Select.Option key={item.strategy_code} value={item.strategy_code}>
                {item.strategy_name}
              </Select.Option>
            ))}
        </Select>
      </StyledStrategy>
      <Table
        scroll={{ y: 'calc(100vh - 345px)' }}
        rowKey="fund_id"
        pagination={false}
        loading={loading}
        dataSource={dataSource}
        columns={[
          {
            title: (
              <Select
                style={{ width: '100%' }}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  if (!option || !option.props || !option.props.children) {
                    return false;
                  }
                  return (
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
                placeholder="Select Sub Strategy"
                onChange={onSelectSubStrategy}
              >
                {subStrategyOptions.map(item => (
                  <Option key={item.sub_strategy_code} value={item.sub_strategy_code}>
                    {item.sub_strategy_name}
                  </Option>
                ))}
              </Select>
            ),
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '70%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
    </Container>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => ({
  addPane: record => {
    dispatch({ type: RootActionEnums.ADD_RANKING_PANE, payload: record });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StrategyTable);
