import { Button, Col, Collapse, Form, Icon, Row, Select, Spin, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AxiosPromise } from 'axios';
import JsFileDownload from 'js-file-download';
import moment from 'moment';
import React, { FormEvent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { CombinedReducers } from '../../../..';
import APIService from '../../../../shared/api';
import {
  FundDetailRegionalFocus,
  FundDetails,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../../../shared/api/models/FundDetails';
import {
  BenchmarkRecord,
  FundListRecord,
  PeerGroupFundBenchmarkResponse,
  PeerGroupUniverseStatsResponse,
  UserFundRecord,
  UserProfileDetails,
} from '../../../../shared/api/models/ResponsesTypes';
import { FetchPeerGroupPdfParams } from '../../../../shared/api/services/peer-group.service';
import { FetchUserFundPeerGroupPdfParams } from '../../../../shared/api/services/user-fund.service';
import { UniverseSelectTypeEnum } from '../../../../shared/components/UniverseSelect';
import { useServiceState } from '../../../../shared/hooks/useServiceState';
import GenUtil from '../../../../shared/utils/gen-util';
import { PerformanceVersusUniverseChart } from '../PerformanceVersusUniverseChart';
import { resolveFundAndBenchmarkData, resolveUniverseStatData } from './utils';

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    padding: 16px 0;
  }
`;

export interface PeerGroupTableDataType {
  name: string;
  current: number | undefined;
  prevMonth: number | undefined;
  threeMonths: number | undefined;
  sixMonths: number | undefined;
  ytd: number | undefined;
  oneYear: number | undefined;
  twoYears: number | undefined;
  threeYears: number | undefined;
  fiveYears: number | undefined;
}

const { Option } = Select;

type Props = {
  fundId: string;
  fundData?: FundDetails;
  userFundData?: UserFundRecord;
  endDateOptions: string[];
  fundAndBenchmarkService: ({
    fundId,
    benchmark1,
    benchmark2,
    benchmark3,
    endDate,
  }: {
    fundId: string;
    benchmark1: string;
    benchmark2: string;
    benchmark3: string;
    endDate: string | moment.Moment;
  }) => AxiosPromise<PeerGroupFundBenchmarkResponse>;
  universeStatsService: ({
    fundId,
    universeListId,
    fundListId,
    portfolioModelId,
    endDate,
    staticIndexIds,
    dynamicIndexIds,
    strategy,
    subStrategy,
    regionalFocus,
    inHFRI,
    inEmergingMarkets,
  }: {
    fundId: string;
    universeListId?: string[];
    fundListId?: string[];
    portfolioModelId?: string[];
    endDate: string;
    staticIndexIds: string[];
    dynamicIndexIds: string[];
    strategy: string[];
    subStrategy: string[];
    regionalFocus: string[];
    inHFRI: boolean;
    inEmergingMarkets: boolean;
  }) => AxiosPromise<PeerGroupUniverseStatsResponse>;
} & FormComponentProps<{
  strategies: number[];
  subStrategy: number[];
  universeList: string[];
  benchmark1: number;
  benchmark2: number;
  benchmark3: number;
  endDate: string;
  hfrIndexes?: string[];
  inHFRI: string;
  inEmergingMarkets: string;
  regionalFocus: string[];
}>;

interface StateProps {
  user: UserProfileDetails;
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
  universeListData?: FundListRecord[];
  hfrIndexes?: FundListRecord[];
  benchmarkOptions: BenchmarkRecord[];
}

const renderTable = (dataSource: PeerGroupTableDataType[], endDate: string) => (
  <StyledTable dataSource={dataSource} rowKey="name" pagination={false}>
    <Table.Column key="name" dataIndex="name" title="" width={200} />
    <Table.Column
      key="current"
      dataIndex="current"
      title={endDate}
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.current && row2.current ? row1.current - row2.current : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="prevMonth"
      dataIndex="prevMonth"
      title="Prev Month"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.prevMonth && row2.prevMonth ? row1.prevMonth - row2.prevMonth : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="threeMonths"
      dataIndex="threeMonths"
      title="3 Months"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.threeMonths && row2.threeMonths ? row1.threeMonths - row2.threeMonths : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="sixMonths"
      dataIndex="sixMonths"
      title="6 Months"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.sixMonths && row2.sixMonths ? row1.sixMonths - row2.sixMonths : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="ytd"
      dataIndex="ytd"
      title="YTD"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.ytd && row2.ytd ? row1.ytd - row2.ytd : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="oneYear"
      dataIndex="oneYear"
      title="1 Year"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.oneYear && row2.oneYear ? row1.oneYear - row2.oneYear : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="twoYears"
      dataIndex="twoYears"
      title="2 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.twoYears && row2.twoYears ? row1.twoYears - row2.twoYears : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="threeYears"
      dataIndex="threeYears"
      title="3 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.threeYears && row2.threeYears ? row1.threeYears - row2.threeYears : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
    <Table.Column
      key="fiveYears"
      dataIndex="fiveYears"
      title="5 Year (ann)"
      sorter={(row1: PeerGroupTableDataType, row2: PeerGroupTableDataType) =>
        row1.fiveYears && row2.fiveYears ? row1.fiveYears - row2.fiveYears : 0
      }
      render={text => (text && GenUtil.digitWithMaxPrecision(text)) || null}
      align="right"
    />
  </StyledTable>
);

/**
 * Peer Group Tab in Fund Details
 * @param {string} fundId - ID of the current fund
 * @param {BenchmarkRecord[]} benchmarksOptions - The benchmarks data for the benchmark selections
 * @param {FundListRecord[]} universeListData  - Universe & user defined list data for dropdown (redux store)
 * @param {WrappedFormUtils} form - Antd's form
 * @param {IFundDetailStrategy} strategyOptions - strategy options for dropdown (redux store)
 * @param {IFundDetailSubStrategy} subStrategyOptions - sub-strategy options for dropdown (redux store)
 * @param {IFundDetailRegionalFocus} regionalFocusOptions - regional focus options for dropdown (redux store)
 */
const PeerGroupForm: React.FC<StateProps & Props> = ({
  fundId,
  fundData,
  userFundData,
  endDateOptions,
  benchmarkOptions,
  universeListData,
  hfrIndexes,
  form,
  user,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
  fundAndBenchmarkService,
  universeStatsService,
}) => {
  const { getFieldDecorator } = form;

  const [selectedEndDate, setSelectedEndDate] = React.useState<string>();

  const {
    data: fundAndBenchmarkData,
    invoke: invokeFundAndBenchmark,
    loading: fundBenchmarkLoading,
  } = useServiceState<PeerGroupFundBenchmarkResponse>(fundAndBenchmarkService);
  const {
    data: universeStatData,
    invoke: invokeUniverseStatData,
    loading: universeStatLoading,
  } = useServiceState<PeerGroupUniverseStatsResponse>(universeStatsService);

  const [selectedBenchmarks, setSelectedBenchmarks] = React.useState<string[]>([]);
  const [filteredSubStrategy, setFilteredSubStrategy] = React.useState<FundDetailSubStrategy[]>([]);

  React.useEffect(() => {
    const strategyFromForm = form.getFieldValue('strategies');
    if (strategyFromForm && strategyFromForm.length > 0) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && strategyFromForm.includes(subStrategy.strategy_code),
        ),
      );
    } else {
      setFilteredSubStrategy(subStrategyOptions);
    }
  }, [form, subStrategyOptions, setFilteredSubStrategy]);

  const renderBenchmarkOptions = () => {
    return benchmarkOptions.map(option => {
      return (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      );
    });
  };

  const validateOneOf = (_rule: any, _value: any, callback: any) => {
    const strategyValues = form.getFieldValue('strategies');
    const subStrategyValues = form.getFieldValue('subStrategy');
    const regionalFocusValues = form.getFieldValue('regionalFocus');
    const inHFRIValue = form.getFieldValue('inHFRI');
    const inEmergingMarketValue = form.getFieldValue('inEmergingMarkets');

    if (
      (!strategyValues || strategyValues.length <= 0) &&
      (!subStrategyValues || subStrategyValues.length <= 0) &&
      (!regionalFocusValues || regionalFocusValues.length <= 0) &&
      inHFRIValue === undefined &&
      inEmergingMarketValue === undefined
    ) {
      callback(
        'Requiring at least one of Strategy, Sub-Strategy, Regional Focus, In HFRI, In Emerging Market',
      );
    } else {
      callback();
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const extractedEmptyArrayProps = Object.entries(values).reduce((result, [key, value]) => {
          if (value && Array.isArray(value) && value.length <= 0) {
            return result;
          }
          return {
            ...result,
            [key]: value,
          };
        }, {} as any);

        setSelectedEndDate(extractedEmptyArrayProps.endDate);
        invokeFundAndBenchmark({
          fundId,
          benchmark1: extractedEmptyArrayProps.benchmark1,
          benchmark2: extractedEmptyArrayProps.benchmark2,
          benchmark3: extractedEmptyArrayProps.benchmark3,
          endDate: extractedEmptyArrayProps.endDate,
        });
        setSelectedBenchmarks([
          extractedEmptyArrayProps.benchmark1,
          extractedEmptyArrayProps.benchmark2,
          extractedEmptyArrayProps.benchmark3,
        ]);

        const universeList = extractedEmptyArrayProps.universeList
          .filter((item: string) => item.includes(UniverseSelectTypeEnum.HFR))
          .map((item: string) => Number.parseInt(item.split('-')[1]));
        const fundList = extractedEmptyArrayProps.universeList
          .filter((item: string) => item.includes(UniverseSelectTypeEnum.LIST))
          .map((item: string) => Number.parseInt(item.split('-')[1]));
        const modelList = extractedEmptyArrayProps.universeList
          .filter((item: string) => item.includes(UniverseSelectTypeEnum.PORTFOLIO))
          .map((item: string) => Number.parseInt(item.split('-')[1]));
        const staticIndexIds = extractedEmptyArrayProps.hfrIndexes
          ? extractedEmptyArrayProps.hfrIndexes
              .filter((item: string) => item.includes('static'))
              .map((item: string) => Number.parseInt(item.split('-')[1]))
          : undefined;
        const dynamicIndexIds = extractedEmptyArrayProps.hfrIndexes
          ? extractedEmptyArrayProps.hfrIndexes
              .filter((item: string) => item.includes('dynamic'))
              .map((item: string) => Number.parseInt(item.split('-')[1]))
          : undefined;
        invokeUniverseStatData({
          fundId,
          universeListId: universeList.length > 0 ? universeList : undefined,
          fundListId: fundList.length > 0 ? fundList : undefined,
          portfolioModelId: modelList.length > 0 ? modelList : undefined,
          staticIndexIds: staticIndexIds && staticIndexIds.length > 0 ? staticIndexIds : undefined,
          dynamicIndexIds:
            dynamicIndexIds && dynamicIndexIds.length > 0 ? dynamicIndexIds : undefined,
          inHFRI: extractedEmptyArrayProps.inHFRI
            ? JSON.parse(extractedEmptyArrayProps.inHFRI)
            : undefined,
          inEmergingMarkets: extractedEmptyArrayProps.inEmergingMarkets
            ? JSON.parse(extractedEmptyArrayProps.inEmergingMarkets)
            : undefined,
          regionalFocus: extractedEmptyArrayProps.regionalFocus,
          strategy: extractedEmptyArrayProps.strategies,
          subStrategy: extractedEmptyArrayProps.subStrategy,
          endDate: extractedEmptyArrayProps.endDate,
        });

        const jsonData = {
          fund_id: fundId,
          end_date: extractedEmptyArrayProps.endDate,
          benchmark1: extractedEmptyArrayProps.benchmark1,
          benchmark2: extractedEmptyArrayProps.benchmark2,
          benchmark3: extractedEmptyArrayProps.benchmark3,

          in_emerging_markets: extractedEmptyArrayProps.inEmergingMarkets
            ? JSON.parse(extractedEmptyArrayProps.inEmergingMarkets)
            : undefined,
          in_HFRI: extractedEmptyArrayProps.inHFRI
            ? JSON.parse(extractedEmptyArrayProps.inHFRI)
            : undefined,
          strategy: extractedEmptyArrayProps.strategies,
          sub_strategy: extractedEmptyArrayProps.subStrategy,
          regional_focus: extractedEmptyArrayProps.regionalFocus,
          static_index_ids:
            staticIndexIds && staticIndexIds.length > 0 ? staticIndexIds : undefined,
          dynamic_index_ids:
            dynamicIndexIds && dynamicIndexIds.length > 0 ? dynamicIndexIds : undefined,
          universe_list_id: universeList.length > 0 ? universeList : undefined,
        };
        APIService.eventService.sendViewPgaEvent(JSON.parse(JSON.stringify(jsonData)));
      }
    });
  };
  const generatePeerGroupPdfReport = async () => {
    if (!fundData && !userFundData) {
      return;
    }
    const universeList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.HFR))
      .map((item: string) => Number.parseInt(item.split('-')[1]));
    const fundList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.LIST))
      .map((item: string) => Number.parseInt(item.split('-')[1]));
    const modelList = form
      .getFieldValue('universeList')
      .filter((item: string) => item.includes(UniverseSelectTypeEnum.PORTFOLIO))
      .map((item: string) => Number.parseInt(item.split('-')[1]));
    const staticIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('static'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : [];
    const dynamicIndexIds = form.getFieldValue('hfrIndexes')
      ? form
          .getFieldValue('hfrIndexes')
          .filter((item: string) => item.includes('dynamic'))
          .map((item: string) => Number.parseInt(item.split('-')[1]))
      : [];
    const strategy = form.getFieldValue('strategies') || [];
    const subStrategy = form.getFieldValue('subStrategy') || [];
    const regionalFocus = form.getFieldValue('regionalFocus')
      ? form.getFieldValue('regionalFocus').map((item: string) => Number(item))
      : [];
    const inHFRI = form.getFieldValue('inHFRI');
    const inEmergingMarkets = form.getFieldValue('inEmergingMarkets');

    if (fundData) {
      const data: FetchPeerGroupPdfParams = {
        end_date: form.getFieldValue('endDate'),
        benchmark1: form.getFieldValue('benchmark1'),
        benchmark2: form.getFieldValue('benchmark2'),
        benchmark3: form.getFieldValue('benchmark3'),
        universe_list_id: universeList.length ? universeList : undefined,
        fund_list_id: fundList.length ? fundList : undefined,
        portfolio_model_id: modelList.length ? modelList : undefined,
        static_index_ids: staticIndexIds.length ? staticIndexIds : undefined,
        dynamic_index_ids: dynamicIndexIds.length ? dynamicIndexIds : undefined,
        strategy: strategy.length ? strategy : undefined,
        sub_strategy: subStrategy.length ? subStrategy : undefined,
        regional_focus: regionalFocus.length ? regionalFocus : undefined,
        in_HFRI: inHFRI ? JSON.parse(inHFRI) : undefined,
        in_emerging_markets: inEmergingMarkets ? JSON.parse(inEmergingMarkets) : undefined,
      };
      const res = await APIService.peerGroupAnalysisService.fetchPeerGroupPdfReport(fundId, data);
      JsFileDownload(
        res.data,
        `${fundData.fund_name.replace('-', ' ').replace(',', ' ')} PGA.pdf`,
        'application/pdf',
      );
    } else if (userFundData) {
      const data: FetchUserFundPeerGroupPdfParams = {
        end_date: form.getFieldValue('endDate'),
        benchmark1: form.getFieldValue('benchmark1'),
        benchmark2: form.getFieldValue('benchmark2'),
        benchmark3: form.getFieldValue('benchmark3'),
        universe_list_ids: universeList.length ? universeList : undefined,
        fund_list_ids: fundList.length ? fundList : undefined,
        portfolio_model_ids: modelList.length ? modelList : undefined,
        static_index_ids: staticIndexIds.length ? staticIndexIds : undefined,
        dynamic_index_ids: dynamicIndexIds.length ? dynamicIndexIds : undefined,
        strategy: strategy.length ? strategy : undefined,
        sub_strategy: subStrategy.length ? subStrategy : undefined,
        regional_focus: regionalFocus.length ? regionalFocus : undefined,
        in_HFRI: inHFRI ? JSON.parse(inHFRI) : undefined,
        in_emerging_markets: inEmergingMarkets ? JSON.parse(inEmergingMarkets) : undefined,
      };
      const res = await APIService.userFundService.fetchFundPdfReport({ fundId, data });
      JsFileDownload(
        res.data,
        `${userFundData.name.replace('-', ' ').replace(',', ' ')} PGA.pdf`,
        'application/pdf',
      );
    }
  };

  const resolvedUniverseStatData = resolveUniverseStatData(universeStatData);
  const resolvedFundAndBenchmarkData: PeerGroupTableDataType[] = resolveFundAndBenchmarkData(
    fundAndBenchmarkData,
    benchmarkOptions,
    selectedBenchmarks,
  );

  return (
    <div id="peer-group-wrapper">
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={8}>
            <Button type="primary" htmlType="submit" style={{ marginRight: 16 }}>
              Generate Report
            </Button>
            <Button
              disabled={
                !(
                  form.getFieldValue('endDate') &&
                  form.getFieldValue('universeList').length &&
                  form.getFieldValue('benchmark1') &&
                  form.getFieldValue('benchmark2') &&
                  form.getFieldValue('benchmark3')
                ) || user.hfrdb_trial
              }
              onClick={generatePeerGroupPdfReport}
            >
              <Icon type="printer" /> Print
            </Button>
          </Col>

          <Col span={8} offset={8}>
            <Form.Item label="End Date">
              {getFieldDecorator('endDate', {
                rules: [{ required: true, message: 'End date is required' }],
                initialValue: endDateOptions[0],
              })(
                <Select>
                  {endDateOptions.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Universe List">
              {getFieldDecorator('universeList', {
                rules: [
                  { type: 'array' },
                  { required: true, message: 'Universe List is required' },
                ],
                initialValue: universeListData &&
                  universeListData.length > 0 && [universeListData[0].id],
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select Universe"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    if (!option || !option.props || !option.props.children) {
                      return false;
                    }
                    return (
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {universeListData &&
                    universeListData.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="HFR Index Constituents">
              {getFieldDecorator('hfrIndexes', {
                rules: [{ type: 'array' }],
              })(
                <Select
                  mode="multiple"
                  placeholder="Select HFR Index"
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    if (!option || !option.props || !option.props.children) {
                      return false;
                    }
                    return (
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {hfrIndexes &&
                    hfrIndexes.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Benchmark 1">
              {getFieldDecorator('benchmark1', {
                rules: [{ required: true, message: 'Benchmark 1 is required' }],
                initialValue: fundData ? fundData.benchmarks.benchmark_1 : '2899',
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select Benchmark 1"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {renderBenchmarkOptions()}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Strategy">
              {getFieldDecorator('strategies', {
                rules: [{ type: 'array' }, { validator: validateOneOf }],
                initialValue:
                  fundData && fundData.strategy.strategy_code
                    ? [fundData.strategy.strategy_code]
                    : userFundData && userFundData.strategy_code
                    ? [userFundData.strategy_code]
                    : undefined,
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  placeholder="Select Strategy"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {strategyOptions.map((item: FundDetailStrategy) => {
                    const { strategy_code, strategy_name } = item;
                    return (
                      <Option key={strategy_code} value={strategy_code}>
                        {strategy_name}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Regional Focus">
              {getFieldDecorator('regionalFocus')(
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  placeholder="Select Regional Focus"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                  style={{ width: '100%' }}
                >
                  {regionalFocusOptions.map((item: FundDetailRegionalFocus) => {
                    const { id, name } = item;
                    return (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="Benchmark 2">
              {getFieldDecorator('benchmark2', {
                rules: [{ required: true, message: 'Benchmark 3 is required' }],
                initialValue: fundData ? fundData.benchmarks.benchmark_2 : '2899',
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select Benchmark 2"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {renderBenchmarkOptions()}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Sub - Strategy">
              {getFieldDecorator('subStrategy', {
                initialValue:
                  fundData && fundData.sub_strategy.sub_strategy_code
                    ? [fundData.sub_strategy.sub_strategy_code]
                    : userFundData && userFundData.sub_strategy_code
                    ? [userFundData.sub_strategy_code]
                    : undefined,
              })(
                <Select
                  mode="multiple"
                  placeholder="Select Sub Strategy"
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {filteredSubStrategy.map((item: FundDetailSubStrategy) => {
                    const { sub_strategy_code, sub_strategy_name } = item;
                    return (
                      <Option key={sub_strategy_code} value={sub_strategy_code}>
                        {sub_strategy_name}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="In HFRI">
                  {getFieldDecorator('inHFRI')(
                    <Select style={{ width: '100%' }} placeholder="Is in HFRI?" allowClear>
                      <Option key={'true'}>Yes</Option>
                      <Option key={'false'}>No</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="In Emerging Market">
                  {getFieldDecorator('inEmergingMarkets')(
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Is in Emerging Market?"
                      allowClear
                    >
                      <Option key={'true'}>Yes</Option>
                      <Option key={'false'}>No</Option>
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Benchmark 3">
              {getFieldDecorator('benchmark3', {
                rules: [{ required: true, message: 'Benchmark 3 is required' }],
                initialValue: fundData ? fundData.benchmarks.benchmark_3 : '2899',
              })(
                <Select
                  placeholder="Select Benchmark 3"
                  style={{ width: '100%' }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.props &&
                    option.props.children &&
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                  }
                >
                  {renderBenchmarkOptions()}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: '20px' }} id="fund-and-benchmark">
        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Fund and Benchmark Performance" key="1" showArrow>
            <Spin spinning={fundBenchmarkLoading}>
              {renderTable(resolvedFundAndBenchmarkData, fundAndBenchmarkData.common_end_date)}
            </Spin>
          </Collapse.Panel>
        </Collapse>
      </Row>
      <Row style={{ marginTop: '20px' }} id="universe-statistics">
        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Universe Statistics" key="1" showArrow>
            <Spin spinning={universeStatLoading}>
              {selectedEndDate && renderTable(resolvedUniverseStatData, selectedEndDate)}
            </Spin>
          </Collapse.Panel>
        </Collapse>
      </Row>
      <Row style={{ marginTop: '20px' }} id="performance-universe">
        <Collapse defaultActiveKey={['1']}>
          <Collapse.Panel header="Performance vs Universe" key="1" showArrow>
            <Spin spinning={universeStatLoading}>
              {selectedEndDate && (
                <PerformanceVersusUniverseChart
                  data={[
                    ...resolvedUniverseStatData,
                    ...resolvedFundAndBenchmarkData.filter(
                      (fundBenchmarkData: PeerGroupTableDataType) =>
                        fundBenchmarkData.name === 'Fund',
                    ),
                  ]}
                  endDate={selectedEndDate}
                />
              )}
            </Spin>
          </Collapse.Panel>
        </Collapse>
      </Row>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
  benchmarkOptions: state.searchReducer.benchmarkOptions,
  strategyOptions: state.searchReducer.strategyOptions,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
  regionalFocusOptions: state.searchReducer.regionalFocusOptions,
  universeListData: [
    ...state.rootReducer.universeList.map(universeItem => ({
      ...universeItem,
      id: `${UniverseSelectTypeEnum.HFR}-${universeItem.id}`,
      name: `(${UniverseSelectTypeEnum.HFR}) ${universeItem.name}`,
    })),
    ...state.rootReducer.fundList.map(fundItem => ({
      ...fundItem,
      id: `${UniverseSelectTypeEnum.LIST}-${fundItem.id}`,
      name: `(${UniverseSelectTypeEnum.LIST}) ${fundItem.name}`,
    })),
    ...state.modelReducer.portfolioModels.map(portfolioItem => ({
      ...portfolioItem,
      id: `${UniverseSelectTypeEnum.PORTFOLIO}-${portfolioItem.id}`,
      name: `(${UniverseSelectTypeEnum.PORTFOLIO}) ${portfolioItem.name}`,
    })),
  ],
  hfrIndexes: [
    ...state.rootReducer.hfrStaticIndex.map(staticItem => ({
      ...staticItem,
      id: `static-${staticItem.id}`,
      name: String(staticItem.name),
    })),
    ...state.rootReducer.hfrDynamicIndex.map(dynamicItem => ({
      ...dynamicItem,
      id: `dynamic-${dynamicItem.id}`,
      name: String(dynamicItem.name),
    })),
  ],
});

export default Form.create<Props>({ name: 'PeerGroupForm' })(
  connect(mapStateToProps, null)(PeerGroupForm),
);
