import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import {
  CustomRendererProps,
  FIELD_TYPES,
  FieldProps,
  renderFormFields,
} from './helper/advanceFormFields';

type Props = {
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
  options: string[];
};

const MonthRangePicker: React.FC<Props> = ({ data, update, options }) => {
  const [fields, setFields] = useState<Array<FieldProps | CustomRendererProps>>([]);
  const [dates, setDates] = useState<{
    latestRORDateFrom: string | undefined;
    latestRORDateTo: string | undefined;
  }>({
    latestRORDateFrom: data.latestRORDateFrom,
    latestRORDateTo: data.latestRORDateTo,
  });

  useEffect(() => {
    const newFields: (FieldProps | CustomRendererProps)[] = [
      {
        key: 'latestRORDateFrom',
        name: 'Latest ROR Date From',
        type: FIELD_TYPES.DROPDOWN,
        optionData: options
          .filter(d =>
            dates.latestRORDateTo
              ? moment(d, 'YYYY-MM') < moment(dates.latestRORDateTo, 'YYYY-MM')
              : true,
          )
          .map(date => {
            return { label: date, value: date };
          }),
      },
      {
        key: 'latestRORDateTo',
        name: 'Latest ROR Date To',
        type: FIELD_TYPES.DROPDOWN,
        optionData: options
          .slice()
          .reverse()
          .filter(d => {
            return dates.latestRORDateFrom
              ? moment(d, 'YYYY-MM') > moment(dates.latestRORDateFrom, 'YYYY-MM')
              : true;
          })
          .map(date => {
            return { label: date, value: date };
          }),
      },
    ];
    setFields(newFields);
  }, [dates, options]);

  const handleUpdate: React.Dispatch<Partial<FetchFundParams>> = (
    payload: Partial<FetchFundParams>,
  ) => {
    setDates(prevDates => {
      return {
        ...prevDates,
        ...payload,
      };
    });
    update(payload);
  };

  return <>{renderFormFields(fields, data, handleUpdate)}</>;
};

export default MonthRangePicker;
