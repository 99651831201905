import { Empty } from 'antd';
import React from 'react';
import Plot from 'react-plotly.js';
import { connect, MapDispatchToPropsFunction } from 'react-redux';
import { InvestorDBLocation } from '../../shared/api/services/investor-db.service';
import GenUtil from '../../shared/utils/gen-util';
import InvestorActionEnums from './redux/investor-action-enums';
import { InvestorPane } from './redux/investor.reducer';

interface DispatchProps {
  addPane: (data: InvestorPane) => void;
}

type Props = {
  data: InvestorDBLocation[];
};

const InvestorMap: React.FC<Props & DispatchProps> = ({ data: locationData, addPane }) => {
  const data =
    locationData && locationData.length
      ? [
          {
            type: 'scattermapbox',
            lat: locationData.map(item => item.latitude),
            lon: locationData.map(item => item.longitude),
            customdata: locationData.map(item => ({
              company_name: item.company_name || '-',
              id: item.hfr_id || '-',
              investor_type: item.investor_type || '-',
              entity_type: item.entity_type || '-',
              investment_executive: item.investment_executive || '-',
              total_assets: item.total_assets
                ? `USD ${GenUtil.getformattedBilionOrMilion(item.total_assets)}`
                : '-',
              hedge_fund_assets: item.hedge_fund_assets
                ? `USD ${GenUtil.getformattedBilionOrMilion(item.hedge_fund_assets)}`
                : '-',
              alternative_assets: item.alternative_assets
                ? `USD ${GenUtil.getformattedBilionOrMilion(item.alternative_assets)}`
                : '-',
            })),
            mode: 'markers+text',
            name: null,
            marker: {
              size: 14,
            },
            text: locationData.map(item => item.company_name),
            hovertemplate: `<b>Company: %{text}</b><br />Investor Type: %{customdata.investor_type}<br />Entity Type: %{customdata.entity_type}<br />Investment Executive: %{customdata.investment_executive}<br />Total Asset: %{customdata.total_assets}<br />Hedge Fund Asset: %{customdata.hedge_fund_assets}<br />Alternative Asset: %{customdata.alternative_assets}
            <extra></extra>`,
          },
        ]
      : [];

  const handleSelectFund = (e: Readonly<Plotly.PlotMouseEvent>) => {
    //@ts-ignore
    const fund = e.points[0].customdata;
    if (!fund) {
      return;
    }

    fund.company_name &&
      fund.id &&
      addPane({ title: fund.company_name, key: fund.id.toString(), id: fund.id });
  };

  return (
    <>
      {locationData.length === 0 ? (
        <Empty style={{ flex: 1 }} />
      ) : (
        <Plot
          // @ts-ignore
          data={data}
          style={{ width: '100%' }}
          onClick={handleSelectFund}
          config={{
            mapboxAccessToken:
              'pk.eyJ1Ijoic2FkbnlzdGVyIiwiYSI6ImNrZjZndjA0YjBoeTUydHBodzVxeGpucmMifQ.pnm--UXu0qIdbouumrAMRg',
            responsive: true,
            displaylogo: false,
            displayModeBar: false,
          }}
          layout={{
            title: 'Investor DB Locations',
            height: window.innerHeight - 200,
            margin: {
              r: 20,
              t: 40,
              b: 20,
              l: 20,
              pad: 0,
            },
            dragmode: 'zoom',
            mapbox: {
              center: {
                lat: 38.03697222,
                lon: -90.70916722,
              },
              domain: {
                x: [0, 1],
                y: [0, 1],
              },
              style: 'dark',
              bearing: 0,
              pitch: 0,
              zoom: 4,
            },
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    addPane: (data: InvestorPane) => {
      dispatch({ type: InvestorActionEnums.ADD_PANE, payload: data });
    },
  };
};
export default connect(undefined, mapDispatchToProps)(InvestorMap);
