class RootActionEnums {
  static RESET_STORE = 'RESET_STORE';
  static REMOVE_PANE = 'Remove_Pane';
  static REMOVE_RANKING_PANE = 'REMOVE_RANKING_PANE';
  static ADD_PANE = 'Add Pane';
  static UPDATE_PANE_TITLE = 'UPDATE_PANE_TITLE';
  static ADD_RANKING_PANE = 'ADD_RANKING_PANE';
  static CHANGE_PANE = 'Change Pane';
  static CHANGE_RANKING_PANE = 'CHANGE_RANKING_PANE';
  static FETCH_FUND_LIST_DATA = 'FETCH_FUND_LIST_DATA';
  static FETCH_UNIVERSE_LIST_DATA = 'FETCH_UNIVERSE_LIST_DATA';
  static UPDATE_FUND_LIST_DATA = 'UPDATE_FUND_LIST_DATA';
  static UPDATE_SELECTED_FUNDS = 'UPDATE_SELECTED_FUNDS';
  static UPDATE_FUND_TABLE_COLUMNS = 'UPDATE_FUND_TABLE_COLUMNS';
  static FETCH_STATIC_HFR_INDEX_DATA = 'FETCH_STATIC_HFR_INDEX_DATA';
  static FETCH_DYNAMIC_HFR_INDEX_DATA = 'FETCH_DYNAMIC_HFR_INDEX_DATA';
  static FETCH_PEER_GROUP_DATA = 'FETCH_PEER_GROUP_DATA';
  static UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
  static UPDATE_INVESTOR_TABLE_COLUMNS = 'UPDATE_INVESTOR_TABLE_COLUMNS';
}

export default RootActionEnums;
