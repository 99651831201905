import React from 'react';

/**
 * This component renders table cells based on its value ('-' for undefined and red for negative value)
 * @param val
 * @constructor
 */
const RenderTableCell = (val: number) => {
  if (val === null || val === undefined) {
    return <span> - </span>;
  }
  return val < 0 ? (
    <span style={{ color: 'red' }}>
      {' '}
      {val.toLocaleString(undefined, { minimumFractionDigits: 2 })}
    </span>
  ) : (
    <span> {val.toLocaleString(undefined, { minimumFractionDigits: 2 })} </span>
  );
};

export default RenderTableCell;
