import AppSettingActionEnums from './appSetting.ActionEnums';

export interface InitialAppSettingState {
  showPrompt: boolean;
}

export const initAppSettingState: InitialAppSettingState = {
  showPrompt: true,
};

export const appSettingReducer = (
  state = initAppSettingState,
  { type, payload }: { type: AppSettingActionEnums; payload: any },
): InitialAppSettingState => {
  switch (type) {
    case AppSettingActionEnums.TOGGLE_SHOW_PROMPT: {
      return {
        ...state,
        showPrompt: payload,
      };
    }
    default: {
      return state;
    }
  }
};
