import { Button, Icon, Modal, notification, Table, Tooltip } from 'antd';
import { ColumnProps, TableRowSelection } from 'antd/lib/table';
import React, { useEffect } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { PortfolioModelFundRecord } from '../../../shared/api/models/ResponsesTypes';
import { InitialRootReducer } from '../../../redux/reducers/root.reducer';
import ModelActionEnums from '../redux/model-action-enums';
import APIService from '../../../shared/api';
import EditableCell from './EditableCell';

const { confirm } = Modal;

interface Props {
  portfolioModelId: number | undefined;
  onTotalAllocationChanged: (datas: PortfolioModelFundRecord[]) => void;
  dataSource: PortfolioModelFundRecord[];
  onSetDataSource: (datas: PortfolioModelFundRecord[]) => void;
}

interface StateProps {
  selectedFunds: string[];
}

interface DispatchProps {
  updateSelectedFunds: (selectedFunds: string[]) => void;
}

/**
 * Main component of PortfolioView, renders the portfolio models in table layout
 * @param tableData
 * @param onTotalAllocationChanged
 */

const PortfolioModelTable: React.FC<Props & StateProps & DispatchProps> = ({
  portfolioModelId,
  onTotalAllocationChanged,
  dataSource,
  onSetDataSource,
  selectedFunds,
  updateSelectedFunds,
}) => {
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    onTotalAllocationChanged(dataSource);
  }, [dataSource, onTotalAllocationChanged]);

  const onCellChange = (_text: string, record: PortfolioModelFundRecord) => {
    return (value: string) => {
      record.weight = value ? Number(value) : 0;
      onSetDataSource([...dataSource]);
    };
  };
  const handleDelete = (record: PortfolioModelFundRecord) => {
    confirm({
      title: `Do you want to delete '${
        record.fund_name ? record.fund_name : record.user_fund_name
      }'?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          if (record.fund_id) {
            await APIService.portfolioModelService.updatePortfolioModel({
              modelId: `${portfolioModelId}`,
              data: { delete: [record.fund_id] },
            });
            onSetDataSource([...dataSource.filter(data => data.fund_id !== record.fund_id)]);
          } else {
            await APIService.portfolioModelService.updatePortfolioModel({
              modelId: `${portfolioModelId}`,
              data: { user_fund_delete: [+record.user_fund_id] },
            });
            onSetDataSource([
              ...dataSource.filter(data => data.user_fund_id !== record.user_fund_id),
            ]);
          }
          notification.success({
            message: `${
              record.fund_name ? record.fund_name : record.user_fund_name
            } Deleted From the Model`,
            placement: 'bottomLeft',
          });
        } catch (error) {
          console.error(error);
          notification.error({
            message: 'Something went wrong. Please try again later!',
            placement: 'bottomLeft',
          });
        }
      },
    });
  };
  const columns: ColumnProps<PortfolioModelFundRecord>[] = [
    {
      title: '% Weight',
      dataIndex: 'weight',
      key: 'weight',
      sorter: true,
      render: (text: string, record: PortfolioModelFundRecord, _index: number) => (
        <EditableCell value={text} type="number" onChange={onCellChange(text, record)} />
      ),
    },
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      render: (fundName, record) => (fundName ? fundName : record.user_fund_name),
      sorter: true,
    },
    {
      title: 'Firm Name',
      dataIndex: 'firm_name',
      key: 'firm_name',
      render: firmName => (firmName ? firmName : '-'),
      sorter: true,
    },
    {
      title: 'Main Strategy',
      dataIndex: 'strategy_name',
      key: 'strategy_name',
      render: (strategy, record) => (strategy ? strategy : record.user_fund_strategy_name),
      sorter: true,
    },
    {
      title: 'Sub-Strategy',
      dataIndex: 'sub_strategy_name',
      key: 'sub_strategy_name',
      render: (subStrategy, record) =>
        subStrategy ? subStrategy : record.user_fund_sub_strategy_name,
      sorter: true,
    },
    {
      title: 'Regional Focus',
      dataIndex: 'regional_focus',
      key: 'regional_focus',
      render: regionalFocus => (regionalFocus ? regionalFocus : '-'),
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Tooltip title="Delete">
          <Button type="danger" onClick={() => handleDelete(record)}>
            <Icon type="delete" />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const rowSelection: TableRowSelection<PortfolioModelFundRecord> = {
    selectedRowKeys: selectedFunds,
    onChange: (_, selectedRows) => {
      updateSelectedFunds(
        selectedRows.map((row: PortfolioModelFundRecord) =>
          row.fund_id ? row.fund_id : `${row.user_fund_id}-USER_FUND`,
        ),
      );
    },
  };

  return (
    <Table
      rowKey={'fund_id'}
      className="portfolioModelTable"
      rowSelection={rowSelection}
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 15 }}
      bordered
    />
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    selectedFunds: state.modelReducer.selectedFunds,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    updateSelectedFunds: (selectedFunds: string[]) => {
      dispatch({ type: ModelActionEnums.UPDATE_SELECTED_FUNDS, payload: { selectedFunds } });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioModelTable);
