import { Icon, Row } from 'antd';
import React from 'react';
import JsFileDownload from 'js-file-download';
import APIService from '../../../shared/api';
import { DownloadTableData } from './DownloadDataTable';

interface Props {
  record: DownloadTableData;
}

const validFileName = (type: string, name: string) => {
  const txt = name.split('.');
  if (txt.length < 2) {
    return false;
  }
  const ext = txt[txt.length - 1];
  return ext === type;
};

/**
 * Download file table cell component
 * @param record
 */
const DownloadTableCell: React.FC<Props> = ({ record }) => {
  const [fileDownloading, setFileDownloading] = React.useState<boolean>(false);

  const downloadFile = async (record: DownloadTableData) => {
    //disable multiple clicks
    if (!fileDownloading) {
      const fileName = validFileName(record.type, record.file)
        ? record.file
        : `${record.file}.${record.type}`;
      setFileDownloading(true);
      const res = await APIService.downloadFileService.downloadFile(+record.key);
      APIService.eventService.sendDownloadFileAccessEvent();
      JsFileDownload(res.data, `${fileName}`, `application/${record.type}`);
      setFileDownloading(false);
    }
  };

  return (
    <Row style={{ cursor: 'pointer' }} onClick={() => downloadFile(record)}>
      <Icon
        style={{ color: '#1890ff', fontSize: 20 }}
        type={fileDownloading ? 'loading' : 'download'}
      />
    </Row>
  );
};

export default DownloadTableCell;
