import { Button, Checkbox, Icon, Layout, Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/hfr-logo.png';
import AppSettingActionEnums from '../../modules/home/redux/appSetting.ActionEnums';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import APIService from '../api';
import { UserProfileDetails } from '../api/models/ResponsesTypes';
import { useServiceState } from '../hooks/useServiceState';

/**
 * Main Layout of the Application
 * @param props
 * @returns {*}
 * @constructor
 */

const { Header, Content } = Layout;

const HEADER_HEIGHT = 48;

const StyledHeader = styled(Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
`;

const ContentContainer = styled(Content)`
  display: flex;
  position: relative;
  flex: 1;
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
`;
const ImgLogo = styled.img`
  margin: 6px 10px;
  height: 36px;
  width: auto;
`;

interface Props extends RouteComponentProps {}

export interface StateProps {
  user: UserProfileDetails;
  showPrompt: boolean;
}

interface DispatchProps {
  toggleShowPrompt: (showPrompt: boolean) => void;
}

const MainLayout: React.FC<Props & StateProps & DispatchProps> = ({
  user,
  children,
  history,
  match,
  showPrompt,
  toggleShowPrompt,
}) => {
  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState(
    APIService.fundService.fetchDisclaimerPrompt,
  );

  const { invoke: approveDisclaimerPrompt, loading } = useServiceState(
    APIService.fundService.approveDisclaimerPrompt,
  );

  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    invokeDisclaimerData();
  }, [invokeDisclaimerData]);

  const handleSubmitPrompt = () => {
    if (dontShowAgain) {
      approveDisclaimerPrompt();
    }
    toggleShowPrompt(false);
  };

  return (
    <Layout hasSider={false} style={{ minHeight: '100%', height: '100%' }}>
      <StyledHeader className={'menu-header'}>
        <LogoContainer to={'/'}>
          <ImgLogo alt={'Logo'} src={logo} className={'logo'} />
        </LogoContainer>

        <Menu
          mode="horizontal"
          theme="dark"
          selectedKeys={[match.path]}
          defaultSelectedKeys={['/search']}
          style={{ lineHeight: `${HEADER_HEIGHT}px` }}
        >
          <Menu.Item key="/" onClick={() => history.push('/')}>
            <Icon type="dollar" />
            Search Funds
          </Menu.Item>
          {user.has_investor_db_access && (
            <Menu.Item key="/investor-database" onClick={() => history.push('/investor-database')}>
              <Icon type="database" />
              Investor DB
            </Menu.Item>
          )}
          {user.index_scope_access && (
            <Menu.Item key="/indexscope" onClick={() => history.push('/indexscope')}>
              <Icon type="appstore" />
              IndexScope
            </Menu.Item>
          )}
          <Menu.Item key="/rankings" onClick={() => history.push('/rankings')}>
            {' '}
            <Icon type="number" />
            Rankings
          </Menu.Item>
          <Menu.Item key="/my-data" onClick={() => history.push('/my-data')}>
            <Icon type="appstore" />
            My Data
          </Menu.Item>
          {user.has_files_access && (
            <Menu.Item
              key="/download"
              disabled={user.hfrdb_trial}
              onClick={() => {
                history.push('/download');
              }}
            >
              <Icon type="download" />
              Download
            </Menu.Item>
          )}
          <Menu.Item key="/help-and-faqs" onClick={() => history.push('/help-and-faqs')}>
            <Icon type="bulb" />
            <span>Help And FAQs</span>
          </Menu.Item>
          <Menu.SubMenu
            key="/user"
            title={
              <>
                <Icon type="user" />
                {`${user.first_name} ${user.last_name}`}
              </>
            }
          >
            <Menu.Item onClick={() => history.push('/user/profile')}>
              <Icon type="user" />
              Your Profile
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/user/change-password')}>
              <Icon type="key" />
              Change Password
            </Menu.Item>
            <Menu.Item onClick={() => history.push('/user/release-notes')}>
              <Icon type="snippets" />
              Release Notes
            </Menu.Item>
            {/*<Menu.Item onClick={() => window.open('https://odb.hfrdatabase.com', '_blank')}>*/}
            {/*  <Icon type="interaction" />*/}
            {/*  Open Legacy Platform*/}
            {/*</Menu.Item>*/}
            {user.is_staff && (
              <Menu.Item>
                <Link to={'/admin'} target="_blank">
                  <Icon type="user" />
                  Open Admin Panel
                </Link>
              </Menu.Item>
            )}
            {/*<Menu.Item onClick={() => window.open('https://www.hfrindexscope.com', '_blank')}>*/}
            {/*  <Icon type="interaction" />*/}
            {/*  Open old HFR Index Scope*/}
            {/*</Menu.Item>*/}
            <Menu.Item onClick={() => APIService.logout()}>
              <Icon type="logout" />
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </StyledHeader>
      <ContentContainer>{children}</ContentContainer>
      {disclaimerData && (
        <Modal
          width="60%"
          title={disclaimerData.prompt ? disclaimerData.prompt.title : ''}
          visible={showPrompt && disclaimerData.show_prompt}
          onCancel={() => toggleShowPrompt(false)}
          footer={
            <>
              <Checkbox checked={dontShowAgain} onChange={e => setDontShowAgain(e.target.checked)}>
                Don't show this again
              </Checkbox>
              <Button loading={loading} type="primary" onClick={handleSubmitPrompt}>
                OK
              </Button>
            </>
          }
        >
          <div
            dangerouslySetInnerHTML={{
              __html: disclaimerData.prompt ? `${disclaimerData.prompt.content}` : '',
            }}
          />
        </Modal>
      )}
    </Layout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
    showPrompt: state.appSettingReducer.showPrompt,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    toggleShowPrompt: (showPrompt: boolean) => {
      dispatch({ type: AppSettingActionEnums.TOGGLE_SHOW_PROMPT, payload: showPrompt });
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));
