import { AxiosInstance, AxiosPromise } from 'axios';

export interface ReleaseNoteResponse {
  content: string;
  created_date: string;
}

export interface ArticlesResponse {
  id: number;
  name: string;
  created_date: string;
}

export interface ArticleDetailResponse {
  id: number;
  name: string;
  content: string;
  created_date: string;
}

export interface ContentService {
  fetchReleaseNote: () => AxiosPromise<ReleaseNoteResponse[]>;
  fetchArticles: () => AxiosPromise<ArticlesResponse[]>;
  fetchArticleDetail: (articleId: string) => AxiosPromise<ArticleDetailResponse>;
}

export const ContentService = (request: AxiosInstance): ContentService => {
  return {
    fetchReleaseNote: () => request.get('/release-note'),
    fetchArticles: () => request.get('/article'),
    fetchArticleDetail: articleId => request.get(`/article/${articleId}`),
  };
};
