import React, { Component } from 'react';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import Rollbar from 'rollbar';
import { connect, MapStateToProps } from 'react-redux';
import Session from './shared/components/Session';
import HomeView from './modules/home/HomeView';
import DownloadView from './modules/download/DownloadView';
import Auth from './modules/auth/Auth';
import { history } from './shared/history';
import MyData from './modules/mydata/MyData';
import UserView from './modules/user/UserProfileView';
import Rankings from './modules/rankings/Rankings';
import ProtectedRoute from './shared/components/ProtectedRoute';
import { UserProfileDetails } from './shared/api/models/ResponsesTypes';
import { InitialRootReducer } from './redux/reducers/root.reducer';
import HelpAndFaqView from './modules/help/HelpAndFaqView';
import Admin from './modules/admin/Admin';
import IndexScope from './modules/indexscope/IndexScope';
import InvestorDatabase from './modules/investordatabase/InvestorDatabase';

interface StateProps {
  user: UserProfileDetails;
}

class App extends Component<StateProps> {
  constructor(props: any) {
    super(props);
    const rollbarAccessToken = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
    if (rollbarAccessToken) {
      new Rollbar({
        accessToken: rollbarAccessToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: 'production',
        },
      });
    }
  }

  render() {
    return (
      <>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        <Router history={history}>
          <Switch>
            <Route path="/auth" component={Auth} />
            <Route
              path="/funds/:fundId/:token"
              render={props => (
                <Session>
                  <HomeView {...props} />
                </Session>
              )}
            />
            <Route
              exact
              path="/"
              render={props => (
                <Session>
                  <HomeView {...props} />
                </Session>
              )}
            />
            <ProtectedRoute
              path="/investor-database"
              hasAccess={this.props.user.has_investor_db_access}
              component={props => (
                <Session>
                  <InvestorDatabase {...props} />
                </Session>
              )}
            />
            <Route
              exact
              path="/indexscope"
              render={props => (
                <Session>
                  <IndexScope {...props} />
                </Session>
              )}
            />
            <Route
              path="/my-data"
              render={props => (
                <Session>
                  <MyData {...props} />
                </Session>
              )}
            />
            <Route
              path="/rankings"
              render={props => (
                <Session>
                  <Rankings {...props} />
                </Session>
              )}
            />
            <ProtectedRoute
              path="/download"
              hasAccess={this.props.user.has_files_access}
              component={props => (
                <Session>
                  <DownloadView {...props} />
                </Session>
              )}
            />
            <Route
              path="/help-and-faqs"
              render={props => (
                <Session>
                  <HelpAndFaqView {...props} />
                </Session>
              )}
            />
            <Route
              path="/user"
              render={props => (
                <Session>
                  <UserView {...props} />
                </Session>
              )}
            />
            <Route
              path="/admin"
              render={props => (
                <Session>
                  <Admin {...props} />
                </Session>
              )}
            />
            <Redirect to="/" />
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
  };
};

export default connect(mapStateToProps)(App);
