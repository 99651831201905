import React from 'react';
import { Button, Icon, Modal, notification, Pagination, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import styled from 'styled-components';
import { UniverseFundListRecord } from '../../../shared/api/services/universe-list.service';
import APIService from '../../../shared/api';
import { ExtendFundListRecord } from '../../../shared/api/models/ResponsesTypes';

const { confirm } = Modal;

const Meta = styled('div')`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 16px;
    font-weight: 500;
  }
`;

type Props = {
  selectedList: ExtendFundListRecord;
  onDelete: () => void;
  dataSource?: UniverseFundListRecord[];
  loading: boolean;
  handlePagination: any;
  page: number;
  total: number;
};

type StateProps = {};

/**
 * Table showing fund list detail of the selected List
 * @param dataSource
 * @param loading
 * @param handlePagination
 * @param total
 * @constructor
 */
const UniverseFundDataTable: React.FC<Props & StateProps> = ({
  dataSource,
  loading,
  handlePagination,
  page,
  total,
  onDelete,
  selectedList,
}) => {
  const handleDelete = (record: UniverseFundListRecord) => {
    confirm({
      title: `Do you want to delete '${
        record.fund_name ? record.fund_name : record.user_fund_name
      }'?`,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          if (record.fund_id) {
            await APIService.universeListService.updateFundList(selectedList.id, {
              delete: [record.fund_id],
            });
          } else {
            await APIService.universeListService.updateFundList(selectedList.id, {
              user_fund_delete: [record.user_fund_id],
            });
          }
          onDelete();
          notification.success({
            message: `'${record.fund_name ? record.fund_name : record.user_fund_name}' Deleted!`,
            placement: 'bottomLeft',
          });
        } catch (error) {
          console.error(error);
          notification.error({
            message: 'Something went wrong. Please try again later!',
            placement: 'bottomLeft',
          });
        }
      },
    });
  };

  const COLUMNS: ColumnProps<UniverseFundListRecord>[] = [
    {
      title: 'Fund Name',
      dataIndex: 'fund_name',
      key: 'fund_name',
      render: (fundName, record) => (fundName ? fundName : record.user_fund_name),
    },
    {
      title: 'Fund Id',
      dataIndex: 'fund_id',
      key: 'fund_id',
      render: fundId => (fundId ? fundId : '-'),
    },
    {
      title: 'Firm Name',
      dataIndex: 'firm_name',
      key: 'firm_name',
      render: firm => (firm ? firm : '-'),
    },
    {
      title: 'Strategy',
      dataIndex: 'strategy_name',
      key: 'strategy_name',
      render: (strategy, record) => (strategy ? strategy : record.user_fund_strategy_name),
    },
    {
      title: 'Sub-Strategy',
      dataIndex: 'sub_strategy_name',
      key: 'sub_strategy_name',
      render: (subStrategy, record) => (subStrategy ? subStrategy : record.user_fund_sub_strategy_name),
    },
    {
      title: 'Inception Date',
      dataIndex: 'inception_date',
      key: 'inception_date',
      render: date => (date ? date : '-'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Tooltip title="Delete">
          <Button type="danger" onClick={() => handleDelete(record)}>
            <Icon type="delete" />
          </Button>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      <Table
        size="small"
        loading={loading}
        dataSource={dataSource}
        columns={COLUMNS}
        pagination={false}
      />
      <Meta>
        <Pagination
          onChange={(p: number) => {
            handlePagination(p);
          }}
          current={page}
          total={total}
          pageSize={15}
        />
      </Meta>
    </>
  );
};

export default UniverseFundDataTable;
