import { Anchor, Button, Col, Form, Icon, Row, Select, Tabs } from 'antd';
import JsFileDownload from 'js-file-download';
import { default as Lodash } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { oc } from 'ts-optchain';
import { CombinedReducers } from '../../index';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import APIService from '../../shared/api';
import { FundDetails } from '../../shared/api/models/FundDetails';
import {
  AUMRecord,
  BenchmarkRecord,
  DisclaimerResponse,
  ListResponse,
  ProfileStats,
  RORDetails,
  UserProfileDetails,
} from '../../shared/api/models/ResponsesTypes';
import { FetchFundPdfParams } from '../../shared/api/services/fund.service';
import VamiGraph from '../../shared/components/VamiGraph';
import { useServiceState } from '../../shared/hooks/useServiceState';
import GenUtil from '../../shared/utils/gen-util';
import TokenUtil from '../../shared/utils/token-util';
import DistributionGraphComponent from './components/DistributionGraph';
import DrawdownGraphComponent from './components/DrawdownGraph';
import FirmFundDescription from './components/FirmFundDescription';
import FundStrategyDescription from './components/FundStrategyDescription';
import PeerGroupForm from './components/PeerGroup/PeerGroupForm';
import UpDownCaptureGraphComponent from './components/UpDownCaptureGraph';
import YearlyMonthlyDatatable from './components/YearlyMonthlyDatatable';
import FundDetailsUtils from './fund-details-utils';

const { Option } = Select;
const { Link } = Anchor;

const FixedHeader = styled('div')`
  background: white;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 16px;

  .ant-anchor {
    display: flex;
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding-left: 0;
  }
`;

const Container = styled(PerfectScrollbar)`
  width: 100%;
  background: white;
  flex: 1;
  padding: 16px;

  h2 {
    text-align: center;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-content {
    border: none !important;
    margin-top: 0 !important;
  }
`;

const StyledDownloadBtn = styled(Button)`
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 10px;
  top: 4px;
  z-index: 1;
`;

const DisclaimerContainer = styled(Row)`
  margin-top: 20px;
  font-size: 9px;
`;

interface Props {
  fundId: string;
}

interface StateProps {
  user: UserProfileDetails;
  benchmarkOptions: BenchmarkRecord[];
}

interface DispatchProps {
  updatePaneTitle: (key: string, title: string) => void;
}

enum ActiveTabEnum {
  Profile = 'Profile',
  PeerGroup = 'Peer Group',
}

const FundDetailsView: React.FC<StateProps & DispatchProps & Props> = ({
  fundId,
  user,
  benchmarkOptions,
  updatePaneTitle,
}) => {
  const [activeTab, setActiveTab] = React.useState(ActiveTabEnum.Profile);
  const { data: fundData, invoke: invokeFundDetails } = useServiceState<FundDetails>(
    APIService.fundService.fetchFundDetails,
  );

  const { data: rorData, invoke: invokeRORData } = useServiceState<RORDetails>(
    APIService.fundService.fetchRORDetails,
  );

  const { data: aumData, invoke: invokeAUMData } = useServiceState<ListResponse<AUMRecord>>(
    APIService.fundService.fetchAUMDetails,
  );
  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  const { data: profileStatsData, invoke: invokeProfileStats } = useServiceState<ProfileStats>(
    APIService.fundService.fetchProfileStatsDetail,
  );

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [datesRangeArray, setDatesRangeArray] = useState<string[]>([]);
  const [benchmark1, setBenchmark1] = useState<string>('');
  const [benchmark2, setBenchmark2] = useState<string>('');
  const [benchmark3, setBenchmark3] = useState<string>('');
  const [isDateRangeUpdated, setIsDateRangeUpdated] = useState<Boolean>(false);

  useEffect(() => {
    invokeFundDetails(fundId);
  }, [fundId, invokeFundDetails]);

  useEffect(() => {
    if (!Lodash.isEmpty(fundData)) {
      setBenchmark1(oc(fundData).benchmarks.benchmark_1(''));
      setBenchmark2(oc(fundData).benchmarks.benchmark_2(''));
      setBenchmark3(oc(fundData).benchmarks.benchmark_3(''));

      updatePaneTitle(fundData.fund_id || fundData.firm.firm_id, fundData.fund_name);
    }
  }, [fundData, updatePaneTitle]);

  useEffect(() => {
    invokeRORData({ fundId });
    invokeAUMData({ fundId });
    invokeDisclaimerData('profile');
  }, [fundId, invokeRORData, invokeAUMData, invokeDisclaimerData]);

  useEffect(() => {
    if (fundId && benchmark1 && benchmark2 && benchmark3) {
      invokeProfileStats({ fundId, benchmark1, benchmark2, benchmark3 });
    }
  }, [benchmark1, benchmark2, benchmark3, fundId, invokeProfileStats]);

  useEffect(() => {
    if (!Lodash.isEmpty(rorData) && !isDateRangeUpdated) {
      const endDate = Math.max(...rorData.rors.map(rorItem => +rorItem.yyyymmm));
      const startDate = Math.min(...rorData.rors.map(rorItem => +rorItem.yyyymmm));
      const datesRange = FundDetailsUtils._getDatesRangeList(
        GenUtil.getFormattedYearAndMonth(String(startDate), 'YYYY0MM'),
        GenUtil.getFormattedYearAndMonth(String(endDate), 'YYYY0MM'),
      );
      setFromDate(GenUtil.getFormattedYearAndMonth(String(startDate), 'YYYY0MM'));
      setToDate(GenUtil.getFormattedYearAndMonth(String(endDate), 'YYYY0MM'));
      setDatesRangeArray(datesRange);
      setIsDateRangeUpdated(true);
    }
  }, [rorData, isDateRangeUpdated]);

  const generatePdfReport = async () => {
    const data: FetchFundPdfParams = {
      benchmark1,
      benchmark2,
      benchmark3,
      start_date: fromDate,
      end_date: toDate,
    };
    const res = await APIService.fundService.fetchFundPdfReport({ fundId, data });
    JsFileDownload(
      res.data,
      `${fundData.fund_name.replace('-', ' ').replace(',', ' ')} Profile.pdf`,
      'application/pdf',
    );
  };

  const fetchRorAumProfileStats = (startDate: string, endDate: string) => {
    const fromDate = startDate;
    const toDate = endDate;
    invokeRORData({ fundId, fromDate, toDate });
    invokeAUMData({ fundId, fromDate, toDate });
    invokeProfileStats({
      fundId,
      benchmark1,
      benchmark2,
      benchmark3,
      startDate: fromDate,
      endDate: toDate,
    });
  };

  const renderBenchmarkOptions = () => {
    return benchmarkOptions.map(option => {
      return (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      );
    });
  };

  const handleTabChange = (activeKey: ActiveTabEnum) => {
    setActiveTab(activeKey);
  };

  return (
    <div
      id="fund-detail-cy"
      style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%' }}
    >
      <FixedHeader id="fund-detail-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 style={{ marginBottom: 0 }}>{fundData.fund_name}</h2>
        </div>
        <Anchor affix={false}>
          {// TODO: TBD: what user permission is needed for showing this edit fund button
          user.has_manager_link_access && user.hfrml_internal_user && (
            <Button type="primary" style={{ marginRight: 16 }}>
              <a
                href={`${process.env.REACT_APP_MANAGER_LINK_URL}/funds/${
                  fundData.fund_id
                }?token=${TokenUtil.getToken()}`}
                target="hfr_ml"
              >
                <Icon type="edit" style={{ marginRight: 8 }} />
                Edit Fund
              </a>
            </Button>
          )}
          {activeTab === ActiveTabEnum.Profile && (
            <Select
              placeholder="Select quick navigation"
              style={{ width: 250 }}
              defaultActiveFirstOption
              firstActiveValue={'top'}
            >
              <Option key={'top'}>
                <Link href="#info" title="Top" />
              </Option>
              <Option key={'strategy-description'}>
                <Link href="#strategy-description" title="Strategy Description" />
              </Option>
              <Option key={'monthly-ror'}>
                <Link href="#monthly-ror" title="Monthly ROR" />
              </Option>
              <Option key={'monthly-aum'}>
                <Link href="#monthly-aum" title="Monthly AUM" />
              </Option>
              <Option key={'vami'}>
                <Link href="#vami" title="Value Added Money Index" />
              </Option>
              <Option key={'fund-stats'}>
                <Link href="#fund-stats" title="Fund Stats" />
              </Option>
              <Option key={'distribution'}>
                <Link href="#distribution" title="Distribution &amp; Up/down capture" />
              </Option>
              <Option key={'peak-to'}>
                <Link href="#peak-to" title="Peak to Trough Drawdown" />
              </Option>
            </Select>
          )}
          {activeTab === ActiveTabEnum.PeerGroup && (
            <Select
              placeholder="Select quick navigation"
              style={{ width: 250 }}
              defaultActiveFirstOption
              firstActiveValue={'top'}
            >
              <Option key={'top'}>
                <Link href="#info" title="Top" />
              </Option>

              <Option key={'fund-and-benchmark'}>
                <Link href="#fund-and-benchmark" title="Fund and Benchmark" />
              </Option>

              <Option key={'universe-statistics'}>
                <Link href="#universe-statistics" title="Universe Stats" />
              </Option>

              <Option key={'performance-universe'}>
                <Link href="#performance-universe" title="Performance vs Universe" />
              </Option>
            </Select>
          )}
        </Anchor>
      </FixedHeader>
      <Container>
        <span id="info" />
        <Tabs
          defaultActiveKey={ActiveTabEnum.Profile}
          type="card"
          tabBarGutter={8}
          onChange={activeKey => handleTabChange(activeKey as ActiveTabEnum)}
        >
          <Tabs.TabPane tab="Profile" key={ActiveTabEnum.Profile}>
            <Row style={{ textAlign: 'center' }}>
              <StyledDownloadBtn
                type="primary"
                disabled={
                  user.hfrdb_trial ||
                  !(fundId && toDate && fromDate && benchmark3 && benchmark2 && benchmark1)
                }
                onClick={generatePdfReport}
              >
                <Icon type="download" /> Fund PDF Report
              </StyledDownloadBtn>
              <Form.Item>
                <Select
                  value={fromDate}
                  style={{ width: 120 }}
                  placeholder="Select a Start date"
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    setFromDate(value);
                    fetchRorAumProfileStats(value, toDate);
                  }}
                >
                  {datesRangeArray.map((item: string) => {
                    return (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    );
                  })}
                </Select>

                <Select
                  value={toDate}
                  style={{ marginLeft: 4, width: 120 }}
                  placeholder="Select a End date"
                  onChange={(value: any) => {
                    setToDate(value);
                    fetchRorAumProfileStats(fromDate, value);
                  }}
                >
                  {datesRangeArray
                    .slice()
                    .reverse()
                    .filter(d => moment(d, 'YYYY-MM') > moment(fromDate, 'YYYY-MM'))
                    .map((item: string) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Row>
            <FirmFundDescription description={FundDetailsUtils.getFundDescription(fundData)} />
            <Row id="strategy-description" style={{ marginTop: '20px' }}>
              <FundStrategyDescription
                fundStrategyDescription={oc(fundData).fund_info.strategy_description('')}
              />
            </Row>
            <Row style={{ marginTop: '20px' }} />
            {oc(rorData).rors([]).length > 0 ? (
              <Row id="monthly-ror" style={{ marginTop: '20px' }}>
                <YearlyMonthlyDatatable
                  header={`Monthly ROR (${fundData.reporting_style} in ${fundData.returns_currency})`}
                  tableData={FundDetailsUtils.getFormattedTableDataForRORAndAUM(
                    oc(rorData).rors([]),
                    oc(rorData).annualized_rors({}),
                  )}
                  showTotal={true}
                />
              </Row>
            ) : null}

            {oc(aumData).items([]).length > 0 ? (
              <Row id="monthly-aum" style={{ marginTop: '20px' }}>
                <YearlyMonthlyDatatable
                  header={`Monthly AUM (M in ${fundData.fund_assets_currency})`}
                  tableData={FundDetailsUtils.getFormattedTableDataForRORAndAUM(
                    oc(aumData).items([]),
                  )}
                />
              </Row>
            ) : null}

            <Row style={{ marginTop: '35px' }}>
              <div>
                <Row gutter={16} style={{ marginBottom: '30px' }}>
                  <Col span={8}>
                    Benchmark 1
                    <br />
                    <Select
                      id="benchmark-1"
                      value={benchmark1}
                      style={{ width: 280 }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.props &&
                        option.props.children &&
                        (option.props.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      onChange={(val: string) => {
                        setBenchmark1(val);
                        // fetchRORAndAUMCallback();
                      }}
                    >
                      {renderBenchmarkOptions()}
                    </Select>
                  </Col>
                  <Col span={8}>
                    Benchmark 2
                    <br />
                    <Select
                      id="benchmark-2"
                      value={benchmark2}
                      style={{ width: 280 }}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.props &&
                        option.props.children &&
                        (option.props.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(val: string) => {
                        setBenchmark2(val);
                        // fetchRORAndAUMCallback();
                      }}
                    >
                      {renderBenchmarkOptions()}
                    </Select>
                  </Col>
                  <Col span={8}>
                    Benchmark 3
                    <br />
                    <Select
                      id="benchmark-3"
                      value={benchmark3}
                      style={{ width: 280 }}
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.props &&
                        option.props.children &&
                        (option.props.children as string)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(val: string) => {
                        setBenchmark3(val);
                        // fetchRORAndAUMCallback();
                      }}
                    >
                      {renderBenchmarkOptions()}
                    </Select>
                  </Col>
                </Row>
              </div>
              {!Lodash.isEmpty(profileStatsData) ? (
                <VamiGraph vami={oc(profileStatsData).vami()} />
              ) : null}
            </Row>
            <Row id="fund-stats" style={{ marginTop: '30px', position: 'relative' }}>
              <div style={{ position: 'absolute', top: 12, right: 30, fontWeight: 550 }}>
                {profileStatsData.fund_si && (
                  <span style={{ marginRight: 16 }}>
                    **Date:{' '}
                    {GenUtil.getFormattedYearAndMonth(
                      profileStatsData.fund_si_start_date,
                      'YYYY0MM',
                    )}
                    {' - '}
                    {GenUtil.getFormattedYearAndMonth(profileStatsData.fund_si_end_date, 'YYYY0MM')}
                  </span>
                )}
                *Date: {profileStatsData.start_date} - {profileStatsData.end_date}
              </div>
              <YearlyMonthlyDatatable
                {...FundDetailsUtils.getFundStatsTableData(
                  profileStatsData.b1,
                  profileStatsData.b2,
                  profileStatsData.b3,
                  profileStatsData.fund,
                  profileStatsData.fund_si,
                )}
              />
            </Row>
            <Row id="distribution" style={{ marginTop: '30px' }} gutter={16}>
              <Col span={12}>
                {!Lodash.isEmpty(profileStatsData) ? (
                  <DistributionGraphComponent distribution={oc(profileStatsData).distribution()} />
                ) : null}
              </Col>
              <Col span={12}>
                {!Lodash.isEmpty(profileStatsData) ? (
                  <UpDownCaptureGraphComponent up_and_down={oc(profileStatsData).up_and_down()} />
                ) : null}
              </Col>
            </Row>
            <Row id="peak-to" style={{ marginTop: '30px' }}>
              {!Lodash.isEmpty(profileStatsData) ? (
                <DrawdownGraphComponent drawdown={oc(profileStatsData).drawdown()} />
              ) : null}
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Peer Group" key={ActiveTabEnum.PeerGroup}>
            <PeerGroupForm
              fundId={fundId}
              fundData={fundData}
              endDateOptions={datesRangeArray.slice().reverse() || []}
              fundAndBenchmarkService={APIService.peerGroupAnalysisService.fetchFundAndBenchmark}
              universeStatsService={APIService.peerGroupAnalysisService.fetchUniverseStats}
            />
          </Tabs.TabPane>
        </Tabs>
        <DisclaimerContainer
          dangerouslySetInnerHTML={{
            __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
          }}
        />
      </Container>
    </div>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    user: state.rootReducer.userProfile,
    benchmarkOptions: state.searchReducer.benchmarkOptions,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    updatePaneTitle: (key: string, title: string) =>
      dispatch({ type: RootActionEnums.UPDATE_PANE_TITLE, payload: { key, title } }),
  };
};

export default connect<StateProps, DispatchProps, Props, CombinedReducers>(
  mapStatesToProps,
  mapDispatchToProps,
)(React.memo(FundDetailsView));
