import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

type Props = {
  onSelectIndex: (selected: IndexSelections) => void;
  defaultIndex: IndexSelections;
};

export enum IndexSelections {
  HFRI = 'HFRI',
  HFRX = 'HFRX',
  HFRU = 'HFRU',
  HFRP = 'HFRP',
  HFRB = 'HFRB',
}

const IndexSelect: React.FC<Props> = ({ onSelectIndex, defaultIndex }) => {
  return (
    <Select
      style={{ width: '100%' }}
      defaultValue={defaultIndex}
      placeholder="Select Index"
      onChange={onSelectIndex}
    >
      <Option key={IndexSelections.HFRI} value={IndexSelections.HFRI}>
        {IndexSelections.HFRI}
      </Option>
      <Option key={IndexSelections.HFRX} value={IndexSelections.HFRX}>
        {IndexSelections.HFRX}
      </Option>
      <Option key={IndexSelections.HFRU} value={IndexSelections.HFRU}>
        {IndexSelections.HFRU}
      </Option>
      <Option key={IndexSelections.HFRP} value={IndexSelections.HFRP}>
        HFR Risk Parity ({IndexSelections.HFRP})
      </Option>
      <Option key={IndexSelections.HFRB} value={IndexSelections.HFRB}>
        HFR Blockchain ({IndexSelections.HFRB})
      </Option>
    </Select>
  );
};

export default IndexSelect;
