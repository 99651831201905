import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { MapStateToProps, connect } from 'react-redux';
import { Result, Button, Spin } from 'antd';
import { UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import 'antd/dist/antd.css';
import APIService from '../../shared/api';

type Props = {} & RouteComponentProps;

type StateProps = {
  user: UserProfileDetails;
};

const Admin: React.FC<Props & StateProps> = ({ user }) => {
  const history = useHistory();

  React.useEffect(() => {
    if (user.is_staff) {
      APIService.adminService.openAdmin();
    }
  });

  if (!user.is_staff) {
    return (
      <Result
        // @ts-ignore
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={() => history.push('/')} type="primary">
            Back Home
          </Button>
        }
      />
    );
  }
  return <Spin spinning={true} />;
};

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
  };
};

export default connect(mapStateToProps)(Admin);
