import React from 'react';
import { oc } from 'ts-optchain';
import _ from 'lodash';
import { default as Plotly, PlotData } from 'plotly.js';
import { DateValueRecord, DateValueRecordList } from '../../../shared/api/models/ResponsesTypes';
import PanelDetailGraph from '../../../shared/components/PanelDetailGraph';

interface OwnProps {
  drawdown: DateValueRecordList | undefined;
}

/**
 * This will renders DrawDown graph component based on data from profileStats api
 * @param drawdown
 * @constructor
 */
const DrawdownGraph: React.FC<OwnProps> = ({ drawdown }) => {
  const getHoverText = (item: DateValueRecord) => {
    return `<i>Value: </i>: ${item.value}<br><i>Date</i>: ${item.date}<br><extra></extra>`;
  };
  const datesArray: string[] = [];
  const getDrawdownGraphData = () => {
    const graphData: Partial<PlotData>[] = [];
    if (drawdown) {
      for (const key in drawdown) {
        const x: string[] = [];
        const y: number[] = [];
        const hovertemplate: string[] = [];
        oc<any>(drawdown)
          [key]([])
          .forEach((item: DateValueRecord) => {
            x.push(item.date);
            y.push(item.value);
            datesArray.push(item.date);
            hovertemplate.push(getHoverText(item));
          });
        graphData.push({
          x,
          y,
          hovertemplate,
          name: key[0].toUpperCase() + key.slice(1),
        });
      }
    }

    const graphLayout: Partial<Plotly.Layout> = {
      autosize: true,
      xaxis: {
        showgrid: false,
        showline: false,
        tickformat: '%Y-%m',
        categoryorder: 'array',
        categoryarray: _.uniq(datesArray),
      },
      yaxis: {},
      margin: {
        l: 50,
        r: 50,
        b: 100,
        t: 100,
        pad: 4,
      },
      title: 'Peak To Trough Drawdown',
    };
    return {
      header: 'Peak To Trough Drawdown',
      graphData: graphData,
      graphLayout: graphLayout,
    };
  };

  return <PanelDetailGraph {...getDrawdownGraphData()} />;
};

export default React.memo(DrawdownGraph);
