class ModelActionEnums {
  static RESET_STORE = 'RESET_STORE';
  static REMOVE_PORTFOLIO_PANE = 'Remove Portfolio Pane';
  static ADD_PORTFOLIO_PANE = 'Add Portfolio Pane';
  static CHANGE_PORTFOLIO_PANE = 'Change Portfolio Pane';
  static UPDATE_PORTFOLIO_MODELS = 'Update Portfolio Models';
  static UPDATE_PORTFOLIO_PANE = 'Update Portfolio Pane';
  static UPDATE_SELECTED_FUNDS = 'Update Selected Funds';
}

export default ModelActionEnums;
