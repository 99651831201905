import React from 'react';

type HandleSetState<T> = React.Dispatch<React.SetStateAction<T | undefined>>;

type UseSafeState<T> = [T | undefined, HandleSetState<T>];

export function useSafeState<T>(initialState?: T | (() => T)): UseSafeState<T> {
  const mountedRef = React.useRef(false);

  React.useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [state, setState] = React.useState<T | undefined>(initialState);

  const handleSetState: HandleSetState<T> = React.useCallback(newState => {
    if (mountedRef.current) {
      setState(newState);
    }
  }, []);

  return [state, handleSetState];
}
