import React, { FormEvent } from 'react';
import { Button, Form } from 'antd';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CombinedReducers } from '../../../..';
import { InitialSearchState } from '../../redux/search.reducers';
import QueryGroup from '../QueryGroup/QueryGroup';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import SearchActionEnums from '../../redux/search.ActionEnums';
import { ExtendFundListRecord } from '../../../../shared/api/models/ResponsesTypes';
import BasicFilters from './BasicFilters';
import AdvancedFilterModal from './AdvancedFilterModal';
import ActiveAdvancedFilters from './ActiveAdvancedFilters';

// styled components
const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-spin-nested-loading {
    flex: 1;

    .ant-spin-container {
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(217, 217, 217);
  padding: 16px;
  align-items: center;

  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

const Content = styled(PerfectScrollbar)`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled('div')`
  padding: 16px;
  padding-bottom: 0;
  border-top: 1px solid rgb(217, 217, 217);
  flex-wrap: 'wrap';
  display: flex;
`;

interface DispatchProps {
  addSearchParams: (params: Partial<FetchFundParams>) => void;
}

type Props = {
  onSearch: (value: Partial<FetchFundParams>) => void;
  loading: boolean;
};

type StateProps = InitialSearchState & {
  universeList: ExtendFundListRecord[];
};

const SearchForm: React.FC<Props & StateProps & DispatchProps> = ({
  onSearch,
  searchParams,
  addSearchParams,
}) => {
  const reducer = (
    state: Partial<FetchFundParams>,
    action: Partial<FetchFundParams>,
  ): Partial<FetchFundParams> => ({
    ...state,
    ...action,
  });

  const initialState: Partial<FetchFundParams> = {
    // Basic
    fundOrFirmName: undefined,
    fundListId: [],
    universeListId: [],
    portfolioModelId: [],
    staticIndexIds: undefined,
    dynamicIndexIds: undefined,
    peerGroupIds: undefined,
    strategy: undefined,
    subStrategy: undefined,
    regionalFocus: undefined,
    // General
    ucitsCompliant: undefined,
    // Liquid Alt Info Panel
    liquidAltProduct: undefined,
    liquidAltType: undefined,
    liquidAltRegion: undefined,
    max_12b1Fee: undefined,
    maxFrontFee: undefined,
    maxDeferredFee: undefined,
    //Fund Info Panel
    fundName: undefined,
    fundId: undefined,
    isEmrgngmrktsFund: undefined,
    isIndexFund: undefined,
    isHfrxFund: undefined,
    isHfruFund: undefined,
    isOffshore: undefined,
    isHfrRiskParityFund: undefined,
    isHfrBlockchainFund: undefined,
    productClass: undefined,
    strategyDescription: undefined,
    regionInvFocusCountry: undefined,
    inceptionDateFrom: undefined,
    inceptionDateTo: undefined,
    addedToDbFrom: undefined,
    addedToDbTo: undefined,
    // Firm Info Panel
    firmId: undefined,
    firmName: undefined,
    iardCrdNumber: undefined,
    secNumber: undefined,
    secRegistered: undefined,
    isDiversityFirm: undefined,
    nonSecRegCountry: undefined,
    nonSecRegAuth: undefined,
    nonSecRegAuthType: undefined,
    contactWeb: undefined,
    email: undefined,
    // Service Provider Info Panel
    administrator: undefined,
    annualPerformAudit: undefined,
    auditDateFrom: undefined,
    auditDateTo: undefined,
    auditor: undefined,
    bankingAgentOnshore: undefined,
    custodian: undefined,
    legalAdviserOnshore: undefined,
    primeBroker: undefined,
    // Contact Info Panel
    principals: undefined,
    firstContact: undefined,
    secondContact: undefined,
    address: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    postalCode: undefined,
    phone: undefined,
    facsimile: undefined,
    // Investment Info Panel
    advanceNotice: undefined,
    domicile: undefined,
    structure: undefined,
    highWatermark: undefined,
    hurdleRate: undefined,
    individualAccountMinGte: undefined,
    individualAccountMinLte: undefined,
    leverage: undefined,
    isLockup: undefined,
    lockup: undefined,
    minInvestmentCurr: undefined,
    minimumInvestmentLte: undefined,
    minimumInvestmentGte: undefined,
    additionalInvestmentLte: undefined,
    additionalInvestmentGte: undefined,
    openForInvestment: undefined,
    openToInvestorType: undefined,
    reporting: undefined,
    reportingStyle: undefined,
    returnsCurrency: undefined,
    fundRegistrationCode: undefined,
    redemptions: undefined,
    subscriptions: undefined,
    mgmtFee: undefined,
    incentiveFee: undefined,
    //Assets Info Panel
    firmAssetsCurrency: undefined,
    firmAssetsRepDateFrom: undefined,
    firmAssetsRepDateTo: undefined,
    fundAssetsCurrency: undefined,
    fundAssetsRepDateFrom: undefined,
    fundAssetsRepDateTo: undefined,
    firmUsdValueGte: undefined,
    firmUsdValueLte: undefined,
    fundUsdValueGte: undefined,
    fundUsdValueLte: undefined,
    sortBy: undefined,
    orderBy: undefined,
    //Statistics Filters Panel
    latestRORDateFrom: undefined,
    latestRORDateTo: undefined,
    latestRORValueGte: undefined,
    latestRORValueLte: undefined,
    trackRecordGte: undefined,
    trackRecordLte: undefined,
    totalTrackRecordGte: undefined,
    totalTrackRecordLte: undefined,
    annRorGte: undefined,
    annRorLte: undefined,
    annStdevGte: undefined,
    annStdevLte: undefined,
    geoAvgMonthRorGte: undefined,
    geoAvgMonthRorLte: undefined,
    highMonthRorGte: undefined,
    highMonthRorLte: undefined,
    lowMonthRorGte: undefined,
    lowMonthRorLte: undefined,
    stdevGte: undefined,
    stdevLte: undefined,
    sharpeRatioGte: undefined,
    sharpeRatioLte: undefined,
    oneMonthRorGte: undefined,
    oneMonthRorLte: undefined,
    threeMonthRorGte: undefined,
    threeMonthRorLte: undefined,
    sixMonthRorGte: undefined,
    sixMonthRorLte: undefined,
    oneYearAnnRorGte: undefined,
    oneYearAnnRorLte: undefined,
    oneYearAnnStdevGte: undefined,
    oneYearAnnStdevLte: undefined,
    oneYearAnnSharpeRatioGte: undefined,
    oneYearAnnSharpeRatioLte: undefined,
    twoYearAnnRorGte: undefined,
    twoYearAnnRorLte: undefined,
    twoYearAnnStdevGte: undefined,
    twoYearAnnStdevLte: undefined,
    twoYearAnnSharpeRatioGte: undefined,
    twoYearAnnSharpeRatioLte: undefined,
    threeYearAnnRorGte: undefined,
    threeYearAnnRorLte: undefined,
    threeYearAnnStdevGte: undefined,
    threeYearAnnStdevLte: undefined,
    threeYearAnnSharpeRatioGte: undefined,
    threeYearAnnSharpeRatioLte: undefined,
    fiveYearAnnRorGte: undefined,
    fiveYearAnnRorLte: undefined,
    fiveYearAnnStdevGte: undefined,
    fiveYearAnnStdevLte: undefined,
    fiveYearAnnSharpeRatioGte: undefined,
    fiveYearAnnSharpeRatioLte: undefined,
    sevenYearAnnRorGte: undefined,
    sevenYearAnnRorLte: undefined,
    sevenYearAnnStdevGte: undefined,
    sevenYearAnnStdevLte: undefined,
    sevenYearAnnSharpeRatioGte: undefined,
    sevenYearAnnSharpeRatioLte: undefined,
    tenYearAnnRorGte: undefined,
    tenYearAnnRorLte: undefined,
    tenYearAnnStdevGte: undefined,
    tenYearAnnStdevLte: undefined,
    tenYearAnnSharpeRatioGte: undefined,
    tenYearAnnSharpeRatioLte: undefined,
    max_drawdown_gte: undefined,
    max_drawdown_lte: undefined,
    ytd_gte: undefined,
    ytd_lte: undefined,
    // MPT Statistics Panel
    alphaGte: undefined,
    alphaLte: undefined,
    betaGte: undefined,
    betaLte: undefined,
    rSquareGte: undefined,
    rSquareLte: undefined,
    correlationGte: undefined,
    correlationLte: undefined,
    upAlphaGte: undefined,
    upAlphaLte: undefined,
    upBetaGte: undefined,
    upBetaLte: undefined,
    upRSquareGte: undefined,
    upRSquareLte: undefined,
    downAlphaGte: undefined,
    downAlphaLte: undefined,
    downBetaGte: undefined,
    downBetaLte: undefined,
    downRSquareGte: undefined,
    downRSquareLte: undefined,
    // Stats Calculation Settings
    statsStartDate: undefined,
    statsEndDate: undefined,
    stats_benchmark: undefined,
    // Fund ESG
    is_fund_esg: undefined,
    esg_strategy: undefined,
    esg_sub_strategy: undefined,
    app_positivescreen: undefined,
    app_negativescreen: undefined,
    app_esgintegration: undefined,
    app_impactinvest: undefined,
    app_sustaininvest: undefined,
    env_greenbuilding: undefined,
    env_pollutiontaxes: undefined,
    env_climatechange: undefined,
    env_sustainable: undefined,
    env_cleantech: undefined,
    env_waterconservation: undefined,
    soc_workplacesafety: undefined,
    soc_communitydev: undefined,
    soc_laborrelations: undefined,
    soc_workplacebenefits: undefined,
    soc_avoidtobacco: undefined,
    soc_diversityantibias: undefined,
    soc_humanrights: undefined,
    gov_corppolcontrib: undefined,
    gov_anticorruption: undefined,
    gov_executivecomp: undefined,
    gov_boardindependence: undefined,
    gov_boarddiversity: undefined,
    team_engagepcm: undefined,
    // Fund Diversity & Inclusion
    is_minority_or_woman_managed_fund: undefined,
    fund_diversity_and_inclusion_types: undefined,
    // Firm Diversity & Inclusion
    is_minority_or_woman_owned_firm: undefined,
    firm_diversity_and_inclusion_types: undefined,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showAdvanced, setShowAdvanced] = React.useState(false);
  const [selectedField, setSelectedField] = React.useState<string>();

  React.useEffect(() => {
    dispatch({ ...searchParams });
  }, [searchParams]);

  // React.useEffect(() => {
  //   if (
  //     state.universeListId &&
  //     state.universeListId.length === 0 &&
  //     state.portfolioModelId && state.portfolioModelId.length ===0 &&
  //     state.fundListId &&state.fundListId.length === 0 &&
  //     universeList &&
  //     universeList.length > 0
  //   ) {
  //     console.log(state)
  //     dispatch({
  //       universeListId: [Number.parseInt(universeList[0].id)],
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [universeList]);
  // Handlers
  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    const extractedEmptyArrayProps = Object.entries(state).reduce((result, [key, value]) => {
      if (value && Array.isArray(value) && value.length <= 0) {
        return result;
      }
      return {
        ...result,
        [key]: value,
      };
    }, {} as Partial<FetchFundParams>);
    console.log({ extractedEmptyArrayProps });
    onSearch(extractedEmptyArrayProps);
  };

  const handleClearForm = () => {
    dispatch({ ...initialState });
    addSearchParams({});
    toast.info('Search Filters cleared!');
  };

  return (
    <StyledForm style={{ height: '100%' }} onSubmit={handleSubmit}>
      <Header>
        <span>Search Query</span>
        <QueryGroup params={state} />
      </Header>
      <Content>
        <BasicFilters data={state} update={dispatch} />
        <ActiveAdvancedFilters
          data={state}
          update={dispatch}
          onTagClick={fieldName => {
            setShowAdvanced(true);
            setSelectedField(fieldName);
          }}
        />
      </Content>

      <AdvancedFilterModal
        isOpen={showAdvanced}
        onRequestClose={() => {
          setShowAdvanced(false);
          setSelectedField(undefined);
        }}
        data={state}
        update={dispatch}
        initialFocus={selectedField}
      />
      <Footer>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8, marginBottom: 8 }}>
          Search
        </Button>
        <Button type="default" onClick={handleClearForm} style={{ marginRight: 8 }}>
          Clear
        </Button>
        <Button type="link" onClick={() => setShowAdvanced(true)}>
          Advanced Search
        </Button>
      </Footer>
    </StyledForm>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    ...state.searchReducer,
    universeList: state.rootReducer.universeList,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addSearchParams: params => {
      dispatch({ type: SearchActionEnums.UPDATE_FORM_SEARCH_PARAMS, payload: params });
    },
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(SearchForm);
