import { isNullOrUndefined } from 'util';
import React from 'react';
import { Tag, Divider } from 'antd';
import { MapStateToProps, connect } from 'react-redux';
import styled from 'styled-components';
import { InitialSearchState } from '../../redux/search.reducers';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import { CombinedReducers } from '../../../..';
import { getAdvancedFields } from './helper/AdvancedFields';
import {
  FIELD_TYPES,
  PairValueProps,
  FieldProps,
  CustomRendererProps,
} from './helper/advanceFormFields';

const StyledTag = styled(Tag)`
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type ValueType = string | number | boolean | string[] | number[] | undefined;
type StateProps = InitialSearchState & {};
type Props = {
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
  onTagClick: (fieldName: string) => void;
};

function ifHaveValue(value: ValueType) {
  if (isNullOrUndefined(value)) {
    return false;
  }
  if (typeof value === 'string' && !value) {
    return false;
  }
  if (Array.isArray(value) && !value.length) {
    return false;
  }
  return true;
}

const ActiveAdvancedFilters: React.FC<StateProps & Props> = ({
  data,
  update,
  onTagClick,
  // redux props
  benchmarkOptions,
  liquidAltTypeOptions,
  liquidAltRegionOptions,
  maxFrontFeeOptions,
  maxDeferredFeeOptions,
  max12B1FeeOptions,
  productClassOptions,
  advanceNoticeOptions,
  minInvestmentCurrOptions,
  reportingOptions,
  reportingStyleOptions,
  returnsCurrencyOptions,
  redemptionOptions,
  subscriptionsOptions,
  firmAssetCurrencyOptions,
  fundAssetCurrencyOptions,
  stateOptions,
  countryOptions,
  rorDateRangeOptions,
  regionalFocusCountryOptions,
  nonSecRegAuthorities,
  nonSecCountryOptions,
  esgStrategyOptions,
  esgSubStrategyOptions,
  minorityTypeOptions,
  fundDropdowns,
}) => {
  const fullAdvancedFilters = React.useMemo(() => {
    return getAdvancedFields({
      data,
      update,
      benchmarkOptions,
      liquidAltTypeOptions,
      liquidAltRegionOptions,
      maxFrontFeeOptions,
      maxDeferredFeeOptions,
      max12B1FeeOptions,
      productClassOptions,
      advanceNoticeOptions,
      minInvestmentCurrOptions,
      reportingOptions,
      reportingStyleOptions,
      returnsCurrencyOptions,
      redemptionOptions,
      subscriptionsOptions,
      firmAssetCurrencyOptions,
      fundAssetCurrencyOptions,
      stateOptions,
      countryOptions,
      rorDateRangeOptions,
      regionalFocusCountryOptions,
      nonSecRegAuthorities,
      nonSecCountryOptions,
      esgStrategyOptions,
      esgSubStrategyOptions,
      minorityTypeOptions,
      fundDropdowns,
    });
  }, [
    advanceNoticeOptions,
    benchmarkOptions,
    countryOptions,
    data,
    firmAssetCurrencyOptions,
    fundAssetCurrencyOptions,
    liquidAltRegionOptions,
    liquidAltTypeOptions,
    max12B1FeeOptions,
    maxDeferredFeeOptions,
    maxFrontFeeOptions,
    minInvestmentCurrOptions,
    productClassOptions,
    redemptionOptions,
    reportingOptions,
    reportingStyleOptions,
    returnsCurrencyOptions,
    rorDateRangeOptions,
    stateOptions,
    subscriptionsOptions,
    regionalFocusCountryOptions,
    update,
    nonSecRegAuthorities,
    nonSecCountryOptions,
    esgStrategyOptions,
    esgSubStrategyOptions,
    minorityTypeOptions,
    fundDropdowns,
  ]);

  const deprecatedList = React.useMemo(() => {
    if (!fullAdvancedFilters) {
      return [];
    }

    // setup record
    const dataKeys = Object.keys(data) as Array<keyof typeof data>;
    const allCheckRecords = dataKeys.reduce((prev, curKey) => {
      const value = data[curKey];
      const cond = ifHaveValue(value);
      if (!cond) {
        return prev;
      }
      return {
        ...prev,
        [curKey]: value,
      };
    }, {} as Record<string, any>);
    // delete non-advanced filter
    delete allCheckRecords.fundOrFirmName;
    delete allCheckRecords.fundListId;
    delete allCheckRecords.universeListId;
    delete allCheckRecords.portfolioModelId;
    delete allCheckRecords.staticIndexIds;
    delete allCheckRecords.dynamicIndexIds;
    delete allCheckRecords.peerGroupIds;
    delete allCheckRecords.strategy;
    delete allCheckRecords.subStrategy;
    delete allCheckRecords.regionalFocus;
    //
    fullAdvancedFilters.map(group => {
      return group.fields.map(field => {
        if (field.type === FIELD_TYPES.PAIR_VALUE) {
          const currentField = field as PairValueProps;
          const atleastValue = allCheckRecords[currentField.atleastKey];
          const atmostValue = allCheckRecords[currentField.atmostKey];

          if (!isNullOrUndefined(atleastValue)) {
            delete allCheckRecords[currentField.atleastKey];
          }
          if (!isNullOrUndefined(atmostValue)) {
            delete allCheckRecords[currentField.atmostKey];
          }
          return '';
        }
        if (field.type === FIELD_TYPES.BOOLEAN) {
          const currentField = field as FieldProps;
          const currentKey = currentField.key as keyof FetchFundParams;
          const currentValue = allCheckRecords[currentKey];
          if (currentValue !== undefined) {
            delete allCheckRecords[currentKey];
            return currentValue;
          }
          return '';
        }
        if (field.type === FIELD_TYPES.CUSTOM) {
          const currentField = field as CustomRendererProps;
          const currentKeys = currentField.key as (keyof FetchFundParams)[];
          currentKeys.map(key => {
            if (allCheckRecords[key]) {
              delete allCheckRecords[key];
              return true;
            }
            return false;
          });
          return '';
        }
        if (field.type === FIELD_TYPES.DROPDOWN) {
          const currentField = field as FieldProps;
          const currentValue = allCheckRecords[currentField.key as keyof FetchFundParams];
          if (ifHaveValue(currentValue)) {
            delete allCheckRecords[currentField.key as keyof FetchFundParams];
          }
          return '';
        }
        // all other cases
        const currentField = field as FieldProps;
        const currentValue = allCheckRecords[currentField.key as keyof FetchFundParams];
        if (ifHaveValue(currentValue)) {
          delete allCheckRecords[currentField.key as keyof FetchFundParams];
        }

        return '';
      });
    });

    const deprectedFieldKeys = Object.keys(allCheckRecords);
    const results = deprectedFieldKeys.map(key => {
      return {
        name: key,
        value: allCheckRecords[key],
      };
    });
    if (!results.length) {
      return results;
    }
    const updateRemove = results.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.name]: undefined,
      };
    }, {} as Record<string, undefined>);
    update(updateRemove);
    return results;
  }, [fullAdvancedFilters, data, update]);

  return (
    <div style={{ width: '100%' }} id="active-advanced-filters">
      {fullAdvancedFilters.map(group => {
        return group.fields.map(field => {
          if (field.type === FIELD_TYPES.PAIR_VALUE) {
            const currentField = field as PairValueProps;
            const atleastValue = data[currentField.atleastKey];
            const atmostValue = data[currentField.atmostKey];
            if (!isNullOrUndefined(atleastValue) || !isNullOrUndefined(atmostValue)) {
              return (
                <Tag
                  key={currentField.name}
                  closable
                  onClick={() => onTagClick(field.name)}
                  onClose={(e: any) => {
                    e.stopPropagation();
                    update({
                      [currentField.atleastKey]: undefined,
                      [currentField.atmostKey]: undefined,
                    });
                  }}
                >
                  <strong>{field.name}</strong>:{' '}
                  {isNullOrUndefined(atleastValue) ? 'N/A' : atleastValue}
                  {' - '}
                  {isNullOrUndefined(atmostValue) ? 'N/A' : atmostValue}
                </Tag>
              );
            }
          }
          if (field.type === FIELD_TYPES.BOOLEAN) {
            const currentField = field as FieldProps;
            const currentKey = currentField.key as keyof FetchFundParams;
            const currentValue = data[currentKey];
            if (currentValue !== undefined) {
              return (
                <Tag
                  key={currentKey}
                  closable
                  onClick={() => onTagClick(field.name)}
                  onClose={(e: any) => {
                    e.stopPropagation();
                    update({ [currentKey]: undefined });
                  }}
                >
                  <strong>{field.name}</strong>: {currentValue ? 'Yes' : 'No'}
                </Tag>
              );
            }
          }
          if (field.type === FIELD_TYPES.CUSTOM) {
            const currentField = field as CustomRendererProps;
            const currentKeys = currentField.key as (keyof FetchFundParams)[];
            const isKeyHasValueInState = currentKeys.some(key => {
              if (data[key]) {
                return true;
              }
              return false;
            });
            if (isKeyHasValueInState) {
              return (
                <Tag
                  key={field.name}
                  closable
                  onClick={() => onTagClick(field.name)}
                  onClose={(e: any) => {
                    e.stopPropagation();
                    currentKeys.map(key => update({ [key]: undefined }));
                  }}
                >
                  <strong>{field.name}</strong>:{' '}
                  {currentKeys.map(
                    (key, index) =>
                      `${data[key] || 'N/A'} ${index !== currentKeys.length - 1 ? ' - ' : ''}`,
                  )}
                </Tag>
              );
            }
          }
          if (field.type === FIELD_TYPES.DROPDOWN) {
            const currentField = field as FieldProps;
            const currentValue = data[currentField.key as keyof FetchFundParams];
            const currentOptions = currentField.optionData;
            let renderValue;
            if (!currentOptions || !currentOptions.length) {
              renderValue = currentValue;
            } else if (Array.isArray(currentValue)) {
              renderValue = (currentValue as Array<number | string>)
                .map((value: any) => {
                  // Edge case: select Regional Focus changes the list of Regional Focus Country thus make the currentOptions don't have the selected focus country's code
                  if (currentField.key === 'regionInvFocusCountry') {
                    const activeOption = regionalFocusCountryOptions.find(
                      option => option.code_code === value,
                    );
                    return activeOption ? activeOption.code_description : value;
                  }
                  const activeOption = currentOptions.find(option => option.value === value);
                  return activeOption ? activeOption.label : value;
                })
                .join(', ');
            } else {
              const activeOption = currentOptions.find(option => option.value === currentValue);
              renderValue = activeOption ? activeOption.label : currentValue;
            }
            return renderValue ? (
              <StyledTag
                key={field.name}
                closable
                onClick={() => onTagClick(field.name)}
                onClose={(e: any) => {
                  e.stopPropagation();
                  update({ [currentField.key as keyof FetchFundParams]: undefined });
                }}
              >
                {<strong>{field.name}</strong>}: {renderValue}
              </StyledTag>
            ) : null;
          }
          // all other cases
          const currentField = field as FieldProps;
          const currentValue = data[currentField.key as keyof FetchFundParams];
          const renderValue = Array.isArray(currentValue)
            ? (currentValue as Array<number | string>).map(
                (value, index) =>
                  `${value || 'N/A'}${index !== currentValue.length - 1 ? ', ' : ''}`,
              )
            : currentValue;
          return currentValue ? (
            <Tag
              key={field.name}
              closable
              onClick={() => onTagClick(field.name)}
              onClose={(e: any) => {
                e.stopPropagation();
                update({ [currentField.key as keyof FetchFundParams]: undefined });
              }}
            >
              <strong>{field.name}</strong>: {renderValue}
            </Tag>
          ) : null;
        });
      })}
      {/* {deprecatedList.length > 0 && (
        <Divider orientation="left" style={{ color: 'red' }}>
          Deprecated
        </Divider>
      )}
      {deprecatedList.length > 0 &&
        deprecatedList.map(currentField => {
          const currentValue = [currentField.value as keyof FetchFundParams];
          const renderValue = Array.isArray(currentValue)
            ? (currentValue as Array<number | string>).map(
                (value, index) =>
                  `${value || 'N/A'}${index !== currentValue.length - 1 ? ', ' : ''}`,
              )
            : currentValue;
          return currentValue ? (
            <Tag
              color="red"
              key={currentField.name}
              closable
              onClose={(e: any) => {
                e.stopPropagation();
                update({ [currentField.name as keyof FetchFundParams]: undefined });
              }}
            >
              <strong>{currentField.name}</strong>: {renderValue}
            </Tag>
          ) : null;
        })} */}
    </div>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    ...state.searchReducer,
  };
};

export default connect(mapStatesToProps)(ActiveAdvancedFilters);
