import React, { useEffect, useState } from 'react';
import { Button, Icon, Input, Modal, Row, Select, Anchor } from 'antd';
import styled from 'styled-components';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { AxiosResponse } from 'axios';
import awaitTo from 'async-await-error-handling';
import { toast } from 'react-toastify';
import _ from 'lodash';
import APIService from '../../shared/api';
import { useServiceState } from '../../shared/hooks/useServiceState';
import {
  DisclaimerResponse,
  PortfolioModelFundRecord,
  PortfolioModelRecord,
  PortfolioModelStats,
  UserProfileDetails,
} from '../../shared/api/models/ResponsesTypes';
import { CombinedReducers } from '../../index';
import ShareModal from '../../shared/components/ShareModal';
import GenUtil from '../../shared/utils/gen-util';
import ModelAnalysis from './components/ModelAnalysis';
import PortfolioModelTable from './components/PortfolioModelTable';
import PortfolioModelAction from './portfolio-model-action';
import CreatePortfolioModal from './components/CreatePortfolioModal';
import ModelActionEnums from './redux/model-action-enums';
import { PortfolioPane } from './redux/model-reducers';
import AddToPortfolioModal from './components/AddFundToPortfolioModal';
import RenamePortfolioModel from './components/RenamePortfolioModel';

const { Option } = Select;
const { Link } = Anchor;

const FixedHeader = styled('div')`
  background: white;
  z-index: 99;
  display: flex;
  justify-content: flex-end;

  .ant-anchor {
    display: flex;
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding-left: 0;
  }
`;

const Container = styled('div')`
  width: 100%;
  padding: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const TableContainer = styled(Row)``;

const ButtonGroupContainer = styled(Row)`
  margin-top: 10px;
`;

const DisclaimerContainer = styled(Row)`
  overflow-y: scroll;
  min-height: 80px;
  max-height: 80px;
  margin-top: 10px;
  padding: 0 10px 0 0;
  font-size: 9px;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

interface Props {
  paneKey: string;
}

interface DispatchProps {
  fetchPortfolioModels: () => Promise<PortfolioModelRecord[]>;
  updatePane: (data: PortfolioPane) => void;
  addPane: (data: PortfolioPane) => void;
  removePane: (key: string) => void;
  updateSelectedFunds: (selectedFunds: string[]) => void;
}

interface StateProps {
  user: UserProfileDetails;
  portfolioModels: PortfolioModelRecord[];
  portfolioPanes: PortfolioPane[];
  selectedFunds: string[];
}

/**
 * Individual Container view for each portfolio tab-pane
 */

const PortfolioView: React.FC<Props & StateProps & DispatchProps> = ({
  paneKey,
  portfolioModels,
  fetchPortfolioModels,
  updatePane,
  removePane,
  user,
  updateSelectedFunds,
  portfolioPanes,
  addPane,
  selectedFunds,
}) => {
  const [modelAnalysis, setModelAnalysis] = useState<boolean>(false);
  const [selectedPortfolioModelId, setSelectedPortfolioModelId] = useState<number | undefined>();
  const [totalAllocated, setTotalAllocated] = useState<number>(0);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [portfolioModelStats, setPortfolioModelStats] = useState<PortfolioModelStats>();
  const [portfolioModelRecords, setPortfolioModelRecords] = useState<PortfolioModelFundRecord[]>(
    [],
  );
  const [showShareModal, setShowShareModal] = useState(false);
  const [isCalculating, setCalculating] = useState(false);

  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  useEffect(() => {
    const filteredPortfolioPanes: PortfolioPane[] = portfolioPanes.filter(p => p.key === paneKey);
    if (filteredPortfolioPanes.length > 0) {
      if (filteredPortfolioPanes[0].portfolioModelId) {
        setSelectedPortfolioModelId(Number(filteredPortfolioPanes[0].portfolioModelId));
      }
    }
  }, [paneKey, portfolioPanes]);

  useEffect(() => {
    fetchPortfolioModels();
  }, [fetchPortfolioModels]);

  useEffect(() => {
    invokeDisclaimerData('model');
  }, [invokeDisclaimerData]);

  useEffect(() => {
    const fetch = async () => {
      if (selectedPortfolioModelId) {
        const res: AxiosResponse<PortfolioModelFundRecord[]> = await APIService.portfolioModelService.fetchPortfolioModelFunds(
          `${selectedPortfolioModelId}`,
        );
        const portfolioRecords: PortfolioModelFundRecord[] = res.data.map(d => {
          d.weight = d.weight / 10000;
          return d.fund_id
            ? { ...d, key: d.fund_id }
            : { ...d, key: `${d.user_fund_id}-USER_FUND` };
        });
        setPortfolioModelRecords(portfolioRecords);
      } else {
        setPortfolioModelRecords([]);
      }
    };
    fetch();
  }, [selectedPortfolioModelId]);

  const isRecalculationEnabled = () => {
    return GenUtil.digitWithMaxPrecision(totalAllocated) === 100;
  };

  const handleRefresh = () => {
    fetchPortfolioModels();
  };
  const handleRename = () => {
    setShowRenameModal(true);
  };

  const handleClone = async () => {
    if (selectedPortfolioModelId) {
      const index: number = portfolioModels.findIndex(
        (val: PortfolioModelRecord) => val.id === selectedPortfolioModelId,
      );
      if (index >= 0) {
        const selectedPortfolioModel: PortfolioModelRecord = portfolioModels[index];
        if (selectedPortfolioModel.name.startsWith('Clone ')) {
          toast.error('This is an already cloned Model');
          return;
        }
        const funds = portfolioModelRecords.map(portfolioFundRecord => {
          if (portfolioFundRecord.fund_id) {
            const { fund_id, weight } = portfolioFundRecord;
            return { fund_id, weight: parseInt(`${weight * 10000}`, 10) };
          } else {
            const { user_fund_id, weight } = portfolioFundRecord;
            return { user_fund_id, weight: parseInt(`${weight * 10000}`, 10) };
          }
        });

        const [err, res] = await awaitTo<any>(
          APIService.portfolioModelService.createNewModel(
            `Clone of ${selectedPortfolioModel.name}`,
            funds,
          ),
        );
        if (err) {
          toast.error("Can't Clone the Model. Model is already cloned");
          return;
        }
        const { id, name }: { id: number; name: string } = res.data;
        addPane({
          key: `${id}`,
          portfolioModelId: `${id}`,
          portfolioModelName: name,
        });
      }
    }
  };
  const handleDelete = async () => {
    if (selectedPortfolioModelId) {
      Modal.confirm({
        title: 'Do you want to delete the selected portfolio model?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: async () => {
          await APIService.portfolioModelService.deleteModel(`${selectedPortfolioModelId}`);

          if (portfolioPanes.length === 1) {
            setSelectedPortfolioModelId(undefined);
          }
          removePane(paneKey);
          fetchPortfolioModels();
        },
      });
    }
  };

  const handleAdd = () => {
    setShowAddModal(true);
  };
  const handleShareSubmit = async (isShareAll: boolean, userIds?: number[]) => {
    if (!selectedPortfolioModelId) {
      return;
    }
    try {
      await APIService.portfolioModelService.sharePortfolioModel(
        selectedPortfolioModelId,
        userIds || [],
        isShareAll,
      );
      toast.success('Portfolio model shared!');
      setShowShareModal(false);
    } catch (error) {
      console.error(error);
      toast.error("Can't shared the selected portfolio model. Please try again later!");
    }
  };

  const showModelAnalysis = async () => {
    if (selectedPortfolioModelId) {
      const res = await APIService.portfolioModelService.fetchPortfolioModelStats(
        `${selectedPortfolioModelId}`,
      );
      setPortfolioModelStats(res.data);
    }
    setModelAnalysis(true);
    setCalculating(false);
    const modelAnalysisEl = document.querySelector('#performance-history');
    modelAnalysisEl &&
      modelAnalysisEl.scrollIntoView({
        behavior: 'smooth',
      });
  };

  const onRecalculate = async () => {
    setCalculating(true);
    setPortfolioModelStats(undefined);
    if (portfolioModelRecords && portfolioModelRecords.length > 0) {
      const data = portfolioModelRecords.map(p => {
        if (p.fund_id) {
          return {
            fund_id: p.fund_id,
            weight: parseInt(`${p.weight * 10000}`, 10),
          };
        }
        return {
          user_fund_id: p.user_fund_id,
          weight: parseInt(`${p.weight * 10000}`, 10),
        };
      });
      const totalWeight: number = 1000000 - data.reduce((total, val) => total + val.weight, 0);
      data[0].weight = data[0].weight + totalWeight;
      await APIService.portfolioModelService.updatePortfolioModel({
        modelId: `${selectedPortfolioModelId}`,
        data: { update: data },
      });
      showModelAnalysis();
    }
  };

  const handleCreateNewModel = () => {
    setShowCreateModal(true);
  };

  const onAllocationChanged = (fundDatas: PortfolioModelFundRecord[]) => {
    const sum: number = fundDatas
      .map((f: PortfolioModelFundRecord) => Number(f.weight))
      .reduce((x: number, y: number) => x + y, 0);
    setTotalAllocated(sum);
  };

  const onChangeModel = (val: number) => {
    APIService.eventService.sendMyModelsAccessEvent();
    updateSelectedFunds([]);
    setPortfolioModelRecords([]);
    const portfolioModelRecords: PortfolioModelRecord[] = portfolioModels.filter(p => p.id === val);
    if (portfolioModelRecords && portfolioModelRecords.length > 0) {
      // Viewing from a blank model tab
      if (paneKey === 'NEW_PORTFOLIO_PANE') {
        removePane(paneKey);
      }
      // Viewing from a existing model pane
      updatePane({
        key: paneKey,
        portfolioModelId: `${portfolioModelRecords[0].id}`,
        portfolioModelName: portfolioModelRecords[0].name,
      });
    }
    setSelectedPortfolioModelId(val);
  };

  const assignEqualWeight = () => {
    if (portfolioModelRecords && portfolioModelRecords.length > 0) {
      const equalWeight: number = parseInt(`${1000000 / portfolioModelRecords.length}`, 10);
      const extraWeight: number = 1000000 - equalWeight * portfolioModelRecords.length;
      portfolioModelRecords[0].weight = equalWeight + extraWeight;
      for (let i = 1; i < portfolioModelRecords.length; i++) {
        portfolioModelRecords[i].weight = equalWeight;
      }
      const records: PortfolioModelFundRecord[] = portfolioModelRecords.map(p => {
        p.weight = p.weight / 10000.0;
        return p;
      });
      setPortfolioModelRecords(records);
    }
  };

  const getPortfolioOptions = () => {
    const modelIds: number[] = portfolioModels.map(model => model.id);
    const paneIds: string[] = portfolioPanes.map(model => model.key);
    // remove the currently open models
    const filteredModelIds: number[] = modelIds.filter(modelId => !paneIds.includes(`${modelId}`));

    return portfolioModels
      .filter(model => filteredModelIds.includes(model.id) || model.id === selectedPortfolioModelId)
      .map(model => (
        <Option key={model.id} value={model.id}>
          {model.name}
        </Option>
      ));
  };

  return (
    <Container>
      {modelAnalysis && portfolioModelStats && selectedPortfolioModelId && (
        <FixedHeader>
          <Anchor>
            <Select
              placeholder="Select quick navigation"
              style={{ width: 250 }}
              defaultActiveFirstOption
              firstActiveValue={'top'}
            >
              <Option key={'top'}>
                <Link href="#info" title="Top" />
              </Option>
              <Option key={'performance-history'}>
                <Link href="#performance-history" title="Hypothetical Performance History" />
              </Option>
              <Option key={'analytics'}>
                <Link href="#analytics" title="Analytics" />
              </Option>
              <Option key={'vami'}>
                <Link href="#vami" title="Value Added Money Index" />
              </Option>
            </Select>
          </Anchor>
        </FixedHeader>
      )}
      <div style={{ overflowY: 'auto' }}>
        <span id="info" />
        <ButtonGroupContainer style={{ marginBottom: '25px' }}>
          <span>Model: </span>
          <Select
            value={selectedPortfolioModelId}
            showSearch
            style={{ width: 200 }}
            placeholder="Select Portfolio Model"
            optionFilterProp="children"
            onChange={onChangeModel}
          >
            {getPortfolioOptions()}
          </Select>
          <StyledButton onClick={handleRefresh}>
            <Icon type="sync" />
          </StyledButton>
          <StyledButton type="primary" onClick={handleRename} disabled={!selectedPortfolioModelId}>
            Rename
          </StyledButton>
          <StyledButton type="primary" onClick={handleClone} disabled={!selectedPortfolioModelId}>
            Clone
          </StyledButton>
          <StyledButton type="danger" onClick={handleDelete} disabled={!selectedPortfolioModelId}>
            Delete
          </StyledButton>
          <StyledButton
            type="default"
            onClick={() => setShowShareModal(true)}
            disabled={!selectedPortfolioModelId || user.hfrdb_trial}
          >
            <Icon type="share-alt" /> Share
          </StyledButton>
          <StyledButton
            type="primary"
            style={{ position: 'absolute', right: '22px' }}
            onClick={handleCreateNewModel}
          >
            Create
          </StyledButton>
        </ButtonGroupContainer>
        <TableContainer>
          <PortfolioModelTable
            portfolioModelId={selectedPortfolioModelId}
            onTotalAllocationChanged={onAllocationChanged}
            dataSource={portfolioModelRecords}
            onSetDataSource={(datas: PortfolioModelFundRecord[]) => setPortfolioModelRecords(datas)}
          />
          {showCreateModal ? (
            <CreatePortfolioModal isOpen={showCreateModal} setOpen={setShowCreateModal} />
          ) : null}
          {showShareModal && (
            <ShareModal
              onSubmit={handleShareSubmit}
              visible={showShareModal}
              onRequestClose={() => setShowShareModal(false)}
            />
          )}

          {showAddModal && selectedPortfolioModelId ? (
            <AddToPortfolioModal
              isOpen={showAddModal}
              setOpen={setShowAddModal}
              modelId={selectedPortfolioModelId}
            />
          ) : null}

          {showRenameModal && selectedPortfolioModelId ? (
            <RenamePortfolioModel
              fetchPortfolioModels={fetchPortfolioModels}
              updatePane={updatePane}
              isOpen={showRenameModal}
              setOpen={setShowRenameModal}
              modelId={selectedPortfolioModelId}
              paneKey={paneKey}
            />
          ) : null}

          <ButtonGroupContainer>
            <StyledButton disabled={_.isEmpty(selectedFunds)} onClick={handleAdd}>
              Add to...
            </StyledButton>
            <span style={{ marginLeft: 12 }}>Total Allocated</span>
            <Input
              style={{ width: '75px', margin: '0 10px' }}
              disabled={true}
              value={GenUtil.digitWithExactPrecision(totalAllocated)}
            />{' '}
            %
            <StyledButton
              type="primary"
              disabled={!isRecalculationEnabled()}
              onClick={onRecalculate}
              loading={isCalculating}
            >
              Calculate
            </StyledButton>
            <StyledButton type="primary" onClick={assignEqualWeight}>
              Equal Weight
            </StyledButton>
          </ButtonGroupContainer>
          {modelAnalysis && portfolioModelStats && selectedPortfolioModelId && (
            <ModelAnalysis
              portfolioModelStats={portfolioModelStats}
              modelId={`${selectedPortfolioModelId}`}
            />
          )}
          <DisclaimerContainer
            dangerouslySetInnerHTML={{
              __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
            }}
          />
        </TableContainer>
      </div>
    </Container>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    user: state.rootReducer.userProfile,
    portfolioModels: state.modelReducer.portfolioModels,
    portfolioPanes: state.modelReducer.portfolioPanes,
    selectedFunds: state.modelReducer.selectedFunds,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    fetchPortfolioModels: (): Promise<PortfolioModelRecord[]> =>
      PortfolioModelAction.fetchPortfolioModels()(dispatch),
    updatePane: (data: PortfolioPane) => {
      dispatch({ type: ModelActionEnums.UPDATE_PORTFOLIO_PANE, payload: data });
    },
    removePane: (key: string) => {
      dispatch({ type: ModelActionEnums.REMOVE_PORTFOLIO_PANE, payload: { key } });
    },
    addPane: (data: PortfolioPane) => {
      dispatch({ type: ModelActionEnums.ADD_PORTFOLIO_PANE, payload: data });
    },
    updateSelectedFunds: (selectedFunds: string[]) => {
      dispatch({ type: ModelActionEnums.UPDATE_SELECTED_FUNDS, payload: { selectedFunds } });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PortfolioView);
