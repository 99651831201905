import * as React from 'react';
import { AxiosPromise } from 'axios';
import { toast } from 'react-toastify';

export const useServiceState = <T = undefined>(
  serviceFunction: (params: any) => AxiosPromise<T>,
): { invoke: any; data: T; error: string; loading: boolean } => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [data, setData] = React.useState<T>({} as T);

  const invoke = React.useCallback(
    (params: any) => {
      const f = async (params: any) => {
        setLoading(true);
        let result;
        try {
          result = await serviceFunction(params);
          setData(result.data);
        } catch (e) {
          const errorMsg = (e.response && e.response.data.message) || e.message || String(e);
          toast.error(errorMsg);
          setError(e);
        } finally {
          setLoading(false);
        }
      };
      f(params);
    },
    [serviceFunction],
  );

  return { invoke, data, error, loading };
};
