import { Form, Row, Select } from 'antd';
import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { CombinedReducers } from '../../../..';
import {
  FundDetailRegionalFocus,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../../../shared/api/models/FundDetails';
import { FundListRecord, UserProfileDetails } from '../../../../shared/api/models/ResponsesTypes';
import { FetchFundParams } from '../../../../shared/api/services/search.service';
import { UniverseSelectTypeEnum } from '../../../../shared/components/UniverseSelect';
import { InitialSearchState } from '../../redux/search.reducers';
import { FormInputItem, FormSelectItem } from './FormItem';

const { Option } = Select;

type Props = {
  data: Partial<FetchFundParams>;
  update: React.Dispatch<Partial<FetchFundParams>>;
};

type StateProps = InitialSearchState & {
  user: UserProfileDetails;
  fundAndUniverseList: FundListRecord[];
  hfrIndexes: FundListRecord[];
};

const PeerGroupSelect: React.FC<{
  value?: number[];
  onChange: (value: number[] | undefined) => void;
  options: FundDetailRegionalFocus[];
}> = ({ onChange, value, options }) => {
  const [searchText, setSearchText] = React.useState<string>();

  const handleSearch = (term: string) => {
    setSearchText(term);
  };

  const filteredOptions = React.useMemo(() => {
    if (!searchText) {
      return options;
    }
    return options.filter(item => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [options, searchText]);

  const existedOptions = value
    ? value.reduce((acc, curr) => {
        const foundPeerGroup = options.find(peerGroup => +peerGroup.id === curr);
        if (foundPeerGroup) {
          return [...acc, foundPeerGroup];
        }
        return acc;
      }, [] as FundDetailRegionalFocus[])
    : [];

  const optionsWithSelectedValue = [
    ...existedOptions,
    ...filteredOptions.filter(item => (value ? !value.includes(+item.id) : true)).slice(0, 30),
  ];

  return (
    <Form.Item label="Peer Group">
      <Select
        mode="multiple"
        onChange={(newValue: number[]) => onChange(newValue)}
        showSearch
        allowClear
        onSearch={input => handleSearch(input)}
        placeholder="Select Peer Group"
        value={value}
        filterOption={false}
      >
        {optionsWithSelectedValue.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name} ({item.id})
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const BasicFilters: React.FC<Props & StateProps> = ({
  data,
  update,
  user,
  fundAndUniverseList,
  hfrIndexes,
  peerGroupOptions,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
}) => {
  const handleUniverseSelect = (selected: string[]) => {
    update({
      fundListId: selected
        .filter(item => item.includes(UniverseSelectTypeEnum.LIST))
        .map(item => Number.parseInt(item.split('-')[1])),
      universeListId: selected
        .filter(item => item.includes(UniverseSelectTypeEnum.HFR))
        .map(item => Number.parseInt(item.split('-')[1])),
      portfolioModelId: selected
        .filter(item => item.includes(UniverseSelectTypeEnum.PORTFOLIO))
        .map(item => Number.parseInt(item.split('-')[1])),
    });
  };

  const handleHFRIndexSelect = (selected: string[]) => {
    update({
      staticIndexIds: selected
        .filter(item => item.toLowerCase().includes('static'))
        .map(item => Number.parseInt(item.split('-')[1])),
    });
    update({
      dynamicIndexIds: selected
        .filter(item => item.toLowerCase().includes('dynamic'))
        .map(item => Number.parseInt(item.split('-')[1])),
    });
  };

  const renderUniverseSelect = () => (
    <FormSelectItem
      isMulti
      label="Universe List"
      placeholder="Select Universe"
      onChange={handleUniverseSelect}
      value={[
        ...(data.universeListId
          ? data.universeListId.map(universeItem => `${UniverseSelectTypeEnum.HFR}-${universeItem}`)
          : []),
        ...(data.fundListId
          ? data.fundListId.map(fundItem => `${UniverseSelectTypeEnum.LIST}-${fundItem}`)
          : []),
        ...(data.portfolioModelId
          ? data.portfolioModelId.map(
              portfolioModelItem => `${UniverseSelectTypeEnum.PORTFOLIO}-${portfolioModelItem}`,
            )
          : []),
      ]}
    >
      {fundAndUniverseList.map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </FormSelectItem>
  );

  const renderHFRIndexSelect = () => {
    const staticIndexes = data.staticIndexIds
      ? data.staticIndexIds.map(universeItem => `static-${universeItem}`)
      : [];
    const dynamicIndexes = data.dynamicIndexIds
      ? data.dynamicIndexIds.map(fundItem => `dynamic-${fundItem}`)
      : [];
    return (
      <FormSelectItem
        label="HFR Index Constituents"
        isMulti
        isDisabled={user.hfrdb_trial}
        onChange={handleHFRIndexSelect}
        placeholder="Select HFR Index"
        value={[...staticIndexes, ...dynamicIndexes]}
      >
        {hfrIndexes.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </FormSelectItem>
    );
  };

  const renderStrategySelect = () => (
    <FormSelectItem
      label="Strategy"
      placeholder="Select Strategy"
      isMulti
      value={data.strategy}
      onChange={value => update({ strategy: value })}
    >
      {strategyOptions.map((item: FundDetailStrategy) => (
        <Option key={item.strategy_code} value={item.strategy_code}>
          {item.strategy_name}
        </Option>
      ))}
    </FormSelectItem>
  );

  const renderSubStrategySelect = () => {
    let filteredSubStrategy: FundDetailSubStrategy[] = [];
    if (data.strategy && data.strategy.length > 0) {
      filteredSubStrategy = subStrategyOptions.filter(
        (subStrategy: FundDetailSubStrategy) =>
          subStrategy.strategy_code &&
          data.strategy &&
          data.strategy.includes(subStrategy.strategy_code),
      );
    } else {
      filteredSubStrategy = subStrategyOptions;
    }
    return (
      <FormSelectItem
        label="Sub Strategy"
        placeholder="Select Sub Strategy"
        isMulti
        value={data.subStrategy}
        onChange={value => update({ subStrategy: value })}
      >
        {filteredSubStrategy.map((item: FundDetailSubStrategy) => {
          const { sub_strategy_code, sub_strategy_name } = item;
          return (
            <Option key={sub_strategy_code} value={sub_strategy_code}>
              {sub_strategy_name}
            </Option>
          );
        })}
      </FormSelectItem>
    );
  };

  const renderRegionalFocusSelect = () => (
    <FormSelectItem
      label="Regional Focus"
      placeholder="Select Regional Focus"
      isMulti
      value={data.regionalFocus}
      onChange={value => update({ regionalFocus: value })}
    >
      {regionalFocusOptions.map((item: FundDetailRegionalFocus) => {
        return (
          <Option key={item.id} value={+item.id}>
            {item.name}
          </Option>
        );
      })}
    </FormSelectItem>
  );

  return (
    <>
      <Row>
        <FormInputItem
          label="Fund Name / Firm Name"
          value={data.fundOrFirmName || ''}
          onChange={value => update({ fundOrFirmName: value as string })}
        />
      </Row>
      <Row>{renderUniverseSelect()}</Row>
      <Row>{renderHFRIndexSelect()}</Row>
      {user.has_peer_group_access && (
        <Row>
          <PeerGroupSelect
            value={data.peerGroupIds}
            options={peerGroupOptions}
            onChange={value => update({ peerGroupIds: value })}
          />
        </Row>
      )}
      <Row>{renderStrategySelect()}</Row>
      <Row>{renderSubStrategySelect()}</Row>
      <Row>{renderRegionalFocusSelect()}</Row>
    </>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    ...state.searchReducer,
    user: state.rootReducer.userProfile,
    fundAndUniverseList: [
      ...state.rootReducer.universeList.map(universeItem => ({
        ...universeItem,
        id: `${UniverseSelectTypeEnum.HFR}-${universeItem.id}`,
        name: `(${UniverseSelectTypeEnum.HFR}) ${universeItem.name}`,
      })),
      ...state.rootReducer.fundList.map(fundItem => ({
        ...fundItem,
        id: `${UniverseSelectTypeEnum.LIST}-${fundItem.id}`,
        name: `(${UniverseSelectTypeEnum.LIST}) ${fundItem.name}`,
      })),
      ...state.modelReducer.portfolioModels.map(portfolioItem => ({
        ...portfolioItem,
        id: `${UniverseSelectTypeEnum.PORTFOLIO}-${portfolioItem.id}`,
        name: `(${UniverseSelectTypeEnum.PORTFOLIO}) ${portfolioItem.name}`,
      })),
    ],
    hfrIndexes: [
      ...state.rootReducer.hfrStaticIndex.map(staticItem => ({
        ...staticItem,
        id: `static-${staticItem.id}`,
        name: staticItem.name,
      })),
      ...state.rootReducer.hfrDynamicIndex.map(dynamicItem => ({
        ...dynamicItem,
        id: `dynamic-${dynamicItem.id}`,
        name: dynamicItem.name,
      })),
    ],
  };
};

export default connect(mapStatesToProps)(BasicFilters);
