import React, { FormEvent, useState } from 'react';
import { Form, Icon, Input, Button, Checkbox, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Colors } from '../../shared/colors';
import Logo from '../../assets/hfr-logo-white.png';
import API, { APIService } from '../../shared/api';
import { useFetchState } from '../../shared/hooks/useFetchState';
import AuthLayout from './AuthLayout';
import CheckCodeLogin from './CheckCodeLogin';

enum LoginSteps {
  LOGIN,
  CHECK_CODE,
}
// Styled
const LoginContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  form {
    max-width: 300px;
    width: 100%;
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const LogoImg = styled.img`
  width: 150px;
  margin-bottom: 50px;
`;

const ErrorMessage = styled.div`
  color: ${Colors.danger};
`;

// Main Component
interface Props extends FormComponentProps {}

const LoginView: React.FC<Props> = ({ form: { getFieldDecorator } }) => {
  const history = useHistory();
  const [step, setStep] = useState(LoginSteps.LOGIN);
  const [email, setEmail] = useState(
    process.env.NODE_ENV === 'development' ? 'hfrdbdev@hfr.com' : '',
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === 'development' ? 'hfrmgmt1' : '',
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [{ loading, error }, { setLoading }] = useFetchState();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    API.authService
      .preLogin(email, password)
      .then(async res => {
        if (res.data.disable_2fa) {
          await APIService.login(email, password, '', rememberMe)
            .then(() => {
              notification.success({ message: 'Welcome!' });
              setLoading(false);
              history.push('/');
            })
            .catch(e => {
              toast.error(e.response.data.message);
              setLoading(false);
            });
          return;
        }
        setStep(LoginSteps.CHECK_CODE);
        notification.success({
          message: `A verification code has been sent to email: ${email}!`,
        });
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        toast.error(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthLayout title="HFR Database">
      <LoginContainer>
        <LogoImg src={Logo} />
        {step === LoginSteps.LOGIN && (
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item validateStatus={error ? 'error' : undefined}>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email is required' }],
                initialValue: email,
              })(
                <Input
                  name={'email'}
                  onChange={e => setEmail(e.target.value)}
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Email"
                />,
              )}
            </Form.Item>
            <Form.Item validateStatus={error ? 'error' : undefined}>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Password is required' }],
                initialValue: password,
              })(
                <Input
                  name={'password'}
                  onChange={e => setPassword(e.target.value)}
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Password"
                />,
              )}
            </Form.Item>
            <ErrorMessage>{error}</ErrorMessage>
            <Form.Item>
              <Checkbox checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}>
                Remember me
              </Checkbox>
              <Link to={'/auth/forgot-password'} style={{ float: 'right' }}>
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{ width: '100%' }}
              >
                Log in
              </Button>
              Or <Link to={'/auth/sign-up'}>Register now!</Link>
            </Form.Item>
          </Form>
        )}
        {step === LoginSteps.CHECK_CODE && (
          <CheckCodeLogin email={email} password={password} rememberMe={rememberMe} />
        )}
      </LoginContainer>
    </AuthLayout>
  );
};

export default Form.create<Props>({ name: 'login-form' })(LoginView);
