import React from 'react';
import * as R from 'ramda';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';
import { notification } from 'antd';
import { UserProfileDetails } from '../api/models/ResponsesTypes';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';

interface StateProps {
  user: UserProfileDetails;
}

interface Props extends RouteComponentProps {
  component: React.FunctionComponent<any>;
  hasAccess: boolean;
  path: string;
}

const ProtectedRoute: React.FunctionComponent<Props & StateProps> = props => {
  if (props.user && props.hasAccess) {
    const routeProps = R.omit(['component', 'render', 'access'], props);
    return <Route {...routeProps} render={props.component} />;
  } else {
    notification.warn({ message: 'Access denied!' });
    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  }
};

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    user: state.rootReducer.userProfile,
  };
};
export default connect(mapStateToProps)(withRouter<Props, any>(ProtectedRoute));
