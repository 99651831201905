import React from 'react';
import { Tabs } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { connect, MapStateToProps, MapDispatchToPropsFunction } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Colors } from '../../shared/colors';
import MainLayout from '../../shared/components/MainLayout';
import { InitialRootReducer, Pane } from '../../redux/reducers/root.reducer';
import FundDetailsView from '../funddetails/FundDetailsView';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import APIService from '../../shared/api';
import { EventCodes } from '../../shared/api/services/event-service';
import StrategyRanking from './StrategyRanking';
import FundRanking from './FundRanking';
import IndexRanking from './IndexRanking';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  .ant-tabs-content {
    padding-left: 16px;
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;
    overflow: hidden;

    .ant-tabs-tabpane-active {
      height: 100%;
    }
  }

  div::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  div::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  .ant-table-body {
    overflow: auto !important;
  }

  .ant-table-hide-scrollbar {
    ::-webkit-scrollbar-track {
      box-shadow: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      outline: 1px solid transparent;
    }
  }
`;

interface StateProps {
  rankingPanes: Pane[];
  activeRankingKey: string | number;
}

interface DispatchProps {
  changePane: (key: string) => void;
  removePane: (key: string) => void;
}

type Props = {} & RouteComponentProps;

/**
 * Rankings Container
 */

const Rankings: React.FC<Props & StateProps & DispatchProps> = ({
  rankingPanes,
  activeRankingKey,
  changePane,
  removePane,
}) => {
  const handleTabChange = (activeKey: string) => {
    changePane(activeKey);
    switch (activeKey) {
      case 'fund':
        APIService.eventService.sendBackendEvent({ code: EventCodes.VIEW_FUND_RANKING });
        break;
      case 'strategy':
        APIService.eventService.sendBackendEvent({ code: EventCodes.VIEW_STRATEGY_RANKING });
        break;
      case 'riskPremia':
        APIService.eventService.sendBackendEvent({ code: EventCodes.VIEW_RISK_PREMIA_RANKING });
        break;
      case 'indexFamily':
        APIService.eventService.sendBackendEvent({ code: EventCodes.VIEW_INDEX_FAMILY });
        break;
    }
  };

  const handleRemovePane = (targetKey: string | React.MouseEvent<HTMLElement>) => {
    if (typeof targetKey === 'string') {
      removePane(targetKey as string);
    }
  };

  return (
    <MainLayout>
      <StyledTabs
        hideAdd
        activeKey={activeRankingKey.toString()}
        type="editable-card"
        onChange={handleTabChange}
        onEdit={handleRemovePane}
      >
        <TabPane tab="Fund Ranking" key="fund" closable={false}>
          <FundRanking />
        </TabPane>
        <TabPane tab="Strategy Ranking" key="strategy" closable={false}>
          <StrategyRanking />
        </TabPane>
        <TabPane tab="Risk Premia Ranking" key="riskPremia" closable={false}>
          <StrategyRanking isRiskPremia />
        </TabPane>
        <TabPane tab="Index Family" key="indexFamily" closable={false}>
          <IndexRanking />
        </TabPane>
        {rankingPanes &&
          rankingPanes.map(pane => (
            <TabPane tab={`${pane.title.substr(0, 16)}..`} key={pane.key}>
              <PerfectScrollbar>
                <FundDetailsView fundId={pane.fundId} />
              </PerfectScrollbar>
            </TabPane>
          ))}
      </StyledTabs>
    </MainLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    rankingPanes: state.rootReducer.rankingPanes,
    activeRankingKey: state.rootReducer.activeRankingKey,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    changePane: (key: string) => {
      dispatch({ type: RootActionEnums.CHANGE_RANKING_PANE, payload: key });
    },
    removePane: (key: string) => {
      dispatch({ type: RootActionEnums.REMOVE_RANKING_PANE, payload: key });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rankings);
