export const Colors = {
  primary: '#1890ff',
  secondary: '#91d5ff',
  danger: '#f5222d',
  text: '#262626',

  gray: '#bfbfbf',
  border: '#e8e8e8',
  white: '#FFF',
};
