import React from 'react';
import { Select } from 'antd';
import { FetchFundParams } from '../../../../../shared/api/services/search.service';
import { NewIndicator } from '../../../../../shared/components/NewIndicator';
import { FormInputItem, FormSelectItem, FormPairValueItem } from '../FormItem';

const { Option } = Select;

export enum FIELD_TYPES {
  INPUT_TEXT = 'INPUT_TEXT',
  DROPDOWN = 'DROPDOWN',
  BOOLEAN = 'BOOLEAN',
  PAIR_VALUE = 'PAIR_VALUE',
  CUSTOM = 'CUSTOM',
}

export type FieldProps = {
  key: keyof FetchFundParams | (keyof FetchFundParams)[];
  name: string;
  isNew?: boolean;
  type: FIELD_TYPES;
  isMultiple?: boolean;
  optionData?: { label: string; value: string }[];
};

export type PairValueProps = {
  name: string;
  atleastKey: keyof FetchFundParams;
  atmostKey: keyof FetchFundParams;
  type: FIELD_TYPES;
};

export type CustomRendererProps = {
  key: (keyof FetchFundParams)[];
  renderer: ((data: Partial<FetchFundParams>) => React.ReactNode) | React.ReactNode;
  type: FIELD_TYPES;
  name: string;
};

const getBooleanString = (val: any) => {
  if (val === true) {
    return 'true';
  } else if (val === false) {
    return 'false';
  } else {
    return val;
  }
};

export const renderFormFields = (
  fields: (FieldProps | PairValueProps | CustomRendererProps)[],
  data: Partial<FetchFundParams>,
  update: React.Dispatch<Partial<FetchFundParams>>,
) =>
  fields.map(item => {
    const fieldItem = item as FieldProps;
    const key = fieldItem.key;

    switch (fieldItem.type) {
      case FIELD_TYPES.CUSTOM:
        const rendererItem = item as CustomRendererProps;
        if (typeof rendererItem.renderer === 'function') {
          return rendererItem.renderer(data);
        }
        return (item as CustomRendererProps).renderer;

      case FIELD_TYPES.PAIR_VALUE:
        const pairValueItem = item as PairValueProps;
        const atleastData = data[pairValueItem.atleastKey];
        const atmostData = data[pairValueItem.atmostKey];

        return (
          <>
            <FormPairValueItem
              style={{ marginRight: 16, width: '31%' }}
              key={pairValueItem.atleastKey}
              label={
                fieldItem.isNew ? (
                  <NewIndicator>{pairValueItem.name}</NewIndicator>
                ) : (
                  pairValueItem.name
                )
              }
              atleastValue={atleastData as number}
              atmostValue={atmostData as number}
              updateAtleastValue={value =>
                update({
                  [pairValueItem.atleastKey]: isNaN(value) ? null : value,
                })
              }
              updateAtmostValue={value =>
                update({
                  [pairValueItem.atmostKey]: isNaN(value) ? null : value,
                })
              }
            />
          </>
        );

      case FIELD_TYPES.INPUT_TEXT:
        return (
          <FormInputItem
            key={fieldItem.key as keyof FetchFundParams}
            style={{ marginRight: 16, width: '31%' }}
            label={fieldItem.isNew ? <NewIndicator>{fieldItem.name}</NewIndicator> : fieldItem.name}
            placeholder={fieldItem.name}
            value={data[key as keyof FetchFundParams] || ''}
            onChange={value => update({ [key as keyof FetchFundParams]: value })}
          />
        );

      case FIELD_TYPES.DROPDOWN:
        return (
          <FormSelectItem
            key={fieldItem.key as keyof FetchFundParams}
            style={{ width: '31%', marginRight: 16 }}
            label={fieldItem.isNew ? <NewIndicator>{fieldItem.name}</NewIndicator> : fieldItem.name}
            isMulti={fieldItem.isMultiple}
            placeholder={fieldItem.name}
            value={data[fieldItem.key as keyof FetchFundParams]}
            onChange={value => update({ [key as keyof FetchFundParams]: value })}
          >
            {fieldItem.optionData &&
              fieldItem.optionData.map((option: { value: string; label: string }) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </FormSelectItem>
        );
      case FIELD_TYPES.BOOLEAN:
        return (
          <FormSelectItem
            key={fieldItem.key as keyof FetchFundParams}
            style={{ width: '31%', marginRight: 16 }}
            label={fieldItem.isNew ? <NewIndicator>{fieldItem.name}</NewIndicator> : fieldItem.name}
            placeholder={fieldItem.name}
            value={getBooleanString(data[fieldItem.key as keyof FetchFundParams])}
            onChange={value =>
              update({ [key as keyof FetchFundParams]: value ? JSON.parse(value) : undefined })
            }
          >
            <Option key={'true'} value={'true'}>
              {'Yes'}
            </Option>
            <Option key={'false'} value={'false'}>
              {'No'}
            </Option>
          </FormSelectItem>
        );
      default:
        return null;
    }
  });
