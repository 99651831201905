import { Card, Col, Row, Statistic, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { MapStateToProps, connect } from 'react-redux';
import Plot from 'react-plotly.js';
import { PlotData, Datum, PlotMouseEvent } from 'plotly.js';
import { SearchStatistic } from '../../../shared/api/models/ResponsesTypes';
import { InitialRootReducer } from '../../../redux/reducers/root.reducer';
import { FundDetailStrategy, FundDetailSubStrategy } from '../../../shared/api/models/FundDetails';
import { Colors } from '../../../shared/colors';

const Wrapper = styled(Row)`
  padding: 1rem;
`;

const StatCol: React.FC = ({ children }) => (
  <Col
    xl={{ span: 6 }}
    lg={{ span: 8 }}
    md={{ span: 8 }}
    sm={{ span: 12 }}
    style={{ marginBottom: 16 }}
  >
    {children}
  </Col>
);
type Props = {
  data: SearchStatistic;
};
type StateProps = {
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
};
const FundStatistics: React.FC<Props & StateProps> = ({
  data,
  strategyOptions,
  subStrategyOptions,
}) => {
  const [selectedStrategyId, setSelectedStrategyId] = React.useState<number>();
  const [selectedStrategyName, setSelectedStrategyName] = React.useState<string>();

  const handleClickStrategy = (eventData: Readonly<PlotMouseEvent>) => {
    const strategyIdList = eventData.points[0].data.labels;
    const strategyNameList = eventData.points[0].data.x;
    setSelectedStrategyId(strategyIdList[eventData.points[0].pointNumber] as number);
    setSelectedStrategyName(strategyNameList[eventData.points[0].pointNumber] as string);
  };

  const graphStrategyData: Partial<PlotData> = Object.keys(data.strategy_distribution).reduce(
    (acc, curr) => {
      if (!curr) {
        return acc;
      }
      const foundStrategy = strategyOptions.find(item => item.strategy_code === +curr);
      if (!foundStrategy) {
        return acc;
      }
      return {
        x: [...(acc.x || []), foundStrategy.strategy_name] as Datum[],
        y: [...(acc.y || []), data.strategy_distribution[+curr]] as Datum[],
        labels: [...(acc.labels || []), foundStrategy.strategy_code] as Datum[],
        type: 'bar',
      };
    },
    {} as Partial<PlotData>,
  );

  const graphSubStrategyData: Partial<PlotData> = Object.keys(
    data.sub_strategy_distribution,
  ).reduce((acc, curr) => {
    if (!curr) {
      return acc;
    }
    const foundSubStrategy = selectedStrategyId
      ? subStrategyOptions.find(
          item => item.sub_strategy_code === +curr && item.strategy_code === selectedStrategyId,
        )
      : subStrategyOptions.find(item => item.sub_strategy_code === +curr);
    if (!foundSubStrategy) {
      return acc;
    }
    return {
      x: [...(acc.x || []), foundSubStrategy.sub_strategy_name] as Datum[],
      y: [...(acc.y || []), data.sub_strategy_distribution[+curr]] as Datum[],
      labels: [...(acc.labels || []), foundSubStrategy.sub_strategy_code] as Datum[],
      type: 'bar',
    };
  }, {} as Partial<PlotData>);

  const graphRegionalFocus: Partial<PlotData> = Object.keys(
    data.regional_focus_distribution,
  ).reduce((acc, curr) => {
    if (!curr) {
      return acc;
    }
    return {
      x: [...(acc.x || []), curr] as Datum[],
      y: [...(acc.y || []), data.regional_focus_distribution[curr]] as Datum[],
      type: 'bar',
    };
  }, {} as Partial<PlotData>);

  return (
    <Wrapper>
      <Card title="Overview" style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <StatCol>
            <Statistic title="# of Funds" value={data.fund_count} />
          </StatCol>
          <StatCol>
            <Statistic title="# of Firms" value={data.firm_count} />
          </StatCol>
        </Row>
      </Card>
      <Card title="# of Funds of Strategy" style={{ marginBottom: 16 }}>
        <Row gutter={16} justify="end">
          {selectedStrategyId && (
            <Button onClick={() => setSelectedStrategyId(undefined)}>Reset Select Strategy</Button>
          )}
          <Plot
            style={{ width: '100%', height: '100%' }}
            data={[
              {
                ...graphStrategyData,
                marker: graphStrategyData.labels
                  ? {
                      color: graphStrategyData.labels.map(id => {
                        if (id === selectedStrategyId) {
                          return Colors.primary;
                        }
                        return 'rgb(31, 119, 180)';
                      }),
                    }
                  : undefined,
              },
            ]}
            layout={{
              yaxis: {
                automargin: true,
              },
              xaxis: {
                automargin: true,
              },
              autosize: true,
            }}
            config={{
              displaylogo: false,
            }}
            onClick={handleClickStrategy}
          />
        </Row>
      </Card>
      <Card title="# of Funds of Sub-Strategy" style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Plot
            style={{ width: '100%', height: '100%' }}
            data={[graphSubStrategyData]}
            layout={{
              title: selectedStrategyName
                ? `Sub-Strategy Of ${selectedStrategyName}`
                : 'All Sub-Strategy',
              yaxis: {
                automargin: true,
              },
              xaxis: {
                automargin: true,
              },
              autosize: true,
            }}
            config={{
              displaylogo: false,
            }}
          />
        </Row>
      </Card>
      <Card title="# of Funds of Regional Focus" style={{ marginBottom: 16 }}>
        <Row gutter={16}>
          <Plot
            style={{ width: '100%', height: '100%' }}
            data={[graphRegionalFocus]}
            layout={{
              yaxis: {
                automargin: true,
              },
              xaxis: {
                automargin: true,
              },
              autosize: true,
            }}
            config={{
              displaylogo: false,
            }}
          />
        </Row>
      </Card>
    </Wrapper>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    strategyOptions: state.searchReducer.strategyOptions,
    subStrategyOptions: state.searchReducer.subStrategyOptions,
  };
};

export default connect(mapStateToProps)(FundStatistics);
