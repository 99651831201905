import { Reducer } from 'redux';
import {
  ExtendFundListRecord,
  FundListRecord,
  UserProfileDetails,
} from '../../shared/api/models/ResponsesTypes';
import { FetchFundParams } from '../../shared/api/services/search.service';
import RootActionEnums from '../actions/root.ActionEnums';

export type Pane = {
  title: string;
  key: string;
  fundId: string;
};

export type FundFirmPane = {
  title: string;
  key: string;
  type: 'firm' | 'fund';
  fundFirmId: string;
};

export type InitialRootReducer = {
  panes: FundFirmPane[];
  rankingPanes: Pane[];
  activeKey: string | number;
  activeRankingKey: string | number;
  searchParams: Partial<FetchFundParams>;
  selectedFunds: string[];
  fundList: ExtendFundListRecord[];
  universeList: ExtendFundListRecord[];
  hfrStaticIndex: FundListRecord[];
  hfrDynamicIndex: FundListRecord[];
  userProfile: UserProfileDetails;
};

const initRootState: InitialRootReducer = {
  panes: [],
  rankingPanes: [],
  activeKey: '1',
  activeRankingKey: 'fund',
  searchParams: {},
  selectedFunds: [],
  fundList: [],
  universeList: [],
  hfrStaticIndex: [],
  hfrDynamicIndex: [],
  userProfile: {
    first_name: '',
    last_name: '',
    export_ror_fund_limit: 0,
    has_export_search_access: false,
    has_contact_info_access: false,
    hfrdb_subscriber: false,
    has_files_access: false,
    historical_ror_aum_access: false,
    index_scope_access: false,
    hfrdb_expiration_date: '',
    is_web_only: false,
    hfrdb_trial: false,
    company: '',
    job_title: '',
    business_phone: '',
    address: '',
    address2: '',
    city: '',
    postal_code: '',
    country: '',
    state: '',
    is_staff: false,
    email: '',
    has_manager_link_access: false,
    hfrml_internal_user: false,
    has_investor_db_access: false,
    has_peer_group_access: false,
  },
};

const rootReducer: Reducer<InitialRootReducer, any> = (
  state: InitialRootReducer = initRootState,
  { type, payload },
) => {
  switch (type) {
    case RootActionEnums.RESET_STORE: {
      return initRootState;
    }
    case RootActionEnums.REMOVE_PANE: {
      // remove the pane from view
      const panes = state.panes.filter(pane => pane.key !== payload);

      // Setting the activeKey to 1 (search funds Pane) if removed pane was previously active Pane
      let { activeKey } = state;
      activeKey = activeKey === payload ? 1 : activeKey;
      return {
        ...state,
        panes,
        activeKey,
      };
    }
    case RootActionEnums.REMOVE_RANKING_PANE: {
      return {
        ...state,
        // remove the pane from view
        rankingPanes: state.rankingPanes.filter(pane => pane.key !== payload),
        // Setting the activeKey to 1 (search funds Pane) if removed pane was previously active Pane
        activeRankingKey: state.activeRankingKey === payload ? 'fund' : state.activeRankingKey,
      };
    }
    case RootActionEnums.ADD_PANE: {
      //check if pane is already opened or not
      let isPaneOpenArr = state.panes.filter(pane => pane.key === payload.key);
      // if fund is already opened in tab, set focus to it
      if (isPaneOpenArr.length) {
        return {
          ...state,
          activeKey: payload.key,
        };
      } else if (state.panes.length < 11) {
        const panes = [
          ...state.panes,
          {
            title: payload.title,
            key: payload.key,
            type: payload.type,
            fundFirmId: payload.fundFirmId,
          },
        ];
        return {
          ...state,
          panes,
          activeKey: payload.key,
        };
      } else {
        return state;
      }
    }
    case RootActionEnums.UPDATE_PANE_TITLE: {
      const { key, title } = payload;
      const updatedPaneIndex = state.panes.findIndex(item => item.fundFirmId === key);
      if (updatedPaneIndex === -1) {
        return state;
      }
      return {
        ...state,
        panes: [
          ...state.panes.slice(0, updatedPaneIndex),
          {
            ...state.panes[updatedPaneIndex],
            title,
          },
          ...state.panes.slice(updatedPaneIndex + 1, state.panes.length),
        ],
      };
    }
    case RootActionEnums.ADD_RANKING_PANE: {
      const isPaneOpen = state.rankingPanes.some(pane => pane.key === payload.fund_id);
      if (isPaneOpen) {
        return {
          ...state,
          activeRankingKey: payload.fund_id,
        };
      } else if (state.rankingPanes.length < 8) {
        const rankingPanes = [
          ...state.rankingPanes,
          {
            title: payload.fund_name,
            key: payload.fund_id,
            fundId: payload.fund_id,
          },
        ];
        return {
          ...state,
          rankingPanes,
          activeRankingKey: payload.fund_id,
        };
      }
      return state;
    }
    case RootActionEnums.CHANGE_PANE: {
      return {
        ...state,
        activeKey: payload,
      };
    }
    case RootActionEnums.CHANGE_RANKING_PANE: {
      return {
        ...state,
        activeRankingKey: payload,
      };
    }
    case RootActionEnums.UPDATE_SELECTED_FUNDS: {
      return {
        ...state,
        selectedFunds: payload,
      };
    }
    case RootActionEnums.UPDATE_FUND_TABLE_COLUMNS: {
      return {
        ...state,
        fundColumns: payload,
      };
    }
    case RootActionEnums.UPDATE_INVESTOR_TABLE_COLUMNS: {
      return {
        ...state,
        investorColumns: payload,
      };
    }
    case RootActionEnums.FETCH_FUND_LIST_DATA: {
      return {
        ...state,
        fundList: payload.data,
      };
    }
    case RootActionEnums.FETCH_UNIVERSE_LIST_DATA: {
      return {
        ...state,
        universeList: payload.data,
      };
    }
    case RootActionEnums.FETCH_STATIC_HFR_INDEX_DATA: {
      return {
        ...state,
        hfrStaticIndex: payload.data,
      };
    }
    case RootActionEnums.FETCH_DYNAMIC_HFR_INDEX_DATA: {
      return {
        ...state,
        hfrDynamicIndex: payload.data,
      };
    }
    case RootActionEnums.UPDATE_USER_PROFILE: {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default rootReducer;

export { initRootState };
