import * as React from 'react';
import { useState } from 'react';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import _ from 'lodash';
import { Modal, Select } from 'antd';
import { toast } from 'react-toastify';
import { CombinedReducers } from '../../../index';
import { PortfolioModelRecord } from '../../../shared/api/models/ResponsesTypes';
import APIService from '../../../shared/api';
import { PortfolioPane } from '../redux/model-reducers';
import ModelActionEnums from '../redux/model-action-enums';

const { Option } = Select;

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  modelId: number;
}

interface DispatchProps {
  addPane: (data: PortfolioPane) => void;
  updateSelectedFunds: (selectedFunds: string[]) => void;
}

interface StateProps {
  portfolioModels: PortfolioModelRecord[];
  selectedFunds: string[];
}

/**
 *  Modal used to Add the selected funds in another Portfolio model
 * @param isOpen
 * @param setOpen - handler to close the modal
 * @param portfolioModels - list of available portfolio models
 * @param modelId - current portfolio-model id
 * @param selectedFunds - list of selected funds
 * @param addPane - dispatcher to open new pane
 * @param updateSelectedFunds - dispatcher to update the selected funds
 */

const AddFundToPortfolioModal: React.FC<Props & DispatchProps & StateProps> = ({
  isOpen,
  setOpen,
  portfolioModels,
  modelId,
  selectedFunds,
  addPane,
  updateSelectedFunds,
}) => {
  const [selectedPortfolioModel, setSelectedPortfolioModel] = useState<{
    key: string;
    label: string;
  }>();

  const addToPortfolioModels = () => {
    if (!selectedPortfolioModel) {
      toast.error('Select one of the Portfolio Model');
      return;
    } else {
      // data contains selected funds with their initialized weight as 0
      const data: Array<
        { fund_id: string; weight: number } | { user_fund_id: number; weight: number }
      > = selectedFunds.map((fund_id: string) => {
        return fund_id.split('-')[1] === 'USER_FUND'
          ? { user_fund_id: +fund_id.split('-')[0], weight: 0 }
          : { fund_id, weight: 0 };
      });
      APIService.portfolioModelService
        .updatePortfolioModel({
          modelId: selectedPortfolioModel.key,
          data: { add: data },
        })
        .then(() => {
          // clear the selected funds
          updateSelectedFunds([]);
          // Add and open-up the portfolio-model in new pane
          addPane({
            key: `${selectedPortfolioModel.key}`,
            portfolioModelId: selectedPortfolioModel.key,
            portfolioModelName: selectedPortfolioModel.label,
          });
          toast.success('Fund(s) Added to the Portfolio Model!');
        })
        .catch(() => {
          toast.error('Fund(s) already present in the Model!');
        });
      setOpen(false);
    }
  };

  const renderPortfolioModelOptions = () => {
    if (!_.isEmpty(portfolioModels)) {
      return portfolioModels.map((item: PortfolioModelRecord) => {
        // filter the current model
        return item.id !== modelId ? (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ) : null;
      });
    } else {
      return null;
    }
  };

  return (
    <Modal
      title="Add Funds To Portfolio Model"
      visible={isOpen}
      okText="Add Fund"
      onOk={addToPortfolioModels}
      onCancel={() => setOpen(false)}
    >
      <Select
        labelInValue
        placeholder="Select Portfolio Models"
        style={{ width: '100%' }}
        showSearch
        value={selectedPortfolioModel}
        onChange={(value: { key: string; label: string }) => {
          setSelectedPortfolioModel(value);
        }}
        filterOption={(input, option) => {
          if (!option || !option.props || !option.props.children) {
            return false;
          }
          return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {renderPortfolioModelOptions()}
      </Select>
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    portfolioModels: state.modelReducer.portfolioModels,
    selectedFunds: state.modelReducer.selectedFunds,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    addPane: (data: PortfolioPane) => {
      dispatch({ type: ModelActionEnums.ADD_PORTFOLIO_PANE, payload: data });
    },
    updateSelectedFunds: (selectedFunds: string[]) => {
      dispatch({ type: ModelActionEnums.UPDATE_SELECTED_FUNDS, payload: { selectedFunds } });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFundToPortfolioModal);
