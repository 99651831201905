import React, { FormEvent } from 'react';
import Papa from 'papaparse';
import { Form, notification, Row, Col, Select, Button, Input, Upload, Icon, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { MapStateToProps, connect } from 'react-redux';
import uuid from 'uuid';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import APIService from '../../shared/api';
import UserFundDataTable, { UserFundTableData } from '../home/UserFund/UserFundDataTable';
import { UserFundRecord, UserFundDetails } from '../../shared/api/models/ResponsesTypes';
import { useServiceState } from '../../shared/hooks/useServiceState';
import { CombinedReducers } from '../..';
import {
  FundDetailStrategy,
  FundDetailSubStrategy,
  FundDetailRegionalFocus,
} from '../../shared/api/models/FundDetails';
import { diffTwoArrays } from '../../shared/utils/utils';
import GenUtil from '../../shared/utils/gen-util';
import PeerGroupForm from '../funddetails/components/PeerGroup/PeerGroupForm';

const Desc = styled('div')`
  margin-left: 10px;
`;

const Download = styled(Link)`
  text-decoration: underline;
`;

type Props = {
  isViewOnly: boolean;
  selectedUserFund?: UserFundRecord;
  onFundListUpdated: () => void;
  onRequestClose: () => void;
} & FormComponentProps;

type StateProps = {
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
  currencyOptions: string[];
};

const UserFundForm: React.FC<Props & StateProps> = ({
  isViewOnly,
  form,
  onFundListUpdated,
  onRequestClose,
  selectedUserFund,
  strategyOptions,
  subStrategyOptions,
  regionalFocusOptions,
  currencyOptions,
}) => {
  const { getFieldDecorator } = form;
  const originalData = React.useRef<UserFundTableData[]>([]);

  const [dataSource, setDataSource] = React.useState<UserFundTableData[]>([]);

  const {
    data: fundDetailData,
    invoke: invokeFetchFundDetails,
    loading: fundDetailLoading,
  } = useServiceState<UserFundDetails>(APIService.userFundService.fetchUserFundDetails);
  const [filteredSubStrategy, setFilteredSubStrategy] = React.useState<FundDetailSubStrategy[]>([]);

  // Load initial data of selected user fund
  React.useEffect(() => {
    if (selectedUserFund) {
      invokeFetchFundDetails(selectedUserFund.id);
    }
  }, [invokeFetchFundDetails, selectedUserFund]);

  // Filter sub-strategy whenever strategy changes
  React.useEffect(() => {
    const strategyFromForm = form.getFieldValue('strategies');
    if (strategyFromForm) {
      setFilteredSubStrategy(
        subStrategyOptions.filter(
          (subStrategy: FundDetailSubStrategy) =>
            subStrategy.strategy_code && strategyFromForm === subStrategy.strategy_code,
        ),
      );
    } else {
      setFilteredSubStrategy(subStrategyOptions);
    }
  }, [form, subStrategyOptions, setFilteredSubStrategy]);

  // Prepare the selected user fund's data for the table
  React.useEffect(() => {
    if (!fundDetailData || !fundDetailData.user_fund_data) {
      return;
    }
    const transformedDataSource = fundDetailData.user_fund_data.map(item => ({
      id: item.id,
      key: item.yyyymmm,
      date: GenUtil.getFormattedYearAndMonth(item.yyyymmm, 'YYYY0MM'),
      aum: item.cur_asset_value.toString(),
      ror: item.cur_ror_value.toString(),
      // currency: '$',
    }));
    originalData.current = transformedDataSource;
    setDataSource(transformedDataSource);
  }, [fundDetailData]);

  const handleCreateUserFund = async (values: any) => {
    try {
      await APIService.userFundService.createNewUserFund({
        name: values.name,
        strategyCode: values.strategies,
        subStrategyCode: values.subStrategy,
        regionInvFocus: +values.regionalFocus,
        currency_code: values.currencies,
        data: dataSource.map(userFundItem => ({
          yyyymmm: moment(userFundItem.date, 'YYYY-MM').format('YYYY0MM'),
          cur_ror_value: Number.parseFloat(userFundItem.ror),
          cur_asset_value: Number.parseFloat(userFundItem.aum),
        })),
      });
      notification.success({ message: 'New user fund has been created!', placement: 'bottomLeft' });
      onRequestClose();
      onFundListUpdated();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Cannot create user fund! Please try again later',
        placement: 'bottomLeft',
      });
    }
  };

  const handleUpdateUserFund = async (data: any) => {
    if (!selectedUserFund) {
      return;
    }
    try {
      const { added, deleted } = diffTwoArrays(originalData.current, dataSource, 'date');
      await APIService.userFundService.updateUserFund(selectedUserFund.id.toString(), {
        name: data.name,
        strategyCode: data.strategies,
        subStrategyCode: data.subStrategy,
        regionInvFocus: +data.regionalFocus,
        currency_code: data.currencies,
        add: added.map(userFundItem => ({
          yyyymmm: moment(userFundItem.date, 'YYYY-MM').format('YYYY0MM'),
          cur_ror_value: Number.parseFloat(userFundItem.ror),
          cur_asset_value: Number.parseFloat(userFundItem.aum),
        })),
        delete: deleted.map(item => item.id!),
      });
      notification.success({ message: 'User fund data updated!', placement: 'bottomLeft' });
      onRequestClose();
      onFundListUpdated();
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Cannot update user fund! Please try again later',
        placement: 'bottomLeft',
      });
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (!selectedUserFund) {
          handleCreateUserFund(values);
          return;
        }
        handleUpdateUserFund(values);
      }
    });
  };

  const handleStrategyChange = () => {
    form.setFieldsValue({ subStrategy: undefined });
  };

  return (
    <Tabs defaultActiveKey="1" type="card" tabBarGutter={8}>
      <Tabs.TabPane tab="Profile" key="1">
        <Form onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col span={4}>
              <Form.Item label="Fund Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Fund name is required!' }],
                  initialValue: selectedUserFund && selectedUserFund.name,
                })(<Input placeholder="Enter Fund Name" disabled={isViewOnly} />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Strategy">
                {getFieldDecorator('strategies', {
                  initialValue: selectedUserFund ? selectedUserFund.strategy_code : undefined,
                })(
                  <Select
                    showSearch
                    allowClear
                    disabled={isViewOnly}
                    placeholder="Select Strategy"
                    optionFilterProp="children"
                    onChange={handleStrategyChange}
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {strategyOptions.map((item: FundDetailStrategy) => {
                      const { strategy_code, strategy_name } = item;
                      return (
                        <Select.Option key={strategy_code} value={strategy_code}>
                          {strategy_name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Sub Strategy">
                {getFieldDecorator('subStrategy', {
                  initialValue: selectedUserFund ? selectedUserFund.sub_strategy_code : undefined,
                })(
                  <Select
                    placeholder="Select Sub Strategy"
                    showSearch
                    allowClear
                    disabled={isViewOnly}
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {filteredSubStrategy.map((item: FundDetailSubStrategy) => {
                      const { sub_strategy_code, sub_strategy_name } = item;
                      return (
                        <Select.Option key={sub_strategy_code} value={sub_strategy_code}>
                          {sub_strategy_name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Regional Focus">
                {getFieldDecorator('regionalFocus', {
                  initialValue: selectedUserFund ? selectedUserFund.region_inv_focus : undefined,
                })(
                  <Select
                    optionFilterProp="children"
                    allowClear
                    showSearch
                    disabled={isViewOnly}
                    placeholder="Select Regional Focus"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {regionalFocusOptions.map((item: FundDetailRegionalFocus) => {
                      const idAsNumber = Number.parseInt(item.id);
                      return (
                        <Select.Option key={idAsNumber} value={idAsNumber}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Currency">
                {getFieldDecorator('currencies', {
                  initialValue: selectedUserFund ? selectedUserFund.currency_code : undefined,
                })(
                  <Select
                    optionFilterProp="children"
                    allowClear
                    showSearch
                    disabled={isViewOnly}
                    placeholder="Select Currency"
                    filterOption={(input, option: any) =>
                      option.props &&
                      option.props.children &&
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {currencyOptions.map((item: string) => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>
          {!isViewOnly && (
            <Row type="flex" align="middle">
              <Upload
                accept=".txt, .csv"
                showUploadList={false}
                beforeUpload={file => {
                  Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: results => {
                      setDataSource([
                        ...dataSource,
                        ...results.data.map(item => ({
                          ...item,
                          key: `${uuid()}-${item.date}`,
                        })),
                      ]);
                    },
                  });
                  // Prevent upload
                  return false;
                }}
              >
                <Button style={{ marginBottom: 8 }}>
                  <Icon type="upload" /> Select .csv file
                </Button>
              </Upload>
              <Desc>
                You can upload your custom fund data to be used with HFR existing data.{' '}
                <Download to="/fund-template.csv" target="_blank">
                  Download
                </Download>{' '}
                our sample CSV for reference.
              </Desc>
            </Row>
          )}
          <UserFundDataTable
            isViewOnly={isViewOnly}
            data={dataSource}
            onDataChange={setDataSource}
            loading={fundDetailLoading}
          />
          {!isViewOnly && (
            <Row style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
              <Form.Item>
                <Button
                  style={{ marginRight: 8 }}
                  type="primary"
                  htmlType="submit"
                  disabled={isViewOnly}
                >
                  {selectedUserFund ? 'Save' : 'Create'}
                </Button>
                <Button type="default" onClick={onRequestClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Row>
          )}
        </Form>
      </Tabs.TabPane>
      {isViewOnly && selectedUserFund && (
        <Tabs.TabPane tab="Peer Group Analysis" key="2">
          <PeerGroupForm
            fundId={selectedUserFund.id.toString()}
            userFundData={selectedUserFund}
            endDateOptions={
              fundDetailData && fundDetailData.user_fund_data
                ? _.orderBy(fundDetailData.user_fund_data, ['yyyymmm'], ['desc']).map(
                    d => `${d.yyyymmm.substring(0, 4)}-${d.yyyymmm.substring(5, 7)}`,
                  )
                : []
            }
            fundAndBenchmarkService={APIService.userFundService.fetchFundAndBenchmark}
            universeStatsService={APIService.userFundService.fetchUniverseStats}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  strategyOptions: state.searchReducer.strategyOptions,
  subStrategyOptions: state.searchReducer.subStrategyOptions,
  regionalFocusOptions: state.searchReducer.regionalFocusOptions,
  currencyOptions: state.searchReducer.fundAssetCurrencyOptions,
});

export default Form.create<Props>({ name: 'UserFundForm' })(connect(mapStateToProps)(UserFundForm));
