import { Pagination, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';
import { HistoricalDataRecord } from '../../../shared/api/services/historic-ror-aum-service';
import GenUtil from '../../../shared/utils/gen-util';
import FundDetailsUtils from '../../funddetails/fund-details-utils';
import { HistoricSearchType } from '../HistoricRorAumView';

const Meta = styled('div')`
  display: flex;
  margin-top: 12px;
  align-items: center;
  justify-content: flex-end;
  span {
    margin-right: 16px;
    font-weight: 500;
  }
`;

type TableDataType = HistoricalDataRecord & {
  historical_data: {
    [key: string]: number;
  };
};

interface Props {
  tableData: HistoricalDataRecord[];
  historicSearchType: HistoricSearchType;
  page: number;
  setPage: (page: number) => void;
  totalPages: number;
  totalCount: number;
  minDate: string;
  maxDate: string;
}
// Base columns for all three type of historic data
const COLUMNS: ColumnProps<HistoricalDataRecord>[] = [
  {
    title: 'Fund Name',
    dataIndex: 'fund_name',
    key: 'fund_name',
    width: 200,
    fixed: 'left',
  },
  {
    title: 'Firm Name',
    dataIndex: 'firm_name',
    width: 200,
    key: 'firm_name',
    fixed: 'left',
  },
  {
    title: 'Main Strategy',
    dataIndex: 'strategy_name',
    width: 200,
    key: 'strategy_name',
  },
  {
    title: 'Sub-Strategy',
    dataIndex: 'sub_strategy_name',
    width: 200,
    key: 'sub_strategy_name',
  },
  {
    title: 'Regional Focus',
    dataIndex: 'regional_focus',
    width: 200,
    key: 'regional_focus',
  },
  {
    title: 'Inception Date',
    dataIndex: 'inception_date',
    width: 140,
    key: 'inception_date',
    render: text => GenUtil.getFormattedYearMonthAndDay(text),
  },
  {
    title: 'Fund ID',
    dataIndex: 'fund_id',
    width: 100,
    key: 'fund_id',
    render: (fundId, record) => {
      if (fundId) {
        return fundId;
      }
      return record.user_fund_id;
    },
  },
];

const transformTableData = (originalData: HistoricalDataRecord[]) => {
  return originalData.reduce((acc, curr) => {
    const transformedHistoricalData = curr.historical_data.reduce((acc1, curr1) => {
      return {
        ...acc1,
        [curr1.date]: GenUtil.digitWithMaxPrecision(curr1.value),
      };
    }, {});
    return [
      ...acc,
      {
        ...curr,
        historical_data: transformedHistoricalData as any, // todo: fix type later
      },
    ];
  }, [] as TableDataType[]);
};

const resolveHistoricalDataColumns = (
  minDate: string,
  maxDate: string,
  historicSearchType: HistoricSearchType,
) => {
  const dateRanges = FundDetailsUtils._getDatesRangeList(
    minDate,
    maxDate,
    historicSearchType === HistoricSearchType.YEARLYROR ? 'year' : 'month',
  );
  return dateRanges.map(item => {
    return {
      title: item,
      dataIndex: `historical_data.${item}`,
      key: `historical_data.${item}`,
      width: 100,
      align: 'right' as 'right',
    };
  });
};

/**
 * Table showing the data for historic ror-aum funds
 * @param tableData
 * @param historicSearchType
 */
const HistoricRorAumTable: React.FC<Props> = ({
  tableData,
  historicSearchType,
  page,
  setPage,
  totalPages,
  minDate,
  maxDate,
  totalCount,
}) => {
  tableData = tableData || [];

  const historicalDataColumns = React.useMemo(() => {
    return totalPages > 0 ? resolveHistoricalDataColumns(minDate, maxDate, historicSearchType) : [];
  }, [historicSearchType, minDate, maxDate, totalPages]);

  return (
    <>
      <Table
        bordered
        rowKey="fund_id"
        pagination={false}
        dataSource={transformTableData(tableData)}
        scroll={{ x: 'calc(100% - 420px)', y: 'calc(100vh - 445px)' }}
        columns={[...COLUMNS, ...historicalDataColumns]}
      />
      <Meta>
        <Pagination
          onChange={(p: number) => {
            setPage(p);
          }}
          showTotal={(total, range) => (total ? `${range[0]}-${range[1]} of ${total} items` : '')}
          total={totalCount}
          current={page}
          pageSize={20}
        />
      </Meta>
    </>
  );
};

export default React.memo(HistoricRorAumTable);
