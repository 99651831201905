import {
  FundDetailRegionalFocus,
  FundDetailRegionalFocusCountry,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../../shared/api/models/FundDetails';
import {
  BenchmarkRecord,
  CodeRecord,
  ValueRecord,
  OptionType,
  EsgOptionType,
  MinorityType,
  FundDropdownsType,
} from '../../../shared/api/models/ResponsesTypes';
import SearchActionEnums from './search.ActionEnums';
export interface InitialSearchState {
  searchParams: any;
  strategyOptions: FundDetailStrategy[];
  subStrategyOptions: FundDetailSubStrategy[];
  regionalFocusOptions: FundDetailRegionalFocus[];
  regionalFocusCountryOptions: FundDetailRegionalFocusCountry[];
  liquidAltTypeOptions: CodeRecord[];
  liquidAltRegionOptions: CodeRecord[];
  max12B1FeeOptions: ValueRecord[];
  maxFrontFeeOptions: ValueRecord[];
  maxDeferredFeeOptions: ValueRecord[];
  productClassOptions: CodeRecord[];
  benchmarkOptions: BenchmarkRecord[];
  advanceNoticeOptions: string[];
  minInvestmentCurrOptions: string[];
  reportingOptions: string[];
  reportingStyleOptions: string[];
  returnsCurrencyOptions: string[];
  redemptionOptions: string[];
  subscriptionsOptions: string[];
  firmAssetCurrencyOptions: string[];
  fundAssetCurrencyOptions: string[];
  countryOptions: OptionType[];
  stateOptions: string[];
  rorDateRangeOptions: string[];
  peerGroupOptions: FundDetailRegionalFocus[];
  nonSecRegAuthorities: OptionType[];
  nonSecCountryOptions: OptionType[];
  esgStrategyOptions: EsgOptionType[];
  esgSubStrategyOptions: EsgOptionType[];
  minorityTypeOptions: MinorityType[];
  fundDropdowns: FundDropdownsType;
}
export const initSearchState: InitialSearchState = {
  searchParams: {},
  strategyOptions: [],
  subStrategyOptions: [],
  regionalFocusOptions: [],
  regionalFocusCountryOptions: [],
  liquidAltRegionOptions: [],
  liquidAltTypeOptions: [],
  max12B1FeeOptions: [],
  maxFrontFeeOptions: [],
  maxDeferredFeeOptions: [],
  productClassOptions: [],
  benchmarkOptions: [],
  advanceNoticeOptions: [],
  minInvestmentCurrOptions: [],
  reportingOptions: [],
  reportingStyleOptions: [],
  returnsCurrencyOptions: [],
  redemptionOptions: [],
  subscriptionsOptions: [],
  firmAssetCurrencyOptions: [],
  fundAssetCurrencyOptions: [],
  countryOptions: [],
  stateOptions: [],
  rorDateRangeOptions: [],
  peerGroupOptions: [],
  nonSecRegAuthorities: [],
  nonSecCountryOptions: [],
  esgStrategyOptions: [],
  esgSubStrategyOptions: [],
  minorityTypeOptions: [],
  fundDropdowns: {},
};

export const searchReducer = (
  state = initSearchState,
  { type, payload }: { type: SearchActionEnums; payload: any },
): InitialSearchState => {
  switch (type) {
    case SearchActionEnums.RESET_STORE: {
      return initSearchState;
    }
    case SearchActionEnums.UPDATE_FORM_SEARCH_PARAMS: {
      return { ...state, searchParams: payload };
    }

    case SearchActionEnums.UPDATE_STRATEGY_OPTIONS: {
      return { ...state, strategyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_SUB_STRATEGY_OPTIONS: {
      return { ...state, subStrategyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_REGIONAL_FOCUS_OPTIONS: {
      return { ...state, regionalFocusOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_REGIONAL_FOCUS_COUNTRY_OPTIONS: {
      return { ...state, regionalFocusCountryOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_LIQUID_ALT_TYPE_OPTIONS: {
      return { ...state, liquidAltTypeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_LIQUID_ALT_REGION_OPTIONS: {
      return { ...state, liquidAltRegionOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_MAX_12B1_FEE_OPTIONS: {
      return { ...state, max12B1FeeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_MAX_FRONT_FEE_OPTIONS: {
      return { ...state, maxFrontFeeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_MAX_DEFERRED_FEE_OPTIONS: {
      return { ...state, maxDeferredFeeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_PRODUCT_CLASS_OPTIONS: {
      return { ...state, productClassOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_BENCHMARK_OPTIONS: {
      return { ...state, benchmarkOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_ADVANCE_NOTICE_OPTIONS: {
      return { ...state, advanceNoticeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_MIN_INVESTMENT_CURR_OPTIONS: {
      return { ...state, minInvestmentCurrOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_REPORTING_OPTIONS: {
      return { ...state, reportingOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_REPORTING_STYLE_OPTIONS: {
      return { ...state, reportingStyleOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_RETURNS_CURRENCY_OPTIONS: {
      return { ...state, returnsCurrencyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_REDEMPTION_OPTIONS: {
      return { ...state, redemptionOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_SUBSCRIPTIONS_OPTIONS: {
      return { ...state, subscriptionsOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_FIRM_ASSET_CURRENCY_OPTIONS: {
      return { ...state, firmAssetCurrencyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_FUND_ASSET_CURRENCY_OPTIONS: {
      return { ...state, fundAssetCurrencyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_COUNTRY_OPTIONS: {
      return { ...state, countryOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_STATE_OPTIONS: {
      return { ...state, stateOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_ROR_DATE_RANGE_OPTIONS: {
      return { ...state, rorDateRangeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_PEER_GROUP_OPTIONS: {
      return { ...state, peerGroupOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_NON_SEC_REG_AUTHORITIES_OPTIONS: {
      return { ...state, nonSecRegAuthorities: payload.data };
    }

    case SearchActionEnums.UPDATE_NON_SEC_COUNTRY_OPTIONS: {
      return { ...state, nonSecCountryOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_ESG_STRATEGIES: {
      return { ...state, esgStrategyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_ESG_SUB_STRATEGIES: {
      return { ...state, esgSubStrategyOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_MINORITY_TYPES: {
      return { ...state, minorityTypeOptions: payload.data };
    }

    case SearchActionEnums.UPDATE_FUND_DROPDOWNS: {
      return { ...state, fundDropdowns: payload.data };
    }

    default: {
      return state;
    }
  }
};
