import React, { FormEvent } from 'react';
import { Form, Icon, Input, Button, Checkbox, Divider, notification, Select } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import { FormComponentProps } from 'antd/lib/form';
import API, { APIService } from '../../../shared/api';
import { States } from '../../../shared/states';
import { useServiceState } from '../../../shared/hooks/useServiceState';

const SignUpContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 420px;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
    &:last-child {
      margin: 0;
    }
  }
`;

const Mask = styled.div`
  width: 100%;
  height: 50px;
  background: white;
  position: relative;
  margin-top: -50px;
  z-index: 10;
`;

interface Props extends FormComponentProps {
  verificationCode: string;
  email: string;
  nextStep: () => void;
}

const SignUpForm: React.FC<Props> = ({ form, email, verificationCode, nextStep }) => {
  const { getFieldDecorator, getFieldError } = form;
  // componentDidMount() {
  //   this.subscribe(authStore, SIGN_UP_ERROR_EVENT, this.onError);
  //   this.subscribe(authStore, SIGN_UP_EVENT, () => {
  //     toast.success('Welcome!');
  //     this.props.history.push('/log-in');
  //   });
  // }

  const [loading, setLoading] = React.useState(false);

  const {
    data: countriesData,
    loading: countriesLoading,
    invoke: invokeFetchCountries,
  } = useServiceState(APIService.fundService.fetchAllCountries);

  React.useEffect(() => {
    invokeFetchCountries();
  }, [invokeFetchCountries]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        delete values.passwordConfirm;
        API.authService
          .signUp({ ...values, code: verificationCode })
          .then(() => {
            APIService.eventService.sendSignupEvent();
            notification.success({ message: 'Account has been created!' });
            nextStep();
          })
          .catch(err => {
            console.error(err);
            notification.error({ message: 'Something went wrong!' });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const compareToFirstPassword = (_: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback('Password does not match!');
    } else {
      callback();
    }
  };

  const checkCheckBox = (message: string) => (_: any, value: boolean, callback: any) => {
    if (!value) {
      callback(message);
    } else {
      callback();
    }
  };

  const getCountryOptions = () => {
    if (_.isEmpty(countriesData)) {
      return;
    } else {
      const countryCodes = Object.keys(countriesData).filter(
        code => !['US', 'GB', 'CA'].includes(code),
      );
      countryCodes.unshift('US', 'GB', 'CA');
      return countryCodes.map((countryKey: any) => {
        return (
          <Select.Option key={countryKey} value={countryKey}>
            {countriesData[countryKey]}
          </Select.Option>
        );
      });
    }
  };

  const getStateOptions = () => {
    const statesOptions = [];
    for (const stateKey in States) {
      statesOptions.push(
        <Select.Option key={stateKey} value={States[stateKey]}>
          {States[stateKey]}
        </Select.Option>,
      );
    }
    return statesOptions;
  };

  return (
    <SignUpContainer>
      <Form onSubmit={handleSubmit} className="login-form" layout={'horizontal'}>
        {/* FirstName */}
        <Divider orientation={'left'}>Personal Information</Divider>

        <Inline>
          <Form.Item>
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: 'First Name is required' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="First Name"
              />,
            )}
          </Form.Item>
          {/* LastName */}
          <Form.Item>
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Last Name is required' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Last Name"
              />,
            )}
          </Form.Item>
        </Inline>
        {/* Email */}
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Email must be valid' },
            ],
            initialValue: email,
          })(
            <Input
              disabled
              formNoValidate={true}
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="email"
              placeholder="Email"
            />,
          )}
        </Form.Item>
        {/* Password */}
        <Inline>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                { required: true, message: 'Password is required' },
                {
                  pattern: RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
                  message: (
                    <>
                      <Icon type="question-circle" theme="filled" style={{ marginRight: 5 }} />
                      <span>Password is invalid</span>
                    </>
                  ),
                },
              ],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Choose Password"
              />,
            )}
          </Form.Item>
          {/* Password */}
          <Form.Item hasFeedback>
            {getFieldDecorator('passwordConfirm', {
              rules: [
                { required: true, message: 'Confirm password is required' },
                { validator: compareToFirstPassword },
              ],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Confirm Password"
              />,
            )}
          </Form.Item>
        </Inline>
        {!!getFieldError('password') && (
          <Inline>
            <span>
              Make sure it's at least 8 characters including 1 number, 1 lowercase, 1 uppercase
              letter and 1 special character.
            </span>
          </Inline>
        )}
        {/* Company */}
        <Divider orientation={'left'}>Company Information</Divider>
        <Form.Item>
          {getFieldDecorator('company', {
            rules: [{ required: true, message: 'Company name is required' }],
          })(
            <Input
              prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Company"
            />,
          )}
        </Form.Item>
        {/* JobTitle */}
        <Form.Item>
          {getFieldDecorator('jobTitle', {
            rules: [{ required: true, message: 'Job Title is required' }],
          })(
            <Input
              prefix={<Icon type="project" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Job Title"
            />,
          )}
        </Form.Item>
        {/* Business Phone */}
        <Form.Item>
          {getFieldDecorator('businessPhone', {
            rules: [{ required: true, message: 'Business Phone is required' }],
          })(
            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Business Phone"
            />,
          )}
        </Form.Item>
        {/* Address1 */}
        <Form.Item>
          {getFieldDecorator('address', {
            rules: [{ required: true, message: 'Company address is required' }],
          })(
            <Input
              prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Address"
            />,
          )}
        </Form.Item>
        {/* Address2 */}
        <Form.Item>
          {getFieldDecorator('address2')(
            <Input
              prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              placeholder="Address 2 (Optional)"
            />,
          )}
        </Form.Item>
        {/* City */}
        <Inline>
          <Form.Item>
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'City is required' }],
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="City"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('state')(
              form.getFieldValue('country') === 'US' ? (
                <Select
                  placeholder="State (USA Only)"
                  showSearch
                  style={{ width: 160 }}
                  filterOption={(input, option) => {
                    if (!option || !option.props || !option.props.children) {
                      return false;
                    }
                    return (
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                >
                  {getStateOptions()}
                </Select>
              ) : (
                <Input
                  prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="text"
                  placeholder="State/Province"
                />
              ),
            )}
          </Form.Item>
          {/* US/Canada Postal Code */}
          <Form.Item>
            {getFieldDecorator('postalCode', {
              rules: [],
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="US/Canada Postal Code"
              />,
            )}
          </Form.Item>
        </Inline>
        {/* Country */}
        <Form.Item>
          {getFieldDecorator('country', {
            rules: [{ required: true, message: 'Country is required' }],
          })(
            <Select
              loading={countriesLoading}
              placeholder={
                <React.Fragment>
                  <Icon type="global" style={{ color: 'rgba(0,0,0,.25)' }} />
                  &nbsp; Country
                </React.Fragment>
              }
              showSearch
              filterOption={(input, option) => {
                if (!option || !option.props || !option.props.children) {
                  return false;
                }
                return (
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
              }}
              allowClear
            >
              {getCountryOptions()}
            </Select>,
          )}
        </Form.Item>
        {/* US/Canada Postal Code */}
        <Form.Item>
          {getFieldDecorator('registration_code', {
            rules: [{ max: 15, message: 'Event Code must be 15 characters or less' }],
          })(
            <Input
              prefix={<Icon type="calendar" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text"
              maxLength={15}
              placeholder="Event Code"
            />,
          )}
        </Form.Item>
        <Form.Item style={{ visibility: 'hidden' }}>
          {getFieldDecorator('honeypot')(
            <Input
              prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="hidden"
              placeholder="Honey Pot?"
            />,
          )}
          <Mask />
        </Form.Item>
        {/* User Agreement */}
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator('userAgreement', {
            rules: [{ validator: checkCheckBox('Please accept the User Agreement.') }],
          })(
            <Checkbox name={'userAgreement'}>
              Accept Trial User Agreement{' '}
              <a
                href={'https://www.hfrdatabase.com/HFRDatabase.com_Trial_Use_Agreement.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                (See Terms)
              </a>
            </Checkbox>,
          )}
        </Form.Item>
        {/* Accredited Investor */}
        <Form.Item>
          {getFieldDecorator('accreditedInvestor', {
            rules: [{ validator: checkCheckBox('Please confirm you are an Accredited Investor.') }],
          })(
            <Checkbox name={'accreditedInvestor'}>
              Yes, I am an Accredited Investor{' '}
              <a
                href={'https://www.hfrdatabase.com/Accredited_Investor_Verification_Letter.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                (See Terms)
              </a>
            </Checkbox>,
          )}
        </Form.Item>
        <Inline>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ flex: 2 }}
          >
            Sign up
          </Button>
          <Link to="/auth/log-in" style={{ flex: 1 }}>
            <Button type="link" style={{ width: '100%' }}>
              Cancel
            </Button>
          </Link>
        </Inline>
      </Form>
    </SignUpContainer>
  );
};

export default Form.create<Props>({ name: 'Registration' })(SignUpForm);
