import React from 'react';
import { Select } from 'antd';

type Props = {
  placeholder?: string;
  data?: { value: number; text: string }[];
  onCreate: (isCreate: boolean) => void;
  value?: string;
  onChange?: (value: string) => void;
};

const { Option } = Select;

const CreatableSelect: React.FC<Props> = ({ data, placeholder, onCreate, onChange, value }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSearch = (value: any) => {
    setSearchTerm(value);
  };

  const handleChange = (value: string) => {
    if (value === 'create') {
      setSearchTerm('');
      onChange && onChange(searchTerm);
      onCreate(true);
    } else {
      onChange && onChange(value);
      onCreate(false);
    }
  };

  const renderCreateNew = () => {
    return (
      <Option key="create">
        Create <b>{searchTerm}</b>
      </Option>
    );
  };

  const filteredOptions =
    data && data.filter(item => (searchTerm ? item.text.includes(searchTerm) : true));

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={true}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      // notFoundContent={null}
    >
      {filteredOptions && filteredOptions.map(d => <Option key={d.value}>{d.text}</Option>)}
      {(!filteredOptions || filteredOptions.length <= 0) && searchTerm && renderCreateNew()}
    </Select>
  );
};

export default React.forwardRef(CreatableSelect);
