import { Col, Row, Select } from 'antd';
import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { BenchmarkRecord, Benchmarks } from '../../../shared/api/models/ResponsesTypes';
import { CombinedReducers } from '../../../index';

interface Props {
  benchmarks: Benchmarks;
  handleChange: (benchmarks: Benchmarks) => void;
}
interface StateProps {
  benchmarkOptions: BenchmarkRecord[];
}

const { Option } = Select;
/**
 * Rendering all the three benchmark Options in Model Analysis
 * @param benchmarkOptions - list of benchmarks
 * @param benchmarks - values of benchmark-1, 2, and 3
 * @param handleBenchmarkChange - method to propagate updated values of benchmarks up in ModelAnalysis
 */
const BenchmarkOptions: React.FC<Props & StateProps> = ({
  benchmarkOptions,
  benchmarks,
  handleChange,
}) => {
  const { benchmark1, benchmark2, benchmark3 } = benchmarks;
  const renderBenchmarkOptions = () => {
    return benchmarkOptions.map(option => {
      return (
        <Option key={option.id} value={option.id}>
          {option.name}
        </Option>
      );
    });
  };

  return (
    <Row id="vami" gutter={16} style={{ marginBottom: '30px' }}>
      <Col span={8}>
        Benchmark 1
        <br />
        <Select
          value={benchmark1}
          style={{ width: 280 }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option.props &&
            option.props.children &&
            (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          onChange={(val: string) => {
            handleChange({
              benchmark1: val,
              benchmark2,
              benchmark3,
            });
          }}
        >
          {renderBenchmarkOptions()}
        </Select>
      </Col>
      <Col span={8}>
        Benchmark 2
        <br />
        <Select
          value={benchmark2}
          style={{ width: 280 }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option.props &&
            option.props.children &&
            (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(val: string) => {
            handleChange({
              benchmark1,
              benchmark2: val,
              benchmark3,
            });
          }}
        >
          {renderBenchmarkOptions()}
        </Select>
      </Col>
      <Col span={8}>
        Benchmark 3
        <br />
        <Select
          value={benchmark3}
          style={{ width: 280 }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            option.props &&
            option.props.children &&
            (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(val: string) => {
            handleChange({
              benchmark1,
              benchmark2,
              benchmark3: val,
            });
          }}
        >
          {renderBenchmarkOptions()}
        </Select>
      </Col>
    </Row>
  );
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    benchmarkOptions: state.searchReducer.benchmarkOptions,
  };
};

export default connect<StateProps, {}, Props, CombinedReducers>(mapStatesToProps)(BenchmarkOptions);
