import React from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import { MapStateToProps, connect, MapDispatchToProps } from 'react-redux';
import APIService from '../../shared/api';
import { useServiceState } from '../../shared/hooks/useServiceState';
import { RankingParams } from '../../shared/api/services/ranking.service';
import { CombinedReducers } from '../..';
import { FundListRecord, UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { Colors } from '../../shared/colors';
import RankingLayout from './RankingLayout';

const StyledTable = styled(Table)`
  min-width: 300px;
  max-width: 25%;
  /* @media screen and (min-width: 1500px) {
    width: 25%;
  }
  @media screen and (max-width: 1500px) {
    width: 50%;
  } */
`;

type Props = {};
type StateProps = {
  user: UserProfileDetails;
  universeListForHFRIndices?: FundListRecord;
};

type DispatchProps = {
  addPane: (record: any) => void;
};

const FundRanking: React.FC<Props & StateProps & DispatchProps> = ({
  user,
  universeListForHFRIndices,
  addPane,
}) => {
  const [params, updateParams] = React.useState<Partial<RankingParams>>({});

  const [pageSize, setPageSize] = React.useState(10);

  const {
    data: myRankingData,
    loading: myRankingLoading,
    invoke: fetchMyRanking,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: singleManagerData,
    loading: singleManagerLoading,
    invoke: fetchSingleManager,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: fundOfFundsData,
    loading: fundOfFundsLoading,
    invoke: fetchFundOfFunds,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: hfrIndicesData,
    loading: hfrIndicesLoading,
    invoke: fetchHfrIndices,
  } = useServiceState(APIService.rankingService.ranking);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchMyRanking({ ...params, limit: +pageSize });
  }, [params, fetchMyRanking, pageSize]);

  React.useEffect(() => {
    if (!params.month && !params.year) {
      return;
    }
    fetchSingleManager({
      year: params.year,
      month: params.month,
      strategy: [1, 2, 3, 4, 18],
      limit: +pageSize,
    });
    fetchFundOfFunds({ year: params.year, month: params.month, strategy: [5], limit: +pageSize });
    universeListForHFRIndices &&
      fetchHfrIndices({
        year: params.year,
        month: params.month,
        universe_list_ids: [universeListForHFRIndices.id],
        limit: +pageSize,
      });
  }, [
    params.year,
    params.month,
    fetchSingleManager,
    fetchFundOfFunds,
    fetchHfrIndices,
    universeListForHFRIndices,
    pageSize,
  ]);

  const FundName = styled('span')`
    color: ${user.hfrdb_trial ? Colors.gray : Colors.primary};
    cursor: ${user.hfrdb_trial ? 'auto' : 'pointer'};
  `;

  return (
    <RankingLayout
      params={params}
      updateParams={updateParams}
      pageSize={pageSize}
      setPageSize={setPageSize}
      showAllFilter
    >
      <StyledTable
        className="ranking-table"
        scroll={{ y: 'calc(100vh - 345px)' }}
        rowKey="fund_id"
        pagination={false}
        loading={myRankingLoading}
        dataSource={myRankingData.length > 0 ? myRankingData : []}
        columns={[
          {
            title: 'My Ranking',
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '75%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
      <StyledTable
        scroll={{ y: 'calc(100vh - 345px)' }}
        className="ranking-table"
        rowKey="fund_id"
        pagination={false}
        loading={singleManagerLoading}
        dataSource={singleManagerData.length > 0 ? singleManagerData : []}
        columns={[
          {
            title: 'Single Manager',
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '75%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
      <StyledTable
        scroll={{ y: 'calc(100vh - 345px)' }}
        className="ranking-table"
        rowKey="fund_id"
        pagination={false}
        loading={fundOfFundsLoading}
        dataSource={fundOfFundsData.length > 0 ? fundOfFundsData : []}
        columns={[
          {
            title: 'Fund of Funds',
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '75%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
      <StyledTable
        scroll={{ y: 'calc(100vh - 345px)' }}
        className="ranking-table"
        rowKey="fund_id"
        pagination={false}
        loading={hfrIndicesLoading}
        dataSource={hfrIndicesData.length > 0 ? hfrIndicesData : []}
        columns={[
          {
            title: 'HFR Indices',
            dataIndex: 'fund_name',
            key: 'fund_name',
            ellipsis: true,
            width: '75%',
            render: (text: string) => <FundName>{text}</FundName>,
            onCell: (record: any) => ({
              onClick: () => {
                if (!user.hfrdb_trial) {
                  APIService.eventService.sendViewFundProfileEvent(record.fund_id);
                  addPane(record);
                }
              },
            }),
          },
          {
            title: 'ROR %',
            dataIndex: 'ror',
            key: 'ror',
            render: data => data.toFixed(2),
            align: 'right',
            width: 100,
          },
        ]}
      />
    </RankingLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
  universeListForHFRIndices: state.rootReducer.universeList.find(
    item => item.name === 'HFR Indices',
  ),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addPane: record => {
      dispatch({ type: RootActionEnums.ADD_RANKING_PANE, payload: record });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundRanking);
