import { AxiosPromise, AxiosInstance } from 'axios';

export interface RankingParams {
  month: string;
  year: number;
  ucits_compliant: boolean | undefined;
  liquid_alt: boolean | undefined;
  universe_list_ids: number[];
  strategy: number[] | undefined;
  sub_strategy: number[] | undefined;
  regional_focus: number[] | undefined;
  in_HFRI: boolean | undefined;
  denomination: string | undefined;
  fund_assets_gte: number | undefined;
  fund_assets_lte: number | undefined;
  limit: number;
}

export interface RankingResponse {
  ror: number;
  fund_id: string;
  fund_name: string;
  rank: number;
}

export interface RankingDateResponse {
  first: string;
  last: string;
  first_year: string;
  last_year: string;
}

export interface RankingService {
  ranking: ({
    month,
    year,
    ucits_compliant,
    liquid_alt,
    universe_list_ids,
    strategy,
    sub_strategy,
    regional_focus,
    in_HFRI,
    denomination,
    fund_assets_gte,
    fund_assets_lte,
  }: RankingParams) => AxiosPromise<RankingResponse[]>;
  fetchRankingDateRange: () => AxiosPromise<RankingDateResponse>;
  fetchDenomination: () => AxiosPromise<any[]>;
}

export const RankingService = (request: AxiosInstance): RankingService => {
  return {
    ranking: params =>
      request.post('/rank-funds', {
        ...params,
      }),
    fetchDenomination: () => request.get('/funds-api/data/fund-currency-codes'),
    fetchRankingDateRange: () => request.get('/rank-funds/dates'),
  };
};
