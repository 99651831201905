import React from 'react';
import { Icon, Layout, Menu } from 'antd';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import MainLayout from '../../shared/components/MainLayout';
import APIService from '../../shared/api';
import UserProfileUpdateForm from './components/UserProfileUpdateForm';
import UserProfileChangePasswordForm from './components/UserProfileChangePasswordForm';
import ReleaseNotes from './components/ReleaseNotes';

const { Sider } = Layout;

type Props = {} & RouteComponentProps;

/**
 * User Profile View
 */
const UserView: React.FC<Props> = () => {
  const [menuCollapsed, setMenuCollapsed] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const selectedMenuItem = location.pathname.split('/')[2];
  const handleLogout = () => {
    APIService.logout();
  };
  return (
    <MainLayout>
      <Sider theme="dark" collapsible collapsed={menuCollapsed} onCollapse={setMenuCollapsed}>
        <Menu
          mode="inline"
          theme="dark"
          defaultSelectedKeys={[selectedMenuItem]}
          selectedKeys={[selectedMenuItem]}
          defaultOpenKeys={['profile']}
        >
          <Menu.Item key="profile" onClick={() => history.push('/user/profile')}>
            <Icon type="user" />
            <span>User Profile</span>
          </Menu.Item>
          <Menu.Item key="change-password" onClick={() => history.push('/user/change-password')}>
            <Icon type="key" />
            <span>Change Password</span>
          </Menu.Item>
          <Menu.Item key="release-notes" onClick={() => history.push('/user/release-notes')}>
            <Icon type="snippets" />
            <span>Release Notes</span>
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            <Icon type="logout" />
            <span>Log-out</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Switch>
        <Route path="/user/profile" component={UserProfileUpdateForm} />
        <Route exact path="/user/change-password" component={UserProfileChangePasswordForm} />
        <Route exact path="/user/release-notes" component={ReleaseNotes} />
        <Redirect to="/user/profile" />
      </Switch>
    </MainLayout>
  );
};

export default UserView;
