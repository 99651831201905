import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import nProgress from 'nprogress';
import qs from 'querystring';
import { oc } from 'ts-optchain';
import { store } from '../../index';
import { history } from '../history';
import TokenUtil from '../utils/token-util';
import './nprogress.css';
import { AdminService } from './services/admin.service';
import { AuthService } from './services/auth.service';
import { ClientService } from './services/client.service';
import { ContentService } from './services/content-service';
import { DashboardService } from './services/dashboard.service';
import { DownloadFileService } from './services/download-files.services';
import EventService from './services/event-service';
import { FundService } from './services/fund.service';
import { HFRIndexService } from './services/hfr-index-constituents';
import { HistoricRorAumService } from './services/historic-ror-aum-service';
import { InvestorDBService } from './services/investor-db.service';
import { PeerGroupAnalysisService } from './services/peer-group.service';
import { PortfolioModelService } from './services/portfolio-model.service';
import { QueryService } from './services/query.service';
import { RankingService } from './services/ranking.service';
import { SearchService } from './services/search.service';
import { UniverseListService } from './services/universe-list.service';
import { UserFundService } from './services/user-fund.service';

export class APIClient {
  private request: AxiosInstance;

  private accessToken: string | null;
  private activeRequests: AxiosRequestConfig[] = [];
  private requestTimeOut?: number;

  public searchService: SearchService;
  public fundService: FundService;
  public historicRorAumService: HistoricRorAumService;
  public authService: AuthService;
  public peerGroupAnalysisService: PeerGroupAnalysisService;
  public universeListService: UniverseListService;
  public downloadFileService: DownloadFileService;
  public portfolioModelService: PortfolioModelService;
  public userFundService: UserFundService;
  public hfrIndexService: HFRIndexService;
  public queryService: QueryService;
  public contentService: ContentService;
  public clientService: ClientService;
  public eventService: EventService;
  public rankingService: RankingService;
  public adminService: AdminService;
  public dashboardService: DashboardService;
  public investorDBService: InvestorDBService;

  private paramsSerialize(params: any) {
    return qs.stringify(
      Object.keys(params).reduce((s: { [key: string]: any }, key: string) => {
        if (!params[key] || (Array.isArray(params[key]) && params[key].length === 0)) {
          return s;
        }
        return {
          [key]: Array.isArray(params[key]) ? JSON.stringify(params[key]) : params[key],
          ...s,
        };
      }, {}),
    );
  }

  constructor() {
    this.request = Axios.create({
      baseURL: process.env.REACT_APP_AUTH_API_URL,
      maxRedirects: 0,
      paramsSerializer: params => this.paramsSerialize(params),
    });

    this.searchService = SearchService(this.request);
    this.fundService = FundService(this.request);
    this.historicRorAumService = HistoricRorAumService(this.request);
    this.authService = AuthService(this.request);
    this.peerGroupAnalysisService = PeerGroupAnalysisService(this.request);
    this.universeListService = UniverseListService(this.request);
    this.downloadFileService = DownloadFileService(this.request);
    this.userFundService = UserFundService(this.request);
    this.portfolioModelService = PortfolioModelService(this.request);
    this.hfrIndexService = HFRIndexService(this.request);
    this.queryService = QueryService(this.request);
    this.contentService = ContentService(this.request);
    this.clientService = ClientService(this.request);
    this.eventService = new EventService(this.request);
    this.rankingService = RankingService(this.request);
    this.adminService = AdminService(this.request);
    this.dashboardService = DashboardService(this.request);
    this.investorDBService = InvestorDBService(this.request);

    this.accessToken = TokenUtil.getToken();

    this.request.interceptors.response.use(
      response => response,
      error => {
        if (error) {
          if (this.accessToken && error.response && error.response.status === 401) {
            this.accessToken = null;
            TokenUtil.clearToken();
            history.push('/auth');
            return;
          }
        }
        throw error;
      },
    );

    this.request.interceptors.request.use(config => {
      if (this.accessToken) {
        config.headers.Authorization = `Bearer ${this.accessToken}`;
      }
      return config;
    });
    this._setupLoadingIndicator();
  }
  private static shouldShowProgressBar(req: AxiosRequestConfig) {
    // No Progress Bar when Downloading files.
    if (
      !oc<AxiosRequestConfig>(req)
        .url('')
        .startsWith('/files/')
    ) {
      nProgress.start();
      nProgress.set(0.1);
    }
  }
  private _setupLoadingIndicator() {
    this.request.interceptors.request.use(req => {
      if (this.requestTimeOut) {
        clearTimeout(this.requestTimeOut);
      }
      this.requestTimeOut = setTimeout(() => nProgress.done(), 10000);
      this.activeRequests.push(req);
      APIClient.shouldShowProgressBar(req);
      return req;
    });
    this.request.interceptors.response.use(
      res => {
        if (res) {
          this.activeRequests = this.activeRequests.filter(i => i !== res.config);
          if (!this.activeRequests.length) {
            nProgress.done();
          }
        }
        return res;
      },
      error => {
        if (Axios.isCancel(error)) {
          const canceledIndex = this.activeRequests.findIndex(
            i => i.url && i.url.includes(error.message),
          );
          this.activeRequests = this.activeRequests.filter((_, i) => i !== canceledIndex);
        } else {
          this.activeRequests = this.activeRequests.filter(i => i !== error.config);
        }
        if (!this.activeRequests.length) {
          nProgress.done();
        }
        throw error;
      },
    );
  }

  public async login(username: string, password: string, code: string, rememberMe: boolean) {
    try {
      const res = await this.authService.login(username, password, code, rememberMe);
      TokenUtil.setToken(res.data.token);
      this.accessToken = TokenUtil.getToken();
      APIService.eventService.sendLoginEvent();
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  public setToken(token: string) {
    TokenUtil.setToken(token);
    this.accessToken = token;
  }

  public async logout() {
    await APIService.eventService.sendLogoutEvent();
    await this.authService.logout();
    this.accessToken = null;
    TokenUtil.clearToken();
    // clean (Re-Init) Redux Store
    await store.dispatch({ type: 'RESET_STORE' });
    // clear the localstorage
    localStorage.clear();
    history.push('/auth/log-in');
  }
}

export const APIService = new APIClient();
export default APIService;
