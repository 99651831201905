import * as React from 'react';
import { Input, Modal } from 'antd';
import { useState } from 'react';
import { toast } from 'react-toastify';
import APIService from '../../../shared/api';
import { PortfolioModelRecord } from '../../../shared/api/models/ResponsesTypes';
import { PortfolioPane } from '../redux/model-reducers';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  modelId: number;
  fetchPortfolioModels: () => Promise<PortfolioModelRecord[]>;
  updatePane: (data: PortfolioPane) => void;
  paneKey: string;
}

/**
 * Modal used to Re-name the Portfolio model
 * @param isOpen
 * @param modelId - current portfolio-model id
 * @param setOpen - handler to close the modal
 * @param fetchPortfolioModels - list of available portfolio models
 * @param updatePane - dispatcher for updating the pane
 * @param paneKey - key of the pane stored in redux
 * @constructor
 */
const RenamePortfolioModel: React.FC<Props> = ({
  modelId,
  isOpen,
  setOpen,
  fetchPortfolioModels,
  updatePane,
  paneKey,
}) => {
  const [newListName, setNewListName] = useState<string>('');

  const changePortfolioModelName = async () => {
    if (newListName && newListName.length > 0) {
      try {
        await APIService.portfolioModelService.updatePortfolioModel({
          modelId: `${modelId}`,
          data: { name: newListName },
        });
        await fetchPortfolioModels();
        updatePane({
          key: paneKey,
          portfolioModelName: newListName,
          portfolioModelId: `${modelId}`,
        });
        setOpen(false);
      } catch (e) {
        toast.error('Unable to rename the Model');
      }
    }
  };

  return (
    <Modal
      title={'Rename Model'}
      visible={isOpen}
      okText="Rename Model"
      onOk={changePortfolioModelName}
      onCancel={() => setOpen(false)}
    >
      <Input
        style={{ marginTop: '30px', display: 'inline-block' }}
        placeholder="Enter name"
        value={newListName}
        onChange={event => setNewListName(event.target.value)}
      />
    </Modal>
  );
};

export default RenamePortfolioModel;
