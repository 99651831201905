import 'array-flat-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { load, save } from 'redux-localstorage-simple';
import App from './App';
// @ts-ignore
// import { Server, Response } from 'miragejs';
import './index.css';
import investorReducer, {
  InitialInvestorReducer,
  initInvestorState,
} from './modules/investordatabase/redux/investor.reducer';
import modelReducer, {
  InitialModelReducer,
  initModelState,
} from './modules/model/redux/model-reducers';
import {
  InitialSearchState,
  initSearchState,
  searchReducer,
} from './modules/search/redux/search.reducers';
import rootReducer, { InitialRootReducer, initRootState } from './redux/reducers/root.reducer';
import {
  appSettingReducer,
  InitialAppSettingState,
  initAppSettingState,
} from './modules/home/redux/appSetting.reducers';
import * as serviceWorker from './serviceWorker';
import GenUtil from './shared/utils/gen-util';

export interface CombinedReducers {
  rootReducer: InitialRootReducer;
  searchReducer: InitialSearchState;
  modelReducer: InitialModelReducer;
  investorReducer: InitialInvestorReducer;
  appSettingReducer: InitialAppSettingState;
}

const mainReducer = combineReducers<CombinedReducers>({
  rootReducer,
  searchReducer,
  modelReducer,
  investorReducer,
  appSettingReducer,
});

// change PREVIOUS and CURRENT namespace when LS structure changes
const PREVIOUS_NAMESPACE = 'hfr-v2';
const CURRENT_NAMESPACE = 'hfr-v3';

GenUtil.clearNamespace({ namespace: PREVIOUS_NAMESPACE });

const createStoreWithMiddleware = applyMiddleware(
  save({
    namespace: CURRENT_NAMESPACE,
    states: ['rootReducer', 'searchReducer', 'modelReducer', 'investorReducer'],
  }),
)(createStore);

export const store = createStoreWithMiddleware(
  mainReducer,
  load({
    namespace: CURRENT_NAMESPACE,
    states: ['rootReducer', 'searchReducer', 'modelReducer', 'investorReducer'],
    preloadedState: {
      rootReducer: { ...initRootState },
      searchReducer: { ...initSearchState },
      modelReducer: { ...initModelState },
      investorReducer: { ...initInvestorState },
      appSettingReducer: { ...initAppSettingState },
    },
  }),
);

// @ts-ignore
// if (window.Cypress) {
//   // mirage cypress server
//   new Server({
//     environment: 'test',
//     routes() {
//       let methods = ['get', 'put', 'patch', 'post', 'delete'];
//       methods.forEach(method => {
//         this[method]('/*', async (_: any, request: any) => {
//           // @ts-ignore
//           let [status, headers, body] = await window.handleFromCypress(request);
//           return new Response(status, headers, body);
//         });
//       });
//     },
//   });
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
