import { AxiosInstance, AxiosPromise } from 'axios';
import { FundListRecord } from '../models/ResponsesTypes';

export interface HFRIndexService {
  fetchStaticIndex: () => AxiosPromise<FundListRecord[]>;
  fetchDynamicIndex: () => AxiosPromise<FundListRecord[]>;
}

export const HFRIndexService = (request: AxiosInstance): HFRIndexService => {
  return {
    fetchStaticIndex: () => request.get('/index/static'),
    fetchDynamicIndex: () => request.get('/index/dynamic'),
  };
};
