import React from 'react';
import { useHistory, useLocation, Route, Redirect, Switch } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import { Layout, Menu, Icon } from 'antd';
import MainLayout from '../../shared/components/MainLayout';
import HistoricRorAumView from '../historicdata/HistoricRorAumView';
import ProtectedRoute from '../../shared/components/ProtectedRoute';
import { CombinedReducers } from '../../index';
import { UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import RawDataFileView from './RawDataFileView';

const { Sider } = Layout;

type StateProps = {
  user: UserProfileDetails;
};
interface Props {}

/** The Download view container.
 * @constructor
 */

const DownloadView: React.FC<StateProps & Props> = ({ user }) => {
  const [menuCollapsed, setMenuCollapsed] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const selectedMenuItem = location.pathname.split('/')[2];

  return (
    <MainLayout>
      <Sider theme="dark" collapsible collapsed={menuCollapsed} onCollapse={setMenuCollapsed}>
        <Menu
          mode="inline"
          theme="dark"
          defaultSelectedKeys={[selectedMenuItem]}
          selectedKeys={[selectedMenuItem]}
        >
          <Menu.Item key="files" onClick={() => history.push('/download/files')}>
            <Icon type="switcher" />
            <span>Files</span>
          </Menu.Item>
          <Menu.Item
            key="historical-ror-aum"
            onClick={() => history.push('/download/historical-ror-aum')}
          >
            <Icon type="history" />
            <span>Historical ROR/AUM</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <Switch>
        <Route path="/download/files" component={RawDataFileView} />
        <ProtectedRoute
          path="/download/historical-ror-aum"
          hasAccess={user.historical_ror_aum_access}
          component={props => <HistoricRorAumView {...props} />}
        />
        <Redirect to="/download/files" />
      </Switch>
    </MainLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  user: state.rootReducer.userProfile,
});

export default connect(mapStateToProps)(DownloadView);
