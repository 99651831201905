import { Dispatch } from 'react';
import { AxiosResponse } from 'axios';
import { PortfolioModelRecord } from '../../shared/api/models/ResponsesTypes';
import APIService from '../../shared/api';
import ModelActionEnums from './redux/model-action-enums';

class PortfolioModelAction {
  static fetchPortfolioModels(): (dispatch: Dispatch<any>) => Promise<PortfolioModelRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<PortfolioModelRecord[]> => {
      try {
        const res: AxiosResponse<PortfolioModelRecord[]> = await APIService.portfolioModelService.fetchPortfolioModels();
        dispatch({
          type: ModelActionEnums.UPDATE_PORTFOLIO_MODELS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (err) {
        return [];
      }
    };
  }
}

export default PortfolioModelAction;
