// @ts-nocheck

import { Select } from 'antd';
import Lodash from 'lodash';
import moment from 'moment';
import { PlotData } from 'plotly.js';
import React from 'react';
import Plot from 'react-plotly.js';
import { connect, MapStateToProps } from 'react-redux';
import styled from 'styled-components';
import { CombinedReducers } from '../../../index';
import { FundDetailStrategy } from '../../../shared/api/models/FundDetails';
import { UserProfileDetails } from '../../../shared/api/models/ResponsesTypes';
import { EventSubCode } from '../../../shared/api/services/event-service';
import { LatestRORData } from '../SearchView';
import RootActionEnums from '../../../redux/actions/root.ActionEnums';
import APIService from '../../../shared/api';

const DELTA = 1;

const normalizeROR = (key: keyof LatestRORData) => (acc: LatestRORData[], curr: LatestRORData) => {
  if (acc.length && Math.abs(curr[key] - acc[acc.length - 1][key]) < DELTA) {
    return acc;
  }
  return [...acc, curr];
};

const Wrapper = styled('div')`
  flex-grow: 1;
`;

const graphLayoutCons: Partial<Plotly.Layout> = {
  hovermode: 'closest',
  autosize: true,
  hoverlabel: { bgcolor: '#FFF' },
  xaxis: {
    title: 'Date (YYYY-MM)',
    showgrid: false,
    showline: false,
    tickformat: '%Y-%m',
    categoryorder: 'array',
  },
  height: 600,
  yaxis: {
    title: 'ROR Values',
    showline: true,
  },
  margin: {
    l: 50,
    r: 50,
    b: 100,
    t: 100,
    pad: 4,
  },
  title: 'Funds Chart View',
};

const CHARTS = [
  {
    name: 'ROR',
    stats: [
      {
        name: 'Geo Avg Month ROR %',
        key: 'geo_avg_month_ror',
      },
      {
        name: 'High Month ROR %',
        key: 'high_month_ror',
      },
      {
        name: 'Low Month ROR %',
        key: 'low_month_ror',
      },
      {
        name: '1 Month ROR %',
        key: 'one_month_ror',
      },
      {
        name: '3 Month ROR %',
        key: 'three_month_ror',
      },
      {
        name: '6 Month ROR %',
        key: 'six_month_ror',
      },
      {
        name: 'YTD',
        key: 'ytd',
      },
    ],
  },
  {
    name: 'Annualized ROR',
    stats: [
      {
        name: 'Ann ROR %',
        key: 'ann_ror',
      },
      {
        name: '1 Year Ann ROR %',
        key: 'one_year_ann_ror',
      },
      {
        name: '2 Year Ann ROR %',
        key: 'two_year_ann_ror',
      },
      {
        name: '3 Year Ann ROR %',
        key: 'three_year_ann_ror',
      },
      {
        name: '5 Year Ann ROR %',
        key: 'five_year_ann_ror',
      },
      {
        name: '7 Year Ann ROR %',
        key: 'seven_year_ann_ror',
      },
      {
        name: '10 Year Ann ROR %',
        key: 'ten_year_ann_ror',
      },
    ],
  },
  {
    name: 'Standard Deviation',
    stats: [
      {
        name: 'Stdev %',
        key: 'stdev',
      },
      {
        name: 'Ann Stdev %',
        key: 'ann_stdev',
      },
      {
        name: '1 Year Ann Stdev %',
        key: 'one_year_ann_stdev',
      },
      {
        name: '2 Year Ann Stdev %',
        key: 'two_year_ann_stdev',
      },
      {
        name: '3 Year Ann Stdev %',
        key: 'three_year_ann_stdev',
      },
      {
        name: '5 Year Ann Stdev %',
        key: 'five_year_ann_stdev',
      },
      {
        name: '7 Year Ann Stdev %',
        key: 'seven_year_ann_stdev',
      },
      {
        name: '10 Year Ann Stdev %',
        key: 'ten_year_ann_stdev',
      },
    ],
  },
  {
    name: 'Sharpe Ratio',
    stats: [
      {
        name: 'Sharpe Ratio',
        key: 'sharpe_ratio',
      },
      {
        name: '1 Year Ann Sharpe Ratio',
        key: 'one_year_ann_sharpe_ratio',
      },
      {
        name: '2 Year Ann Sharpe Ratio',
        key: 'two_year_ann_sharpe_ratio',
      },
      {
        name: '3 Year Ann Sharpe Ratio',
        key: 'three_year_ann_sharpe_ratio',
      },
      {
        name: '5 Year Ann Sharpe Ratio',
        key: 'five_year_ann_sharpe_ratio',
      },
      {
        name: '7 Year Ann Sharpe Ratio',
        key: 'seven_year_ann_sharpe_ratio',
      },
      {
        name: '10 Year Ann Sharpe Ratio',
        key: 'ten_year_ann_sharpe_ratio',
      },
    ],
  },
  {
    name: 'Regression',
    stats: [
      {
        name: 'Alpha',
        key: 'alpha',
      },
      {
        name: 'Beta',
        key: 'beta',
      },
      {
        name: 'R-Squared',
        key: 'r_square',
      },
      {
        name: 'Correlation',
        key: 'correlation',
      },
      {
        name: 'Up Alpha',
        key: 'up_alpha',
      },
      {
        name: 'Up Beta',
        key: 'up_beta',
      },
      {
        name: 'Up R-Squared',
        key: 'up_r_square',
      },
      {
        name: 'Down Alpha',
        key: 'down_alpha',
      },
      {
        name: 'Down Beta',
        key: 'down_beta',
      },
      {
        name: 'Down R-Squared',
        key: 'down_r_square',
      },
    ],
  },
];

interface Props {
  rorData: LatestRORData[];
}

interface StateProps {
  strategyOptions: FundDetailStrategy[];
  user: UserProfileDetails;
}

interface DispatchProps {
  addPane: (data: FundFirmPane) => void;
}

const FundsChart: React.FC<Props & StateProps & DispatchProps> = ({
  rorData,
  strategyOptions,
  user,
  addPane,
}) => {
  const [activeChart, setActiveChart] = React.useState(-1);

  const getFundsChartData = (items: LatestRORData[]): Partial<PlotData>[] => {
    items.sort((o1: LatestRORData, o2: LatestRORData) => {
      return moment(o1.date) < moment(o2.date) ? -1 : moment(o1.date) > moment(o2.date) ? 1 : 0;
    });

    const strategyLatestRorDataMap: { [index: number]: LatestRORData[] } = {};

    for (const item of items) {
      if (strategyLatestRorDataMap[item.strategy]) {
        strategyLatestRorDataMap[item.strategy].push(item);
      } else {
        strategyLatestRorDataMap[item.strategy] = [item];
      }
    }
    const formattedChartData: Partial<PlotData>[] = [];
    for (const strategy in strategyLatestRorDataMap) {
      const x: string[] = [];
      const y: number[] = [];
      const hovertemplate: string[] = [];
      for (const item of strategyLatestRorDataMap[strategy]) {
        x.push(moment(item.date).format('YYYY-MM'));
        y.push(item.value);
        hovertemplate.push(getHoverText(item));
      }
      formattedChartData.push({
        x,
        y,
        hovertemplate,
        mode: 'markers',
        type: 'scatter',
        name: strategyOptions.filter(
          (s: FundDetailStrategy) => Number(s.strategy_code) === Number(strategy),
        )[0].strategy_name,
      });
    }

    return formattedChartData;
  };
  const categoryarray: string[] = Lodash.uniq(
    rorData
      .map((item: LatestRORData) => item.date)
      .sort()
      .map(d => moment(d).format('YYYY-MM')),
  );
  if (graphLayoutCons && graphLayoutCons.xaxis) {
    graphLayoutCons.xaxis.categoryarray = categoryarray;
  }

  const graphData = getFundsChartData(rorData);

  const getChartData: Partial<PlotData>[] = React.useMemo(() => {
    if (activeChart === -1) {
      return [];
    }
    let groupedByStrategy: { [strategyId: number]: LatestRORData[] } = {};

    rorData.map(item => {
      if (groupedByStrategy[item.strategy]) {
        groupedByStrategy[item.strategy].push(item);
      } else {
        groupedByStrategy[item.strategy] = [item];
      }
    });

    const activeStats = CHARTS[activeChart].stats;

    return Object.keys(groupedByStrategy).map(strategyAsKey => {
      const currentStrategyGroup = groupedByStrategy[+strategyAsKey];

      const groupedByStat = activeStats.reduce((acc, currStat) => {
        return {
          ...acc,
          [currStat.key]: currentStrategyGroup
            // .map(item => ({ y: item[currStat.key], ...item }))
            .sort((a, b) => a[currStat.key] - b[currStat.key])
            .reduce<LatestRORData[]>(normalizeROR(currStat.key), []), // normalize data
        };
      }, {} as { [key: string]: LatestRORData[] });

      return Object.keys(groupedByStat).reduce(
        (acc, currStat) => {
          return {
            ...acc,
            y: [...acc.y, ...groupedByStat[currStat].map(item => item[currStat])],
            x: [
              ...acc.x,
              ...Array(groupedByStat[currStat].length).fill(
                activeStats.find(item => item.key === currStat).name,
              ),
            ],
            hovertemplate: [
              ...acc.hovertemplate,
              ...groupedByStat[currStat].map(item => {
                const statTemplate = activeStats
                  .map(
                    stat =>
                      `<i>${stat.name}</i>: ${item[stat.key] ? `<b>${item[stat.key]}%</b>` : ''}`,
                  )
                  .join('<br>');
                return `<i>Fund Name</i>: <b>${item.fund_name}</b><br><i>Strategy</i>: <b>${item.strategy_name}</b><br><i>Substrategy</i>: <b>${item.sub_strategy_name}</b><br><i>Region</i>: <b>${item.regional_focus}</b><br><br>${statTemplate}</b><extra></extra>`;
              }),
            ],
            customdata: [...acc.customdata, ...groupedByStat[currStat].map(item => item)],
          };
        },
        {
          mode: 'markers',
          type: 'scatter',
          hovertemplate: [],
          customdata: [],
          name: strategyOptions.filter(
            (s: FundDetailStrategy) => +s.strategy_code === +strategyAsKey,
          )[0].strategy_name,
          x: [],
          y: [],
        } as { x: string[]; y: number[] },
      );
    });
  }, [activeChart, rorData, strategyOptions]);

  React.useEffect(() => {
    switch (activeChart) {
      case 0:
        return APIService.eventService.sendChartViewEvent(EventSubCode.ROR_VIEW_EVENT);
      case 1:
        return APIService.eventService.sendChartViewEvent(EventSubCode.ANNUALIZED_ROR_VIEW_EVENT);
      case 2:
        return APIService.eventService.sendChartViewEvent(
          EventSubCode.STANDARD_DEVIATION_VIEW_EVENT,
        );
      case 3:
        return APIService.eventService.sendChartViewEvent(EventSubCode.SHARPE_RATIO_VIEW_EVENT);
      case 4:
        return APIService.eventService.sendChartViewEvent(EventSubCode.REGRESSION_VIEW_EVENT);
      default:
        break;
    }
  }, [activeChart]);

  const handleSelectFund = (e: Readonly<Plotly.PlotMouseEvent>) => {
    const fund = e.points[0].customdata;
    if (!fund) {
      return;
    }
    APIService.eventService.sendViewFundProfileEvent(fund.fund_id);
    addPane({
      title: fund.fund_name,
      key: `${fund.fund_id}-fund`,
      type: 'fund',
      fundFirmId: fund.fund_id,
    });
  };

  return (
    <Wrapper>
      {user.hfrdb_subscriber && (
        <Select
          value={activeChart}
          onChange={(e: any) => {
            setActiveChart(e);
          }}
          style={{ width: 200, marginLeft: 16 }}
        >
          <Select.Option value={-1} key={-1}>
            Fund Chart
          </Select.Option>
          {CHARTS.map((chart, index) => (
            <Select.Option value={index} key={index}>
              {chart.name}
            </Select.Option>
          ))}
        </Select>
      )}
      {(user.hfrdb_trial || user.hfrdb_subscriber) && activeChart === -1 && (
        <Plot
          data={graphData}
          layout={graphLayoutCons}
          useResizeHandler={true}
          style={{ width: '100%', height: '100%' }}
          config={{
            displaylogo: false,
          }}
        />
      )}
      {user.hfrdb_subscriber && activeChart !== -1 && (
        <Plot
          onClick={handleSelectFund}
          data={getChartData}
          style={{ width: '100%', height: '100%' }}
          config={{
            displaylogo: false,
          }}
          layout={{
            title: CHARTS[activeChart].name,
            yaxis: {
              ticksuffix: '%',
            },
            margin: {
              l: 50,
              r: 50,
              b: 100,
              t: 100,
              pad: 4,
            },
            height: 600,
            hovermode: 'closest',
            autosize: true,
            hoverlabel: { bgcolor: '#FFF' },
          }}
        />
      )}
    </Wrapper>
  );
};

const getHoverText = (item: LatestRORData) => {
  return `<i>Fund Name</i>: ${item.fund_name}<br><i>Date</i>: ${moment(item.date).format(
    'YYYY-MM',
  )}<br><i>ROR</i>: ${item.value}% <extra></extra>`;
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addPane: (data: FundFirmPane) => {
      dispatch({ type: RootActionEnums.ADD_PANE, payload: data });
    },
  };
};

const mapStatesToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    user: state.rootReducer.userProfile,
    strategyOptions: state.searchReducer.strategyOptions,
  };
};

export default connect<StateProps & DispatchProps>(
  mapStatesToProps,
  mapDispatchToProps,
)(FundsChart);
