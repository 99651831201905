import React from 'react';
import { Modal, notification, Row, Select } from 'antd';
import awaitTo from 'async-await-error-handling';
import APIService from '../../../shared/api';
import { FundListRecord, PortfolioModelRecord } from '../../../shared/api/models/ResponsesTypes';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import { connect, MapStateToProps } from 'react-redux';
import { CombinedReducers } from '../../../index';
import { UniverseSelectTypeEnum } from '../../../shared/components/UniverseSelect';

interface Props {
  visible: boolean;
  onRequestClose: () => void;
  selectedFunds: number[];
}

interface StateProps {
  portfolioModels: PortfolioModelRecord[];
}

/**
 * AddToFundListModal - Renders DropDown to choose the fund list from.
 * @param visible [boolean] - Modal visibility flag
 * @param onRequestClose - callback to call when modal is being closed
 * @param selectedFunds - Funds to be added in the Fund List
 */
const AddToFundListModal: React.FC<Props & StateProps> = ({
  visible,
  onRequestClose,
  selectedFunds,
  portfolioModels,
}) => {
  const [selectedFundList, setSelectedFundList] = React.useState<string>();

  const {
    data: fundListData,
    invoke: invokeFundListData,
    loading: fundListLoading,
  } = useServiceState<FundListRecord[]>(APIService.universeListService.fetchFundList);

  React.useEffect(() => {
    invokeFundListData();
  }, [invokeFundListData]);

  const handleSubmit = async () => {
    if (!selectedFundList) {
      notification.error({ message: 'Please select the Fund List' });
      return;
    } else if (selectedFundList.split('-')[1] === UniverseSelectTypeEnum.PORTFOLIO) {
      handleAddToPortfolio(selectedFundList.split('-')[0]);
    } else {
      handleAddToFundList(selectedFundList);
    }
  };

  const handleAddToFundList = async (fundListId: string) => {
    const [err] = await awaitTo<any>(
      APIService.universeListService.updateFundList(fundListId, {
        user_fund_add: selectedFunds,
      }),
    );
    if (err) {
      notification.error({ message: err.message });
      return;
    }
    notification.success({ message: 'Added User funds to Selected Fund List' });
    onRequestClose();
  };

  const handleAddToPortfolio = async (modelId: string) => {
    const [err] = await awaitTo<any>(
      APIService.portfolioModelService.updatePortfolioModel({
        modelId,
        data: {
          add: selectedFunds.map((user_fund_id: number) => {
            return {
              user_fund_id,
              weight: 0,
            };
          }),
        },
      }),
    );
    if (err) {
      notification.error({ message: err.message });
      return;
    }
    notification.success({ message: 'Added User funds to Selected Portfolio Model' });
    onRequestClose();
  };

  return (
    <Modal
      title="Add To Fund List"
      visible={visible}
      onCancel={onRequestClose}
      onOk={handleSubmit}
      okText="Add"
    >
      <b>{selectedFunds.length} funds selected</b>
      <Row style={{ marginTop: 16 }}>
        <Select
          loading={fundListLoading}
          style={{ width: '100%' }}
          placeholder="Select Fund List"
          allowClear
          onChange={(value: string) => setSelectedFundList(value)}
          showSearch
          filterOption={(input, option) => {
            if (!option || !option.props || !option.props.children) {
              return false;
            }
            return (
              (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        >
          {fundListData &&
            fundListData.length >= 0 &&
            fundListData.map((item: FundListRecord) => (
              <Select.Option key={`${item.id}`} value={`${item.id}`}>
                (My List) {item.name}
              </Select.Option>
            ))}
          {portfolioModels &&
            portfolioModels.length >= 0 &&
            portfolioModels.map((item: PortfolioModelRecord) => (
              <Select.Option
                key={`${item.id}-${UniverseSelectTypeEnum.PORTFOLIO}`}
                value={`${item.id}-${UniverseSelectTypeEnum.PORTFOLIO}`}
              >
                (My Model) {item.name}
              </Select.Option>
            ))}
        </Select>
      </Row>
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    portfolioModels: state.modelReducer.portfolioModels,
  };
};
export default connect(mapStateToProps)(AddToFundListModal);
