import React, { useState } from 'react';
import AuthLayout from '../AuthLayout';
import PreSignUpForm from './PreSignUpForm';
import SignUpForm from './SignUpForm';
import ThankYouPage from './ThankYouPage';

enum SignUpSteps {
  PRE_SIGN_UP,
  SIGN_UP,
  THANK_YOU,
}

type Props = {};

const SignUpView: React.FC<Props> = () => {
  const [signUpStep, setSignUpStep] = useState(SignUpSteps.PRE_SIGN_UP);
  const [verificationCode, setVerificationCode] = React.useState();
  const [email, setEmail] = React.useState();
  return (
    <>
      {signUpStep !== SignUpSteps.THANK_YOU && (
        <AuthLayout isRegister title="Sign Up" description="Register for 2-week trial">
          {signUpStep === SignUpSteps.PRE_SIGN_UP && (
            <PreSignUpForm
              nextStep={() => setSignUpStep(SignUpSteps.SIGN_UP)}
              setEmail={setEmail}
              setVerificationCode={setVerificationCode}
            />
          )}
          {signUpStep === SignUpSteps.SIGN_UP && (
            <SignUpForm
              verificationCode={verificationCode}
              email={email}
              nextStep={() => setSignUpStep(SignUpSteps.THANK_YOU)}
            />
          )}
        </AuthLayout>
      )}
      {signUpStep === SignUpSteps.THANK_YOU && <ThankYouPage />}
    </>
  );
};

export default SignUpView;
