import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { InvestorDatabaseType } from '../../../shared/api/services/investor-db.service';
import GenUtil from '../../../shared/utils/gen-util';

export interface ColumnPropsInvestorExtended extends ColumnProps<InvestorDatabaseType> {
  key: string;
}

export interface InvestorColumnSource {
  groupName: string;
  columns: ColumnPropsInvestorExtended[];
}
export const defaultColumns: ColumnPropsInvestorExtended[] = [
  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name',
    width: '10%',
  },
  {
    title: 'Investor Type',
    dataIndex: 'investor_type',
    key: 'investor_type',
  },
  {
    title: 'Entity Type',
    dataIndex: 'entity_type',
    key: 'entity_type',
  },
  {
    title: 'Investment Strategy',
    dataIndex: 'investment_strategy_string',
    key: 'investment_strategy_string',
  },
  {
    title: 'Investment Executive',
    dataIndex: 'investment_executive',
    key: 'investment_executive',
  },
  {
    title: 'Other Investment Professionals',
    dataIndex: 'other_investment_professionals',
    key: 'other_investment_professionals',
  },
  // {
  //   title: 'Primary Address 1',
  //   dataIndex: 'primary_address1',
  //   key: 'primary_address1',
  // },
  // {
  //   title: 'Primary Address 2',
  //   dataIndex: 'primary_address2',
  //   key: 'primary_address2',
  // },
  // {
  //   title: 'Primary Country',
  //   dataIndex: 'primary_country',
  //   key: 'primary_country',
  // },
];

export const allColumns: InvestorColumnSource[] = [
  {
    groupName: 'General',
    columns: defaultColumns,
  },
  {
    groupName: 'Assets',
    columns: [
      {
        title: 'Total Assets (USD)',
        dataIndex: 'total_assets',
        key: 'total_assets',
        render: value => (value ? `USD ${GenUtil.getformattedBilionOrMilion(value)}` : null),
      },
      {
        title: 'Date Total Assets',
        dataIndex: 'total_assets_as_of',
        key: 'total_assets_as_of',
      },
      {
        title: 'Hedge Fund Assets (USD)',
        dataIndex: 'hedge_fund_assets',
        render: (value: number | string) =>
          value ? `USD ${GenUtil.getformattedBilionOrMilion(value)}` : null,
        key: 'hedge_fund_assets',
      },
      {
        title: 'Date Hedge Fund Assets',
        dataIndex: 'hedge_fund_assets_as_of',
        key: 'hedge_fund_assets_as_of',
      },
      {
        title: 'Alternative Assets (USD)',
        dataIndex: 'alternative_assets',
        key: 'alternative_assets',
        render: value => (value ? `USD ${GenUtil.getformattedBilionOrMilion(value)}` : null),
      },
      {
        title: 'Date Alternative Assets',
        dataIndex: 'alternative_assets_as_of',
        key: 'alternative_assets_as_of',
      },
    ],
  },
  {
    groupName: 'Contact Info',
    columns: [
      {
        title: 'Website Address',
        dataIndex: 'website_address',
        key: 'website_address',
        render: value =>
          value ? (
            <a href={value} target="_blank">
              {value}
            </a>
          ) : null,
      },
      {
        title: 'Company Email',
        dataIndex: 'company_email',
        key: 'company_email',
      },
      {
        title: 'Primary Phone Number',
        dataIndex: 'primary_phone_number',
        key: 'primary_phone_number',
      },
      {
        title: 'Primary Address 1',
        dataIndex: 'primary_address1',
        key: 'primary_address1',
      },
      {
        title: 'Primary Address 2',
        dataIndex: 'primary_address2',
        key: 'primary_address2',
      },
      {
        title: 'Primary City',
        dataIndex: 'primary_city',
        key: 'primary_city',
      },
      {
        title: 'Primary State/Province',
        dataIndex: 'primary_state',
        key: 'primary_state',
      },
      {
        title: 'Primary Country',
        dataIndex: 'primary_country',
        key: 'primary_country',
      },
      {
        title: 'Primary Postal Code',
        dataIndex: 'primary_postal_code',
        key: 'primary_postal_code',
      },
    ],
  },
  {
    groupName: 'Secondary Contact Info',
    columns: [
      {
        title: 'Secondary Phone Number',
        dataIndex: 'secondary_phone_number',
        key: 'secondary_phone_number',
      },
      {
        title: 'Secondary Address 1',
        dataIndex: 'secondary_address1',
        key: 'secondary_address1',
      },
      {
        title: 'Secondary Address 2',
        dataIndex: 'secondary_address2',
        key: 'secondary_address2',
      },
      {
        title: 'Secondary City',
        dataIndex: 'secondary_city',
        key: 'secondary_city',
      },
      {
        title: 'Secondary State/Province',
        dataIndex: 'secondary_state',
        key: 'secondary_state',
      },
      {
        title: 'Secondary Country',
        dataIndex: 'secondary_country',
        key: 'secondary_country',
      },
      {
        title: 'Secondary Postal Code',
        dataIndex: 'secondary_postal_code',
        key: 'secondary_postal_code',
      },
    ],
  },
  // {
  //   title: 'Company Name',
  //   dataIndex: 'company_name',
  //   key: 'company_name',
  //   width: '10%',
  // },
  // {
  //   title: 'Investor Type',
  //   dataIndex: 'investor_type',
  //   key: 'investor_type',
  // },
  // Primary Info

  // Secondary Info

  // {
  //   title: 'CIO/Similar Executive First Name',
  //   dataIndex: 'cio_similar_executive_first_name',
  //   key: 'cio_similar_executive_first_name',
  // },
  // {
  //   title: 'CIO/Similar Executive Last Name',
  //   dataIndex: 'cio_similar_executive_last_name',
  //   key: 'cio_similar_executive_last_name',
  // },
  // {
  //   title: 'Other Investment Professionals',
  //   dataIndex: 'other_investment_professionals',
  //   key: 'other_investment_professionals',
  // },
  // {
  //   title: 'Entity Type',
  //   dataIndex: 'entity_type',
  //   key: 'entity_type',
  // },
  // {
  //   title: 'Investment Strategy',
  //   dataIndex: 'investment_strategy',
  //   key: 'investment_strategy',
  // },
];
