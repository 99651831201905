import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { FundDetails } from '../../../../shared/api/models/FundDetails';
import GenUtil from '../../../../shared/utils/gen-util';

export interface ColumnPropsExtended extends ColumnProps<FundDetails> {
  key: string;
}

export interface ColumnSource {
  groupName: string;
  columns: ColumnPropsExtended[];
}

export const defaultColumns: ColumnPropsExtended[] = [
  {
    title: 'Fund Name',
    dataIndex: 'fund_name',
    key: 'fund_name',
    sorter: true,
  },
  {
    title: 'Firm Name',
    dataIndex: 'firm.firm_name',
    key: 'firm_name',
    sorter: true,
  },
  {
    title: 'Main Strategy',
    dataIndex: 'strategy.strategy_name',
    key: 'strategy_name',
    sorter: true,
  },
  {
    title: 'Sub Strategy',
    dataIndex: 'sub_strategy.sub_strategy_name',
    key: 'sub_strategy_name',
    sorter: true,
  },
  {
    title: 'Inception',
    dataIndex: 'fund_info.inception_date',
    key: 'inception_date',
    render: (date: string | undefined) => {
      return date ? GenUtil.getFormattedYearMonthAndDay(date) : null;
    },
    sorter: true,
    width: 120,
  },
];

export const allColumns: ColumnSource[] = [
  {
    groupName: 'General',
    columns: defaultColumns,
  },
  {
    groupName: 'Liquid Alt Info',
    columns: [
      {
        title: 'Liquid Alt Product',
        dataIndex: 'fund_info.liquid_alt_product',
        key: 'liquid_alt_product',
        render: (val: boolean) => {
          return !val ? 'No' : 'Yes';
        },
        sorter: true,
      },
      {
        title: 'Liquid Alt Regions',
        dataIndex: 'liquid_alt_region',
        key: 'liquid_alt_region',
      },
      {
        title: 'Max Front Fees',
        dataIndex: 'fund_fees.max_front_fee',
        key: 'max_front_fee',
      },
      {
        title: 'Liquid Alt Type',
        dataIndex: 'liquid_alt_type',
        key: 'liquid_alt_type',
      },
      {
        title: 'Max 1 2 B 1 Fees',
        dataIndex: 'fund_fees.max_12b1_fee',
        key: 'max_12b1_fee',
      },
      {
        title: 'Max Deferred Fees',
        dataIndex: 'fund_fees.max_deferred_fee',
        key: 'max_deferred_fee',
      },
    ],
  },
  {
    groupName: 'Fund Info',
    columns: [
      {
        title: 'Fund Name',
        dataIndex: 'fund_name',
        key: 'fund_name',
        sorter: true,
        fixed: 'left',
      },
      {
        title: 'In HFRX?',
        dataIndex: 'is_hfrx_fund',
        key: 'is_hfrx_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Fund Id',
        dataIndex: 'fund_id',
        key: 'fund_id',
        width: 100,
      },
      // {
      //   title: 'Is Offshore Vehicle?',
      //   dataIndex: 'fund_info.is_offshore',
      //   key: 'is_offshore',
      //   render: (val: boolean) => {
      //     return val ? 'Yes' : 'No';
      //   },
      // },
      {
        title: 'Is HFRU Fund?',
        dataIndex: 'is_hfru_fund',
        key: 'is_hfru_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Is HFR Risk Parity Fund?',
        dataIndex: 'is_hfr_risk_parity_fund',
        key: 'is_hfr_risk_parity_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Is HFR Blockchain Fund?',
        dataIndex: 'is_hfr_blockchain_fund',
        key: 'is_hfr_blockchain_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Product Class',
        dataIndex: 'fund_info.product_class',
        key: 'product_class',
      },
      {
        title: 'In Emerging Markets',
        dataIndex: 'is_emrgngmrkts_fund',
        key: 'is_emrgngmrkts_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Strategy Description',
        dataIndex: 'fund_info.strategy_description',
        key: 'strategy_description',
        render: (data: string) => {
          return data ? (
            <Tooltip title={data} placement="top">
              {data.substring(0, 50)}...
            </Tooltip>
          ) : null;
        },
      },
      {
        title: 'In HFRI?',
        dataIndex: 'is_index_fund',
        key: 'is_index_fund',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Date added to DB',
        dataIndex: 'added_to_db',
        key: 'added_to_db',
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearMonthAndDay(date) : null;
        },
        sorter: true,
      },
      {
        title: 'Inception',
        dataIndex: 'fund_info.inception_date',
        key: 'inception_date',
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearMonthAndDay(date) : null;
        },
        sorter: true,
      },
      {
        title: 'Regional Inv. Focus Sub-Region',
        dataIndex: 'fund_info.region_inv_focus_country',
        key: 'region_inv_focus_country',
        sorter: true,
        width: 200,
      },
    ],
  },
  {
    groupName: 'Firm Info',
    columns: [
      {
        title: 'Firm ID',
        dataIndex: 'firm.firm_id',
        key: 'firm_id',
        width: 100,
      },
      {
        title: 'Firm Name',
        dataIndex: 'firm.firm_name',
        key: 'firm_name',
        sorter: true,
      },
      {
        title: 'Firm IARD CRD Number',
        dataIndex: 'firm_sec.iard_crd_number',
        key: 'iard_crd_number',
      },
      {
        title: 'Firm SEC Number',
        dataIndex: 'firm_sec.sec_number',
        key: 'sec_number',
      },
      {
        title: 'Firm SEC Registered',
        dataIndex: 'firm_sec.sec_registered',
        key: 'sec_registered',
        render: (val: boolean) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Non-SEC Registration Country',
        dataIndex: 'firm_sec.non_sec_reg_country',
        key: 'non_sec_reg_country',
      },
      {
        title: 'Non-SEC Registration Authority',
        dataIndex: 'firm_sec.non_sec_reg_auth',
        key: 'non_sec_reg_auth',
      },
      {
        title: 'Non-SEC Registration Authority Type No',
        dataIndex: 'firm_sec.non_sec_reg_auth_type',
        key: 'non_sec_reg_auth_type',
      },
      {
        title: 'Website',
        dataIndex: 'firm_contact.contact_web',
        key: 'contact_web',
      },
      {
        title: 'Firm Contact Email',
        dataIndex: 'firm_contact.email',
        key: 'email',
      },
    ],
  },
  {
    groupName: 'Service Provider Info',
    columns: [
      {
        title: 'Administrator',
        dataIndex: 'fund_info.administrator',
        key: 'administrator',
      },
      {
        title: 'Annual Perform Audit',
        dataIndex: 'fund_info.annual_perform_audit',
        key: 'annual_perform_audit',
        render: (val: boolean) => {
          return !val ? 'No' : 'Yes';
        },
      },
      // {
      //   title: 'Audit Date',
      //   dataIndex: 'fund_info.audit_date',
      //   render: (date: string | undefined) => {
      //     return date ? GenUtil.getFormattedYearMonthAndDay(date) : null;
      //   },
      //   key: 'audit_date',
      // },
      {
        title: 'Auditor',
        dataIndex: 'fund_info.auditor',
        key: 'auditor',
      },
      {
        title: 'Banking Agent Onshore',
        dataIndex: 'fund_info.banking_agent_onshore',
        key: 'banking_agent_onshore',
      },
      {
        title: 'Custodian',
        dataIndex: 'fund_info.custodian',
        key: 'custodian',
      },
      {
        title: 'Legal Adviser Onshore',
        dataIndex: 'fund_info.legal_adviser_onshore',
        key: 'legal_adviser_onshore',
      },
      {
        title: 'Prime Broker',
        dataIndex: 'fund_info.prime_broker',
        key: 'prime_broker',
      },
    ],
  },
  {
    groupName: 'Contact Info',
    columns: [
      { title: 'Principals', dataIndex: 'firm_contact.principals', key: 'principals' },
      { title: '1st Contact', dataIndex: 'firm_contact.first_contact', key: 'first_contact' },
      { title: '2nd Contact', dataIndex: 'firm_contact.second_contact', key: 'second_contact' },
      { title: 'Address', dataIndex: 'firm_contact.address', key: 'address' },
      { title: 'City', dataIndex: 'firm_contact.city', key: 'city' },
      { title: 'State', dataIndex: 'firm_contact.state', key: 'state' },
      { title: 'Country', dataIndex: 'firm_contact.country', key: 'country' },
      { title: 'Postal Code', dataIndex: 'firm_contact.', key: '' },
      { title: 'Phone', dataIndex: 'firm_contact.phone', key: 'phone' },
      // { title: 'Facsimile', dataIndex: 'firm_contact.facsimile', key: 'facsimile' },
    ],
  },
  {
    groupName: 'Investment Info',
    columns: [
      {
        title: 'Advance Notice',
        dataIndex: 'fund_info.advance_notice',
        key: 'advance_notice',
      },
      {
        title: 'Domicile',
        dataIndex: 'domicile',
        key: 'Domicile',
      },
      {
        title: 'Structure',
        dataIndex: 'structure',
        key: 'structure',
      },
      {
        title: 'High Watermark',
        dataIndex: 'fund_fees.high_watermark',
        key: 'high_watermark',
        render: (val: boolean | undefined) => {
          return val ? 'Yes' : 'No';
        },
      },
      {
        title: 'Hurdle Rate',
        dataIndex: 'fund_fees.hurdle_rate',
        key: 'hurdle_rate',
      },
      {
        title: 'Individual Account Min.',
        dataIndex: 'fund_info.individual_account_min',
        render: (val: string | undefined) => {
          return val ? GenUtil.getformattedNumber(val) : null;
        },
        key: 'individual_account_min',
      },
      {
        title: 'Leverage',
        dataIndex: 'leverage',
        key: 'leverage',
      },
      {
        title: 'Is Lockup?',
        dataIndex: 'fund_info.is_lockup',
        key: 'is_lockup',
        render: (val: boolean) => {
          return !val ? 'No' : 'Yes';
        },
      },
      {
        title: 'Lockup',
        dataIndex: 'fund_info.lockup',
        key: 'lockup',
      },
      {
        title: 'Min. Investment Curr',
        dataIndex: 'min_investment_curr',
        key: 'min_investment_curr',
      },
      {
        title: 'Minimum Investment',
        dataIndex: 'fund_info.minimum_investment',
        render: (val: string | undefined) => {
          return val != null ? GenUtil.getformattedNumber(val) : null;
        },
        key: 'minimum_investment',
      },
      {
        title: 'Additional Investment',
        dataIndex: 'fund_info.additional_investment',
        render: (val: string | undefined) => {
          return val != null ? GenUtil.getformattedNumber(val) : null;
        },
        key: 'additional_investment',
      },
      {
        title: 'Open For Investment',
        dataIndex: 'fund_info.open_for_investment',
        key: 'open_for_investment',
        render: (val: boolean) => {
          return !val ? 'No' : 'Yes';
        },
      },
      {
        title: 'Open To Investor Type',
        dataIndex: 'fund_info.open_to_investor_type',
        key: 'open_to_investor_type',
      },
      // {
      //   title: 'Reporting',
      //   dataIndex: 'fund_info.reporting',
      //   key: 'reporting',
      // },
      {
        title: 'Reporting Style',
        dataIndex: 'reporting_style',
        key: 'reporting_style',
      },
      {
        title: 'Returns Currency',
        dataIndex: 'returns_currency',
        key: 'returns_currency',
      },
      {
        title: 'Fund Registration Code',
        dataIndex: 'fund_info.fund_registration_code',
        key: 'fund_registration_code',
      },
      {
        title: 'Redemptions',
        dataIndex: 'redemptions',
        key: 'redemptions',
      },
      {
        title: 'Subscriptions',
        dataIndex: 'subscriptions',
        key: 'subscriptions',
      },
      {
        title: 'Management Fees %',
        dataIndex: 'fund_fees.mgmt_fee',
        key: 'mgmt_fee',
      },
      {
        title: 'Incentive Fees %',
        dataIndex: 'fund_fees.incentive_fee',
        key: 'incentive_fee',
      },
    ],
  },
  {
    groupName: 'Assets Info',
    columns: [
      {
        title: 'Fund Assets',
        dataIndex: 'fund_assets',
        key: 'fund_assets',
      },
      {
        title: 'Firm Assets',
        dataIndex: 'firm.firm_assets',
        key: 'firm_assets',
      },
      {
        title: 'Firm Assets Currency',
        dataIndex: 'firm.firm_assets_currency',
        key: 'firm_assets_currency',
      },
      {
        title: 'Firm Assets Rep Date',
        dataIndex: 'firm.firm_assets_date',
        render: (date: string | undefined) => {
          return date ? GenUtil.getFormattedYearMonthAndDay(date) : null;
        },
        key: 'firm_assets_date',
      },
      {
        title: 'Fund Assets Currency',
        dataIndex: 'fund_assets_currency',
        key: 'fund_assets_currency',
      },
      {
        title: 'Fund Assets Rep Date',
        dataIndex: 'fund_assets_rep_date',
        key: 'fund_assets_rep_date',
      },
    ],
  },
  {
    groupName: 'Region',
    columns: [
      {
        title: 'Regional Focus',
        dataIndex: 'regional_focus',
        sorter: true,
        key: 'regional_focus',
        width: 200,
      },
    ],
  },
  {
    groupName: 'Statistics',
    columns: [
      {
        title: 'Latest ROR Date',
        dataIndex: 'latest_ror_date',
        sorter: true,
        key: 'latest_ror_date',
        width: 150,
      },
      {
        title: 'Latest ROR Value',
        dataIndex: 'latest_ror_value',
        sorter: true,
        key: 'latest_ror_value',
      },
      {
        title: 'Track Record',
        dataIndex: 'statistics.track_record',
        sorter: true,
        key: 'track_record',
      },
      {
        title: 'Total Track Record',
        dataIndex: 'statistics.total_track_record',
        sorter: true,
        key: 'total_track_record',
      },
      {
        title: 'Start Date',
        dataIndex: 'statistics.start_date',
        sorter: true,
        key: 'start_date',
      },
      {
        title: 'End Date',
        dataIndex: 'statistics.end_date',
        sorter: true,
        key: 'end_date',
      },
      {
        title: 'Ann ROR %',
        dataIndex: 'statistics.ann_ror',
        sorter: true,
        key: 'ann_ror',
      },
      {
        title: 'Ann Stdev %',
        dataIndex: 'statistics.ann_stdev',
        sorter: true,
        key: 'ann_stdev',
      },
      {
        title: 'Geo Avg Month ROR %',
        dataIndex: 'statistics.geo_avg_month_ror',
        sorter: true,
        key: 'geo_avg_month_ror',
      },
      {
        title: 'High Month ROR %',
        dataIndex: 'statistics.high_month_ror',
        sorter: true,
        key: 'high_month_ror',
      },
      {
        title: 'Low Month ROR %',
        dataIndex: 'statistics.low_month_ror',
        sorter: true,
        key: 'low_month_ror',
      },
      {
        title: 'Stdev %',
        dataIndex: 'statistics.stdev',
        sorter: true,
        key: 'stdev',
      },
      {
        title: '1 Month ROR %',
        dataIndex: 'statistics.one_month_ror',
        sorter: true,
        key: 'one_month_ror',
      },
      {
        title: '3 Month ROR %',
        dataIndex: 'statistics.three_month_ror',
        sorter: true,
        key: 'three_month_ror',
      },
      {
        title: '6 Month ROR %',
        dataIndex: 'statistics.six_month_ror',
        sorter: true,
        key: 'six_month_ror',
      },
      {
        title: '1 Year Ann ROR %',
        dataIndex: 'statistics.one_year_ann_ror',
        sorter: true,
        key: 'one_year_ann_ror',
      },
      {
        title: '2 Year Ann ROR %',
        dataIndex: 'statistics.two_year_ann_ror',
        sorter: true,
        key: 'two_year_ann_ror',
      },
      {
        title: '3 Year Ann ROR %',
        dataIndex: 'statistics.three_year_ann_ror',
        sorter: true,
        key: 'three_year_ann_ror',
      },
      {
        title: '5 Year Ann ROR %',
        dataIndex: 'statistics.five_year_ann_ror',
        sorter: true,
        key: 'five_year_ann_ror',
      },
      {
        title: '7 Year Ann ROR %',
        dataIndex: 'statistics.seven_year_ann_ror',
        sorter: true,
        key: 'seven_year_ann_ror',
      },
      {
        title: '10 Year Ann ROR %',
        dataIndex: 'statistics.ten_year_ann_ror',
        sorter: true,
        key: 'ten_year_ann_ror',
      },

      {
        title: '1 Year Ann Stdev %',
        dataIndex: 'statistics.one_year_ann_stdev',
        sorter: true,
        key: 'one_year_ann_stdev',
      },
      {
        title: '2 Year Ann Stdev %',
        dataIndex: 'statistics.two_year_ann_stdev',
        sorter: true,
        key: 'two_year_ann_stdev',
      },
      {
        title: '3 Year Ann Stdev %',
        dataIndex: 'statistics.three_year_ann_stdev',
        sorter: true,
        key: 'three_year_ann_stdev',
      },
      {
        title: '5 Year Ann Stdev %',
        dataIndex: 'statistics.five_year_ann_stdev',
        sorter: true,
        key: 'five_year_ann_stdev',
      },
      {
        title: '7 Year Ann Stdev %',
        dataIndex: 'statistics.seven_year_ann_stdev',
        sorter: true,
        key: 'seven_year_ann_stdev',
      },
      {
        title: '10 Year Ann Stdev %',
        dataIndex: 'statistics.ten_year_ann_stdev',
        sorter: true,
        key: 'ten_year_ann_stdev',
      },
      {
        title: 'Sharpe Ratio',
        dataIndex: 'statistics.sharpe_ratio',
        sorter: true,
        key: 'sharpe_ratio',
      },

      {
        title: '1 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.one_year_ann_sharpe_ratio',
        sorter: true,
        key: 'one_year_ann_sharpe_ratio',
      },
      {
        title: '2 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.two_year_ann_sharpe_ratio',
        sorter: true,
        key: 'two_year_ann_sharpe_ratio',
      },
      {
        title: '3 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.three_year_ann_sharpe_ratio',
        sorter: true,
        key: 'three_year_ann_sharpe_ratio',
      },
      {
        title: '5 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.five_year_ann_sharpe_ratio',
        sorter: true,
        key: 'five_year_ann_sharpe_ratio',
      },
      {
        title: '7 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.seven_year_ann_sharpe_ratio',
        sorter: true,
        key: 'seven_year_ann_sharpe_ratio',
      },
      {
        title: '10 Year Ann Sharpe Ratio',
        dataIndex: 'statistics.ten_year_ann_sharpe_ratio',
        sorter: true,
        key: 'ten_year_ann_sharpe_ratio',
      },
      {
        title: 'Max Drawdown',
        dataIndex: 'statistics.max_drawdown',
        sorter: true,
        key: 'max_drawdown',
      },
      {
        title: 'YTD',
        dataIndex: 'statistics.ytd',
        sorter: true,
        key: 'ytd',
      },
    ],
  },
  {
    groupName: 'MPT Statistics',
    columns: [
      {
        title: 'Alpha',
        dataIndex: 'statistics.alpha',
        sorter: true,
        key: 'alpha',
      },
      {
        title: 'Beta',
        dataIndex: 'statistics.beta',
        sorter: true,
        key: 'beta',
      },
      {
        title: 'R-Squared',
        dataIndex: 'statistics.r_square',
        sorter: true,
        key: 'r_square',
      },
      {
        title: 'Correlation',
        dataIndex: 'statistics.correlation',
        sorter: true,
        key: 'correlation',
      },
      {
        title: 'Up Alpha',
        dataIndex: 'statistics.up_alpha',
        sorter: true,
        key: 'up_alpha',
      },
      {
        title: 'Up Beta',
        dataIndex: 'statistics.up_beta',
        sorter: true,
        key: 'up_beta',
      },
      {
        title: 'Up R-Squared',
        dataIndex: 'statistics.up_r_square',
        sorter: true,
        key: 'up_r_square',
      },
      {
        title: 'Down Alpha',
        dataIndex: 'statistics.down_alpha',
        sorter: true,
        key: 'down_alpha',
      },
      {
        title: 'Down Beta',
        dataIndex: 'statistics.down_beta',
        sorter: true,
        key: 'down_beta',
      },
      {
        title: 'Down R-Squared',
        dataIndex: 'statistics.down_r_square',
        sorter: true,
        key: 'down_r_square',
      },
    ],
  },
  {
    groupName: 'Fund ESG',
    columns: [
      {
        title: 'Is Fund ESG?',
        dataIndex: 'fund_esg.is_fund_esg',
        key: 'is_fund_esg',
        sorter: true,
      },
      {
        title: 'ESG Strategy',
        dataIndex: 'esg_strategy',
        sorter: true,
        key: 'esg_strategy',
      },
      {
        title: 'ESG Sub-Strategy',
        dataIndex: 'esg_sub_strategy',
        sorter: true,
        key: 'esg_sub_strategy',
      },
      //
      {
        title: 'Fund ESG Positive/Best-in-Class Screening',
        dataIndex: 'fund_esg.app_positivescreen',
        sorter: true,
        key: 'app_positivescreen',
      },
      {
        title: 'Fund ESG Negative/Exclusionary Screening',
        dataIndex: 'fund_esg.app_negativescreen',
        sorter: true,
        key: 'app_negativescreen',
      },
      {
        title: 'Fund ESG Integration',
        dataIndex: 'fund_esg.app_esgintegration',
        sorter: true,
        key: 'app_esgintegration',
      },
      {
        title: 'Fund ESG Impact Investing',
        dataIndex: 'fund_esg.app_impactinvest',
        sorter: true,
        key: 'app_impactinvest',
      },
      {
        title: 'Fund ESG Sustainability Theme Investing',
        dataIndex: 'fund_esg.app_sustaininvest',
        sorter: true,
        key: 'app_sustaininvest',
      },
      //
      {
        title: 'Fund ESG Green Building / Smart Growth',
        dataIndex: 'fund_esg.env_greenbuilding',
        sorter: true,
        key: 'env_greenbuilding',
      },
      {
        title: 'Fund ESG Pollution / Toxic',
        dataIndex: 'fund_esg.env_pollutiontaxes',
        sorter: true,
        key: 'env_pollutiontaxes',
      },
      {
        title: 'Fund ESG Climate Change / Carbon',
        dataIndex: 'fund_esg.env_climatechange',
        sorter: true,
        key: 'env_climatechange',
      },
      {
        title: 'Fund ESG Sustainable Nature Resources / Agriculture',
        dataIndex: 'fund_esg.env_sustainable',
        sorter: true,
        key: 'env_sustainable',
      },
      {
        title: 'Fund ESG Clean Technology',
        dataIndex: 'fund_esg.env_cleantech',
        sorter: true,
        key: 'env_cleantech',
      },
      {
        title: 'Fund ESG Water Use Conservation',
        dataIndex: 'fund_esg.env_waterconservation',
        sorter: true,
        key: 'env_waterconservation',
      },
      //
      {
        title: 'Fund ESG Workplace Safety',
        dataIndex: 'fund_esg.soc_workplacesafety',
        sorter: true,
        key: 'soc_workplacesafety',
      },
      {
        title: 'Fund ESG Community Development',
        dataIndex: 'fund_esg.soc_communitydev',
        sorter: true,
        key: 'soc_communitydev',
      },
      {
        title: 'Fund ESG Labor Relations',
        dataIndex: 'fund_esg.soc_laborrelations',
        sorter: true,
        key: 'soc_laborrelations',
      },
      {
        title: 'Fund ESG Workplace Benefits',
        dataIndex: 'fund_esg.soc_workplacebenefits',
        sorter: true,
        key: 'soc_workplacebenefits',
      },
      {
        title: 'Fund ESG Avoidance of Tobacco or other Harmful Products',
        dataIndex: 'fund_esg.soc_avoidtobacco',
        sorter: true,
        key: 'soc_avoidtobacco',
      },
      {
        title: 'Fund ESG Diversity Anti Bias',
        dataIndex: 'fund_esg.soc_diversityantibias',
        sorter: true,
        key: 'soc_diversityantibias',
      },
      {
        title: 'Fund ESG Human Rights',
        dataIndex: 'fund_esg.soc_humanrights',
        sorter: true,
        key: 'soc_humanrights',
      },
      //
      {
        title: 'Fund ESG Corporate Political Contributions',
        dataIndex: 'fund_esg.gov_corppolcontrib',
        sorter: true,
        key: 'gov_corppolcontrib',
      },
      {
        title: 'Fund ESG Anti Corruption Policies',
        dataIndex: 'fund_esg.gov_anticorruption',
        sorter: true,
        key: 'gov_anticorruption',
      },
      {
        title: 'Fund ESG Executive Compensation',
        dataIndex: 'fund_esg.gov_executivecomp',
        sorter: true,
        key: 'gov_executivecomp',
      },
      {
        title: 'Fund ESG Board Independence',
        dataIndex: 'fund_esg.gov_boardindependence',
        sorter: true,
        key: 'gov_boardindependence',
      },
      {
        title: 'Fund ESG Board Diversity',
        dataIndex: 'fund_esg.gov_boarddiversity',
        sorter: true,
        key: 'gov_boarddiversity',
      },
      //
      {
        title: 'Fund ESG Engage PCM?',
        dataIndex: 'fund_esg.team_engagepcm',
        sorter: true,
        key: 'team_engagepcm',
      },
      // HIDE FOR NOW
      // {
      //   title: 'Fund ESG MSCI',
      //   dataIndex: 'fund_esg.data_msci',
      //   sorter: true,
      //   key: 'data_msci',
      // },
      // {
      //   title: 'Fund ESG Sustainalytics',
      //   dataIndex: 'fund_esg.data_sustainalytics',
      //   sorter: true,
      //   key: 'data_sustainalytics',
      // },
      // {
      //   title: 'Fund ESG Bloomberg',
      //   dataIndex: 'fund_esg.data_bloomberg',
      //   sorter: true,
      //   key: 'data_bloomberg',
      // },
      // {
      //   title: 'Fund ESG Factset',
      //   dataIndex: 'fund_esg.data_factset',
      //   sorter: true,
      //   key: 'data_factset',
      // },
      // {
      //   title: 'Fund ESG Proprietary or Internal',
      //   dataIndex: 'fund_esg.data_internal',
      //   sorter: true,
      //   key: 'data_internal',
      // },
    ],
  },
  {
    groupName: 'Firm ESG',
    columns: [
      {
        title: 'Is Firm ESG?',
        dataIndex: 'firm_esg.is_firm_esg',
        sorter: true,
        key: 'is_firm_esg',
      },
      {
        key: 'applied_firmculture',
        title: 'Firm ESG Firm Culture',
        dataIndex: 'firm_esg.applied_firmculture',
        sorter: true,
      },
      {
        key: 'applied_investmentprocess',
        title: 'Firm ESG Investment Process',
        dataIndex: 'firm_esg.applied_investmentprocess',
        sorter: true,
      },
      {
        key: 'applied_portfolioconstruction',
        title: 'Firm ESG Portfolio Construction',
        dataIndex: 'firm_esg.applied_portfolioconstruction',
        sorter: true,
      },
      {
        key: 'applied_esgproducts',
        title: 'Firm ESG Products',
        dataIndex: 'firm_esg.applied_esgproducts',
        sorter: true,
      },
      {
        key: 'applied_esgintegration',
        title: 'Firm ESG Integration',
        dataIndex: 'firm_esg.applied_esgintegration',
        sorter: true,
      },
      {
        key: 'receive_guidance',
        title: 'Firm ESG  Receive Guidance',
        dataIndex: 'firm_esg.receive_guidance',
        sorter: true,
      },
      {
        key: 'thirdparty_guidance',
        title: 'Firm ESG 3rd Party Guidance',
        dataIndex: 'firm_esg.thirdparty_guidance',
        sorter: true,
      },
      {
        key: 'future',
        title: 'Firm ESG Future',
        dataIndex: 'firm_esg.future',
        sorter: true,
      },
      {
        key: 'applied_esgactivist',
        title: 'Firm ESG Activist / Engaged Ownership',
        dataIndex: 'firm_esg.applied_esgactivist',
        sorter: true,
      },
      {
        key: 'applied_diversityinclusion',
        title: 'Firm ESG Diversity And Inclusion',
        dataIndex: 'firm_esg.applied_diversityinclusion',
        sorter: true,
      },
    ],
  },
  {
    groupName: 'Fund Diversity & Inclusion',
    columns: [
      {
        title: 'Is Minority or Woman Managed Fund?',
        dataIndex: 'fund_diversity_and_inclusion.is_minority_or_woman_managed_fund',
        sorter: true,
        key: 'is_minority_or_woman_managed_fund',
      },
      {
        title: 'Fund Diversity & Inclusion Types',
        dataIndex: 'fund_diversity_and_inclusion.fund_diversity_and_inclusion_types',
        sorter: true,
        key: 'fund_diversity_and_inclusion_types',
        render: (val?: string[]) => {
          return val ? val.join(', ') : '';
        },
      },
    ],
  },
  {
    groupName: 'Firm Diversity & Inclusion',
    columns: [
      {
        title: 'Is Minority or Woman Owned Firm?',
        dataIndex: 'firm_diversity_and_inclusion.is_minority_or_woman_owned_firm',
        sorter: true,
        key: 'is_minority_or_woman_owned_firm',
      },
      {
        title: 'Firm Diversity & Inclusion Types',
        dataIndex: 'firm_diversity_and_inclusion.firm_diversity_and_inclusion_types',
        sorter: true,
        key: 'firm_diversity_and_inclusion_types',
        render: (val?: string[]) => {
          return val ? val.join(', ') : '';
        },
      },
    ],
  },
  {
    groupName: 'Firm Co-Investments',
    columns: [
      {
        title: 'Offer Co-Investments',
        dataIndex: 'firm_coinvestments.offer_coinvestments',
        sorter: true,
        key: 'offer_coinvestments',
      },
      {
        title: 'Typical Deal Size',
        dataIndex: 'firm_coinvestments.typical_size',
        sorter: true,
        key: 'typical_size',
      },
      {
        title: 'Typical Liquidity',
        dataIndex: 'firm_coinvestments.typical_liquidity',
        sorter: true,
        key: 'typical_liquidity',
      },
      {
        title: 'Number of Deals Per Year',
        dataIndex: 'firm_coinvestments.number_of_deals_per_year',
        sorter: true,
        key: 'number_of_deals_per_year',
      },
    ],
  },
];
