import React, { useEffect, FormEvent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Button, Form, Icon, Input, Row, Select, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import { toast } from 'react-toastify';
import { connect, MapStateToProps } from 'react-redux';
import APIService from '../../../shared/api';
import { UserProfileDetails } from '../../../shared/api/models/ResponsesTypes';
import { InitialRootReducer } from '../../../redux/reducers/root.reducer';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import GenUtil from '../../../shared/utils/gen-util';
import { States } from '../../../shared/states';

const { Title } = Typography;

const Wrapper = styled('div')`
  background: white;
  display: flex;
  width: 100%;
  padding: 20px 80px;
  padding-top: 0;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 2;
  margin: 20px auto;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 40px;
  padding-top: 20px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`;

const Header = styled(Title)`
  position: relative;
  padding: 10px 0;
  height: auto;
  text-align: center;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`;

interface Props extends FormComponentProps {}
interface StateProps {
  userProfile: UserProfileDetails;
}
/**
 * Form component to update the user profile
 * @param form
 * @constructor
 */
const UserProfileUpdateForm: React.FC<StateProps & Props & RouteComponentProps> = ({
  userProfile,
  form,
}) => {
  const { getFieldDecorator } = form;

  const {
    data: countriesData,
    loading: countriesLoading,
    invoke: invokeFetchCountries,
  } = useServiceState(APIService.fundService.fetchAllCountries);

  useEffect(() => {
    invokeFetchCountries();
  }, [invokeFetchCountries]);

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const handleUpdateProfile = async (data: Partial<UserProfileDetails>) => {
    try {
      const res = await APIService.authService.updateProfile(data);
      if (res.status === 200) {
        toast.success('Profile Updated!');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        handleUpdateProfile({
          job_title: values.jobTitle,
          business_phone: values.businessPhone,
          address: values.address,
          address2: values.address2,
          city: values.city,
          postal_code: values.postalCode,
          country: values.country,
          state: values.state,
        });
      }
    });
  };
  const getCountryOptions = () => {
    if (_.isEmpty(countriesData)) {
      return;
    } else {
      const countryCodes = Object.keys(countriesData).filter(
        code => !['US', 'GB', 'CA'].includes(code),
      );
      countryCodes.unshift('US', 'GB', 'CA');
      return countryCodes.map((countryKey: any) => {
        return (
          <Select.Option key={countryKey} value={countryKey}>
            {countriesData[countryKey]}
          </Select.Option>
        );
      });
    }
  };
  const getStateOptions = () => {
    const statesOptions = [];
    for (const stateKey in States) {
      statesOptions.push(
        <Select.Option key={stateKey} value={States[stateKey]}>
          {States[stateKey]}
        </Select.Option>,
      );
    }
    return statesOptions;
  };
  const getCountryCode = (country: string) => {
    let countryCode = '';
    if (!_.isEmpty(countriesData)) {
      for (const key in countriesData) {
        if (countriesData[key] === country) {
          countryCode = key;
          break;
        }
      }
    }
    return countryCode;
  };
  return (
    <Container>
      <Wrapper>
        <Form onSubmit={handleSubmit} className="login-form" {...formItemLayout}>
          {/* Company */}
          <Header level={4}>User Profile Data</Header>
          <Form.Item label="Name">
            {getFieldDecorator('username', {
              rules: [],
              initialValue: `${userProfile.first_name} ${userProfile.last_name}`,
            })(
              <Input
                disabled
                prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Company"
              />,
            )}
          </Form.Item>
          <Form.Item label="Company">
            {getFieldDecorator('company', {
              rules: [],
              initialValue: userProfile.company,
            })(
              <Input
                disabled
                prefix={<Icon type="shop" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Company"
              />,
            )}
          </Form.Item>
          <Form.Item label="Expiration Date">
            {getFieldDecorator('expirationDate', {
              rules: [],
              initialValue: userProfile.hfrdb_expiration_date
                ? GenUtil.getFormattedYearMonthAndDay(userProfile.hfrdb_expiration_date)
                : '',
            })(
              <Input
                disabled
                prefix={<Icon type="clock-circle" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Expiration Time"
              />,
            )}
          </Form.Item>
          {/* JobTitle */}
          <Form.Item label="Job Title">
            {getFieldDecorator('jobTitle', {
              rules: [{ required: true, message: 'Job Title is required' }],
              initialValue: userProfile.job_title,
            })(
              <Input
                prefix={<Icon type="project" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Job Title"
              />,
            )}
          </Form.Item>
          {/* Business Phone */}
          <Form.Item label="Business Phone">
            {getFieldDecorator('businessPhone', {
              rules: [{ required: true, message: 'Business Phone is required' }],
              initialValue: userProfile.business_phone,
            })(
              <Input
                prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Business Phone"
              />,
            )}
          </Form.Item>
          {/* Address */}
          <Form.Item label="Address">
            {getFieldDecorator('address', {
              rules: [{ required: true, message: 'Company address is required' }],
              initialValue: userProfile.address,
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Address"
              />,
            )}
          </Form.Item>
          {/* Address 2*/}
          <Form.Item label="Address Line 2">
            {getFieldDecorator('address2', {
              rules: [],
              initialValue: userProfile.address2,
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="Address-2"
              />,
            )}
          </Form.Item>
          {/* City */}
          <Form.Item label="City">
            {getFieldDecorator('city', {
              rules: [{ required: true, message: 'City is required' }],
              initialValue: userProfile.city,
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="City"
              />,
            )}
          </Form.Item>
          {/* State */}
          <Form.Item label="State">
            {getFieldDecorator('state', {
              initialValue: userProfile.state,
            })(
              form.getFieldValue('country') === 'US' ? (
                <Select
                  placeholder="State (USA Only)"
                  showSearch
                  filterOption={(input, option) => {
                    if (!option || !option.props || !option.props.children) {
                      return false;
                    }
                    return (
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                >
                  {getStateOptions()}
                </Select>
              ) : (
                <Input
                  prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="text"
                  placeholder="State/Province"
                />
              ),
            )}
          </Form.Item>
          {/* US/Canada Postal Code */}
          <Form.Item label="Postal Code">
            {getFieldDecorator('postalCode', {
              rules: [],
              initialValue: userProfile.postal_code,
            })(
              <Input
                prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="text"
                placeholder="US/Canada Postal Code"
              />,
            )}
          </Form.Item>
          {/* Country */}
          <Form.Item label={'Country'}>
            {getFieldDecorator('country', {
              rules: [{ required: true, message: 'Country is required' }],
              initialValue: getCountryCode(userProfile.country),
            })(
              <Select
                loading={countriesLoading}
                placeholder="Country"
                showSearch
                filterOption={(input, option) => {
                  if (!option || !option.props || !option.props.children) {
                    return false;
                  }
                  return (
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  );
                }}
                allowClear
              >
                {getCountryOptions()}
              </Select>,
            )}
          </Form.Item>
          <Row style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginTop: 50, width: 160 }}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, InitialRootReducer> = (state: any) => {
  return {
    userProfile: state.rootReducer.userProfile,
  };
};

export default Form.create<Props>({ name: 'UserProfileForm' })(
  withRouter(connect(mapStateToProps)(UserProfileUpdateForm)),
);
