import React from 'react';
import { default as Plotly, PlotData } from 'plotly.js';
import { DistributionRecordList } from '../../../shared/api/models/ResponsesTypes';
import PanelDetailGraph from '../../../shared/components/PanelDetailGraph';

interface OwnProps {
  distribution: DistributionRecordList | undefined;
}

/**
 * This will renders Distribution graph component based on data from profileStats api
 * @param distribution
 * @constructor
 */
const DistributionGraph: React.FC<OwnProps> = ({ distribution }) => {
  const getDistributionGraphData = () => {
    const graphData: Partial<PlotData>[] = [];
    let sortedXAxis: string[] = [];
    if (distribution) {
      const x: string[] = [];
      const distGroup: { b1: number[]; b2: number[]; b3: number[]; fund: number[] } = {
        b1: [],
        b2: [],
        b3: [],
        fund: [],
      };
      for (const key in distribution) {
        x.push(key);
        distGroup.b1.push(distribution[key].b1);
        distGroup.b2.push(distribution[key].b2);
        distGroup.b3.push(distribution[key].b3);
        distGroup.fund.push(distribution[key].fund);
      }
      graphData.push({
        x,
        y: distGroup.b1,
        name: 'B1',
        type: 'bar',
      });
      graphData.push({
        x,
        y: distGroup.b2,
        name: 'B2',
        type: 'bar',
      });
      graphData.push({
        x,
        y: distGroup.b3,
        name: 'B3',
        type: 'bar',
      });
      graphData.push({
        x,
        y: distGroup.fund,
        name: 'Fund',
        type: 'bar',
      });

      sortedXAxis = x.sort((o1: string, o2: string) => {
        const a_val = o1.split('to')[0].replace('..', '');
        const b_val = o2.split('to')[0].replace('..', '');
        if (parseInt(a_val) >= parseInt(b_val)) {
          return 1;
        }
        return -1;
      });
    }

    const graphLayout: Partial<Plotly.Layout> = {
      autosize: true,
      xaxis: {
        showgrid: false,
        showline: false,
        categoryorder: 'array',
        categoryarray: sortedXAxis,
      },
      yaxis: {},
      margin: {
        l: 50,
        r: 50,
        b: 100,
        t: 100,
        pad: 4,
      },
      title: 'Distribution %',
    };
    return {
      header: 'Distribution %',
      graphData: graphData,
      graphLayout: graphLayout,
    };
  };

  return <PanelDetailGraph {...getDistributionGraphData()} />;
};

export default React.memo(DistributionGraph);
