import React from 'react';
import { Modal, Upload, Icon } from 'antd';
import styled from 'styled-components';
import Papa from 'papaparse';
import { Colors } from '../../shared/colors';
import APIService from '../../shared/api';
import UploadCustomFundForm from './UserFund/UploadCustomFundForm';
import { UserFundTableData } from './UserFund/UserFundDataTable';

const { Dragger } = Upload;
const { confirm } = Modal;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const Desc = styled('div')`
  margin-bottom: 60px;
`;

const Download = styled('a')`
  text-decoration: underline;
`;

const UploadInfoText = styled('p')`
  color: ${Colors.primary};
`;

enum UploadStep {
  UPLOAD_FILE,
  SAVE_TO_FUND,
}

/**
 * Upload Custom Fund Modal
 * @param isOpen - The visibility of the modal
 * @param onRequestClose - Callback to close the modal
 */
const UploadCustomFundModal: React.FC<Props> = ({ isOpen, onRequestClose }) => {
  const [step, setStep] = React.useState(UploadStep.UPLOAD_FILE);
  const [fileData, setFileData] = React.useState<UserFundTableData[]>();

  return (
    <Modal
      title="Upload your data"
      visible={isOpen}
      width="90%"
      centered
      footer={null}
      onCancel={() => {
        if (step === UploadStep.SAVE_TO_FUND) {
          confirm({
            title: 'There are unsaved data. Are you sure to close the modal?',
            onOk: () => onRequestClose(),
          });
        } else {
          onRequestClose();
        }
      }}
    >
      {step === UploadStep.UPLOAD_FILE && (
        <>
          <Desc>
            You can upload your custom fund data to be used with HFR existing data.{' '}
            <Download>Download</Download> our sample CSV for reference
          </Desc>

          <Dragger
            accept=".txt, .csv"
            showUploadList={false}
            beforeUpload={file => {
              Papa.parse(file, {
                header: true,
                complete: results => {
                  APIService.eventService.sendUploadFundsAccessEvent();
                  setStep(UploadStep.SAVE_TO_FUND);
                  setFileData(
                    results.data.map((item, index) => ({
                      ...item,
                      key: index,
                    })),
                  );
                },
              });
              // Prevent upload
              return false;
            }}
            style={{ minHeight: '200px' }}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="upload" />
            </p>
            <UploadInfoText>Drag and drop csv file here or click to browse</UploadInfoText>
          </Dragger>
        </>
      )}
      {step === UploadStep.SAVE_TO_FUND && fileData && (
        <UploadCustomFundForm dataFromFile={fileData} onRequestClose={onRequestClose} />
      )}
    </Modal>
  );
};

export default UploadCustomFundModal;
