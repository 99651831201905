import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RankingParams } from '../../shared/api/services/ranking.service';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';
import { CombinedReducers } from '../..';
import { FundDetailStrategy } from '../../shared/api/models/FundDetails';
import StrategyTable from './StrategyTable';
import RankingLayout from './RankingLayout';

type StateProps = {
  strategyOptions: FundDetailStrategy[];
};

type Props = {
  isRiskPremia?: boolean;
};

const StrategyRanking: React.FC<Props & StateProps> = ({ isRiskPremia, strategyOptions }) => {
  const [params, updateParams] = React.useState<Partial<RankingParams>>({});
  const [pageSize, setPageSize] = React.useState(10);

  // Data
  const AVAILABLE_STRATEGIES = [
    'Equity Hedge',
    'Event-Driven',
    'Macro',
    'Relative Value',
    'Fund of Funds',
    'Risk Parity',
    'Blockchain',
  ];
  const availableStrategies = AVAILABLE_STRATEGIES.reduce((acc, curr) => {
    const strategy = strategyOptions.find(item => item.strategy_name === curr);
    if (strategy) {
      return [...acc, strategy];
    }
    return acc;
  }, [] as FundDetailStrategy[]);
  const riskPremiaStrategies = strategyOptions.filter(item =>
    item.strategy_name.includes('Risk Premia'),
  );
  const selectableOptions = isRiskPremia ? riskPremiaStrategies : availableStrategies;

  // initialize state
  const [firstParams, setFirstParams] = React.useState<Partial<RankingParams>>({
    strategy: selectableOptions.length > 0 ? [selectableOptions[0].strategy_code] : undefined,
  });
  const [secondParams, setSecondParams] = React.useState<Partial<RankingParams>>({
    strategy: selectableOptions.length > 1 ? [selectableOptions[1].strategy_code] : undefined,
  });
  const [thirdParams, setThirdParams] = React.useState<Partial<RankingParams>>({
    strategy: selectableOptions.length > 2 ? [selectableOptions[2].strategy_code] : undefined,
  });
  const [fourthParams, setFourthParams] = React.useState<Partial<RankingParams>>({
    strategy: selectableOptions.length > 3 ? [selectableOptions[3].strategy_code] : undefined,
  });

  // services hooks
  const { data: firstData, loading: firstLoading, invoke: fetchFirstStrategy } = useServiceState(
    APIService.rankingService.ranking,
  );
  const { data: secondData, loading: secondLoading, invoke: fetchSecondStrategy } = useServiceState(
    APIService.rankingService.ranking,
  );
  const { data: thirdData, loading: thirdLoading, invoke: fetchThirdStrategy } = useServiceState(
    APIService.rankingService.ranking,
  );
  const { data: fourthData, loading: fourthLoading, invoke: fetchFourthStrategy } = useServiceState(
    APIService.rankingService.ranking,
  );

  // Effects to fetch
  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchFirstStrategy({ ...params, ...firstParams, limit: +pageSize });
  }, [params, firstParams, fetchFirstStrategy, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchSecondStrategy({ ...params, ...secondParams, limit: +pageSize });
  }, [params, secondParams, fetchSecondStrategy, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchThirdStrategy({ ...params, ...thirdParams, limit: +pageSize });
  }, [params, thirdParams, fetchThirdStrategy, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchFourthStrategy({ ...params, ...fourthParams, limit: +pageSize });
  }, [params, fourthParams, fetchFourthStrategy, pageSize]);

  return (
    <RankingLayout
      params={params}
      updateParams={updateParams}
      pageSize={pageSize}
      setPageSize={setPageSize}
    >
      {firstParams.strategy && firstParams.strategy.length > 0 && (
        <StrategyTable
          selectableOptions={isRiskPremia ? riskPremiaStrategies : availableStrategies}
          onSelectStrategy={id => setFirstParams(params => ({ ...params, strategy: [+id] }))}
          onSelectSubStrategy={subId =>
            setFirstParams(params => ({
              ...params,
              sub_strategy: subId ? [subId] : undefined,
            }))
          }
          strategyId={firstParams.strategy[0]}
          dataSource={firstData.length ? firstData : []}
          loading={firstLoading}
        />
      )}
      {secondParams.strategy && secondParams.strategy.length > 0 && (
        <StrategyTable
          selectableOptions={isRiskPremia ? riskPremiaStrategies : availableStrategies}
          onSelectStrategy={id => setSecondParams(params => ({ ...params, strategy: [+id] }))}
          onSelectSubStrategy={subId =>
            setSecondParams(params => ({
              ...params,
              sub_strategy: subId ? [subId] : undefined,
            }))
          }
          strategyId={secondParams.strategy[0]}
          dataSource={secondData.length ? secondData : []}
          loading={secondLoading}
        />
      )}
      {thirdParams.strategy && thirdParams.strategy.length > 0 && (
        <StrategyTable
          selectableOptions={isRiskPremia ? riskPremiaStrategies : availableStrategies}
          onSelectStrategy={id => setThirdParams(params => ({ ...params, strategy: [+id] }))}
          onSelectSubStrategy={subId =>
            setThirdParams(params => ({ ...params, sub_strategy: subId ? [subId] : undefined }))
          }
          strategyId={thirdParams.strategy[0]}
          dataSource={thirdData.length ? thirdData : []}
          loading={thirdLoading}
        />
      )}
      {fourthParams.strategy && fourthParams.strategy.length > 0 && (
        <StrategyTable
          selectableOptions={isRiskPremia ? riskPremiaStrategies : availableStrategies}
          onSelectStrategy={id => setFourthParams(params => ({ ...params, strategy: [+id] }))}
          onSelectSubStrategy={subId =>
            setFourthParams(params => ({ ...params, sub_strategy: subId ? [subId] : undefined }))
          }
          strategyId={fourthParams.strategy[0]}
          dataSource={fourthData.length ? fourthData : []}
          loading={fourthLoading}
        />
      )}
    </RankingLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  strategyOptions: state.searchReducer.strategyOptions,
});

export default connect(mapStateToProps)(StrategyRanking);
