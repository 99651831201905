import * as React from 'react';
import { useEffect, useState } from 'react';
import { Icon, Input } from 'antd';
import styled from 'styled-components';

const EditableCellWrapper = styled.div`
  position: relative;
  & :hover {
    display: inline-block;
  }
`;

const EditableCellInputWrapper = styled.div`
  padding-right: 24px;
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    top: 4px;
    width: 20px;
    cursor: pointer;
    line-height: 28px;
    &:hover {
      color: #108ee9;
    }
  }
`;

const EditableCellTextWrapper = styled.div`
  padding: 5px 24px 5px 5px;
  &:hover .editable-cell-icon {
    color: #108ee9;
    display: inline-block;
  }
  .editable-cell-icon {
    position: absolute;
    right: 0;
    top: 8px;
    width: 20px;
    cursor: pointer;
    line-height: 18px;
    display: none;
  }
`;

interface Props {
  value: string;
  onChange: (val: string) => void;
  type: 'number' | 'text';
}

/**
 * Component used to make table cell editable
 * Used in editing the `% weight` cells of `PortfolioModelTable`
 * @param value - Current value of table cell
 * @param onChange - Handler for editing the value
 */

const EditableCell: React.FC<Props> = ({ value, onChange, type }) => {
  const [val, setVal] = useState<string>(value);
  const [editable, setEditable] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v: string = e.target.value;
    setVal(v);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  const check = () => {
    setEditable(false);
    if (onChange) {
      onChange(val);
    }
  };

  const edit = () => {
    setEditable(true);
  };

  return (
    <EditableCellWrapper>
      {editable ? (
        <EditableCellInputWrapper>
          <Input
            style={{ width: '80%', marginRight: '5px' }}
            value={val}
            type={type}
            onChange={handleChange}
            onPressEnter={check}
          />
          <Icon type="check" className="editable-cell-icon-check" onClick={check} />
        </EditableCellInputWrapper>
      ) : (
        <EditableCellTextWrapper onClick={edit}>
          {value || '0'} %
          <Icon type="edit" className="editable-cell-icon" />
        </EditableCellTextWrapper>
      )}
    </EditableCellWrapper>
  );
};

export default EditableCell;
