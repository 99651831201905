/**
 * Error class for the HttpRequests
 */
export class HTTPError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
    this.name = 'HTTPError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HTTPError);
    }
  }
}

export type FetchHeader = object;

export type RequestOption = {
  url: string;
  data?: any;
  headers?: FetchHeader;
  query?: any;
  responseType?: RESPONSE_TYPES;
  callBefore?: () => void;
  callback?: () => void;
};

export type RequestParam = {
  request: FetchRequest;
  responseType?: RESPONSE_TYPES;
  callBefore?: () => void;
  callback?: () => void;
};

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
export type FetchRequest = {
  method: Method;
  url: string;
  body?: any;
  query?: any;
  headers?: any;
};
export enum RESPONSE_TYPES {
  json = 'json',
  blob = 'blob',
  text = 'text',
}
class Fetch {
  static headers: FetchHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  /**
   * Set global headers instead of setting the same headers on each call.
   * @param headers {Object} headers object
   */
  static setHeaders(headers: FetchHeader) {
    const currentHeaders = Fetch.headers;
    if (typeof headers === 'object') {
      Fetch.headers = { ...currentHeaders, ...headers };
    }
  }

  /**
   * Make a GET request
   *
   * @param {String} url API url to make request to
   * @param {Object} [headers] HTTP Headers
   * @param {Object} [query] Query object
   * @param {String} [responseType] any value of the RESPONSE_TYPES map. Defaults to 'json'
   */
  static get: (options: RequestOption) => any = ({ url, headers, query, responseType }) => {
    const request: FetchRequest = {
      method: 'GET',
      url,
      headers: Object.assign({}, Fetch.headers, headers),
    };
    if (query) {
      const qs = Object.keys(query)
        .filter(k => query[k] !== undefined)
        .map(k => {
          let keyValue = `${encodeURIComponent(k)}=`;
          if (typeof query[k] === 'object') {
            keyValue += encodeURIComponent(JSON.stringify(query[k]));
          } else {
            keyValue += encodeURIComponent(query[k]);
          }
          return keyValue;
        })
        .join('&');
      request.url += `?${qs}`;
    }
    return Fetch._makeRequest({ request, responseType });
  };

  /**
   * Make a PUT request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be updated
   * @param {Object} [headers] HTTP Headers
   * @param {Function} [callBefore] Function to be run before the request
   * @param {Function} [callback] Function to be run after the server responds
   * @param {String} [responseType] any value of the RESPONSE_TYPES map. Defaults to 'json'
   */
  static put: (options: RequestOption) => Promise<any> = ({
    url,
    data,
    headers,
    callBefore,
    callback,
    responseType,
  }) => {
    const request: FetchRequest = {
      method: 'PUT',
      url,
      body: JSON.stringify(data),
      headers: Object.assign(
        {},
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        Fetch.headers,
        headers,
      ),
    };
    return Fetch._makeRequest({ request, callBefore, callback, responseType });
  };

  /**
   * Make a PATCH request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be updated
   * @param {Object} [headers] HTTP Headers
   * @param {Function} [callBefore] Function to be run before the request
   * @param {Function} [callback] Function to be run after the server responds
   * @param {String} [responseType] any value of the RESPONSE_TYPES map. Defaults to 'json'
   */
  static patch: (options: RequestOption) => Promise<any> = ({
    url,
    data,
    headers,
    callBefore,
    callback,
    responseType,
  }) => {
    const request: FetchRequest = {
      method: 'PATCH',
      url,
      body: JSON.stringify(data),
      headers: Object.assign(
        {},
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        Fetch.headers,
        headers,
      ),
    };
    return Fetch._makeRequest({ request, callBefore, callback, responseType });
  };

  /**
   * Make a POST JSON request
   *
   * @param {String} url API url to make request to
   * @param {Object} data The data to be inserted
   * @param {Object} [headers] HTTP Headers
   * @param {String} [responseType] any value of the RESPONSE_TYPES map. Defaults to 'json'
   */
  static post: (options: RequestOption) => Promise<any> = ({
    url,
    data,
    headers,
    responseType,
  }) => {
    const request: FetchRequest = {
      method: 'POST',
      url,
      body: JSON.stringify(data),
      headers: Object.assign(
        {},
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        Fetch.headers,
        headers,
      ),
    };
    return Fetch._makeRequest({ request, responseType });
  };

  /**
   * Make a POST form data request
   *
   * @param {String} url API url to make request to
   * @param {String} data Form data to be inserted
   * @param {Object} [headers] HTTP Headers
   * @param {Function} [callBefore] Function to be run before the request
   * @param {Function} [callback] Function to be run after the server responds
   */
  static postForm: (options: RequestOption) => Promise<any> = ({
    url,
    data,
    headers,
    callBefore,
    callback,
  }) => {
    const request: FetchRequest = {
      method: 'POST',
      url,
      body: data,
      headers: Object.assign({}, Fetch.headers, headers),
    };
    return Fetch._makeRequest({ request, callBefore, callback });
  };

  /**
   * Make a DELETE request
   *
   * @param {String} url API url to make request to
   * @param {Object} [headers] HTTP Headers
   * @param {String} [query] Query string
   * @param {Function} [callBefore] Function to be run before the request
   * @param {Function} [callback] Function to be run after the server responds
   */
  static delete: (options: RequestOption) => Promise<any> = ({
    url,
    headers,
    query,
    callBefore,
    callback,
  }) => {
    const request: FetchRequest = {
      method: 'DELETE',
      url,
      query,
      headers: Object.assign({}, Fetch.headers, headers),
    };
    return Fetch._makeRequest({ request, callBefore, callback });
  };

  /**
   * Make a generic request
   *
   * @param {Object} request FetchRequest to be made. Must be of the form: {method, url, query [optional]}
   * @param {Function} [callBefore] Function to be run before the request
   * @param {String} [responseType] any value of the RESPONSE_TYPES map. Defaults to 'json'
   * @private
   */
  static _makeRequest: (options: RequestParam) => Promise<any> = async ({
    request,
    responseType = RESPONSE_TYPES.json,
  }) => {
    const headers = request.headers || {};

    // Don't set the body if it's a GET request as it will crash on Microsoft Edge
    const params: Partial<FetchRequest> = {
      headers,
      method: request.method || 'GET',
    };
    if (params.method !== 'GET') {
      params.body = request.body || null;
    }

    let response;

    try {
      response = await fetch(request.url, params);
    } catch (e) {
      throw e;
    }

    const responseBody = await response[responseType]();

    if (response.status > 299 || response.status < 200) {
      throw new HTTPError(responseBody);
    }

    return responseBody;
  };
}

export { Fetch };
// exports.RESPONSE_TYPES = Fetch.RESPONSE_TYPES;
// exports.setHeaders = Fetch.setHeaders;
// exports.get = Fetch.get;
// exports.put = Fetch.put;
// exports.put = Fetch.patch;
// exports.post = Fetch.post;
// exports.postForm = Fetch.postForm;
// exports.delete = Fetch.delete;
