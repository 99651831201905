import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import { Collapse, Typography, Spin, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { useServiceState } from '../../../shared/hooks/useServiceState';
import APIService from '../../../shared/api';
import { ReleaseNoteResponse } from '../../../shared/api/services/content-service';

const { Title } = Typography;
const { Panel } = Collapse;

const Wrapper = styled('div')`
  background: white;
  display: flex;
  width: 100%;
  padding: 10px 25px;
  padding-top: 0;
  flex-direction: column;
  height: 100%;
  min-height: -webkit-fill-available;
`;
const Container = styled.div`
  display: flex;
  flex-grow: 2;
  height: 100%;
  margin: 20px auto;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 40px;
  padding-top: 20px;
  padding-bottom: 0;
  overflow-y: auto;
  & .ant-collapse-content {
    background: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 25px;
    border: 0;
  }
`;

const Header = styled(Title)`
  position: relative;
  text-align: center;
  padding: 10px 0;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`;

const ReleaseNoteContainer = styled.div``;

interface Props {}

/**
 * Component rendering all release notes inside Profile page
 * @constructor
 */
const ReleaseNotes: React.FC<Props> = () => {
  const {
    data: releaseNoteData,
    invoke: invokeReleaseNote,
    loading: releaseNotesLoading,
  } = useServiceState(APIService.contentService.fetchReleaseNote);

  useEffect(() => {
    invokeReleaseNote();
  }, [invokeReleaseNote]);

  return (
    <Container>
      <Wrapper>
        <Header level={4}>Release Notes</Header>
        <Spin spinning={releaseNotesLoading}>
          <Collapse
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          >
            {!_.isEmpty(releaseNoteData) &&
              releaseNoteData.map((note: ReleaseNoteResponse, idx: number) => {
                return (
                  <Panel
                    header={`Release note created at ${moment(note.created_date).format('LL')}`}
                    key={String(idx + 1)}
                  >
                    <ReleaseNoteContainer dangerouslySetInnerHTML={{ __html: note.content }} />
                  </Panel>
                );
              })}
          </Collapse>
        </Spin>
      </Wrapper>
    </Container>
  );
};

export default withRouter(ReleaseNotes);
