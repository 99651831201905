import React, { FormEvent } from 'react';
import { Form, Divider, Input, Icon, Button, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import APIService from '../../../shared/api';

const SignUpContainer = styled.div`
  background: white;
  width: 30%;
  min-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 0 40px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
  }
`;

type Props = {
  nextStep: () => void;
  setVerificationCode: (code: string) => void;
  setEmail: (email: string) => void;
} & FormComponentProps;

const PreSignUpForm: React.FC<Props> = ({ form, nextStep, setVerificationCode, setEmail }) => {
  const { getFieldDecorator } = form;
  const [verifyingCode, setVerifyingCode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        if (!verifyingCode) {
          APIService.authService
            .preSignUp(values.email)
            .then(() => {
              notification.success({ message: 'Check your email for registration code!' });
              setEmail(values.email);
              setVerifyingCode(true);
            })
            .catch(err => {
              console.error(err);
              notification.error({ message: 'Something went wrong!' });
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          APIService.authService
            .checkCode(values.email, values.code.trim())
            .then(() => {
              setVerificationCode(values.code.trim());
              nextStep();
              setVerifyingCode(false);
            })
            .catch(err => {
              console.error(err);
              notification.error({ message: 'Something went wrong!' });
            })
            .finally(() => {
              setLoading(true);
            });
        }
      }
    });
  };

  return (
    <SignUpContainer>
      <Form onSubmit={handleSubmit} className="login-form" layout={'horizontal'}>
        <Divider orientation={'left'}>Email to receive registration code</Divider>
        <Inline>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email must be valid' },
              ],
            })(
              <Input
                formNoValidate={true}
                prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="email"
                name="email"
                placeholder="Email"
              />,
            )}
          </Form.Item>
        </Inline>
        {verifyingCode && (
          <Inline>
            <Form.Item>
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Verification Code is required' }],
              })(
                <Input
                  formNoValidate={true}
                  prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Verification Code"
                />,
              )}
            </Form.Item>
          </Inline>
        )}
        <Inline>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ flex: 2 }}
          >
            Submit
          </Button>
          <Link to="/auth/log-in" style={{ flex: 1 }}>
            <Button type="link" style={{ width: '100%' }}>
              Cancel
            </Button>
          </Link>
        </Inline>
      </Form>
    </SignUpContainer>
  );
};

export default Form.create<Props>({ name: 'PreRegistration' })(PreSignUpForm);
