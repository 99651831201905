import { Reducer } from 'redux';
import { FetchInvestorParams } from '../../../shared/api/services/investor-db.service';
import InvestorActionEnums from './investor-action-enums';

export type InvestorPane = {
  title: string;
  key: string;
  id: number;
};

export type InitialInvestorReducer = {
  investorPanes: InvestorPane[];
  investorActiveKey: string;
  investorParams: Partial<FetchInvestorParams>;
};

const initInvestorState: InitialInvestorReducer = {
  investorPanes: [],
  investorActiveKey: 'investor-home',
  investorParams: {},
};

const investorReducer: Reducer<InitialInvestorReducer, any> = (
  state: InitialInvestorReducer = initInvestorState,
  { type, payload },
) => {
  switch (type) {
    case InvestorActionEnums.ADD_PANE: {
      //check if pane is already opened or not
      let isPaneOpenArr = state.investorPanes.filter(pane => pane.key === payload.key);
      // if fund is already opened in tab, set focus to it
      if (isPaneOpenArr.length) {
        return {
          ...state,
          investorActiveKey: payload.key,
        };
      } else if (state.investorPanes.length < 11) {
        const investorPanes = [
          ...state.investorPanes,
          {
            title: payload.title,
            key: payload.key,
            id: payload.id,
          },
        ];
        return {
          ...state,
          investorPanes,
          investorActiveKey: payload.key,
        };
      } else {
        return state;
      }
    }
    case InvestorActionEnums.REMOVE_PANE: {
      // remove the pane from view
      const investorPanes = state.investorPanes.filter(pane => pane.key !== payload);

      // Setting the activeKey to 1 (search funds Pane) if removed pane was previously active Pane
      let { investorActiveKey } = state;
      investorActiveKey = investorActiveKey === payload ? 'investor-home' : investorActiveKey;
      return {
        ...state,
        investorPanes,
        investorActiveKey,
      };
    }
    case InvestorActionEnums.CHANGE_PANE: {
      return {
        ...state,
        investorActiveKey: payload,
      };
    }
    case InvestorActionEnums.UPDATE_SEARCH_PARAMS: {
      return {
        ...state,
        investorParams: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default investorReducer;

export { initInvestorState };
