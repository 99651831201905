import React from 'react';
import { Icon, Layout, Menu } from 'antd';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import UniverseView from '../universe/UniverseView';
import MainLayout from '../../shared/components/MainLayout';
import ModelView from '../model/ModelView';
import UserFundView from '../userfund/UserFundView';
import APIService from '../../shared/api';
import MySearchView from '../mysearch/MySearchView';

const MyDataWrapper = styled('div')`
  .ant-table-body {
    scrollbar-width: auto;
  }
`;

const { Sider } = Layout;

type Props = {} & RouteComponentProps;

/**
 * My Data Container
 */

const MyData: React.FC<Props> = () => {
  const [menuCollapsed, setMenuCollapsed] = React.useState(false);
  const history = useHistory();
  const location = useLocation();

  const selectedMenuItem = location.pathname.split('/')[2];
  const showMyList = () => {
    APIService.eventService.sendViewUclEvent();
    history.push('/my-data/hfr');
  };
  const showMyModel = () => {
    APIService.eventService.sendViewPortfolioModelEvent();
    history.push('/my-data/model');
  };
  const showMyFund = () => {
    APIService.eventService.sendViewMyFundsEvent();
    history.push('/my-data/fund');
  };
  return (
    <MainLayout>
      <Sider theme="dark" collapsible collapsed={menuCollapsed} onCollapse={setMenuCollapsed}>
        <Menu
          mode="inline"
          theme="dark"
          defaultSelectedKeys={[selectedMenuItem]}
          selectedKeys={[selectedMenuItem]}
        >
          <Menu.Item key="hfr" onClick={showMyList}>
            <Icon type="switcher" />
            <span>My List</span>
          </Menu.Item>
          <Menu.Item key="model" onClick={showMyModel}>
            <Icon type="database" />
            <span>My Model</span>
          </Menu.Item>
          <Menu.Item key="fund" onClick={showMyFund}>
            <Icon type="dollar" />
            <span>My Fund</span>
          </Menu.Item>
          <Menu.Item key="search" onClick={() => history.push('/my-data/search')}>
            <Icon type="save" />
            <span>My Search</span>
          </Menu.Item>
        </Menu>
      </Sider>

      <Switch>
        <Route
          path="/my-data/hfr"
          render={props => (
            <MyDataWrapper>
              <UniverseView {...props} />
            </MyDataWrapper>
          )}
        />
        <Route path="/my-data/model" render={props => <ModelView {...props} />} />
        <Route
          path="/my-data/fund"
          render={props => (
            <MyDataWrapper>
              <UserFundView {...props} />
            </MyDataWrapper>
          )}
        />
        <Route
          path="/my-data/search"
          render={props => (
            <MyDataWrapper>
              <MySearchView {...props} />
            </MyDataWrapper>
          )}
        />
        <Redirect to="/my-data/hfr" />
      </Switch>
    </MainLayout>
  );
};

export default MyData;
