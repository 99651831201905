import React, { useState } from 'react';
import { Input, Modal, notification, Tabs } from 'antd';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { AxiosResponse } from 'axios';
import styled from 'styled-components';
import {
  FundListRecord,
  PortfolioModelFundRecord,
  PortfolioModelRecord,
} from '../../../shared/api/models/ResponsesTypes';
import APIService from '../../../shared/api';
import { CombinedReducers } from '../../..';
import UniverseSelect, { UniverseSelectTypeEnum } from '../../../shared/components/UniverseSelect';
import SearchAction from '../search-action';
import { diffTwoArrays } from '../../../shared/utils/utils';
import {
  UniverseFundListRecord,
  UniverseFundListResponse,
} from '../../../shared/api/services/universe-list.service';
import { Colors } from '../../../shared/colors';

const Error = styled('small')`
  color: ${Colors.danger};
`;

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

interface StateProps {
  selectedFunds: string[];
  fundList: FundListRecord[];
  portfolioModels: PortfolioModelRecord[];
}

interface DispatchProps {
  fetchFundList: () => void;
  fetchUniverseList: () => void;
}

enum FundListMode {
  ADD_UNIVERSE = 'ADD_UNIVERSE',
  UPDATE_UNIVERSE = 'UPDATE_UNIVERSE',
}

const FundListModal: React.FC<StateProps & DispatchProps & Props> = ({
  isOpen,
  setOpen,
  selectedFunds,
  fundList,
  portfolioModels,
  fetchFundList,
  fetchUniverseList,
}) => {
  const { TabPane } = Tabs;
  const [selectedListId, setSelectedListId] = useState();
  const [newListName, setNewListName] = useState();
  const [error, setError] = useState();
  const [fundListMode, setFundListMode] = useState<FundListMode>(FundListMode.ADD_UNIVERSE);

  const refetchTable = () => {
    fetchFundList();
    fetchUniverseList();
  };

  const handleNameChange = (name: string) => {
    setNewListName(name);
    if (fundList.find(item => item.name === name)) {
      setError(`The list name "${name}" already exists. Please submit a different name.`);
    } else {
      setError(undefined);
    }
  };

  const handleAddToPortfolioModel = async () => {
    if (!fundList) {
      return;
    }
    let currentFundList;
    const modelId = selectedListId.split('-')[0];
    try {
      const {
        data,
      }: AxiosResponse<PortfolioModelFundRecord[]> = await APIService.portfolioModelService.fetchPortfolioModelFunds(
        modelId,
      );
      currentFundList = data.map((item: PortfolioModelFundRecord) => {
        return item.fund_id;
      });
    } catch (error) {
      notification.error({
        message: 'Failed to get the funds of selected Portfolio model! Please try again later',
      });
      console.error(error);
    }

    try {
      const { added } = diffTwoArrays(currentFundList ? currentFundList : [], selectedFunds);
      await APIService.portfolioModelService.updatePortfolioModel({
        modelId,
        data: {
          add: added.map((fund: string) => {
            return {
              fund_id: fund,
              weight: 0,
            };
          }),
        },
      });
      notification.success({ message: 'Portfolio model updated' });
      refetchTable();
    } catch (e) {
      notification.error({ message: 'Failed to add to Portfolio model! Please try again later' });
      console.error(e);
    } finally {
      setOpen(false);
    }
  };

  const handleAddToFundList = async () => {
    if (!fundList) {
      return;
    }
    let currentFundList;
    try {
      const {
        data,
      }: AxiosResponse<UniverseFundListResponse> = await APIService.universeListService.fetchFundsByListId(
        { listId: selectedListId },
      );
      currentFundList = data.funds
        .filter(item => !!item.fund_id)
        .map((item: UniverseFundListRecord) => {
          return item.fund_id;
        });
    } catch (error) {
      notification.error({
        message: 'Failed to get the funds of selected list! Please try again later',
      });
      console.error(error);
    }

    try {
      const { added } = diffTwoArrays(currentFundList ? currentFundList : [], selectedFunds);
      await APIService.universeListService.updateFundList(selectedListId, {
        add: added,
        delete: [],
      });
      notification.success({ message: 'Fund List updated' });
      refetchTable();
    } catch (e) {
      notification.error({ message: 'Failed to add to Fund List! Please try again later' });
      console.error(e);
    } finally {
      setOpen(false);
    }
  };

  const handleAddNewList = async () => {
    if (!newListName || !selectedFunds) {
      return;
    }
    try {
      await APIService.universeListService.createFundList(newListName, selectedFunds);
      notification.success({ message: `${newListName} list has been created` });
      refetchTable();
      setOpen(false);
    } catch (e) {
      if (e.response.data.error_code === 'ENTITY_NAME_USED') {
        notification.error({
          message: `The list name "${newListName}" already exists. Please submit a different name.`,
        });
      } else {
        notification.error({ message: 'Failed to create new list! Please try again later' });
      }
    }
  };

  return (
    <Modal
      title="Add to Fund List"
      visible={isOpen}
      okText="Add"
      onOk={() => {
        if (fundListMode === FundListMode.ADD_UNIVERSE) {
          handleAddNewList();
        } else if (fundListMode === FundListMode.UPDATE_UNIVERSE) {
          selectedListId && `${selectedListId}`.split('-')[1] === UniverseSelectTypeEnum.PORTFOLIO
            ? handleAddToPortfolioModel()
            : handleAddToFundList();
        }
      }}
      onCancel={() => setOpen(false)}
    >
      <b>{selectedFunds.length} funds selected</b>
      <Tabs activeKey={fundListMode} onChange={key => setFundListMode(key as FundListMode)}>
        <TabPane tab="Add to a new List" key={FundListMode.ADD_UNIVERSE}>
          <span>Add to a new list</span>
          <Input
            placeholder="Enter name"
            value={newListName}
            onChange={event => handleNameChange(event.target.value)}
          />
          {error && <Error>{error}</Error>}
        </TabPane>
        <TabPane tab="Add to an existing List" key={FundListMode.UPDATE_UNIVERSE}>
          <span>Add to an existing list</span>
          <UniverseSelect
            data={fundList}
            models={portfolioModels}
            selectedId={selectedListId}
            onChange={value => setSelectedListId(value.key)}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => {
  return {
    selectedFunds: state.rootReducer.selectedFunds,
    fundList: state.rootReducer.fundList,
    portfolioModels: state.modelReducer.portfolioModels,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    fetchFundList: () => SearchAction.fetchFundList()(dispatch),
    fetchUniverseList: () => SearchAction.fetchUniverseList()(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundListModal);
