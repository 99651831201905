import { Dispatch } from 'react';
import { AxiosResponse } from 'axios';
import {
  FundDetailRegionalFocus,
  FundDetailRegionalFocusCountry,
  FundDetailStrategy,
  FundDetailSubStrategy,
} from '../../shared/api/models/FundDetails';
import APIService from '../../shared/api';
import {
  BenchmarkRecord,
  CodeRecord,
  ValueRecord,
  FundListRecord,
} from '../../shared/api/models/ResponsesTypes';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import FundDetailsUtils from '../funddetails/fund-details-utils';
import SearchActionEnums from './redux/search.ActionEnums';

class SearchAction {
  static fetchStrategyOptions(): (dispatch: Dispatch<any>) => Promise<FundDetailStrategy[]> {
    return async (dispatch: Dispatch<any>): Promise<FundDetailStrategy[]> => {
      try {
        const res: AxiosResponse<FundDetailStrategy[]> = await APIService.searchService.fetchStrategies();
        dispatch({
          type: SearchActionEnums.UPDATE_STRATEGY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchSubStrategyOptions(): (dispatch: Dispatch<any>) => Promise<FundDetailSubStrategy[]> {
    return async (dispatch: Dispatch<any>): Promise<FundDetailSubStrategy[]> => {
      try {
        const res: AxiosResponse<FundDetailSubStrategy[]> = await APIService.searchService.fetchSubStrategies();
        dispatch({
          type: SearchActionEnums.UPDATE_SUB_STRATEGY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchRegionalFocusOptions(): (
    dispatch: Dispatch<any>,
  ) => Promise<FundDetailRegionalFocusCountry[]> {
    return async (dispatch: Dispatch<any>): Promise<FundDetailRegionalFocusCountry[]> => {
      try {
        const res: AxiosResponse<FundDetailRegionalFocusCountry[]> = await APIService.searchService.fetchRegionalFocus();
        dispatch({
          type: SearchActionEnums.UPDATE_REGIONAL_FOCUS_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchRegionalFocusCountryOptions(): (
    dispatch: Dispatch<any>,
  ) => Promise<FundDetailRegionalFocus[]> {
    return async (dispatch: Dispatch<any>): Promise<FundDetailRegionalFocus[]> => {
      try {
        const res: AxiosResponse<FundDetailRegionalFocus[]> = await APIService.searchService.fetchRegionalFocusCountry();
        dispatch({
          type: SearchActionEnums.UPDATE_REGIONAL_FOCUS_COUNTRY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchLiquidAltTypeOptions(): (dispatch: Dispatch<any>) => Promise<CodeRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<CodeRecord[]> => {
      try {
        const res: AxiosResponse<CodeRecord[]> = await APIService.searchService.fetchLiquidAltTypes();
        dispatch({
          type: SearchActionEnums.UPDATE_LIQUID_ALT_TYPE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchLiquidAltRegionOptions(): (dispatch: Dispatch<any>) => Promise<CodeRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<CodeRecord[]> => {
      try {
        const res: AxiosResponse<CodeRecord[]> = await APIService.searchService.fetchLiquidAltRegions();
        dispatch({
          type: SearchActionEnums.UPDATE_LIQUID_ALT_REGION_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchMax12B1FeeOptions(): (dispatch: Dispatch<any>) => Promise<ValueRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<ValueRecord[]> => {
      try {
        const res: AxiosResponse<ValueRecord[]> = await APIService.searchService.fetchMax12B1Fees();
        dispatch({
          type: SearchActionEnums.UPDATE_MAX_12B1_FEE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchMaxFrontFeeOption(): (dispatch: Dispatch<any>) => Promise<ValueRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<ValueRecord[]> => {
      try {
        const res: AxiosResponse<ValueRecord[]> = await APIService.searchService.fetchMaxFrontFees();
        dispatch({
          type: SearchActionEnums.UPDATE_MAX_FRONT_FEE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchMaxDeferredFeeOption(): (dispatch: Dispatch<any>) => Promise<ValueRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<ValueRecord[]> => {
      try {
        const res: AxiosResponse<ValueRecord[]> = await APIService.searchService.fetchMaxDeferredFees();
        dispatch({
          type: SearchActionEnums.UPDATE_MAX_DEFERRED_FEE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchProductClassOptions(): (dispatch: Dispatch<any>) => Promise<CodeRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<CodeRecord[]> => {
      try {
        const res: AxiosResponse<CodeRecord[]> = await APIService.searchService.fetchProductClassOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_PRODUCT_CLASS_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchBenchmarkOptions(): (dispatch: Dispatch<any>) => Promise<BenchmarkRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<BenchmarkRecord[]> => {
      try {
        const res: AxiosResponse<BenchmarkRecord[]> = await APIService.searchService.fetchBenchmarkOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_BENCHMARK_OPTIONS,
          payload: { data: res.data.sort((a, b) => (a.name > b.name ? 1 : -1)) },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchAdvanceNoticeOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchAdvanceNoticeOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_ADVANCE_NOTICE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchMinInvestmentCurrOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchMinInvestmentCurrOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_MIN_INVESTMENT_CURR_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchReportingOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchReportingOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_REPORTING_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchReportingStyleOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchReportingStyleOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_REPORTING_STYLE_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchReturnsCurrencyOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchReturnsCurrencyOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_RETURNS_CURRENCY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchRedemptionOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchRedemptionOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_REDEMPTION_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }
  static fetchSubscriptionsOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchSubscriptionsOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_SUBSCRIPTIONS_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchFirmAssetCurrencyOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchFirmAssetCurrencyOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_FIRM_ASSET_CURRENCY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchFundAssetCurrencyOptions(): (dispatch: Dispatch<any>) => Promise<string[]> {
    return async (dispatch: Dispatch<any>): Promise<string[]> => {
      try {
        const res: AxiosResponse<string[]> = await APIService.searchService.fetchFundAssetCurrencyOptions();
        dispatch({
          type: SearchActionEnums.UPDATE_FUND_ASSET_CURRENCY_OPTIONS,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchFundList(): (dispatch: Dispatch<any>) => Promise<FundListRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<FundListRecord[]> => {
      try {
        const res: AxiosResponse<FundListRecord[]> = await APIService.universeListService.fetchFundList();
        dispatch({
          type: RootActionEnums.FETCH_FUND_LIST_DATA,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static fetchUniverseList(): (dispatch: Dispatch<any>) => Promise<FundListRecord[]> {
    return async (dispatch: Dispatch<any>): Promise<FundListRecord[]> => {
      try {
        const res: AxiosResponse<FundListRecord[]> = await APIService.universeListService.fetchUniverseList();
        dispatch({
          type: RootActionEnums.FETCH_UNIVERSE_LIST_DATA,
          payload: { data: res.data },
        });
        return res.data;
      } catch (e) {
        return [];
      }
    };
  }

  static async fetchStaticHFRIndexes(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any[]> = await APIService.hfrIndexService.fetchStaticIndex();
      dispatch({
        type: RootActionEnums.FETCH_STATIC_HFR_INDEX_DATA,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: RootActionEnums.FETCH_STATIC_HFR_INDEX_DATA,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchDynamicHFRIndexes(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any[]> = await APIService.hfrIndexService.fetchDynamicIndex();
      dispatch({
        type: RootActionEnums.FETCH_DYNAMIC_HFR_INDEX_DATA,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: RootActionEnums.FETCH_DYNAMIC_HFR_INDEX_DATA,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchCountryOptions(dispatch: Dispatch<any>) {
    try {
      // const res: AxiosResponse<any[]> = await APIService.fundService.fetchAllCountryName();
      const res: AxiosResponse<any> = await APIService.searchService.fetchCountries();

      dispatch({
        type: SearchActionEnums.UPDATE_COUNTRY_OPTIONS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_COUNTRY_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchStateOptions(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any[]> = await APIService.fundService.fetchStateCodes();
      dispatch({
        type: SearchActionEnums.UPDATE_STATE_OPTIONS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_STATE_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchRORDateRangeOptions(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.fundService.fetchRORDateRange();
      dispatch({
        type: SearchActionEnums.UPDATE_ROR_DATE_RANGE_OPTIONS,
        payload: {
          data: FundDetailsUtils._getDatesRangeList(res.data.start_date, res.data.end_date),
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_ROR_DATE_RANGE_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchPeerGroupOptions(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.fundService.fetchPeerGroup();
      dispatch({
        type: SearchActionEnums.UPDATE_PEER_GROUP_OPTIONS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_PEER_GROUP_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchNonSecRegAuthorities(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.searchService.fetchNonSecRegAuthorities();
      dispatch({
        type: SearchActionEnums.UPDATE_NON_SEC_REG_AUTHORITIES_OPTIONS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_NON_SEC_REG_AUTHORITIES_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchNonSecCountryOptions(dispatch: Dispatch<any>) {
    try {
      // const res: AxiosResponse<any[]> = await APIService.fundService.fetchAllCountryName();
      const res: AxiosResponse<any> = await APIService.searchService.fetchNonSecCountries();
      dispatch({
        type: SearchActionEnums.UPDATE_NON_SEC_COUNTRY_OPTIONS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_NON_SEC_COUNTRY_OPTIONS,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchEsgStrategies(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.searchService.fetchEsgStrategies();
      dispatch({
        type: SearchActionEnums.UPDATE_ESG_STRATEGIES,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_ESG_STRATEGIES,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchEsgSubStrategies(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.searchService.fetchEsgSubStrategies();
      dispatch({
        type: SearchActionEnums.UPDATE_ESG_SUB_STRATEGIES,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_ESG_SUB_STRATEGIES,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchMinorityTypes(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.searchService.fetchMinorityTypes();
      dispatch({
        type: SearchActionEnums.UPDATE_MINORITY_TYPES,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_MINORITY_TYPES,
        payload: {
          data: [],
        },
      });
    }
  }

  static async fetchFundDropdowns(dispatch: Dispatch<any>) {
    try {
      const res: AxiosResponse<any> = await APIService.searchService.fetchFundDropdowns();
      dispatch({
        type: SearchActionEnums.UPDATE_FUND_DROPDOWNS,
        payload: {
          data: res.data,
        },
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SearchActionEnums.UPDATE_FUND_DROPDOWNS,
        payload: {
          data: [],
        },
      });
    }
  }
}

export default SearchAction;
