import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { Row, Button, Form, Icon, Input, Tooltip, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import { toast } from 'react-toastify';
import APIService from '../../../shared/api';

const { Title } = Typography;

const Wrapper = styled('div')`
  background: white;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0 80px;
  padding-top: 0;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
const Container = styled.div`
  display: flex;
  flex-grow: 2;
  height: 90vh;
  min-width: 500px;
  margin: 20px auto;
  flex-direction: column;
  justify-content: safe center;
  align-items: center;
  padding: 40px;
  padding-top: 20px;
  overflow-y: auto;
  form {
    margin: auto;
    padding: 40px 0;
    width: 100%;
  }
`;

const Header = styled(Title)`
  position: relative;
  padding: 10px 0;
  height: auto;
  margin: 0.5rem 0 1.5rem 0;
  display: block;
`;

interface Props extends FormComponentProps {}

/**
 * Form component to update the user's password
 * @param form
 * @constructor
 */

const UserProfileChangePasswordForm: React.FC<Props & RouteComponentProps> = ({ form }) => {
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const compareToFirstPassword = (_: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Password does not match!');
    } else {
      callback();
    }
  };
  const handleUpdatePassword = async (data: { password: string; newPassword: string }) => {
    try {
      const res = await APIService.authService.updatePassword(data);
      if (res.status === 200) {
        toast.success('Password Updated!');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        handleUpdatePassword({ password: values.password, newPassword: values.newPassword });
      }
    });
  };

  return (
    <Container>
      <Wrapper>
        <Form onSubmit={handleSubmit} className="change-password-form" {...formItemLayout}>
          <Header level={4}>Change Password</Header>
          <Form.Item style={{ marginTop: 40 }} label={'Your Password'}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Password is required!' }],
              initialValue: '',
            })(
              <Input
                prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Your Current password"
              />,
            )}
          </Form.Item>
          <Form.Item label={'Choose Password'}>
            {getFieldDecorator('newPassword', {
              rules: [
                { required: true, message: 'Password is required' },
                {
                  pattern: RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'),
                  message: (
                    <Tooltip title="Password must contain atleast 8 characters, 1 number, 1 lowercase, 1 uppercase and 1 special character">
                      <Icon type="question-circle" theme="filled" style={{ marginRight: 5 }} />
                      <span>Password is invalid</span>
                    </Tooltip>
                  ),
                },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Choose Password"
              />,
            )}
          </Form.Item>
          <Form.Item label={'Confirm Password'}>
            {getFieldDecorator('passwordConfirm', {
              rules: [
                { required: true, message: 'Confirm password is required!' },
                { validator: compareToFirstPassword },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Confirm Password"
              />,
            )}
          </Form.Item>
          <Row style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ marginTop: 50, width: 160 }}
            >
              Update
            </Button>
          </Row>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Form.create<Props>({ name: 'ChangePassword' })(
  withRouter(UserProfileChangePasswordForm),
);
