import { Button, Col, Icon, Layout, Pagination, Row, Table, Tabs } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { ColumnProps, SorterResult } from 'antd/lib/table';
import JsFileDownload from 'js-file-download';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import APIService from '../../shared/api';
import { DisclaimerResponse, UserProfileDetails } from '../../shared/api/models/ResponsesTypes';
import {
  FetchInvestorParams,
  InvestorDatabaseType,
  InvestorDBDropdownRespone,
  InvestorDBResponse,
} from '../../shared/api/services/investor-db.service';
import { Colors } from '../../shared/colors';
import MainLayout from '../../shared/components/MainLayout';
import { useServiceState } from '../../shared/hooks/useServiceState';
import AddToGroupModal from './AddToGroupModal';
import InvestorColumnBuilder from './columnbuilder/InvestorColumnBuilder';
import { allColumns, defaultColumns } from './columnbuilder/investorColumnSource';
import InvestorDetail from './InvestorDetail';
import InvestorMap from './InvestorMap';
import InvestorActionEnums from './redux/investor-action-enums';
import { InitialInvestorReducer, InvestorPane } from './redux/investor.reducer';
import { SearchFormInvestor } from './SearchFormInvestor';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
    height: 100%;
    overflow: hidden;

    .ant-tabs-tabpane-active {
      height: 100%;
    }
  }
  .ant-table-body {
    ::-webkit-scrollbar {
      height: 8px;
    }
    scrollbar-width: thin;
    div::-webkit-scrollbar-track {
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.3);
    }

    div::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }

  .ant-table-pagination {
    display: none;
  }
`;
const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Actions = styled.div`
  & > .ant-btn-group {
    margin-bottom: 0;
  }
  & > button {
    margin-right: 10px;
  }
`;

const CompanyName = styled('span')`
  color: ${Colors.primary};
  cursor: pointer;
`;

enum ViewTypeEnum {
  TABLE = 'TABLE',
  MAP = 'MAP',
}

const DisclaimerContainer = styled(PerfectScrollbar)`
  overflow-y: auto;
  min-height: 80px;
  max-height: 80px;
  padding: 0 20px;
  font-size: 9px;
`;

const MILLIONS = 1000000;
export const FieldFilterMillions = [
  'total_assets_gte',
  'total_assets_lte',
  'hedge_fund_assets_gte',
  'hedge_fund_assets_lte',
  'alternative_assets_gte',
  'alternative_assets_lte',
];

interface DispatchProps {
  changePane: (key: string) => void;
  removePane: (key: string) => void;
  addPane: (data: InvestorPane) => void;
  setParams: (params: Partial<FetchInvestorParams>) => void;
}

type Props = {} & RouteComponentProps;
type StateProps = {
  investorColumns: ColumnProps<InvestorDatabaseType>[];
  investorPanes: InvestorPane[];
  investorActiveKey: string;
  investorParams: Partial<FetchInvestorParams>;
  user: UserProfileDetails;
};

const InvestorDatabase: React.FC<Props & StateProps & DispatchProps> = ({
  investorColumns,
  changePane,
  removePane,
  addPane,
  setParams,
  investorPanes,
  investorActiveKey,
  investorParams,
  user,
}) => {
  const [showColumnModal, setShowColumnModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);

  const [showSearch, setShowSearch] = React.useState(true);
  const [showAddTo, setShowAddTo] = React.useState(false);
  const [selectedInvestors, setSelectedInvestors] = React.useState<string[] | undefined>();
  const [viewType, setViewType] = React.useState(ViewTypeEnum.TABLE);
  const [showDisclaimer, setShowDisclaimer] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);

  const { data: listInvestor, invoke: invokeInvestorSearch, loading } = useServiceState<
    InvestorDBResponse
  >(APIService.investorDBService.searchInvestor);

  const { data: locationData, invoke: fetchLocations } = useServiceState(
    APIService.investorDBService.fetchLocations,
  );

  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.investorDBService.fetchDisclaimer);

  React.useEffect(() => {
    if (investorParams) {
      invokeInvestorSearch({ page, limit: pageSize, ...investorParams });
    }
  }, [investorParams, invokeInvestorSearch, page, pageSize]);

  React.useEffect(() => {
    if (investorParams) {
      fetchLocations(investorParams);
    }
  }, [fetchLocations, investorParams]);

  React.useEffect(() => {
    invokeDisclaimerData();
  }, [invokeDisclaimerData]);

  const { data: listDropdownSearch, invoke: invokeDropdownSearch } = useServiceState<
    InvestorDBDropdownRespone
  >(APIService.investorDBService.getDropdownSearch);

  React.useEffect(() => {
    invokeDropdownSearch();
  }, [invokeDropdownSearch]);

  const handleAddRow = (record: InvestorDatabaseType) => {
    addPane({ title: record.company_name, key: record.hfr_id.toString(), id: record.hfr_id });
  };

  const onChange = (activeKey: string) => {
    changePane(activeKey);
  };

  const handleEdit = (targetKey: string | React.MouseEvent<HTMLElement>) => {
    if (typeof targetKey === 'string') {
      removePane(targetKey as string);
    }
  };

  const columns = investorColumns ? investorColumns : defaultColumns;

  const handleTableChange = (sorter: SorterResult<any>) => {
    if (sorter.order) {
      setParams({
        ...investorParams,
        sort_by: sorter.columnKey,
        order_by: sorter.order === 'ascend' ? 'ASC' : 'DESC',
      });
    } else {
      const { sort_by, order_by, ...rest } = investorParams;
      sort_by && order_by && setParams(rest);
    }
  };

  const handleSearch = (p: Partial<FetchInvestorParams>) => {
    setPage(1);
    const changeFieldMillions = Object.keys(p).reduce((acc, key) => {
      if (FieldFilterMillions.includes(key)) {
        return { ...acc, [key]: Number(p[key as keyof FetchInvestorParams]) * MILLIONS };
      }
      return { ...acc, [key]: p[key as keyof FetchInvestorParams] };
    }, {});

    investorParams.sort_by && investorParams.order_by
      ? setParams({
          ...changeFieldMillions,
          sort_by: investorParams.sort_by,
          order_by: investorParams.order_by,
        })
      : setParams(changeFieldMillions);
  };

  const handleSelectAll = () => {
    setSelectedInvestors(listInvestor.results.map(item => item.company_name));
  };
  const handleClearAll = () => {
    setSelectedInvestors([]);
  };

  const handlePageSizeChange = (_current: number, size: number) => {
    setPage(1);
    setPageSize(size);
  };

  const handelViewTypeChange = (type: ViewTypeEnum) => {
    setViewType(type);
  };

  const handleExport = async () => {
    setExportLoading(true);
    const res = await APIService.investorDBService.fetchExportInvestorDB({ ...investorParams });
    const filename = 'hfr-investordb-results.csv';
    JsFileDownload(res.data, filename, 'application/csv');
    setExportLoading(false);
  };

  return (
    <MainLayout>
      <StyledTabs
        hideAdd
        activeKey={investorActiveKey}
        type="editable-card"
        onChange={onChange}
        onEdit={handleEdit}
      >
        <TabPane tab="Investor Database" key="investor-home" closable={false}>
          <Container>
            <Layout.Sider
              title="Search Funds"
              theme="light"
              collapsible
              collapsedWidth={0}
              collapsed={!showSearch}
              width={400}
              style={{
                height: '100%',
                overflowY: 'auto',
                position: 'relative',
                borderRight: showSearch ? '1px solid #d9d9d9' : 'none',
                boxSizing: 'border-box',
              }}
              trigger={null}
            >
              <SearchFormInvestor
                onSearch={handleSearch}
                listDropdownSearch={listDropdownSearch}
                investorParams={investorParams}
              />
            </Layout.Sider>

            <div
              style={{
                display: 'flex',
                flex: 1,
                overflowX: 'auto',
                flexDirection: 'column',
              }}
            >
              <Row style={{ margin: '8px 16px', justifyContent: 'space-between' }}>
                <Col span={12}>
                  <Button onClick={() => setShowSearch(!showSearch)} style={{ marginRight: 16 }}>
                    <Icon type="search" />
                  </Button>
                  <ButtonGroup>
                    <Button
                      disabled={viewType === ViewTypeEnum.TABLE}
                      onClick={() => handelViewTypeChange(ViewTypeEnum.TABLE)}
                    >
                      Table
                    </Button>
                    <Button
                      disabled={viewType === ViewTypeEnum.MAP}
                      onClick={() => handelViewTypeChange(ViewTypeEnum.MAP)}
                    >
                      Map
                    </Button>
                  </ButtonGroup>
                </Col>
                {viewType === ViewTypeEnum.TABLE && (
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="link" onClick={() => setShowColumnModal(true)}>
                      Filter Columns
                    </Button>
                  </Col>
                )}
              </Row>
              {viewType === ViewTypeEnum.TABLE && (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    overflowX: 'auto',
                    flexDirection: 'column',
                  }}
                >
                  <Table
                    rowKey="hfr_id"
                    dataSource={listInvestor.results}
                    columns={columns.map(col => {
                      const dynamicColWidth = (window.innerWidth - 20 * 4) / columns.length;
                      if (col.key === 'company_name') {
                        return {
                          ...col,
                          sorter: true,
                          width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
                          onCell: (record: InvestorDatabaseType) => ({
                            onClick: () => handleAddRow(record),
                          }),
                          render: text => <CompanyName>{text}</CompanyName>,
                        };
                      }
                      if (
                        col.key === 'investment_strategy_string' ||
                        col.key === 'other_investment_professionals'
                      ) {
                        return {
                          ...col,
                          sorter: true,
                          width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
                          render: text => {
                            if (!text) {
                              return;
                            }
                            const res = text.split(';');
                            return (
                              <div>
                                {res.map((item: string) => (
                                  <div key={item}>{item.trim()}</div>
                                ))}
                              </div>
                            );
                          },
                        };
                      }

                      return {
                        ...col,
                        sorter: true,
                        width: dynamicColWidth < 200 ? 200 : dynamicColWidth,
                      };
                    })}
                    rowSelection={{
                      selectedRowKeys: selectedInvestors,
                      onChange: (_, selectedRows) => {
                        setSelectedInvestors(
                          selectedRows.map((row: InvestorDatabaseType) => row.company_name),
                        );
                      },
                    }}
                    scroll={{ y: 'calc(100vh - 350px)', x: 'min-content' }}
                    loading={loading}
                    pagination={false}
                    onChange={(_pagination, _filter, sorter) => {
                      handleTableChange(sorter);
                    }}
                  />
                  <Row
                    type="flex"
                    justify="space-between"
                    style={{ marginTop: 16, padding: '0 16px' }}
                  >
                    <Actions>
                      <Button.Group>
                        <Button onClick={handleSelectAll}>Select All</Button>
                        <Button onClick={handleClearAll}>Clear All</Button>
                      </Button.Group>
                      <Button
                        type="primary"
                        disabled={!selectedInvestors || selectedInvestors.length <= 0}
                        onClick={() => setShowAddTo(true)}
                      >
                        Add to...
                      </Button>
                      {selectedInvestors && (
                        <AddToGroupModal
                          isOpen={showAddTo}
                          onRequestClose={() => setShowAddTo(false)}
                          selectedInvestors={selectedInvestors}
                        />
                      )}

                      <Button
                        type="primary"
                        disabled={user.hfrdb_trial}
                        style={{ width: 90 }}
                        onClick={handleExport}
                      >
                        {exportLoading ? <Icon type="loading" /> : 'Export'}
                      </Button>
                    </Actions>
                    <Pagination
                      showSizeChanger
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                      onChange={currentPage => setPage(currentPage)}
                      total={listInvestor ? listInvestor.count : 0}
                      current={page}
                      pageSizeOptions={['10', '25', '50', '100']}
                      onShowSizeChange={handlePageSizeChange}
                    />
                  </Row>
                  <Button
                    type="link"
                    style={{ alignSelf: 'flex-end', fontSize: '12px' }}
                    onClick={() => setShowDisclaimer(!showDisclaimer)}
                  >
                    {showDisclaimer ? 'Hide Disclaimer' : 'Show Disclaimer'}
                  </Button>
                  {showDisclaimer && (
                    <DisclaimerContainer
                      dangerouslySetInnerHTML={{
                        __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
                      }}
                    />
                  )}
                </div>
              )}
              {viewType === ViewTypeEnum.MAP && <InvestorMap data={locationData} />}
            </div>
          </Container>
          <InvestorColumnBuilder
            isOpen={showColumnModal}
            onRequestClose={() => setShowColumnModal(false)}
          />
        </TabPane>
        {investorPanes.map(tab => {
          // const tabItem = listInvestor.results.find(item => item.company_name === tab.title);
          // if (!tabItem) {
          //   return null;
          // }
          return (
            <TabPane tab={tab.title} key={tab.key}>
              <InvestorDetail investorId={tab.id} disclaimerData={disclaimerData} />
            </TabPane>
          );
        })}
      </StyledTabs>
    </MainLayout>
  );
};

const mapStateToProps: MapStateToProps<
  StateProps,
  Props,
  InitialInvestorReducer & InitialRootReducer
> = (state: any) => {
  const flattenAllColumns = allColumns.flatMap(item => item.columns);
  const columns = state.rootReducer.investorColumns;
  return {
    investorColumns: columns
      ? columns.map((key: any) => flattenAllColumns.find(item => item.key === key))
      : defaultColumns,
    investorPanes: state.investorReducer.investorPanes,
    investorActiveKey: state.investorReducer.investorActiveKey,
    investorParams: state.investorReducer.investorParams,
    user: state.rootReducer.userProfile,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, Props> = dispatch => {
  return {
    changePane: (key: string) => {
      dispatch({ type: InvestorActionEnums.CHANGE_PANE, payload: key });
    },
    removePane: (key: string) => {
      dispatch({ type: InvestorActionEnums.REMOVE_PANE, payload: key });
    },
    addPane: (data: InvestorPane) => {
      dispatch({ type: InvestorActionEnums.ADD_PANE, payload: data });
    },
    setParams: (params: Partial<FetchInvestorParams>) => {
      dispatch({ type: InvestorActionEnums.UPDATE_SEARCH_PARAMS, payload: params });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvestorDatabase);
