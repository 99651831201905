import { Collapse, Row, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect, MapDispatchToProps } from 'react-redux';
import styled, { css } from 'styled-components';
import { oc } from 'ts-optchain';
import RootActionEnums from '../../redux/actions/root.ActionEnums';
import { FundFirmPane } from '../../redux/reducers/root.reducer';
import APIService from '../../shared/api';
import { FirmDetailResponse, FirmFundDetail } from '../../shared/api/models/FundDetails';
import { DisclaimerResponse } from '../../shared/api/models/ResponsesTypes';
import { Colors } from '../../shared/colors';
import { useServiceState } from '../../shared/hooks/useServiceState';
import GenUtil from '../../shared/utils/gen-util';
import FirmFundDescription from './components/FirmFundDescription';
import FundDetailsUtils from './fund-details-utils';

const { Panel } = Collapse;
const Container = styled(PerfectScrollbar)`
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px;

  h2 {
    text-align: center;
  }

  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-content {
    border: none !important;
    margin-top: 0 !important;
  }
`;

const FundName = styled('span')<{ hasPermission?: boolean }>`
  ${props =>
    props.hasPermission &&
    css`
      color: ${Colors.primary};
      cursor: pointer;
    `};
`;

const DisclaimerContainer = styled(Row)`
  margin-top: 20px;
  font-size: 9px;
`;
interface DispatchProps {
  addPane: (data: FundFirmPane) => void;
}

interface Props {
  firmId: string;
}

/**
 * Firm Detail View Showing firm details and list of funds within it
 * @param firmId
 * @param addPane
 * @constructor
 */
const FirmDetailsView: React.FC<DispatchProps & Props> = ({ firmId, addPane }) => {
  const { data: firmData, invoke: invokeFirmDetails, loading: loadingFirm } = useServiceState<
    FirmDetailResponse
  >(APIService.fundService.fetchFirmDetails);
  const { data: disclaimerData, invoke: invokeDisclaimerData } = useServiceState<
    DisclaimerResponse
  >(APIService.fundService.fetchDisclaimer);

  const COLUMNS: ColumnProps<FirmFundDetail>[] = [
    {
      title: 'Fund Name',
      key: 'fund_name',
      dataIndex: 'fund_name',
      render: (fundName: string, record: FirmFundDetail) => (
        <FundName hasPermission={record.has_permission}>{fundName}</FundName>
      ),
      sorter: (row1: FirmFundDetail, row2: FirmFundDetail) =>
        row1.fund_name.localeCompare(row2.fund_name),
      onCell: (record: FirmFundDetail) => ({
        onClick: () => {
          if (!record.has_permission) {
            return;
          }
          APIService.eventService.sendViewFundProfileEvent(record.fund_id);
          addPane({
            title: record.fund_name,
            key: `${record.fund_id}-fund`,
            type: 'fund',
            fundFirmId: record.fund_id,
          });
        },
      }),
    },
    {
      title: 'Fund Assets',
      key: 'fund_assets',
      dataIndex: 'fund_assets',
      render: (assets: string, row: FirmFundDetail) => {
        return row.fund_assets_rep_date && row.fund_assets_currency && row.fund_assets
          ? `${row.fund_assets_currency} ${GenUtil.getformattedNumber(assets)} M (as of ${
              row.fund_assets_rep_date
            })`
          : '-';
      },
    },
    {
      title: 'Strategy Name',
      key: 'strategy_name',
      dataIndex: 'strategy_name',
    },
    {
      title: 'Sub Strategy Name',
      key: 'sub_strategy_name',
      dataIndex: 'sub_strategy_name',
    },
    {
      title: 'Inception Date',
      key: 'inception_date',
      dataIndex: 'inception_date',
      render: (date: string) => GenUtil.getFormattedYearMonthAndDay(date),
    },
  ];

  useEffect(() => {
    invokeFirmDetails(firmId);
    invokeDisclaimerData('profile');
  }, [firmId, invokeFirmDetails, invokeDisclaimerData]);

  return (
    <>
      <Container>
        <Spin spinning={loadingFirm} tip="Loading...">
          <h2>{oc<FirmDetailResponse>(firmData).firm.firm_name('')}</h2>
          <FirmFundDescription
            description={FundDetailsUtils.getFirmDescription({
              ...firmData.firm,
              ...firmData.firm_contact,
            })}
          />
          <Collapse defaultActiveKey={[1]}>
            <Panel header="Managed Funds" key={1}>
              <Table
                columns={COLUMNS}
                dataSource={firmData.firm_funds}
                pagination={{ pageSize: 10 }}
              />
            </Panel>
          </Collapse>
          <DisclaimerContainer
            dangerouslySetInnerHTML={{
              __html: `<strong>DISCLAIMER:</strong> ${disclaimerData.content || ''}`,
            }}
          />
        </Spin>
      </Container>
    </>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addPane: (data: FundFirmPane) => {
      dispatch({ type: RootActionEnums.ADD_PANE, payload: data });
    },
  };
};

export default connect(null, mapDispatchToProps)(FirmDetailsView);
