import { Tabs } from 'antd';
import React from 'react';
import { RouterProps } from 'react-router';
import styled from 'styled-components';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { Colors } from '../../shared/colors';
import { InitialRootReducer } from '../../redux/reducers/root.reducer';
import APIService from '../../shared/api';
import PortfolioView from './PortfolioView';
import ModelActionEnums from './redux/model-action-enums';
import { PortfolioPane } from './redux/model-reducers';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 16px;
  margin-bottom: 0;
  overflow: hidden;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};

    .ant-tabs-tabpane-active {
      display: flex;
    }
  }
`;
const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 110px);
`;

interface DispatchProps {
  addPane: (key: string) => void;
  removePane: (paneId: string) => void;
  changePane: (paneId: string) => void;
}

interface StateProps {
  portfolioPanes: Array<PortfolioPane>;
  activeKey: string;
}

interface Props extends RouterProps {}

interface State {}

/**
 * The Model View Container
 * @param addPane - action dispatcher to add portfolio pane
 * @param removePane - action dispatcher to remove portfolio pane
 * @param changePane - action dispatcher to change active pane
 * @param panes - list of open portfolio panes
 * @param activeKey - key of current active pane
 */

const ModelView: React.FC<Props & DispatchProps & StateProps & State> = ({
  addPane,
  removePane,
  changePane,
  portfolioPanes,
  activeKey,
}) => {
  const onEdit = (targetKey: string | React.MouseEvent<HTMLElement>, action: string) => {
    action === 'add' ? addPane('NEW_PORTFOLIO_PANE') : removePane(targetKey as string);
  };
  const onChange = (targetKey: string) => {
    APIService.eventService.sendMyModelsAccessEvent();
    changePane(targetKey);
  };

  const renderPanes = () => {
    const stylesPanes = [];
    for (let index = 0; index < portfolioPanes.length; index++) {
      const pane: PortfolioPane = portfolioPanes[index];
      const key: string = `${pane.key}`;
      const tabName: string = pane.portfolioModelName || 'Portfolio Model';
      stylesPanes.push(
        <StyledTabPane tab={tabName} key={key}>
          <PortfolioView key={key} paneKey={`${pane.key}`} />
        </StyledTabPane>,
      );
    }
    return stylesPanes;
  };

  return (
    <StyledTabs activeKey={activeKey} type="editable-card" onEdit={onEdit} onChange={onChange}>
      {renderPanes()}
    </StyledTabs>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, InitialRootReducer> = (state: any) => {
  return {
    portfolioPanes: state.modelReducer.portfolioPanes,
    activeKey: state.modelReducer.portfolioActiveKey,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = dispatch => {
  return {
    addPane: (key: string) => {
      dispatch({ type: ModelActionEnums.ADD_PORTFOLIO_PANE, payload: { key } });
    },
    removePane: (key: string) => {
      dispatch({ type: ModelActionEnums.REMOVE_PORTFOLIO_PANE, payload: { key } });
    },
    changePane: (paneId: string) => {
      dispatch({ type: ModelActionEnums.CHANGE_PORTFOLIO_PANE, payload: paneId });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelView);
