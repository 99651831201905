import { Select } from 'antd';
import React from 'react';
import { FundListRecord, PortfolioModelRecord } from '../api/models/ResponsesTypes';

export enum UniverseSelectTypeEnum {
  HFR = 'HFR',
  LIST = 'LIST',
  PORTFOLIO = 'MODEL',
  PEER_GROUP = 'PEER',
}

interface Props {
  onChange?: (selected: { key: string; label: string }) => void;
  data?: FundListRecord[];
  models?: PortfolioModelRecord[];
  selectedId?: string;
  selectedType?: UniverseSelectTypeEnum;
}

const { Option } = Select;

const UniverseSelect: React.FC<Props> = ({ data, models, onChange, selectedId, selectedType }) => {
  const selectedList =
    (data && data.find(item => item.id === selectedId)) ||
    (models && models.find(item => item.id === +String(selectedId).split('-')[0]));
  return (
    <Select
      labelInValue
      placeholder="Select Universe"
      style={{ width: '100%' }}
      showSearch
      value={
        selectedId && data
          ? {
              key: `${selectedType}-${selectedId}`,
              label: selectedList ? selectedList.name : '',
            }
          : undefined
      }
      onChange={onChange}
      filterOption={(input, option) => {
        if (!option || !option.props || !option.props.children) {
          return false;
        }
        return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {data &&
        data.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      {models &&
        models.map(item => (
          <Option
            key={`${item.id}-${UniverseSelectTypeEnum.PORTFOLIO}`}
            value={`${item.id}-${UniverseSelectTypeEnum.PORTFOLIO}`}
          >
            {item.name}
          </Option>
        ))}
    </Select>
  );
};

export default UniverseSelect;
