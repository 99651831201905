import React from 'react';
import { MapStateToProps, connect } from 'react-redux';
import { useServiceState } from '../../shared/hooks/useServiceState';
import APIService from '../../shared/api';
import { RankingParams } from '../../shared/api/services/ranking.service';
import { CombinedReducers } from '../..';
import { FundDetailStrategy } from '../../shared/api/models/FundDetails';
import { IndexSelections } from './IndexSelectInput';
import IndexTable from './IndexTable';
import RankingLayout from './RankingLayout';

type Props = {};
type StateProps = {
  strategyList: FundDetailStrategy[];
};

const IndexRanking: React.FC<Props & StateProps> = () => {
  const [params, updateParams] = React.useState<Partial<RankingParams>>({});
  const [pageSize, setPageSize] = React.useState(10);

  const [firstParam, setFirstParam] = React.useState({ in_HFRI: true });
  const [secondParam, setSecondParam] = React.useState({ is_hfrx_fund: true });
  const [thirdParam, setThirdParam] = React.useState({
    is_hfru_fund: true,
  });
  const [fourthParam, setFourthParam] = React.useState({
    is_hfr_risk_parity_fund: true,
  });

  const {
    data: firstIndexData,
    loading: firstIndexLoading,
    invoke: fetchFirstIndex,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: secondIndexData,
    loading: secondIndexLoading,
    invoke: fetchSecondIndex,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: thirdIndexData,
    loading: thirdIndexLoading,
    invoke: fetchThirdIndex,
  } = useServiceState(APIService.rankingService.ranking);
  const {
    data: fourthIndexData,
    loading: fourthIndexLoading,
    invoke: fetchFourthIndex,
  } = useServiceState(APIService.rankingService.ranking);

  // Fetching effects
  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchFirstIndex({ ...params, ...firstParam, limit: +pageSize });
  }, [params, firstParam, fetchFirstIndex, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchSecondIndex({ ...params, ...secondParam, limit: +pageSize });
  }, [params, secondParam, fetchSecondIndex, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchThirdIndex({ ...params, ...thirdParam, limit: +pageSize });
  }, [params, thirdParam, fetchThirdIndex, pageSize]);

  React.useEffect(() => {
    if (!params || (!params.month && !params.year)) {
      return;
    }
    fetchFourthIndex({ ...params, ...fourthParam, limit: +pageSize });
  }, [params, fourthParam, fetchFourthIndex, pageSize]);

  // handlers
  const handleSelectParam = (value: IndexSelections, setParam: (param: any) => void) => {
    let param;
    switch (value) {
      case IndexSelections.HFRI:
        param = { in_HFRI: true };
        break;
      case IndexSelections.HFRX:
        param = { is_hfrx_fund: true };
        break;
      case IndexSelections.HFRU:
        param = { is_hfru_fund: true };
        break;
      case IndexSelections.HFRP:
        param = { is_hfr_risk_parity_fund: true };
        break;
      case IndexSelections.HFRB:
        param = { is_hfr_blockchain_fund: true };
        break;
    }
    setParam(param);
  };

  return (
    <RankingLayout
      params={params}
      updateParams={updateParams}
      pageSize={pageSize}
      setPageSize={setPageSize}
    >
      <IndexTable
        onSelectIndexFamily={indexFamily => handleSelectParam(indexFamily, setFirstParam)}
        defaultIndex={IndexSelections.HFRI}
        dataSource={firstIndexData.length ? firstIndexData : []}
        loading={firstIndexLoading}
      />
      <IndexTable
        defaultIndex={IndexSelections.HFRX}
        onSelectIndexFamily={indexFamily => handleSelectParam(indexFamily, setSecondParam)}
        dataSource={secondIndexData.length ? secondIndexData : []}
        loading={secondIndexLoading}
      />

      <IndexTable
        defaultIndex={IndexSelections.HFRU}
        onSelectIndexFamily={indexFamily => handleSelectParam(indexFamily, setThirdParam)}
        dataSource={thirdIndexData.length ? thirdIndexData : []}
        loading={thirdIndexLoading}
      />

      <IndexTable
        defaultIndex={IndexSelections.HFRP}
        onSelectIndexFamily={indexFamily => handleSelectParam(indexFamily, setFourthParam)}
        dataSource={fourthIndexData.length ? fourthIndexData : []}
        loading={fourthIndexLoading}
      />
    </RankingLayout>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  strategyList: state.searchReducer.strategyOptions,
});

export default connect(mapStateToProps)(IndexRanking);
