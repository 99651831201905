import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { connect, MapDispatchToPropsFunction, MapStateToProps } from 'react-redux';
import { ExtendFundListRecord } from '../../shared/api/models/ResponsesTypes';
import SearchAction from '../search/search-action';
import { CombinedReducers } from '../..';
import { Colors } from '../../shared/colors';
import { UniverseSelectTypeEnum } from '../../shared/components/UniverseSelect';
import UniverseFundListTab from './components/UniverseFundListTab';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  margin: 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: visible;
  .ant-tabs-content {
    margin-top: -16px;
    background: white;
    border-left: solid 1px ${Colors.border};
    border-right: solid 1px ${Colors.border};
    border-bottom: solid 1px ${Colors.border};
  }
`;

const StyledTabPane = styled(TabPane)`
  flex-grow: 1;
  height: calc(100vh - 124px);
`;

interface Props {}

interface StateProps {
  data?: ExtendFundListRecord[];
}

interface DispatchProps {
  fetchFundList: () => void;
  fetchUniverseList: () => void;
}

/**
 * Universe Management Page
 */
const UniverseView: React.FC<Props & StateProps & DispatchProps> = ({
  data: fundList,
  fetchFundList,
  fetchUniverseList,
}) => {
  const refetchTable = () => {
    fetchFundList();
    fetchUniverseList();
  };
  return (
    <StyledTabs hideAdd type="editable-card">
      {[UniverseSelectTypeEnum.HFR, UniverseSelectTypeEnum.LIST].map((listType: string) => (
        <StyledTabPane
          tab={listType === UniverseSelectTypeEnum.HFR ? 'Universe List' : 'My List'}
          key={listType}
          closable={false}
        >
          <UniverseFundListTab
            listType={listType}
            listData={fundList ? fundList.filter(list => list.type === listType) : []}
            refetchTable={refetchTable}
          />
        </StyledTabPane>
      ))}
    </StyledTabs>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, CombinedReducers> = (
  state: CombinedReducers,
) => ({
  data: [
    ...state.rootReducer.universeList.map(universeItem => ({
      ...universeItem,
      id: universeItem.id,
      type: UniverseSelectTypeEnum.HFR,
    })),
    ...state.rootReducer.fundList.map(fundItem => ({
      ...fundItem,
      id: fundItem.id,
      type: UniverseSelectTypeEnum.LIST,
    })),
  ],
});

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = dispatch => {
  return {
    fetchFundList: () => SearchAction.fetchFundList()(dispatch),
    fetchUniverseList: () => SearchAction.fetchUniverseList()(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UniverseView);
