import { oc } from 'ts-optchain';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { AUMRecord, KeyValueRecord, RORRecord } from '../../shared/api/models/ResponsesTypes';
import GenUtil from '../../shared/utils/gen-util';
import {
  FirmDetails,
  FirmFundDescriptionRecord,
  FundDetailFirmContact,
  FundDetails,
} from '../../shared/api/models/FundDetails';
import RenderTableCell from './components/RenderTableCell';

interface FundStatsTableData {
  key: string;
  category: string;
  b1: number | undefined;
  b2: number | undefined;
  b3: number | undefined;
  fund: number | undefined;
  fund_si: number | undefined;
}

interface RORAndAUMTableData {
  [key: string]: number | string | undefined;
}

export default class FundDetailsUtils {
  /**
   *
   * @param b1
   * @param b2
   * @param b3
   * @param fund
   */
  static getFundStatsTableData = (
    b1: { [key: string]: number },
    b2: { [key: string]: number },
    b3: { [key: string]: number },
    fund: { [key: string]: number },
    fund_si: { [key: string]: number },
  ): {
    header: string;
    tableData: FundStatsTableData[];
    columns: ColumnProps<FundStatsTableData>[];
  } => {
    const fundStatsTableData: FundStatsTableData[] = [];
    const fundStatsTableOrderedKeys: Array<{ key: string; value: string }> = [
      { key: 'geo_avg_mo_ror', value: 'Geo Avg Mo ROR' },
      { key: 'std_dev', value: 'Std Dev' },
      { key: 'high_mo_ror', value: 'High Mo ROR' },
      { key: 'low_mo_ror', value: 'Low Mo ROR' },
      { key: 'ann_ror', value: 'Ann ROR' },
      { key: 'ann_stdev', value: 'Ann Stdev' },
      { key: 'risk_free_rate', value: 'Risk Free Rate' },
      { key: 'sharpe_ratio', value: 'Sharpe Ratio' },
      { key: 'winning_mo', value: 'Winning Mo (%)' },
      { key: 'max_drawdown', value: 'Max Drawdown' },
      { key: '1_month_ror', value: '1 Month ROR' },
      { key: '3_month_ror', value: '3 Month ROR' },
      { key: '6_month_ror', value: '6 Month ROR' },
      { key: '1_year_ann_ror', value: '1 Year Ann ROR' },
      { key: '2_year_ann_ror', value: '2 Year Ann ROR' },
      { key: '3_year_ann_ror', value: '3 Year Ann ROR' },
      { key: '5_year_ann_ror', value: '5 Year Ann ROR' },
      { key: '7_year_ann_ror', value: '7 Year Ann ROR' },
      { key: '10_year_ann_ror', value: '10 Year Ann ROR' },
      { key: 'since_inception_ann_ror', value: 'Since Inception Ann ROR' },
      { key: 'alpha', value: 'Alpha' },
      { key: 'beta', value: 'Beta' },
      { key: 'r_square', value: 'R-Squared' },
      { key: 'correlation', value: 'Correlation' },
      { key: 'up_alpha', value: 'Up Alpha' },
      { key: 'up_beta', value: 'Up Beta' },
      { key: 'up_r_square', value: 'Up R-Squared' },
      { key: 'down_alpha', value: 'Down Alpha' },
      { key: 'down_beta', value: 'Down Beta' },
      { key: 'down_r_square', value: 'Down R-Squared' },
    ];
    if (b1 && b2 && b3 && fund) {
      fundStatsTableOrderedKeys.forEach((tableCellObj: { key: string; value: string }) => {
        const { key, value } = tableCellObj;
        fundStatsTableData.push({
          key: key,
          category: value,
          b1: b1[key],
          b2: b2[key],
          b3: b3[key],
          fund: fund[key],
          fund_si: fund_si ? fund_si[key] : undefined,
        });
      });
    }

    const fundStatsTableColumns: ColumnProps<FundStatsTableData>[] = [
      { title: 'Category', dataIndex: 'category', key: 'category' },
      {
        title: 'Fund *',
        dataIndex: 'fund',
        key: 'fund',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B1 *',
        dataIndex: 'b1',
        key: 'b1',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B2 *',
        dataIndex: 'b2',
        key: 'b2',
        align: 'right',
        render: RenderTableCell,
      },
      {
        title: 'B3 *',
        dataIndex: 'b3',
        key: 'b3',
        align: 'right',
        render: RenderTableCell,
      },
    ];

    return {
      header: 'Fund Stats',
      tableData: fundStatsTableData,
      columns: fund_si
        ? [
            ...fundStatsTableColumns.slice(0, 1),
            {
              title: 'Fund SI **',
              dataIndex: 'fund_si',
              key: 'fund_si',
              align: 'right',
              render: RenderTableCell,
            },
            ...fundStatsTableColumns.slice(1, fundStatsTableColumns.length),
          ]
        : fundStatsTableColumns,
    };
  };

  // takes two argument , one for months and other(optional) for 'total' column
  static getFormattedTableDataForRORAndAUM = (
    fetchedData: AUMRecord[] | RORRecord[],
    fetchedAnnualData: KeyValueRecord = {} as KeyValueRecord,
  ): Array<RORAndAUMTableData> => {
    let tableData: { [key: string]: KeyValueRecord } = {};
    fetchedData.forEach((ele: RORRecord | AUMRecord) => {
      if (tableData[ele.year]) {
        tableData[ele.year][ele.month] = GenUtil.digitWithMaxPrecision(ele.cur_value);
      } else {
        tableData[ele.year] = { [ele.month]: GenUtil.digitWithMaxPrecision(ele.cur_value) };
      }
    });

    let years = Object.keys(tableData).sort();
    return years.map(year => {
      return {
        key: year,
        year,
        total: fetchedAnnualData[year],
        ...tableData[year],
      };
    });
  };

  /**
   * Returns a range of YYYY-MM between fromDate and toDate
   * @param fromDate
   * @param toDate
   */
  static getDatesRangeList = (fromDate: string, toDate: string): string[] => {
    const datesRangesArr: string[] = [];
    let [fy, fm] = fromDate.split('-');
    let [ty, tm] = toDate.split('-');
    const [fromMonth, fromYear, toMonth, toYear] = [Number(fm), Number(fy), Number(tm), Number(ty)];
    for (let i = fromMonth; i <= 12; i++) {
      datesRangesArr.push(`${fromYear}-${i <= 9 ? '0' + i : i}`);
    }
    for (let y = fromYear + 1; y < toYear; y++) {
      for (let m = 1; m <= 12; m++) {
        datesRangesArr.push(`${y}-${m <= 9 ? '0' + m : m}`);
      }
    }
    for (let i = 1; i <= toMonth; i++) {
      datesRangesArr.push(`${toYear}-${i <= 9 ? '0' + i : i}`);
    }
    return datesRangesArr;
  };

  static _getDatesRangeList = (
    fromDate: string,
    toDate: string,
    type: 'year' | 'month' = 'month',
  ): string[] => {
    const formatType = type === 'year' ? 'YYYY' : 'YYYY-MM';
    const startDate = moment(fromDate, formatType);
    const endDate = moment(toDate, formatType);
    const interim = startDate.clone();

    const datesRangeArr = [];

    while (
      endDate > interim ||
      interim.format(type === 'year' ? 'YYYY' : 'MM') ===
        endDate.format(type === 'year' ? 'YYYY' : 'MM')
    ) {
      datesRangeArr.push(interim.format(formatType));
      interim.add(1, type);
    }

    return datesRangeArr;
  };

  static fillBooleanField = (value: boolean | undefined) => {
    if (value === undefined) {
      return '-';
    } else if (value) {
      return 'Yes';
    } else {
      return 'No';
    }
  };
  /**
   * Returns the data for Header-Columns in firm-profile page
   * @param firmData
   */
  static getFirmDescription = (
    firmData: FirmDetails & FundDetailFirmContact,
  ): Array<FirmFundDescriptionRecord[]> => {
    return [
      [
        {
          key: 'Firm ID',
          value: oc(firmData).firm_id('-'),
          show: true,
        },
        {
          key: 'Principals',
          value: oc(firmData).principals('-'),
          show: true,
        },
        {
          key: 'Firm Assets',
          value:
            oc(firmData).firm_assets_date(undefined) &&
            oc(firmData).firm_assets_currency(undefined) &&
            oc(firmData).firm_assets(undefined) != null
              ? `${firmData.firm_assets_currency} ${GenUtil.getformattedNumber(
                  firmData.firm_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(firmData.firm_assets_date)})`
              : '-',
          show: true,
        },
        {
          key: 'IARD CRD Number',
          value: oc(firmData).iard_crd_number('-'),
          show: true,
        },
        {
          key: 'Sec Number',
          value: oc(firmData).sec_number('-'),
          show: true,
        },
        {
          key: 'Sec Registered',
          value: FundDetailsUtils.fillBooleanField(oc(firmData).sec_registered(undefined)),
          show: true,
        },
        {
          key: 'Is Diversity Firm',
          value: FundDetailsUtils.fillBooleanField(oc(firmData).is_diversity_firm(undefined)),
          show: true,
        },
      ],
      [
        {
          key: 'Address',
          value: [
            oc(firmData).address(' '),
            oc(firmData).city(' '),
            oc(firmData).state('') + oc(firmData).postal_code(' '),
            oc(firmData).country(' '),
          ],
          show: true,
        },
        {
          key: 'Contact',
          value: oc(firmData).first_contact('-'),
          show:
            oc(firmData)
              .second_contact('')
              .trim() === '',
        },
        {
          key: 'First Contact',
          value: oc(firmData).first_contact('-'),
          show:
            oc(firmData)
              .second_contact('')
              .trim() !== '',
        },
        {
          key: 'Second Contact',
          value: oc(firmData).second_contact(''),
          show:
            oc(firmData)
              .second_contact('')
              .trim() !== '',
        },
        {
          key: 'Phone',
          value: oc(firmData).phone('-'),
          show: true,
        },
        {
          key: 'Email',
          value: oc(firmData).email(''),
          show: true,
        },
        // {
        //   key: 'Fax',
        //   value: oc(firmData).facsimile('-'),
        //   show: true,
        // },
      ],
    ];
  };
  /**
   * Returns the data for Header-Columns in fund-profile page
   * @param fundData
   */
  static getFundDescription = (fundData: FundDetails): Array<FirmFundDescriptionRecord[]> => {
    return [
      [
        {
          key: 'Manager',
          value: oc(fundData).firm.firm_name('-'),
          show: true,
        },
        {
          key: 'Strategy',
          value: oc(fundData).strategy.strategy_name('-'),
          show: true,
        },
        {
          key: 'Sub-Strategy',
          value: oc(fundData).sub_strategy.sub_strategy_name('-'),
          show: true,
        },
        {
          key: 'Regional Focus ',
          value: oc(fundData).regional_focus('-'),
          show: true,
        },
        {
          key: 'Inception',
          value: oc(fundData).fund_info.inception_date(undefined)
            ? GenUtil.getFormattedYearMonthAndDay(fundData.fund_info.inception_date)
            : '-',
          show: true,
        },
        {
          key: 'Mgmt Fees %',
          value: oc(fundData).fund_fees.mgmt_fee('-'),
          show: true,
        },
        {
          key: 'Max 12B1 Fee %',
          value: oc(fundData).fund_fees.max_12b1_fee('-'),
          show: oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Max Front Fee %',
          value: oc(fundData).fund_fees.max_front_fee('-'),
          show: oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Max Deferred Fee %',
          value: oc(fundData).fund_fees.max_deferred_fee('-'),
          show: oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Incentive Fees %',
          value: oc(fundData).fund_fees.incentive_fee('-'),
          show: !oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'UCITS Compliant',
          value: FundDetailsUtils.fillBooleanField(oc(fundData).ucits_compliant(undefined)),
          show: true,
        },
        {
          key: 'Liquid Alt Product',
          value: FundDetailsUtils.fillBooleanField(
            oc(fundData).fund_info.liquid_alt_product(undefined),
          ),
          show: true,
        },
      ],
      [
        {
          key: 'Address',
          value: [
            oc(fundData).firm_contact.address(' '),
            oc(fundData).firm_contact.city(' '),
            (
              oc(fundData).firm_contact.state('') +
              ' ' +
              oc(fundData).firm_contact.postal_code(' ')
            ).trim(),
            oc(fundData).firm_contact.country(' '),
          ],
          show: true,
        },
        {
          key: 'Contact',
          value: oc(fundData).firm_contact.first_contact('-'),
          show:
            oc(fundData)
              .firm_contact.second_contact('')
              .trim() === '',
        },
        {
          key: 'First Contact',
          value: oc(fundData).firm_contact.first_contact('-'),
          show:
            oc(fundData)
              .firm_contact.second_contact('')
              .trim() !== '',
        },
        {
          key: 'Second Contact',
          value: oc(fundData).firm_contact.second_contact(''),
          show:
            oc(fundData)
              .firm_contact.second_contact('')
              .trim() !== '',
        },
        {
          key: 'Phone',
          value: oc(fundData).firm_contact.phone('-'),
          show: true,
        },
        {
          key: 'Email',
          value: oc(fundData).firm_contact.email(''),
          show: true,
        },
        // {
        //   key: 'Fax',
        //   value: oc(fundData).firm_contact.facsimile('-'),
        //   show: true,
        // },
        {
          key: 'High Watermark',
          value: FundDetailsUtils.fillBooleanField(
            oc(fundData).fund_fees.high_watermark(undefined),
          ),
          show: true,
        },
        {
          key: 'Hurdle Rate',
          value: oc(fundData).fund_fees.hurdle_rate('No'),
          show: true,
        },
        {
          key: 'Leverage',
          value: oc(fundData).leverage('-'),
          show: true,
        },
      ],
      [
        {
          key: 'Product Class',
          value: oc(fundData).fund_info.product_class(undefined)
            ? `Class ${fundData.fund_info.product_class}`
            : '-',
          show: true,
        },
        {
          key: 'Registration',
          value: oc(fundData).liquid_alt_region('-'),
          show: oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Liquid Alt Type',
          value: oc(fundData).liquid_alt_type('-'),
          show: oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Domicile',
          value: oc(fundData).domicile('-'),
          show: !oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Structure',
          value: oc(fundData).structure('-'),
          show: !oc(fundData).fund_info.liquid_alt_product(false),
        },
        {
          key: 'Fund Assets',
          value:
            fundData.fund_assets_rep_date &&
            fundData.fund_assets_currency &&
            fundData.fund_assets != null
              ? `${fundData.fund_assets_currency} ${GenUtil.getformattedNumber(
                  fundData.fund_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(fundData.fund_assets_rep_date)})`
              : '-',
          show: true,
        },
        {
          key: 'Firm Assets',
          value:
            oc(fundData).firm.firm_assets_date(undefined) &&
            oc(fundData).firm.firm_assets_currency(undefined) &&
            oc(fundData).firm.firm_assets(undefined) != null
              ? `${fundData.firm.firm_assets_currency} ${GenUtil.getformattedNumber(
                  fundData.firm.firm_assets,
                )} M (as of ${GenUtil.getFormattedYearAndMonth(fundData.firm.firm_assets_date)})`
              : '-',
          show: true,
        },
        {
          key: 'Min. Investment',
          value:
            oc(fundData).fund_info.minimum_investment(undefined) != null
              ? `${fundData.min_investment_curr} ${GenUtil.getformattedNumber(
                  fundData.fund_info.minimum_investment,
                )}`
              : '-',
          show: true,
        },
        {
          key: 'Subscriptions',
          value: oc(fundData).subscriptions('-'),
          show: true,
        },
        {
          key: 'Redemptions',
          value: oc(fundData).redemptions('-'),
          show: true,
        },
        {
          key: 'Notice',
          value: oc(fundData).fund_info.advance_notice(undefined)
            ? `${fundData.fund_info.advance_notice} Days`
            : '-',
          show: true,
        },
        {
          key: 'Lockup',
          value: oc(fundData).fund_info.lockup(undefined)
            ? `${fundData.fund_info.lockup} Months`
            : 'No',
          show: true,
        },
      ],
    ];
  };
}
